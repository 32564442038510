import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import {errorMessageStyles} from "../../../jss/gate/Auth/authStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(errorMessageStyles);

/**
 * @return {null}
 */
function ErrorMessage(props) {
  const classes = useStyles();
  const {message} = props;

  return message ? (
    <Typography component="h4" color="error" className={classes.root}>
      {message}
    </Typography>
  ) : null
}

ErrorMessage.propTypes = {
  //data
  message: PropTypes.string,

};

export default ErrorMessage;