import React from "react";
import Collector from "../../../types/model/infrastructure/Collector";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

interface BackendCollectorsTableProps {
    rows: Collector[];
    startAction: (targetId: number) => void;
    stopAction: (targetId: number) => void;
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        // maxHeight: 600,
    },
});

const BackendCollectorsTable: React.FC<BackendCollectorsTableProps> = ({
                                                                           rows,
                                                                           startAction,
                                                                           stopAction
                                                                       }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell>Target</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: Collector) => {
                            return (
                                <TableRow hover key={row.id}>
                                    <TableCell>{row.status}</TableCell>
                                    <TableCell>{row.targetName}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            disabled={row.status === "DOWN"}
                                            onClick={() => stopAction(row.id)}
                                        >
                                            <PauseIcon fontSize="small"/>
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            disabled={!(row.status === "DOWN")}
                                            onClick={() => startAction(row.id)}
                                        >
                                            <PlayArrowIcon fontSize="small"/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default BackendCollectorsTable;
