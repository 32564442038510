export const WEB_SOCKET_ACTION_PREFIX = '/app';
export const WEB_SOCKET_SUBSCR_PREFIX = '/user/topic';
export const WEB_SOCKET_SUBSCR_GROUP_PREFIX = '/topic';
export const BACKEND_KEY_HEADER_NAME = 'backendKey';
export const USER_SESSION_ID_HEADER_NAME = "userSessionId";
export const WEBSOCKET_USER_CLIENT_HEADER = {clientType: "user"};

/* Development authentication header */
export const DEV_AUTH_HEADER = {
    login: "upbeat.test@enteros.com",
    passcode: "enteros2019",
}