import * as integrationWebSocketClient from "api/websocket/hlc/integrationWebSocketClient";
import ListIntegrationsStompResponse from "types/payload/settings/integrations/ListIntegrationsStompResponse";
import {setIntegration, setIntegrations, setIntegrationsLoading} from "reducers/settingsSlice";
import Integration from "types/model/settings/integration/Integration";
import SaveIntegrationStompResponse from "../../types/payload/settings/integrations/SaveIntegrationStompResponse";

export const listIntegrations = (backendKey: string) => (dispatch: any, getState: any) => {
    dispatch(setIntegrationsLoading(true));
    integrationWebSocketClient.listIntegrations(getState(), backendKey);
}

export const handleListIntegrations = (response: ListIntegrationsStompResponse) => (dispatch: any) => {
    const {hasErrors, errorMessage, integrations} = response;
    if (!hasErrors) {
        dispatch(setIntegrations(integrations));
    } else {
        // todo: handle error
        console.error(errorMessage);
        // dispatch(setIntegrations([]));
    }
    dispatch(setIntegrationsLoading(false));
};

export const saveIntegration = (backendKey: string, integration: Integration) =>
    (dispatch: any, getState: any) => {
        dispatch(setIntegrationsLoading(true));
        integrationWebSocketClient.saveIntegration(getState(), backendKey, {
            integration
        });
    };

export const handleSaveIntegrationSettings = (response: SaveIntegrationStompResponse) =>
    (dispatch: any, getState: any) => {
        const {hasErrors, errorMessage, integration} = response;
        if (!hasErrors) {
            dispatch(setIntegration(integration))
        } else {
            // todo: handle error
            console.error(errorMessage);
        }
        dispatch(setIntegrationsLoading(false));
    };
