import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ReactHighcharts from 'react-highcharts/ReactHighstock';
import HighchartsMap from 'highcharts-map';
import regression from 'highcharts-regression';
import Boost from 'react-highcharts/node_modules/highcharts/modules/boost';
import Exporting from 'react-highcharts/node_modules/highcharts/modules/exporting';
import * as _ from "lodash";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "components/common/DialogTitle/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import {Scrollbars} from 'react-custom-scrollbars';
import uuid from 'uuid';
import { Tooltip } from '@material-ui/core';
import Chart from "@material-ui/icons/Assessment";
import { handleLoadChart } from '../actions';
Boost(ReactHighcharts.Highcharts);
Exporting(ReactHighcharts.Highcharts);
// HighchartsCustomEvents(ReactHighcharts.Highcharts);
regression(ReactHighcharts.Highcharts);
HighchartsMap(ReactHighcharts.Highcharts);

const styles = theme => ({
    root: {
        maxWidth: '800px',
    },
    dialogContent: {
        padding: '0',
    },
    actionButtonLabel: {
        fontWeight: 600,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    resetZoomButton: {
        width: '30px',
        height: '30px',
        left: '45%',
        zIndex: '99',
    }
});

function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

class ChartsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fullScreen: false,
            fullWidth: false,
        };
    }

    render() {
        const {classes, handleClose, isLocked, templateName, handleResetZoom, handleLoadSelectedChart, fullScreen} = this.props;

        const handleMaximizeClick = () => {
            if (this.state.fullScreen) {
                this.setState({ fullScreen: false, fullWidth: false });
            } else {
                this.setState({ fullScreen: true, fullWidth: true });
            }
        }

        let charts2 = this.props.configList;
        if (charts2.length==1) {
            const dialogElement = document.getElementsByClassName('chartDialog'); // Change to your dialog class name
            let dialogHeight=0;
            if (dialogElement.length > 0) {
                dialogHeight = dialogElement[0].childNodes[0].clientHeight;
            }            
            charts2 = _.map(this.props.configList, (chartConfig) => {
            return  {
            ...chartConfig,
            chart: {
                ...chartConfig.chart,
                height: this.state.fullScreen ? dialogHeight - 170 : '62.5%',
            }
            }})
        }

        return (
            <Dialog
                className={"chartDialog"}                
                open={true}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="false"
                fullScreen={this.state.fullScreen}
                fullWidth={this.state.fullWidth}
            >
                <DialogTitle id="draggable-dialog-title" onClose={handleClose} onMaximize={handleMaximizeClick} 
                                onResetZoom={handleResetZoom}fullScreen={fullScreen}
                                onLoadSelectedChart={handleLoadSelectedChart} style={{ cursor: 'move' }}>
                 {templateName}
                </DialogTitle>
                <Backdrop className={classes.backdrop} open={isLocked}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Scrollbars style={{ width: this.state.fullScreen ? 'auto' : '100vh', height: '100vh', marginTop: 10, marginRight: 4, maxHeight: this.state.fullScreen ? 'fit-content': '60vh' }}>
                <DialogContent className={classes.dialogContent} style={{padding: 'unset'}}>
                {/* style={{ height: '100vh', width: '100vw' }} */}
                        { charts2.map((config) => {
                            const key = uuid.v4();
                            return (
                                <div key={`chart_container_${config.sectionId}`}
                                     style={{
                                         height: '100%',
                                         width: '100%',
                                         cursor: 'default',
                                         display: 'flex',
                                         justifyContent: 'center',
                                        //  alignItems: 'center',
                                }}>
                                <div style={{ height: '100%', width: '100%' }}>
                                    <ReactHighcharts key={key} config={config} ref={`chart_${config.sectionId}`}></ReactHighcharts>
                                </div>
                            </div>
                            )
                        })}
                        
                    </DialogContent>
                </Scrollbars>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" classes={{
                        label: classes.actionButtonLabel
                    }}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ChartsContainer.propTypes = {
    //data
    chartConfigList: PropTypes.array,
    templateName: PropTypes.array,

    //func
    handleClose: PropTypes.func,
    handleResetZoom: PropTypes.func,
    handleLoadSelectedChart: PropTypes.func,
};

export default withStyles(styles)(ChartsContainer);