import withStyles from "@material-ui/core/styles/withStyles";
import React, {Component} from "react";
import MuiSelect from '@material-ui/core/Select';

const selectStyles = theme => ({
  root: {

  },
  select: {
    padding: '.4rem .5rem',
    border: '1px solid #aab7b8',
    borderRadius: '2px',
  },
  selectMenu: {

  },
});

class Select extends Component {

  render() {
    const {classes, ...other} = this.props;

    return (
        <MuiSelect
            classes={classes}
            disableUnderline
            fullWidth
            {...other}
        />
    );
  }
}

export default withStyles(selectStyles)(Select)