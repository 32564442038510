import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Input} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "components/common/DialogTitle/DialogTitle";

const styles = theme => ({
  root: {
    maxWidth: '700px',
  },
  dialogContent: {
    width: '400px',
  },
  actionButtonLabel: {
    fontWeight: 600,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
});


class EditDashboardGroup extends Component {
  render() {
    const {classes, open, handleClose, handleConfirm, isLocked, errors, objectData, handleChangeInput} = this.props;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" onClose={handleClose}>
            Edit Dashboard Group
          </DialogTitle>

          <Backdrop className={classes.backdrop} open={isLocked}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <DialogContent className={classes.dialogContent}>

            <FormControl key="dash-group-name" fullWidth required error={errors.name} margin="dense">
              <InputLabel id="name-label" htmlFor="dash-grp-name">Group Name</InputLabel>
              <Input
                  id="dash-grp-name"
                  name="name"
                  type="text"
                  value={objectData.name}
                  onChange={handleChangeInput}
              />
            </FormControl>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary" classes={{
              label: classes.actionButtonLabel
            }}>
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" classes={{
              label: classes.actionButtonLabel
            }}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

EditDashboardGroup.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  objectData: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isLocked: PropTypes.bool,
  open: PropTypes.bool,

  //func
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  handleChangeInput: PropTypes.func,
};

export default withStyles(styles)(EditDashboardGroup);