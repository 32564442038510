import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import StartPageLeftNavBar from "views/gate/Start/StartPageLeftNavBar";
import {routeToDashboard, setStartApplication} from "../../../reducers/uiSlice";


class StartPageLeftNavBarContainer extends Component {
  render() {
    return (
        <StartPageLeftNavBar
            {...this.props}
        />
    );
  }
}

StartPageLeftNavBarContainer.propTypes = {
  //data
  isLeftMenuOpened: PropTypes.bool,
  startPageApplication: PropTypes.string,

  //func
  setLeftMenuOpened: PropTypes.func,
  changeStartApplication: PropTypes.func,
  routeToDashboard: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    startPageApplication: state.ui.startPageApplication,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeStartApplication: (appKey) => {
      dispatch(setStartApplication(appKey));
    },
    routeToDashboard: () => {
      dispatch(routeToDashboard());
    },
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StartPageLeftNavBarContainer)