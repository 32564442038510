import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import SubmitButton from "components/common/Button/SubmitButton";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";
import JiraIntegration from "types/model/settings/integration/JiraIntegration";

interface JiraIntegrationProps {
    integration: JiraIntegration;
    onSave: (integration: JiraIntegration) => void;
}

const JiraIntegrationSection: React.FC<JiraIntegrationProps> = ({
                                                                                onSave,
                                                                                ...props
                                                                            }) => {
    const { integration, handleChangeProperty, handleEnabledToggle }
        = useIntegrationSectionState(props.integration);
    const {enabled, baseUrl, username, apiToken, projectKey}
        = integration as JiraIntegration;

    return (
        <>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="flex-start"
                style={{marginTop: "8px", marginBottom: "8px"}}
            >
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="enable">Enabled</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <Switch checked={enabled || false}
                                onChange={handleEnabledToggle}
                                name="enabled"
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="baseUrl">Base URL</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name="baseUrl"
                            fullWidth
                            textAlign="left"
                            value={baseUrl}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="username">Username</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name="username"
                            fullWidth
                            textAlign="left"
                            value={username}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="apiToken">API Token</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name="apiToken"
                            fullWidth
                            textAlign="left"
                            value={apiToken}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="projectKey">Project Key</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name="projectKey"
                            fullWidth
                            textAlign="left"
                            value={projectKey}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={7}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Grid item xs={1}>
                        {/*@ts-ignore*/}
                        <SubmitButton onClick={() => onSave(integration)}>Save</SubmitButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default JiraIntegrationSection;
