import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "@material-ui/core/styles/withStyles";
import SvgIcon from "@material-ui/core/SvgIcon";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, {Component} from "react";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import RefreshIcon from "@material-ui/icons/Refresh";
import SettingsIcon from '@material-ui/icons/Settings';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';

const dropdownMenuItemStyles = theme => ({
  root: {
    "&:hover": {
      backgroundColor: '#fff',
    },
    "&:hover button, &:hover span, &:hover p": {
      color: '#ec7211',
      backgroundColor: '#fff',
    },
    padding: '4px 8px',
  },
  text: {
    color: theme.palette.text.secondary,
    fontSize: '13px',
  },

});

const ICONS = {
  add: AddIcon,
  edit: EditIcon,
  delete: DeleteIcon,
  refresh: RefreshIcon,
  settings: SettingsIcon,
  upload: PublishIcon,
  download: GetAppIcon
};


class DropdownMenuItem extends Component {


  render() {
    const {classes, anchorEl, icon, text, onClick, ...other} = this.props;
    const Icon = this.props.icon;

    return (
        <MenuItem className={classes.root} onClick={onClick} disableRipple disableTouchRipple  {...other}>
          <IconButton size="small" disableRipple disableTouchRipple >
            {this.renderIcon()}
          </IconButton>
          <Typography variant="body2" className={classes.text}>{text}</Typography>
        </MenuItem>
    );
  }

  renderIcon = () => {
    if (this.props.icon instanceof Object) {
      return this.props.icon;
    }

    const Icon = ICONS[this.props.icon];
    return Icon ? (<Icon fontSize="small"/>) : <SvgIcon />;
  }
}

DropdownMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(dropdownMenuItemStyles)(DropdownMenuItem)