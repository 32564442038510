import HLCChartDashletTimeRange from "dashlets/HLCChartDashlet/components/HLCChartDashletTimeRange";
import {newDashletTimeRangeSettings} from "objects/dashboardObjects";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as _ from "lodash";
import {updateDashletTimeRange} from "../../../actions/dashboard/dashletActions";

class HLCChartDashletTimeRangeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      object: props.object ? {...props.object} : this.getEmptyObject(),
      errors: {},
      isLocked: false,
    }
  }

  getEmptyObject = () => {
    return newDashletTimeRangeSettings();
  };

  getObject = () => {
    return this.state.object;
  };

  lockForm = () => {
    this.setState({isLocked: true});
  };

  unlockForm = () => {
    this.setState({isLocked: false});
  };

  handleSubmit = (event) => {
    this.lockForm();

    const errors = this.validate();

    if (!this.hasErrors(errors)) {
      //
      this.props.submit(this.props.dashletId, this.getObject());
      this.props.close();
      //
    } else {
      this.setErrors(errors);
      this.unlockForm();
    }
  };

  hasErrors = (errors) => {
    return _.keys(errors).length > 0;
  };

  setErrors = (errors) => {
    this.setState({errors});
  };

  handleCancel = (event) => {
    this.props.close();
  };

  validate = () => {
    const errors = {};

    const object = this.getObject();
    //todo: implement me!

    return errors;
  };

  handleChange = (name, value) => {
    const object = this.getObject();
    object[name] = value;

    this.setState({object});
  };

  render() {
    const {object, errors, isLocked} = this.state;

    return <HLCChartDashletTimeRange
        timeRangeSettings={object}
        errors={errors}
        isLocked={isLocked}
        handleChangeProperty={this.handleChange}
        handleConfirm={this.handleSubmit}
        handleClose={this.handleCancel}
    />
  }
}

HLCChartDashletTimeRangeContainer.propTypes = {
  //data
  dashletId: PropTypes.number,
  object: PropTypes.object,

  //func
  close: PropTypes.func,
  submit: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return {
    object: state.dashboard.dashboardTree.entities.dashlets[ownProps.dashletId].timeRange,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (dashletId, dashletTimeRange) => {
      dispatch(updateDashletTimeRange(dashletId, dashletTimeRange));
    },
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HLCChartDashletTimeRangeContainer)
