import * as _ from "lodash";

export const extendLayout = (layoutArray, dashlet) => {
  const newLayoutArray = _.slice(layoutArray);

  let maxY = _.max(_.map(newLayoutArray, (item) => {
    return item.y;
  }));

  const newLayoutArrayItem = {
    x: 0,
    y: maxY ? maxY + 1 : 0,
    i: dashlet.id.toString(),
    w: 12,
    h: 10,
    moved: false,
    static: false
  };

  newLayoutArray.push(newLayoutArrayItem);

  return newLayoutArray;
};

export const reduceLayout = (layoutArray, dashletId) => {
  return _.filter(layoutArray, (it) => {
    return Number(it.i) !== Number(dashletId);
  });
};