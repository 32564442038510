import {  Toolbar, Tooltip, TextField, InputLabel, Select, MenuItem, Card, CardContent, Menu } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Add, Settings, Sync } from "@material-ui/icons";
import ScheduleIcon from '@material-ui/icons/Schedule';
import React, { Component } from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import CheckIcon from '@material-ui/icons/Check';
import ReplayIcon from '@material-ui/icons/Replay';
import { ANOMALY_DASHBOARD } from "dashlets/AnomalyDetectionDashlet/constants";
import NumberInput from "components/NumberInput/NumberInput";
import TimeRangeMenu from './TimeRangeMenu';
const styles = theme => ({
  root: {
    height: '48px',
    minHeight: '48px',
  },
  breadcrumbsItem: {
    fontSize: '14px',
  },
  actionButton: {
    // marginLeft: theme.spacing(1),
    backgroundColor: 'inherit',
    // boxShadow: '0 1px 7px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  actionIcon: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
      // backgroundColor: '#fff',
    }
  },
  grow: {
    flexGrow: 1,
  },
  actions: {
    // float: 'left'
    paddingBottom: "15px",
  },  
});

class DashboardToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleChangeInput = (name, value) => {
    this.props.handleChangeProperty(name, value);
  };


  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };


  onRefreshDashboardHandler = () => {
    const { activeDashboard, activeDashlets } = this.props;
    if (activeDashboard && activeDashboard.dashboardType === ANOMALY_DASHBOARD) {
        this.props.onRefreshDashboardHandler(activeDashboard)
    }
  }

  handleConfirm = () => {
    this.setState({ anchorEl: null });
    this.props.handleConfirm();
  }

  render() {
    const { classes, activeDashboard, timeSettings, handleChangeProperty, handleConfirm } = this.props;
    const isMenuOpen = Boolean(this.state.anchorEl);
    return (
      activeDashboard.dashboardType != ANOMALY_DASHBOARD ? 
      <Toolbar className={classes.root}> 
        <div className={classes.grow} />
        {/* <div className={classes.actions}> */}
          <IconButton onClick={null} className={classes.actionButton} size="small" key="dashboard-nav-bar-refresh-dashlet-btn">
            <Sync className={classes.actionIcon} fontSize="medium" />
          </IconButton>
          <IconButton onClick={null} className={classes.actionButton} size="small" key="dashboard-nav-bar-edit-dashlet-btn">
            <Settings className={classes.actionIcon} fontSize="medium" />
          </IconButton>
          <Tooltip title="Add Panel">
            <IconButton onClick={this.props.onAddAccordionHandler} className={classes.actionButton} size="small" key="dashboard-nav-bar-create-dashlet-btn">
              <Add className={classes.actionIcon} fontSize="medium" />
            </IconButton>
          </Tooltip>
        {/* </div> */}
      </Toolbar> : 
      <div>  
      <Toolbar className={classes.root}>
        <div className={classes.grow} />
        <div className={classes.actions} style={{display:'flex', alignItems: 'center', marginTop:'15px'}}>
          <div style={{width: '80%', display:'flex', marginLeft:'20%'}}>
            <InputLabel style={{ marginRight:'10px', marginTop: '10px' , marginRight: '10px'}}>Use:</InputLabel>  
              <div>
                <NumberInput
                  isLocked={false} 
                  initialValue={timeSettings.timeAmount ? timeSettings.timeAmount : 5}
                  onChange={(value) => handleChangeProperty("timeAmount", value)}
                /> 
              </div>
          </div>
          <Select
            value={timeSettings.timeMeasure ? timeSettings.timeMeasure : ''}
            onChange={(event) => handleChangeProperty("timeMeasure", event.target.value)}
            style={{ minWidth: 120, marginLeft: '10px', border: '1px solid #c0c0c4' }}
          >
            {['Minute', 'Hour', 'Day', 'Week', 'Month', 'Year'].map((option) => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </Select> 
          <div style={{ border: '1px solid #c0c0c4', display:'flex', marginLeft:'10px', height:'33px', padding: ''}}>
            <div style={{borderRight: '1px solid #c0c0c4'}}>          
              <Tooltip title="Time Range">
                <IconButton onClick={this.handleMenuOpen} className={classes.actionButton} size="small" key="dashboard-nav-bar-create-dashlet-btn">
                  <ScheduleIcon className={classes.actionIcon} fontSize="medium" />
                </IconButton>
              </Tooltip>
            </div>
            <div style={{borderRight: '1px solid #c0c0c4'}}>
            <Tooltip title="Submit">
              <IconButton onClick={handleConfirm} className={classes.actionButton} size="small" key="dashboard-nav-bar-create-dashlet-btn">
                <CheckIcon className={classes.actionIcon} fontSize="medium" />
              </IconButton>
            </Tooltip>
            </div>
            <Tooltip title="Refresh">
              <IconButton onClick={this.onRefreshDashboardHandler} className={classes.actionButton} size="small">
                <Sync className={classes.actionIcon} fontSize="medium" />
              </IconButton>
            </Tooltip>
          </div>
          <Select
            value={typeof timeSettings.refreshTime === 'string' ? timeSettings.refreshTime : ''}
            onChange={(event) => handleChangeProperty("refreshTime", event.target.value)}
            style={{ width: '40%', marginLeft: '10px', border: '1px solid #c0c0c4' }}
          >
            {[
              { label: 'Off', value: 'Off' },
              { label: '5s', value: '5s' },
              { label: '10s', value: '10s' },
              { label: '30s', value: '30s' },
              { label: '1M', value: '1M' },
              { label: '5M', value: '5M' },
              { label: '15M', value: '15M' },
              { label: '30M', value: '30M' },
              { label: '1h', value: '1h' },
              { label: '2h', value: '2h' },
              { label: '1d', value: '1d' },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        </div>
      </Toolbar>

      <TimeRangeMenu
        timeSettings={timeSettings}
        anchorEl={this.state.anchorEl}
        isMenuOpen={isMenuOpen}
        handleMenuClose={this.handleMenuClose}
        handleChangeInput={this.handleChangeInput}
        handleConfirm={this.handleConfirm}
        onChangeTimeRangeSettingsPropertiesObject={this.props.onChangeTimeRangeSettingsPropertiesObject}
      />
      </div>
    );
  }
}

DashboardToolbar.propTypes = {
  //data
  activeDashlets: PropTypes.object,
  classes: PropTypes.object.isRequired,
  activeDashboard: PropTypes.object,
  timeSettings: PropTypes.object,

  //func
  onAddAccordionHandler: PropTypes.func,
  onRefreshDashboardHandler: PropTypes.func,
  handleChangeProperty: PropTypes.func,
  handleConfirm: PropTypes.func,
  onChangeTimeRangeSettingsPropertiesObject: PropTypes.func,
};

export default (withStyles(styles)(DashboardToolbar));