import React from "react";
import IntegrationSection from "../IntegrationSection";
import Integration from "types/model/settings/integration/Integration";
import IntegrationType from "types/model/settings/integration/IntegrationType";
import GenAIIntegration from "types/model/settings/integration/GenAIIntegration";
import GenAiIntegrationSection from "./sections/GenAiIntegrationSection";

interface GenAiIntegrationProps {
    integrations: Record<string, Integration>;
    onSaveIntegration: (integration: Integration) => void;
}

const GenAiIntegration: React.FC<GenAiIntegrationProps> = ({
                                                             integrations,
                                                             onSaveIntegration
                                                         }) => (
    <>
        {integrations[IntegrationType.GEN_AI]
            && <IntegrationSection
                title={integrations[IntegrationType.GEN_AI].title}
                content={
                    <GenAiIntegrationSection
                        integration={integrations[IntegrationType.GEN_AI] as GenAIIntegration}
                        onSave={onSaveIntegration}
                    />
                }
            />}
    </>
);

export default GenAiIntegration;