import {BASE_API_URL} from "../../config";
import CommonCrudRestClient from "./CommonCrudRestClient";

class AccordionPanelRestClient extends CommonCrudRestClient {

    async create(accordion) {
        let data = accordion;
        return await super.post('', data);
    }

}

export default new AccordionPanelRestClient(`${BASE_API_URL}/dashboard/groups/accordionPanel`);