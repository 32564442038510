import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ForgotPasswordFinish from "./ForgotPasswordFinish";
import * as AuthActions from "../../../actions/authActions";

class ForgotPasswordFinishContainer extends Component {
  render() {
    const {back} = this.props;

    return (
      <ForgotPasswordFinish
        onBack={back}
      />
    );
  }

  componentWillUnmount() {
    // this.props.clearLoginErrorMessage();
  }
}

ForgotPasswordFinishContainer.propTypes = {
  //data

  //func
  back: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    back: () => {
      dispatch(AuthActions.gotoLogin());
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordFinishContainer)