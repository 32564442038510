import React from "react";
import {TableCell} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface BooleanTableCellProps {
    children: boolean | null;
}

const useStyles = makeStyles({
    tableCell: {
        textAlign: "left",
        fontSize: "0.6vw",
    }
});

const BooleanTableCell: React.FC<BooleanTableCellProps> = ({ children }) => {
    const classes = useStyles();

    return <TableCell className={classes.tableCell}>{children != null ? (children ? "Yes" : "No") : "N/A"}</TableCell>;
};

export default BooleanTableCell;
