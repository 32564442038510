import FinOpsDashletView from "./components/FinOpsDashletView";
import FinOpsDashletCreate from "./components/FinOpsDashletCreate";
import FinOpsDashletEditConfig from "./components/FinOpsDashletEditConfig";
import {buildDashletObject, refresh, validateConfig} from "./actions";
import FinOpsDashletTimeRange from "../components/FinOpsDashletTimeRange";


const DASHLET_TYPE_NAME = 'FIN_OPS_DASHLET_TYPE';

export const FIN_OPS_DASHLET_CONFIG = {
    [DASHLET_TYPE_NAME]: {
        typeName: DASHLET_TYPE_NAME,
        typeLabel: 'FinOps',
        viewComponent: FinOpsDashletView,
        createFormComponent: FinOpsDashletCreate,
        editConfigFormComponent: FinOpsDashletEditConfig,

        settingsComponent: null,
        openTemplateAsChartComponent: null,
        timeRangeComponent: FinOpsDashletTimeRange,
        validateConfigFunc: validateConfig,
        loadOptionsAction: null,
        refreshAction: refresh,
        buildDashletObjectFunc: buildDashletObject,
    }
}