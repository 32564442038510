import React from "react";
import MSTeamsIntegration from "types/model/settings/integration/MSTeamsIntegration";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "../../../../../components/common/Switch/Switch";
import TextField from "../../../../../components/common/TextField/TextField";
import SubmitButton from "../../../../../components/common/Button/SubmitButton";

interface MSTeamsIntegrationSectionProps {
    integration: MSTeamsIntegration;
    onSave: (integration: MSTeamsIntegration) => void;
}

const MSTeamsIntegrationSection: React.FC<MSTeamsIntegrationSectionProps> = ({
                                                                                 onSave,
                                                                                 ...props
                                                                             }) => {
    const { integration, handleChangeProperty, handleEnabledToggle }
        = useIntegrationSectionState(props.integration);
    const {
        enabled,
        clientId,
        clientSecret,
        tenantId,
        teamId,
        channelId,
        driveId

    }
        = integration as MSTeamsIntegration;

    return (
        <>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="flex-start"
                style={{marginTop: "8px", marginBottom: "8px"}}
            >
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="enable">Enabled</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <Switch checked={enabled || false}
                                onChange={handleEnabledToggle}
                                name="enabled"
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="clientId">Client ID</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name="clientId"
                            fullWidth
                            textAlign="left"
                            value={clientId}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="clientSecret">Client Secret</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name="clientSecret"
                            fullWidth
                            textAlign="left"
                            value={clientSecret}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="tenantId">Tenant ID</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name="tenantId"
                            fullWidth
                            textAlign="left"
                            value={tenantId}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="teamId">Team ID</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name="teamId"
                            fullWidth
                            textAlign="left"
                            value={teamId}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="channelId">Channel ID</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name="channelId"
                            fullWidth
                            textAlign="left"
                            value={channelId}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="driveId">Drive ID</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name="driveId"
                            fullWidth
                            textAlign="left"
                            value={driveId}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={7}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Grid item xs={1}>
                        {/*@ts-ignore*/}
                        <SubmitButton onClick={() => onSave(integration)}>Save</SubmitButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MSTeamsIntegrationSection;
