import * as backendsLogsWebSocketClient from "api/websocket/hlc/backendsLogsWebSocketClient";
import {setBackendsLogsLines, setBackendsLogsLoading} from "reducers/infrastructure/backendsLogsSlice";
import LogsStompResponse from "types/payload/infrastructure/logs/LogsStompResponse";

export const loadLogs = (backendKey: string) => (dispatch: any, getState: any) => {
    dispatch(setBackendsLogsLoading(true));
    backendsLogsWebSocketClient.loadLogs(getState(), backendKey, {
        linesCount: 500
    });
};

export const handleLoadLogs = (response: LogsStompResponse) => (dispatch: any) => {
    dispatch(setBackendsLogsLoading(false));
    dispatch(setBackendsLogsLines(response.logLines));
};