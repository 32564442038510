import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, {Component} from "react";

const timeRangeTabPanelStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    padding: 8,
  },
  rootHidden: {
    display: 'none',
  },
});

class TimeRangeTabPanel extends Component {

  render() {
    const {classes, hidden, ...other} = this.props;

    return (
        <Paper
            component="div"
            role="tabpanel"
            elevation={0}
            hidden={hidden}
            className={clsx(classes.root, {
              [classes.rootHidden]: hidden
            })}
            {...other}
        >
          {this.props.children}
        </Paper>
    );
  }
}

TimeRangeTabPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  hidden: PropTypes.bool,
};

export default withStyles(timeRangeTabPanelStyles)(TimeRangeTabPanel)