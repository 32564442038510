import {
  cancelEditDashboard,
  saveEditDashboard,
} from "actions/dashboardActions";
import DashboardModal from "components/dashboard/DashboardModal/DashboardModal";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as _ from "lodash";
import {dashboardModalSelector, quickDashboardModalSelector} from "selectors/dashboard/DashboardSelectors";
import {setDashboardModalProperty, setTargetConfiguration} from "../../reducers/dashboard/dashboardSlice";
import {ANOMALY_DASHBOARD, DEFAULT_DASHBOARD, REGULAR_DASHBOARD } from "dashlets/AnomalyDetectionDashlet/constants";
import { toastr } from "react-redux-toastr";
import { selectTargetNames } from "reducers/dashboard/createDashletSlice";

class DashboardModalContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLocked: false,
      errors: {},
      backendKey: "",
      dashboardType: REGULAR_DASHBOARD,
      targetName: "",
      createNewTarget: false,
    };
  }

  lockForm = () => {
    this.setState({isLocked: true});
  };

  unlockForm = () => {
    this.setState({isLocked: false});
  };

  resetForm = () => {
    this.setState({
      errors: {},
      dashboardType: REGULAR_DASHBOARD,
    });
  };

  onClickSubmitHandler = (event) => {
    event.preventDefault();

    this.lockForm();

    if (!this.validate()) {
      let dashboard = this.props.objectData;
        dashboard = {
          ...dashboard,
          dashboardType: this.state.dashboardType,
      }
      this.props.submit(dashboard, this.state.backendKey, this.state.targetName, this.state.createNewTarget);
    }
    this.unlockForm();
  };

    handleDashboardTypeChange = (event) => {
        this.setState({ dashboardType: event.target.value });
    };

  onClickCancelHandler = (event) => {
    event.preventDefault();
    this.props.cancel();
  };

  handleBackendSelect = (event) => {
    this.setState({backendKey: event.target.value})
  }

  onChangeInputHandler = (event) => {
    let {errors} = this.state;
    const target = event.target;
    if (event.target.name === "targetName" || event.target.name == "createNewTarget") {
      if (event.target.name == "createNewTarget") {
        this.setState({[event.target.name]: event.currentTarget.checked});
      } else {
        this.setState({[event.target.name]: event.target.value});
      }
      return;
    }

    this.props.setDashboardModalProperty(target.name, target.value);
    errors[target.name] = false;

    if (this.state.dashboardType === DEFAULT_DASHBOARD) {
      this.props.handleChangeProperty(target.name, target.value);
      errors[target.name] = false;

      if (this.props.targetConfiguration.useDatabaseUrl) {
        this.props.handleChangeProperty(target.name, target.value);
        errors[target.name] = false;
      }
    }
    this.setState({errors});
    event.preventDefault();
  };

  validateObjectData = (objectData) => {
    const errors = {};

    if (!_.trim(objectData.name)) {
      errors.name = true;
    }
    if (this.state.dashboardType === DEFAULT_DASHBOARD) {
      if (this.state.createNewTarget) {
        if (!_.trim(objectData.databaseHost)) {
          errors.databaseHost = true;
        }
        if (!_.trim(objectData.databasePort)) {
          errors.databasePort = true;
        }
        if (!_.trim(objectData.databaseUser)) {
          errors.databaseUser = true;
        }
        if (!_.trim(objectData.databasePassword)) {
          errors.databasePassword = true;
        }
        if (!_.trim(objectData.databaseName)) {
          errors.databaseName = true;
        }
        if (this.props.targetConfiguration.useDatabaseUrl) {
          errors.databaseUrl = true;
          errors.databaseName = false;
          errors.databaseHost = false;
          errors.databasePort = false;
          errors.databaseUser = false;
          errors.databasePassword = false;
        } 
      } else {
        if (this.state.targetName === "") {
          errors.targetName = true;
        }
      }
    }

    if (this.state.dashboardType === ANOMALY_DASHBOARD) {
      if (this.state.backendKey === "") {
        toastr.error("Backend was not selected..");
        errors.backendKey = true;
      }
    }

    return errors;
  };

  validate = () => {
    const {objectData} = this.props;
    const errors = this.validateObjectData(objectData);
    this.setState({errors});

    return _.keys(errors).length > 0;
  };

  render() {
    const {errors, isLocked, createNewTarget, targetName} = this.state;
    const {objectData} = this.props;
    const enabled = Boolean(objectData) && !this.props.isQuickDashboardModalOpne;

    return enabled ? (
        <DashboardModal
            open={enabled}
            objectData={objectData}
            errors={errors}
            isLocked={isLocked}
            targetName={targetName}
            createNewTarget={createNewTarget}
            handleChangeInput={this.onChangeInputHandler}
            handleConfirm={this.onClickSubmitHandler}
            handleClose={this.onClickCancelHandler}
            backendKey={this.state.backendKey}
            handleBackendSelect={this.handleBackendSelect}
            dashboardType={this.state.dashboardType}
            handleDashboardTypeChange={this.handleDashboardTypeChange}
            onOpen={this.resetForm}
            {...this.props}
        />
    ) : null;
  }
}

DashboardModalContainer.propTypes = {
  //data
  objectData: PropTypes.object,
  targetConfiguration: PropTypes.object,
  targetNames: PropTypes.array,

  //func
  cancel: PropTypes.func,
  submit: PropTypes.func,
  setDashboardModalProperty: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    objectData: dashboardModalSelector(state),
    targetConfiguration: state.dashboard.targetConfiguration,
    isQuickDashboardModalOpne: quickDashboardModalSelector(state),
    targetNames: selectTargetNames(state),
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancel: () => {
      dispatch(cancelEditDashboard());
    },
    submit: (objectData, backendKey, targetName, createNewTarget) => {
      dispatch(saveEditDashboard(objectData, backendKey, targetName, createNewTarget));
    },
    setDashboardModalProperty: (propertyName, propertyValue) => {
      dispatch(setDashboardModalProperty(propertyName, propertyValue));
    },
    handleChangeProperty: (propertyName, propertyValue) => {
      dispatch(setTargetConfiguration(propertyName, propertyValue))
    },
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardModalContainer)