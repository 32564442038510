import React, { Component } from "react";
import logo from "../../../img/logo-blue.png";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  subscriptionContainer: {
    height: "100%",
  },
  subscriptionContent: {
    display: "grid",
    alignContent: "center",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
  },
  heading: {
    textAlign: "center",
    fontWeight: "bold",
  },
  paragraph: {
    textAlign: "center",
    marginTop: "5px",
    fontSize: "1.2em",
  },
  callUs: {
    color: "rgb(0, 116, 212)",
  },
});
class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentWillMount() {
    if (
      this.props.subscription &&
      this.props.subscription.status === "active"
    ) {
      // const subscription = {
      //   ...this.props.subscription
      // }
      this.setState({ loading: true });
      this.props.getCustomerPortalURL(this.props.subscription);
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1100);
    }
  }

  render() {
    const { userEmail, userId, classes } = this.props;
    return !this.state.loading ? (
      <div className={classes.subscriptionContainer}>
        <div className={classes.subscriptionContent}>
          <div className={classes.logoContainer}>
            <img className={classes.logoImg} src={logo} alt="Enteros UpBeat" />
          </div>

          <h1 className={classes.heading}>
            Unlock Enteros Excellence With Premium
          </h1>

          <p className={classes.paragraph}>
            Pick the plan that's best for you, or{" "}
            <span className={classes.callUs}>call</span> us to find it
          </p>
          <stripe-pricing-table
            pricing-table-id={
              process.env.NODE_ENV === "development"
                ? process.env.REACT_APP_TEST_PRICING_TABLE_KEY
                : process.env.REACT_APP_PRICING_TABLE_KEY
            }
            publishable-key={
              process.env.NODE_ENV === "development"
                ? process.env.REACT_APP_TEST_STRIPE_PUBLISHABLE_KEY
                : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
            }
            client-reference-id={userId}
            customer-email={userEmail}
          ></stripe-pricing-table>
        </div>
      </div>
    ) : (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading...
      </div>
    );
  }
}
export default withStyles(styles)(Subscription);