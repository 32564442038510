import {configureStore} from "@reduxjs/toolkit";
import authReducer from './authSlice';
import webSocketReducer from './webSocketSlice';
import uiReducer from './uiSlice';
import userInfoReducer from './userInfoSlice';
import emailConfiguration from './EmailConfigurationReducer';
import createDashletReducer from './dashboard/createDashletSlice';
import dashboardReducer from './dashboard/dashboardSlice';
import backendManagementReducer from './infrastructure/backendManagementSlice';
import backendsLogsReducer from './infrastructure/backendsLogsSlice';
import jobsReducer from './infrastructure/jobsSlice';
import backendCollectorsReducer from './infrastructure/backendCollectorsSlice';
import settingsReducer from "./settingsSlice";
import thunkMiddleware from "redux-thunk";
import {createLogger} from "redux-logger";
import {redirect} from "../middlewares/redirect";
import LogRocket from "logrocket";
import {reducer as toastrReducer} from 'react-redux-toastr';

import heatmapSpikePreviewReducer from './dashboard/dashlets/heatmap/heatmapSpikePreviewSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        userInfo: userInfoReducer,
        ui: uiReducer,
        dashboard: dashboardReducer,
        settings: settingsReducer,
        emailConfiguration,
        createDashlet: createDashletReducer,
        webSocket: webSocketReducer,
        toastr: toastrReducer,
        heatmapSpikePreview: heatmapSpikePreviewReducer,
        backendManagement: backendManagementReducer,
        backendsLogs: backendsLogsReducer,
        backendCollectors: backendCollectorsReducer,
        jobs: jobsReducer,
    },
    middleware: [thunkMiddleware, createLogger(), redirect, LogRocket.reduxMiddleware()],
});

export default store;