import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {listReportNames, listTemplateNames} from "../actions";
import DashletConfigDropdown from "components/dashboard/dashlets/DashletConfigDropdown/DashletConfigDropdown";
import * as _ from "lodash";
import {stringAsOption} from "../utils";
import {
    ListHeatmapReportNamesStompResponse,
    ListHeatmapTemplateNamesStompResponse
} from "../types";
import {
    HEATMAP_LIST_REPORT_NAMES_SUBSCRIPTION,
    HEATMAP_LIST_TEMPLATE_NAMES_SUBSCRIPTION
} from "../webSocketClient";
import DashletConfigSelectTargetNames from "../../components/DashletConfigSelectTargetNames";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import { selectIsLoadingReportNames, selectIsLoadingTemplateNames, toggleIsLoadingReportNames, toggleIsLoadingTemplateNames } from 'reducers/dashboard/dashboardSlice';
import { selectReportNames, selectTemplateName, setDashletOptionsProperty } from 'reducers/dashboard/createDashletSlice';

interface HeatmapDashletEditProps {
    dashletConfig: any;
    setDashletConfigProperty: (propertyName: string, propertyValue: unknown) => void;
}

const HeatmapDashletEdit: React.FC<HeatmapDashletEditProps> = ({
                                                                               dashletConfig,
                                                                               setDashletConfigProperty
                                                                           }) => {
    const {backendKey} = dashletConfig;
    // const [isLoadingTemplateNames, setIsLoadingTemplateNames] = useState<boolean>(false);
    // const [isLoadingReportNames, setIsLoadingReportNames] = useState<boolean>(false);
    // const [reportNames, setReportNames] = useState<string[]>([]);
    // const [templateNames, setTemplateNames] = useState<string[]>([]);
    const dispatch = useDispatch();

    const isLoadingTemplateNames = useSelector(selectIsLoadingTemplateNames);
    const isLoadingReportNames = useSelector(selectIsLoadingReportNames);
    const templateNames = useSelector(selectTemplateName)
    const reportNames = useSelector(selectReportNames);

    const isLoading = isLoadingReportNames || isLoadingTemplateNames;

    const onChangeDashletConfigPropertyHandler = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        event.preventDefault();
        const name = event.target.name;
        if (name) {
            setDashletConfigProperty(event.target.name as string, event.target.value);
        }
    };

    const onSubmitTargetNames = (values: string[]) => setDashletConfigProperty('targetNames', values);

    // const handleListHeatmapReportNamesStompResponse = (response: ListHeatmapReportNamesStompResponse) => {
    //     if (!response.hasErrors) {
    //         // setReportNames(response.reportNames);
    //         dispatch(setDashletOptionsProperty({propertyName: "reportNames", propertyValue: response.reportNames}))
    //     } else {
    //         console.log(response.errorMessage); //todo: handle error
    //     }
    //     dispatch(toggleIsLoadingReportNames(false));
    //     // setIsLoadingReportNames(false);
    // };

    // const handleListHeatmapTemplateNamesStompResponse = (response: ListHeatmapTemplateNamesStompResponse) => {
    //     if (!response.hasErrors) {
    //         dispatch(setDashletOptionsProperty({propertyName: "templates", propertyValue: response.templateNames}))
    //         // setTemplateNames(response.templateNames);
    //     } else {
    //         console.log(response.errorMessage); //todo: handle error
    //     }
    //     dispatch(toggleIsLoadingTemplateNames(false))
    //     // setIsLoadingTemplateNames(false);
    // };

    // list reports, templates and targets on change Backend
    useEffect(() => {
        if (backendKey) {
            // load report names
            dispatch(toggleIsLoadingReportNames(true))
            dispatch(listReportNames(backendKey));
            // setIsLoadingReportNames(true);
            // dispatch(listReportNames(backendKey));

            // // load template names
            // setIsLoadingTemplateNames(true);
            dispatch(toggleIsLoadingTemplateNames(true))
            dispatch(listTemplateNames(backendKey));
        }
    }, [backendKey]);

    return <React.Fragment>
        <DashletConfigDropdown
            label="Spike Detection Report"
            name="spikeDetectionReportName"
            value={dashletConfig.spikeDetectionReportName}
            options={_.map(reportNames, (name: string) => stringAsOption(name))}
            disabled={isLoading}
            // error={dashletErrors?.spikeDetectionReportName}
            onChange={onChangeDashletConfigPropertyHandler}
        />
        <DashletConfigDropdown
            label="Static Template"
            name="staticTemplateName"
            value={dashletConfig.staticTemplateName}
            options={_.map(templateNames, (name: string) => stringAsOption(name))}
            disabled={isLoading}
            // error={dashletErrors?.staticTemplateName}
            onChange={onChangeDashletConfigPropertyHandler}
        />
        <DashletConfigSelectTargetNames
            multi
            backendKey={backendKey}
            targetNames={dashletConfig.targetNames}
            onSubmitTargetNames={onSubmitTargetNames}
        />

        {/* <HLCUserSessionWebSocketSubscription
            subscriptionDestination={HEATMAP_LIST_REPORT_NAMES_SUBSCRIPTION}
            handleMessage={handleListHeatmapReportNamesStompResponse}
        /> */}
        {/* <HLCUserSessionWebSocketSubscription
            subscriptionDestination={HEATMAP_LIST_TEMPLATE_NAMES_SUBSCRIPTION}
            handleMessage={response => {
                if (!response.hasErrors) {
                    dispatch(setDashletOptionsProperty({propertyName: "templates", propertyValue: response.templateNames}))
                    // setTemplateNames(response.templateNames);
                } else {
                    console.log(response.errorMessage); //todo: handle error
                }
                dispatch(toggleIsLoadingTemplateNames(false))
                // setIsLoadingTemplateNames(false);
            }}
        /> */}
    </React.Fragment>;
};

export default HeatmapDashletEdit;

