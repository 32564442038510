import * as _ from 'lodash';

export function normalizeScale(data /* Array [x, y]*/) {
  //find max 'y' value
  let maxY = _.max(_.map(data, (point) => {
    return point[1];
  }));

  return _.map(data, (point) => {
    let y = point[1];
    return {
      x: point[0],
      y: y > 0 ? (y / maxY) * 100 : 0,
      absoluteY: y
    }
  })
}