import clsx from "clsx";
import {HLC_WEB_SOCKET_URL, IS_HLC_WS_ENABLED, IS_PEI_WS_ENABLED, PEI_WEB_SOCKET_URL} from "./../../../config";
import DashboardModalContainer from "containers/dashboard/DashboardModalContainer";
import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import EditDashboardGroupContainer from "components/dashboard/EditDashboardGroup/EditDashboardGroupContainer";
import CreateDashletContainer from "views/dashboard/CreateDashlet/CreateDashletContainer";
import DashboardWorkspaceContainer from "views/dashboard/Home/DashboardWorkspaceContainer";
import StompClient from "websocket/StompClient";
import {STOMP_CLIENT} from "reducers/webSocketSlice";
import HLCDefaultChartDashletCreate from "dashlets/HLCChartDashlet/HLCDefaultChartDashletCreate";
import ImportDashletModalContainer from "containers/dashboard/ImportDashletModalContainer";
import QuickDashboardModalContainer from "containers/dashboard/QuickDashboardModalContainer";

const styles = theme => ({
  root: {
    height: '100%',
    width: 'calc(100% - 280px)'
  },
  rootExpanded: {
    height: '100%',
    width: 'calc(100% - 48px)'
  },
});

class DashboardPageContent extends Component {
  render() {
    const {classes, isLeftMenuOpened} = this.props;

    return (

        <div className={clsx(classes.root, {
          [classes.rootExpanded]: !isLeftMenuOpened
        })}>

          <DashboardWorkspaceContainer isLeftMenuOpened={isLeftMenuOpened}/>

          <CreateDashletContainer/>
          <EditDashboardGroupContainer/>
          <DashboardModalContainer />
          <QuickDashboardModalContainer />
          <HLCDefaultChartDashletCreate />
          <ImportDashletModalContainer/>
          {IS_PEI_WS_ENABLED ? [
            <StompClient
                key="hlc-ws-stomp-client-1582840873831"
                stompClientKey={STOMP_CLIENT.PEI}
                sockJsEndpoint={PEI_WEB_SOCKET_URL}
                debugMode
            />,
          ] : null}
          {IS_HLC_WS_ENABLED ? [
            <StompClient
                key="pei-ws-stomp-client-1582840873831"
                stompClientKey={STOMP_CLIENT.HLC}
                sockJsEndpoint={HLC_WEB_SOCKET_URL}
                debugMode
            />,
          ] : null}
        </div>
    );
  }
}

DashboardPageContent.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  isLeftMenuOpened: PropTypes.bool,

  //func
};

export default withStyles(styles)(DashboardPageContent);
