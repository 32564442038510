import React from 'react';

const GUIDELINES_CONTENT = (
    <div  style={{ fontSize: '14px' }}>
        <p><strong>First</strong>, we will add a new group. In this group, we will create dashboards and store all the dashboards there.</p>

        <p>Next to the created group, there are three dots. Left-clicking on them will give you three options: Create Dashlet, Edit Group, and Delete Group.</p>

        <h3>Types of Dashboards:</h3>

        <ol>
            <li>
                <strong>Regular Dashboard:</strong>
                <ul>
                    <li>When you select "Create Dashboard," you need to enter the dashboard name. The dashboard type is set to "Regular Dashboard" by default. After saving, a default overview will open.</li>
                    <li>In the overview, you can add a dashlet. Within "Add Dashlet," you can open any chart saved in the Hlc project by adding it as a dashlet.</li>
                </ul>
            </li>
            <li>
                <strong>Anomaly Dashboard:</strong>
                <ul>
                    <li>When you select "Create Dashboard," you need to enter the dashboard name. Choose "Anomaly Dashboard" as the dashboard type and select "Upbeat Backend" from the Hlc project. After saving, charts will be created for all the targets you have in the Hlc project.</li>
                </ul>
            </li>
            <li>
                <strong>Default Dashboard:</strong>
                <ul>
                    <li>When you select "Create Dashboard," enter the dashboard name and choose "Default Dashboard" as the dashboard type. Then, fill in the fields for Upbeat Backend, Database Type, Host, Port Number of Database Type, Username, Password, and Database of Database Type. After completing these fields, click "Save."</li>
                    <li>This will create 2 default templates that you can view in the app dashboard. Additionally, the target for the selected database in the Hlc project will be created, and you can open the two default charts shown in the dashboard within the Hlc project as well.</li>
                </ul>
            </li>
        </ol>
    </div>
);

export default GUIDELINES_CONTENT;