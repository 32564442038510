import withStyles from "@material-ui/core/styles/withStyles";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import React, {Component} from "react";



const timeRangeTabStyles = theme => ({
  root: {
    color: '#545b64',
    textTransform: 'none',
    minHeight: 'auto',
    minWidth: '84px',
  },
  wrapper: {
    fontWeight: 'bold'
  },
  selected: {
    borderBottom: '3px solid #16191f'
  },
});

class TimeRangeTab extends Component {


  render() {
    const {classes, label, value, ...other} = this.props;

    return (
        <Tab
            label={label}
            value={value}
            classes={classes}
            disableRipple
            disableTouchRipple
            {...other}
        />
    );
  }
}

TimeRangeTab.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
};

export default withStyles(timeRangeTabStyles)(TimeRangeTab)