import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {formatCurrency} from "../../../utils/FormattingUtils";
import {useSelector} from "react-redux";
import {selectDashletData} from "../../../reducers/dashboard/dashboardSlice";

const headerStyle = {
    fontWeight: 'bold'
};

interface HeatmapDashletFinOpsViewProps {
    dashlet: any;
}

//todo: load financial data here!!!!

const HeatmapDashletFinOpsView: React.FC<HeatmapDashletFinOpsViewProps> = ({
                                                                               dashlet
                                                                           }) => {
    const data = useSelector(state => selectDashletData(state, dashlet.id));

    const rows = [
        {target: 'T1', amount: 456.8345},
        {target: 'T2', amount: 562.5},
        // ... more rows
    ];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell style={headerStyle}>Target</TableCell>
                    <TableCell style={headerStyle}>Amount</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => (
                    <TableRow key={index}>
                        <TableCell>{row.target}</TableCell>
                        <TableCell>{formatCurrency(row.amount)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default HeatmapDashletFinOpsView;