import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "../DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CancelButton from "../Button/CancelButton";
import SubmitButton from "../Button/SubmitButton";
import Typography from "@material-ui/core/Typography";

interface ConfirmationDialogProps {
    open: boolean;
    object: any;
    title: string;
    message: string;
    cancelLabel?: string;
    submitLabel?: string;
    onClose: () => void;
    onSubmit: (objectId: any) => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
                                                                   open,
                                                                   object,
                                                                   title,
                                                                   message,
                                                                   cancelLabel,
                                                                   submitLabel,
                                                                   onClose,
                                                                   onSubmit
                                                               }) => {

    const handleSubmit = () => onSubmit(object);

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={onClose}
        >
            <DialogTitle id="confirmation-dialog" onClose={onClose}>
                {title}
            </DialogTitle>
            <DialogContent dividers>
                <Typography>{message}</Typography>
            </DialogContent>
            <DialogActions>
                {/*@ts-ignore*/}
                <CancelButton variant="outlined" onClick={onClose}>
                    {cancelLabel || "Cancel"}
                </CancelButton>
                {/*@ts-ignore*/}
                <SubmitButton onClick={handleSubmit}>
                    {submitLabel || "Confirm"}
                </SubmitButton>
            </DialogActions>

        </Dialog>
    );
};

export default ConfirmationDialog;
