import HeatmapAlertHistoryDashletView from "./components/HeatmapAlertHistoryDashletView";
import { DASHLET_TYPE_LABEL, DASHLET_TYPE_NAME } from "./constants";


export const HEATMAP_ALERT_HISTORY_DASHLET_CONFIG = {
    [DASHLET_TYPE_NAME]: {
      typeName: DASHLET_TYPE_NAME,
      typeLabel: DASHLET_TYPE_LABEL,
      viewComponent: HeatmapAlertHistoryDashletView,
    }
  };