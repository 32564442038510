import SvgIcon from "@material-ui/core/SvgIcon";
import DropdownMenu from "components/common/DropdownMenu/DropdownMenu";
import DropdownMenuItem from "components/common/DropdownMenu/DropdownMenuItem";
import LeftNavBarListItem from "components/common/LeftNavBar/LeftNavBarListItem";
import LeftNavBarListItemText from "components/common/LeftNavBar/LeftNavBarListItemText";
import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PropTypes from "prop-types";
import {MoreHoriz} from "@material-ui/icons";
import { ANOMALY_DASHBOARD } from "dashlets/AnomalyDetectionDashlet/constants";


const styles = theme => ({
  root: {
    "&:hover": {
      backgroundColor: '#fff',
    }
    // paddingLeft: theme.spacing(4),
  },
  icon: {
    minWidth: theme.spacing(4),
  },
  text: {
    "&:hover": {
      color: '#ec7211',
    }
  },
  active: {
    color: '#ec7211',
    fontWeight: 'bold',
  },
  menuItem: {
    "&:hover": {
      backgroundColor: '#fff',
    }
  },
  menuItemText: {
    color: theme.palette.text.secondary,
    fontSize: '13px',
    "&:hover": {
      color: '#ec7211',
    }
  },
});

class DashboardItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  setAnchorEl = (anchorEl) => {
    this.setState({
      anchorEl: anchorEl
    });
  };

  onClickActionsHandler = (e) => {
    e.stopPropagation();
    this.setAnchorEl(e.currentTarget);
  };

  closeActions = () => {
    this.setAnchorEl(null);
  };

  handleActionsClose = (e) => {
    e.stopPropagation();
    this.closeActions();
  };

  onClickAddDashletHandler = (e) => {
    e.stopPropagation();
    this.props.onAddDashletHandler(this.props.dashboardData.id);
    this.closeActions();
  };

  onClickImportDashletHandler = (e) => {
    e.stopPropagation();
    this.props.onImportDashlet(this.props.dashboardData);
    this.closeActions();
  }

  onClickRefreshDashboardHandler = (e) => {
    e.stopPropagation();
    this.props.onRefreshDashboardHandler(this.props.dashboardData.id);
    this.closeActions();
  };

  onClickEditDashboardHandler = (e) => {
    e.stopPropagation();
    this.props.onEditDashboardHandler(this.props.dashboardData.id);
    this.closeActions();
  };

  onClickRemoveDashboardHandler = (e) => {
    e.stopPropagation();
    const {dashboardData, onRemoveDashboardHandler} = this.props;
    onRemoveDashboardHandler(dashboardData.id, dashboardData.name);
    this.closeActions();
  };

  onClickDashboardHandler = (e) => {
    e.stopPropagation();

    if (!this.props.isActive) {
      this.props.onSelect(this.props.dashboardData.id);
    }
  };

  render() {
    const { classes, dashboardData, isActive } = this.props;
    const {anchorEl} = this.state;

    const idText = `dashboard-actions-${dashboardData.id}`;
    return (
        <LeftNavBarListItem>
          <ListItemIcon className={classes.icon}>
            <SvgIcon />
          </ListItemIcon>
          <LeftNavBarListItemText
              primary={dashboardData.name}
              active={isActive}
              onClick={this.onClickDashboardHandler}
          />
          <MoreHoriz
              aria-controls={idText}
              aria-haspopup="true"
              onClick={this.onClickActionsHandler}
          />
          <DropdownMenu
              anchorEl={anchorEl}
              onClose={this.handleActionsClose}
          >
            {dashboardData.dashboardType != ANOMALY_DASHBOARD  ? <DropdownMenuItem text="Create Dashlet" icon="add" onClick={this.onClickAddDashletHandler} /> : null}
            {dashboardData.dashboardType != ANOMALY_DASHBOARD ? <DropdownMenuItem text="Import Dashlet" icon="download" onClick={this.onClickImportDashletHandler}/> : null}
            <DropdownMenuItem text="Refresh Dashboard" icon="refresh" onClick={this.onClickRefreshDashboardHandler}/>
            {dashboardData.dashboardType != ANOMALY_DASHBOARD ? <DropdownMenuItem text="Edit Dashboard" icon="edit" onClick={this.onClickEditDashboardHandler}/> : null}
            {/* {dashboardData.dashboardType != ANOMALY_DASHBOARD ? <DropdownMenuItem text="Delete Dashboard" icon="delete" onClick={this.onClickRemoveDashboardHandler}/> : null} */}
            <DropdownMenuItem text="Delete Dashboard" icon="delete" onClick={this.onClickRemoveDashboardHandler}/>
          </DropdownMenu>
        </LeftNavBarListItem>
    );
  }
}

DashboardItem.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  dashboardData: PropTypes.object,
  isActive: PropTypes.bool,
  //...

  //func
  onSelect: PropTypes.func.isRequired,
  onRemoveDashboardHandler: PropTypes.func.isRequired,
  onEditDashboardHandler: PropTypes.func.isRequired,
  onAddDashletHandler: PropTypes.func.isRequired,
  onRefreshDashboardHandler: PropTypes.func.isRequired,
  onImportDashlet: PropTypes.func.isRequired,
  //...
};

export default withStyles(styles)(DashboardItem);