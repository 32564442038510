import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WidgetsIcon from '@material-ui/icons/Widgets';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import TimelineIcon from '@material-ui/icons/Timeline';
import PropTypes from "prop-types";
import React, {Component} from "react";
import {Scrollbars} from "react-custom-scrollbars";

const paletteHeight = 120;



const paletteStyles = theme => ({
  root: {
    height: paletteHeight,
  },
  paper: {
    height: '100%',
    position: 'relative',
    boxShadow: '8px 0 8px 0 rgba(0, 0, 0, 0.2)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    border: 'none',
  },
  header: {
    display: 'flex',
    height: '24px',
    padding: '0 0 0 8px',
    backgroundColor: '#fafafa',
    borderBottom: '1px solid #eaeded',
  },
  headerTitle: {
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
    fontWeight: 'bold',
  },
  iconButton: {
    "&:hover": {
      backgroundColor: '#fafafa',
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    fontSize: '0.875rem',
  },
  headerActions: {
    // float: 'right',
  },
  grow: {
    flexGrow: 1,
  },
  content: {
    // height: 'calc(100% - 25px)',
    height: 'calc(100% - 31px)',
  },
  list: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    // padding: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(1),
    }
  },
  listItem: {
    width: 80,
    minWidth: 80,
    padding: 8,
  },
  list2: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0
  },
});

class Palette extends Component {

  render() {
    const {classes, open} = this.props;

    return open ? (
        <Drawer
            className={classes.root}
            variant="persistent"
            anchor="bottom"
            open={true}
            classes={{
              paper: classes.paper,
            }}
        >
          <div className={classes.header}>
            <IconButton
                className={classes.iconButton}
                size="small"
                onClick={null}
                disableRipple
                disableTouchRipple
            >
              <WidgetsIcon
                  className={classes.icon}
                  fontSize="small"
              />
            </IconButton>
            <Typography component="span" className={classes.headerTitle}>
              Template Palette
            </Typography>

            <div className={classes.grow}/>

            <IconButton
                className={classes.iconButton}
                size="small"
                onClick={null}
                disableRipple
                disableTouchRipple
            >
              <PlaylistAddIcon
                  className={classes.icon}
                  fontSize="small"
              />
            </IconButton> <IconButton
              className={classes.iconButton}
              size="small"
              onClick={null}
              disableRipple
              disableTouchRipple
          >
            <ExpandMoreIcon
                className={classes.icon}
                fontSize="small"
            />
          </IconButton>
          </div>
          <div className={classes.content}>
            <Scrollbars>
              <List className={classes.list2}>
                <ListItem className={classes.listItem}>
                  <div>
                    <InsertChartIcon fontSize="large" />
                  </div>
                  <div>
                    <Typography component="span">
                      {/*test 123*/}
                    </Typography>
                  </div>

                </ListItem>

              </List>
            </Scrollbars>


            {/*<div className={classes.list}>*/}
            {/*  <Scrollbars>*/}
            {/*    <div className={classes.listItem}>*/}
            {/*      1*/}
            {/*    </div>*/}
            {/*    <div className={classes.listItem}>*/}
            {/*      1*/}
            {/*    </div>*/}
            {/*    <div className={classes.listItem}>*/}
            {/*      1*/}
            {/*    </div>*/}
            {/*    <div className={classes.listItem}>*/}
            {/*      1*/}
            {/*    </div>*/}
            {/*    <div className={classes.listItem}>*/}
            {/*      1*/}
            {/*    </div>*/}
            {/*    <div className={classes.listItem}>*/}
            {/*      3*/}
            {/*    </div>*/}
            {/*    <div className={classes.listItem}>*/}
            {/*      1*/}
            {/*    </div>*/}
            {/*    <div className={classes.listItem}>*/}
            {/*      1*/}
            {/*    </div>*/}
            {/*    <div className={classes.listItem}>*/}
            {/*      14*/}
            {/*    </div>*/}
            {/*    <div className={classes.listItem}>*/}
            {/*      1*/}
            {/*    </div>*/}
            {/*    <div className={classes.listItem}>*/}
            {/*      1*/}
            {/*    </div>*/}
            {/*    <div className={classes.listItem}>*/}
            {/*      5*/}
            {/*    </div>*/}
            {/*    <div className={classes.listItem}>*/}
            {/*      6*/}
            {/*    </div>*/}

            {/*  </Scrollbars>*/}
            {/*</div>*/}
          </div>
        </Drawer>
    ) : null;
  }
}

Palette.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

export default withStyles(paletteStyles)(Palette);
