import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Container, ContainerProps} from "@material-ui/core";

interface SectionContainerProps extends ContainerProps {
}

const useStyles = makeStyles({
    container: {
        marginBottom: "8px"
    }
});

const SectionContainer: React.FC<SectionContainerProps> = ({ children, ...props }) => {
    const classes = useStyles();

    return <Container className={classes.container} {...props}>{children}</Container>;
};

export default SectionContainer;
