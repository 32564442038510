import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, {Component} from "react";
import Menu from '@material-ui/core/Menu';

const dropdownMenuStyles = theme => ({
  root: {

  },
});

class DropdownMenu extends Component {

  render() {
    const {classes, anchorEl, onClose, ...other} = this.props;

    return (
        <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ horizontal: 'right', vertical: 'center'}}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onClose}
            {...other}
        >
          {this.props.children}
        </Menu>
    );
  }
}

DropdownMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func,
};

export default withStyles(dropdownMenuStyles)(DropdownMenu)