import React from "react";
import Integration from "types/model/settings/integration/Integration";
import IntegrationType from "types/model/settings/integration/IntegrationType";
import IntegrationSection from "../IntegrationSection";
import ServiceNowIntegration from "types/model/settings/integration/ServiceNowIntegration";
import ServiceNowIntegrationSection from "./sections/ServiceNowIntegrationSection";
import JiraIntegrationSection from "./sections/JiraIntegrationSection";
import JiraIntegration from "../../../../types/model/settings/integration/JiraIntegration";
import ServiceDeskIntegration from "../../../../types/model/settings/integration/ServiceDeskIntegration";
import ServiceDeskIntegrationSection from "./sections/ServiceDeskIntegrationSection";

interface TicketingIntegrationsProps {
    integrations: Record<string, Integration>;
    onSaveIntegration: (integration: Integration) => void;
}

const TicketingIntegrations: React.FC<TicketingIntegrationsProps> = ({
                                                                         integrations,
                                                                         onSaveIntegration
                                                                     }) => (
    <>
        {integrations[IntegrationType.SERVICE_NOW]
            && <IntegrationSection
                title={integrations[IntegrationType.SERVICE_NOW].title}
                content={
                    <ServiceNowIntegrationSection
                        integration={integrations[IntegrationType.SERVICE_NOW] as ServiceNowIntegration}
                        onSave={onSaveIntegration}
                    />
                }
            />}
        {integrations[IntegrationType.JIRA]
            && <IntegrationSection
                title={integrations[IntegrationType.JIRA].title}
                content={
                    <JiraIntegrationSection
                        integration={integrations[IntegrationType.JIRA] as JiraIntegration}
                        onSave={onSaveIntegration}
                    />
                }
            />}
        {integrations[IntegrationType.SERVICE_DESK]
            && <IntegrationSection
                title={integrations[IntegrationType.SERVICE_DESK].title}
                content={
                    <ServiceDeskIntegrationSection
                        integration={integrations[IntegrationType.SERVICE_DESK] as ServiceDeskIntegration}
                        onSave={onSaveIntegration}
                    />
                }
            />}
    </>
);

export default TicketingIntegrations;
