import React from "react";
import {TableCell} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface HeaderTableCellProps {
    children: React.ReactNode;
}

const useStyles = makeStyles({
    tableCell: {
        fontSize: "0.6vw",
        fontWeight: "bold",
        // color: "white",
        // backgroundColor: "black"
    }
});

const HeaderTableCell: React.FC<HeaderTableCellProps> = ({children}) => {
    const classes = useStyles();

    return <TableCell className={classes.tableCell}>{children}</TableCell>
};

export default HeaderTableCell;
