import history from "routes/history";

import {ROUTING_ACTION_TYPE} from "actions/routingActions";

export const redirect = store => next => action => {
      if (action.type === ROUTING_ACTION_TYPE) {
            history[action.payload.method](action.payload.nextUrl);
      }

      return next(action);
};