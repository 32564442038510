import React from "react";
import ApplicationPage from "components/common/ApplicationPage/ApplicationPage";
import InfrastructureLeftNavBar from "components/gate/InfrastructureLeftNavBar";
import BackendsPageContent from "./BackendsPageContent";

const BackendsPage: React.FC = ({}) => (
    <ApplicationPage
        leftNavBarComponent={<InfrastructureLeftNavBar />}
        contentComponent={<BackendsPageContent />}
    />
);

export default BackendsPage;