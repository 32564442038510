import withStyles from "@material-ui/core/styles/withStyles";
import {cancelButtonStyles} from "jss/common/buttonStyles";
import PropTypes from "prop-types";
import React, {Component} from "react";
import MuiButton from '@material-ui/core/Button';

class CancelButton extends Component {

  render() {
    const {classes, ...other} = this.props;

    return (
        <MuiButton
            classes={classes}
            disableRipple
            disableTouchRipple
            {...other}
        >
          {this.props.children}
        </MuiButton>
    );
  }
}

CancelButton.propTypes = {
  //data
  classes: PropTypes.object.isRequired,

  //func
};

export default withStyles(cancelButtonStyles)(CancelButton)