import moment, {isMoment} from "moment";

export const  serializeDashletTimeRange = (dashletTimeRange) => {
  // convert to UNIX time (millis)
  const timeRange = Object.assign({}, dashletTimeRange);

  if (timeRange.timeRangeStartTime && isMoment(timeRange.timeRangeStartTime)) {
    timeRange.timeRangeStartTime = timeRange.timeRangeStartTime.format('x');
  }
  if (timeRange.timeRangeEndTime && isMoment(timeRange.timeRangeEndTime)) {
    timeRange.timeRangeEndTime = timeRange.timeRangeEndTime.format('x');
  }
  if (timeRange.historicalTimeFrom && isMoment(timeRange.historicalTimeFrom)) {
    timeRange.historicalTimeFrom = timeRange.historicalTimeFrom.format('x');
  }

  return timeRange;
};

export const deserializeDashletTimeRange = (dashletTimeRange) => {
  // convert from UNIX time (millis) to moment object
  const timeRange = Object.assign({}, dashletTimeRange);

  if (timeRange.timeRangeStartTime) {
    timeRange.timeRangeStartTime = moment.unix(timeRange.timeRangeStartTime / 1000);
  }
  if (timeRange.timeRangeEndTime) {
    timeRange.timeRangeEndTime = moment.unix(timeRange.timeRangeEndTime / 1000);
  }
  if (timeRange.historicalTimeFrom) {
    timeRange.historicalTimeFrom = moment.unix(timeRange.historicalTimeFrom / 1000);
  }

  return timeRange;
};
