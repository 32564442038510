import React from "react";
import {useSelector} from "react-redux";
import Dimensions from 'react-dimensions';
import Heatmap from "./Heatmap";
import {selectIsSpikePreviewOpened} from "../../../reducers/dashboard/dashlets/heatmap/heatmapSpikePreviewSlice";
import HeatmapDashletSpikePreview from "./HeatmapDashletSpikePreview";
import HeatmapDashletFinOpsView from "./HeatmapDashletFinOpsView";

/**
 * Heatmap Dashlet View component.
 * @param props {dashlet, data, containerHeight, containerWidth}
 * @returns {JSX.Element}
 * @constructor
 */
const HeatmapDashletView = ({dashlet, containerHeight, containerWidth}) => {
    const spikePreviewOpen = useSelector(selectIsSpikePreviewOpened);
    const displayFinancialInformation = dashlet.settings?.displayFinancialInformation;

    if (displayFinancialInformation) {
        return (
            <HeatmapDashletFinOpsView dashlet={dashlet} />
        )
    }

    return <React.Fragment>
        <Heatmap
            dashlet={dashlet}
            containerHeight={containerHeight}
            containerWidth={containerWidth}
        />
        {spikePreviewOpen && <HeatmapDashletSpikePreview dashlet={dashlet} />}
    </React.Fragment>;
};

export default Dimensions({elementResize: true})(HeatmapDashletView);
