import HLCChartDashletReport from "dashlets/HLCChartDashlet/components/HLCChartDashletReport";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as _ from 'lodash';

const filterChartSeriesList = (chartSeriesList, filterLowValue) => {
  if (filterLowValue !== null) {
    let filteredChartSeriesList = [];
    _.forEach(chartSeriesList, (chartSeriesBean) => {
      let filteredValues =_.filter(chartSeriesBean.values, (point) => {
        let pointValue = point[1];
        //low value
        if (filterLowValue !== null && pointValue < filterLowValue) {
          return false;
        }
        return true;
      });
      if (filteredValues.length > 0) {
        let filteredChartSeriesBean = Object.assign({}, chartSeriesBean, {values: filteredValues});
        filteredChartSeriesList.push(filteredChartSeriesBean)
      }
    });
    return filteredChartSeriesList;
  } else {
    return chartSeriesList;
  }
};

export const convertToReportItems = (chartSeriesList) => {
  return _.map(chartSeriesList, (chartSeriesBean) => {
    const values = _.map(chartSeriesBean.values, (it) => {
      return it[1];
    });
    return {
      target: chartSeriesBean.target,
      capture: chartSeriesBean.capture,
      metric: chartSeriesBean.metric,
      measurement: chartSeriesBean.measurement,
      isHistorical: chartSeriesBean.isHistorical,
      maxValue: _.max(values),
      avgValue: _.mean(values),
      count: values.length,
    }
  })
};

/**
 * Group report items
 */
export const groupReportItemList = (reportItemList, groupBy) => {
  //group by capture, target, measurement, metric
  if (_.includes(['capture', 'target', 'measurement', 'metric'], groupBy)) {
    return _.groupBy(reportItemList, (seriesBean) => {
      return seriesBean[groupBy];
    });
  }

  //group by metric and measurement
  if (groupBy === 'metric_and_measurement') {
    return _.groupBy(reportItemList, (seriesBean) => {
      return `${seriesBean.metric}|${seriesBean.measurement}`;
    });
  }

  //split metrics
  if (groupBy === 'split_metrics') {
    return _.groupBy(reportItemList, (seriesBean) => {
      return `${seriesBean.target}|${seriesBean.capture}|${seriesBean.metric}|${seriesBean.measurement}`;
    });
  }

  //no grouping
  return {'none': reportItemList}
};

const filterByMeasurementsPerGroup = (groupedReportItemList, measurementsPerGroup) => {
  let resultMap = {};

  _.forEach(_.keys(groupedReportItemList), (groupingKey) => {
    let itemList = groupedReportItemList[groupingKey];
    if (itemList.length <= measurementsPerGroup) {
      resultMap[groupingKey] = itemList;
    } else {
      let measurements = _.sortBy(_.uniq(_.map(itemList, (it) => {
        return it.measurement;
      })));
      measurements = _.slice(measurements, 0, Math.min(measurements.length, measurementsPerGroup));
      resultMap[groupingKey] = _.filter(itemList, (it) => {
        return _.includes(measurements, it.measurement);
      });
    }
  });

  return resultMap;
};

export const sortGroupedReportItems = (groupedReportItemsMap, orderProperty, order) => {
  _.each(_.keys(groupedReportItemsMap), (groupKey) => {
    groupedReportItemsMap[groupKey] = _.orderBy(groupedReportItemsMap[groupKey], [orderProperty], [order]);
  });

  return groupedReportItemsMap;
};

class HLCChartDashletReportContainer extends Component {


  render() {
    const {
      dashlet: {
        settings: {
          displayAs,
          groupBy,
          metricsPerGroup,
          isFilterLowValue,
          filterLowValue,
          isFilterHighValue,
          filterHighValue,
          isSplitMetrics,
          chartsPerPage,
          isSmoothData,
          smoothDataValue,
          smoothDataMeasure,
          isNormalizeScale,
          isRegressionTrend,
          reportMeasurementsPerGroup,
        },
      },
      data,
      containerHeight,
      containerWidth
    } = this.props;

    const reportData = {};

    const chartSeriesList = data?.chartSeriesList;
    const timeRangesMap = data?.timeRangesMap;

    if (!chartSeriesList || chartSeriesList.length < 1) {
      reportData.isEmpty = true;
    }

    //sort chart series in order of chart elements
    // const sortedChartSeriesList = sortChartSeriesList(_.slice(chartSeriesList), _.map(chartElements, (chartElement) => {
    //   return chartElement.key;
    // }));
    const sortedChartSeriesList = chartSeriesList;

    const filteredChartSeriesList = filterChartSeriesList(sortedChartSeriesList, isFilterLowValue ? filterLowValue : null);

    //check filtered series count
    let seriesCount = filteredChartSeriesList?.length;
    if (seriesCount === 0 || false) {
      reportData.isEmpty = true;
    }
    let reportItems = [];
    let itemsReport = [];
    if (data && data.report && data.report.reportItems) {
      let report = data.report;
      itemsReport = report.reportItems || [];
      for (let obj in itemsReport) {
        reportItems = reportItems.concat(data.report.reportItems[obj])
      }
    }

    const timeRangesAsList =_.map(_.uniq(_.map(reportItems, (reportItem) => {
      return reportItem.target;
    })), (targetName) => {
      return timeRangesMap[targetName]
    });

    const reportTimeRanges = {
      startTime: _.min(_.filter(_.map(timeRangesAsList, (timeRanges) => {
        return timeRanges.startTime;
      }), (time) => {
        return time !== null;
      })),
      endTime: _.max(_.filter(_.map(timeRangesAsList, (timeRanges) => {
        return timeRanges.endTime;
      }), (time) => {
        return time !== null;
      })),
      historicalStartTime: _.min(_.filter(_.map(timeRangesAsList, (timeRanges) => {
        return timeRanges.historicalStartTime;
      }), (time) => {
        return time !== null;
      })),
      historicalEndTime: _.max(_.filter(_.map(timeRangesAsList, (timeRanges) => {
        return timeRanges.historicalEndTime;
      }), (time) => {
        return time !== null;
      })),
    };

    //grouping
    let groupedReportItemsMap = groupReportItemList(reportItems, isSplitMetrics ? 'split_metrics' : groupBy);

    //get only metricsPerGroup metrics for each group
    //not necessary if splitMetrics is set to true
    if (!isSplitMetrics) {
      groupedReportItemsMap = filterByMeasurementsPerGroup(groupedReportItemsMap, reportMeasurementsPerGroup);
    }

    //sort rows in groups by max value
    groupedReportItemsMap = sortGroupedReportItems(groupedReportItemsMap, 'maxValue', 'desc');

    reportData.isEmpty = false;
    reportData.items = groupedReportItemsMap;
    reportData.timeRanges = reportTimeRanges;

    return (
        <HLCChartDashletReport reportData={reportData} />
    );
  }
}

HLCChartDashletReportContainer.propTypes = {
  //data
  dashlet: PropTypes.object.isRequired,
  data: PropTypes.object,
  containerHeight: PropTypes.number,
  containerWidth: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {

    //...
  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HLCChartDashletReportContainer)
