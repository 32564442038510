import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import {headerStyles} from "../../../jss/gate/Auth/authStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(headerStyles);

/**
 * @return {null}
 */
function AuthFormHeader(props) {
  const classes = useStyles();
  const {title, ...rest} = props;

  return (
    <Typography component="h1" variant="h5" className={classes.root} {...rest}>
      {title}
    </Typography>
  )
}

AuthFormHeader.propTypes = {
  //data
  title: PropTypes.string.isRequired,

};

export default AuthFormHeader;