import withStyles from "@material-ui/core/styles/withStyles";
import Input from "@material-ui/core/Input";
import {authLinkStylesBorder, inputStyles, submitButtonStyles} from '../../../jss/gate/Auth/authStyles';
import React from "react";
import Button from "@material-ui/core/Button";
import AuthLink from "./AuthLink";

function AuthLogo(props) {
  return (
    <div className="b-login-form__header">
      <img src={require("../../../img/logo-blue.png")} alt="" />
    </div>
  );
}

const AuthFormInput = withStyles(inputStyles)(Input);

const AuthSubmitButton = withStyles(submitButtonStyles)(Button);

const AuthLinkBordered = withStyles(authLinkStylesBorder)(AuthLink);

export {
  AuthFormInput,
  AuthSubmitButton,
  AuthLinkBordered,
  AuthLogo,
  AuthLink
};