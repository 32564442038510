import React from "react";
import ApplicationPage from "components/common/ApplicationPage/ApplicationPage";
import InfrastructureLeftNavBar from "components/gate/InfrastructureLeftNavBar";
import BackendsLogsPageContent from "./BackendsLogsPageContent";

const BackendsLogsPage: React.FC = ({}) => (
    <ApplicationPage
        leftNavBarComponent={<InfrastructureLeftNavBar />}
        contentComponent={<BackendsLogsPageContent />}
    />
);

export default BackendsLogsPage;
