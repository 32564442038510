import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import SubmitButton from "components/common/Button/SubmitButton";
import ServiceNowIntegration from "types/model/settings/integration/ServiceNowIntegration";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";

interface ServiceNowIntegrationProps {
    integration: ServiceNowIntegration;
    onSave: (integration: ServiceNowIntegration) => void;
}

const ServiceNowIntegrationSection: React.FC<ServiceNowIntegrationProps> = ({
                                                                                onSave,
                                                                                ...props
                                                                            }) => {
    const { integration, handleChangeProperty, handleEnabledToggle }
        = useIntegrationSectionState(props.integration);
    const {enabled, instanceUrl, username, password}
        = integration as ServiceNowIntegration;

    return (
        <>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="flex-start"
                style={{marginTop: "8px", marginBottom: "8px"}}
            >
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="enable">Enabled</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <Switch checked={enabled || false}
                                onChange={handleEnabledToggle}
                                name="enabled"
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="instanceUrl">Instance URL</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name="instanceUrl"
                            fullWidth
                            textAlign="left"
                            value={instanceUrl}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="username">Username</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name="username"
                            fullWidth
                            textAlign="left"
                            value={username}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name="password"
                            type="password"
                            fullWidth
                            textAlign="left"
                            value={password}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={7}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Grid item xs={1}>
                        {/*@ts-ignore*/}
                        <SubmitButton onClick={() => onSave(integration)}>Save</SubmitButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ServiceNowIntegrationSection;
