import React from "react";
import BackendDTO from "types/model/infrastructure/BackendDTO";
import DialogTitle from "components/common/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Dialog, Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import CancelButton from "components/common/Button/CancelButton";
import SubmitButton from "components/common/Button/SubmitButton";
import {validateEmail} from "utils/ValidationUtils";
import Typography from "@material-ui/core/Typography";

interface GrantAccessDialogProps {
    open: boolean;
    backend: BackendDTO | null;
    onClose: () => void;
    onSubmit: (backendId: number | null, username: string) => void;
}

const GrantAccessDialog: React.FC<GrantAccessDialogProps> = ({
                                                                 open,
                                                                 backend,
                                                                 onClose,
                                                                 onSubmit,
                                                             }) => {
    const [username, setUsername] = React.useState<string>("");

    const isSubmitEnabled = () => {
        return validateEmail(username);
    }

    const handleSubmit = () => onSubmit(backend?.id || null, username);

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={onClose}
        >
            <DialogTitle id="backend-management-dialog" onClose={onClose}>
                Grant Access
            </DialogTitle>
            <DialogContent dividers>
                <Grid container direction="column" spacing={1}>
                    <Grid item xs={12}>
                        <Typography>Please enter email of the user to grant access to Backend '{backend?.name}'</Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            id="username"
                            name="username"
                            label="User Email"
                            value={username}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
                            // disabled={loading || operation === EDIT_TARGET}
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            size="small"
                            required
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {/*@ts-ignore*/}
                <CancelButton variant="outlined" onClick={onClose}>
                    Cancel
                </CancelButton>
                {/*@ts-ignore*/}
                <SubmitButton disabled={!isSubmitEnabled()} onClick={handleSubmit}>
                    Grant Access
                </SubmitButton>
            </DialogActions>
        </Dialog>
    );
};

export default GrantAccessDialog;
