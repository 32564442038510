import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

interface IntegrationSectionProps {
    title: string;
    content: React.ReactNode;
}

const IntegrationSection: React.FC<IntegrationSectionProps> = ({title, content}) => {
    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <ListItem
                button
                onClick={handleExpandClick}
                style={{
                    backgroundColor: "#fafafa",
                    borderBottom: "1px solid #eaeded"
                }}
            >
                <ListItemIcon>
                    <IconButton size="small" edge="start" aria-label="expand">
                        {expanded ? <ExpandLessIcon fontSize="small"/> : <ExpandMoreIcon fontSize="small"/>}
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary={title}/>
            </ListItem>
            <Collapse in={expanded} timeout="auto" style={{width: "100%", paddingLeft: "32px"}}>
                {content}
            </Collapse>
        </>
    );
};

export default IntegrationSection;
