import {BASE_API_URL} from "config";
import CommonRestClient from "api/rest/CommonRestClient";

class HLCDashletsRestClient extends CommonRestClient {

    async listUserBackends() {
        return await this.get("/backends/list");
    }

    //todo: deprecated
    async getActiveBackendKey() {
        return await this.get("/backend/activebackendkey");
    }
}

export default new HLCDashletsRestClient(`${BASE_API_URL}/dashboard/groups/dashboards/dashlets/hlc`);