import {publish} from 'api/websocket/hlc/hlcCommonWebSocketClient';
import {
    WEB_SOCKET_ACTION_PREFIX,
    WEB_SOCKET_SUBSCR_GROUP_PREFIX,
    WEB_SOCKET_SUBSCR_PREFIX
} from "constants/webSocketConstants";

// Load report names
export const HEATMAP_LIST_REPORT_NAMES_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.dashlet.heatmap.report.names.list.load`;
export const HEATMAP_LIST_REPORT_NAMES_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.dashlet.heatmap.report.names.list`;

// Load template names
export const HEATMAP_LIST_TEMPLATE_NAMES_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.dashlet.heatmap.template.names.list.load`;
export const HEATMAP_LIST_TEMPLATE_NAMES_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.dashlet.heatmap.template.names.list`;

// Load target names
export const HEATMAP_LIST_TARGET_NAMES_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.dashlet.heatmap.target.names.list.load`;
export const HEATMAP_LIST_TARGET_NAMES_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.dashlet.heatmap.target.names.list`;

// Load initial data
export const LOAD_HEATMAP_INITIAL_DATA_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.dashlet.heatmap.load`
export const LOAD_HEATMAP_INITIAL_DATA_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.dashlet.heatmap`

// Create Template
export const HEATMAP_CREATE_TEMPLATE_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.dashlet.heatmap.report.create`
export const HEATMAP_CREATE_TEMPLATE_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.dashlet.heatmap.report`

// List Target Spikes/Alerts
export const HEATMAP_LIST_TARGET_SPIKES_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.dashlet.heatmap.target.spikes.list`
export const HEATMAP_LIST_TARGET_SPIKES_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.dashlet.heatmap.target.spikes`

export const HEATMAP_TARGET_ALERTS_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_GROUP_PREFIX}/ui.dashboard.hlc.dashlet.heatmap.push`

export const listReportNames = (appState: any, backendKey: string) =>
    publish(appState, backendKey, HEATMAP_LIST_REPORT_NAMES_ACTION, {
        empty: null
    });

export const listTemplateNames = (appState: any, backendKey: string) =>
    publish(appState, backendKey, HEATMAP_LIST_TEMPLATE_NAMES_ACTION, {
        empty: true
    });

export const listTargetNames = (appState: any, backendKey: string) =>
    publish(appState, backendKey, HEATMAP_LIST_TARGET_NAMES_ACTION, {
        empty: true
    });

export const loadInitialData = (appState: any, backendKey: string, dashletId: number, millisAgo: number) =>
    publish(appState, backendKey, LOAD_HEATMAP_INITIAL_DATA_ACTION,
        {
            dashletId: dashletId,
            millisAgo: millisAgo
        });

export const heatmapLoadTargetSpikePreviewChart = (appState: any, backendKey: string, dashletId: number,
                                                   targetName: string, timestamp: number) =>
    publish(appState, backendKey, HEATMAP_CREATE_TEMPLATE_ACTION,
        {
            dashletId: dashletId,
            targetName: targetName,
            timestamp: timestamp
        });

export const listTargetSpikesRequest = (appState: any, backendKey: string, dashletId: number,
                                        targetName: string, count: number) =>
    publish(appState, backendKey, HEATMAP_LIST_TARGET_SPIKES_ACTION,
        {
            dashletId: dashletId,
            targetName: targetName,
            count: count
        });