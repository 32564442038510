import Popover from "@material-ui/core/Popover";
import DropdownMenu from "components/common/DropdownMenu/DropdownMenu";
import DropdownMenuItem from "components/common/DropdownMenu/DropdownMenuItem";
import {
  getDashletSettingsComponent, getDashletTimeRangeComponent, getOpenTemplateAsChartComponent,
} from "dashlets/dashletsConfig";
import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from '@material-ui/icons/Settings';
import Chart from "@material-ui/icons/Assessment";
import SyncIcon from '@material-ui/icons/Sync';
import PinDropIcon from '@material-ui/icons/PinDrop';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardContent from "@material-ui/core/CardContent";
import { Tooltip } from '@material-ui/core';
import { DASHLET_TYPE_NAME } from "dashlets/AnomalyDetectionDashlet/constants";

const styles = theme => ({
  card: {
    height: '100%',
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
  },

  cardHeader: {
    // padding: theme.spacing(0.5, 1),
    padding: '0 0 0 8px',
    height: 'auto',
    '&:hover': {
      height: 'auto',
      backgroundColor: '#fafafa',
      borderBottom: '1px solid #eaeded',
    },
  },
  cardHeaderAction: {
    margin: theme.spacing(0),
  },
  cardHeaderContent: {
    textAlign: 'left',
    cursor: 'move',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  cardHeaderTitle: {
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
  },
  iconButton: {
    "&:hover": {
      backgroundColor: '#fafafa',
    },
    paddingTop: '6px',
  },
  iconButtonDisable: {
    display: 'none',
  },
  icon: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
    },
    fontSize: '0.875rem',
  },
  cardContent: {
    height: 'calc(100% - 46px)',
    padding: 4,
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
});

class DashletWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      moreAnchorEl: null,
      timeRangeAnchorEl: null,
      propertiesAnchorEl: null,
      menuAnchorE1: null
    };
  }

  setAnchorEl = (anchorEl, value) => {
    this.setState({
      [anchorEl]: value
    });
  };

  setMoreAnchorEl = (value) => {
    this.setAnchorEl("moreAnchorEl", value);
  };

  setTimeRangeAnchorEl = (value) => {
    this.setAnchorEl("timeRangeAnchorEl", value);
  };

  setPropertiesAnchorEl = (value) => {
    this.setAnchorEl("propertiesAnchorEl", value);
  };

  setMenuAnchorE1 = (value) => {
    this.setAnchorEl("menuAnchorE1", value);
  };

  onClickMoreActionsHandler = (e) => {
    e.stopPropagation();
    this.setMoreAnchorEl(e.currentTarget);
  };

  onClickTimeRangeHandler = (e) => {
    e.stopPropagation();
    this.setTimeRangeAnchorEl(e.currentTarget);
  };

  onClickPropertiesHandler = (e) => {
    e.stopPropagation();
    this.setPropertiesAnchorEl(e.currentTarget);
  };

  onClickMenuIconHandler = (e) => {
    e.stopPropagation();
    this.setMenuAnchorE1(e.currentTarget);
    this.props.onClickMenuHandler();
  }

  closeMoreActions = () => {
    this.setMoreAnchorEl(null);
  };

  closeTimeRange = () => {
    this.setTimeRangeAnchorEl(null);
  };

  closeProperties = () => {
    this.setPropertiesAnchorEl(null);
  };

  closeMenu = () => {
    this.setMenuAnchorE1(null);
  };

  handleMoreActionsClose = (e) => {
    e.stopPropagation();
    this.closeMoreActions();
  };

  onClickRefreshHandler = (e) => {
    e.stopPropagation();
    this.props.onRefreshHandler();
    this.closeMoreActions();
  };

  onClickEditConfigHandler = (e) => {
    e.stopPropagation();
    this.props.onEditConfigHandler();
    this.closeMoreActions();
  };

  onClickRemoveHandler = (e) => {
    e.stopPropagation();
    this.props.onRemoveHandler();
    this.closeMoreActions();
  };

  onClickExportDashletHandler = (e) => {
    e.stopPropagation();
    this.props.onExportDashletHandler();
    this.closeMoreActions();
  }


  render() {
    const {classes, dashlet: {id, name, type, config}, onRefreshHandler, onRemoveHandler, updateDashletConfig, onDashletPreviewHandler} = this.props;
    const {moreAnchorEl, timeRangeAnchorEl, propertiesAnchorEl, menuAnchorE1} = this.state;
    const iconShowed = !(type == 'EMPTY_DASHLET_TYPE' || type == 'HEATMAP_DASHLET_TYPE' || type == DASHLET_TYPE_NAME)

    const DashletSettingsComponent = getDashletSettingsComponent(type);
    const DashletTimeRangeComponent = getDashletTimeRangeComponent(type);
    const DashletOpenTemplateAsChartComponent = getOpenTemplateAsChartComponent(type);

    return (
        <Card className={classes.card} key={`dashlet-wrapper-card-1586045386775-${id}`}>
          <CardHeader
              key={`dashlet-card-header-1586045386775-${id}`}
              className="GridDragHandler"
              classes={{
                root: classes.cardHeader,
                action: classes.cardHeaderAction,
                content: classes.cardHeaderContent,
                title: classes.cardHeaderTitle,
              }}
              action={[
                <Tooltip key={`Preview-Dashlet-${id}`} title="Preview">
                  <IconButton aria-label="Maximize" size="small" className={classes.iconButton} onClick={onDashletPreviewHandler} >
                    <TrendingUpIcon className={classes.icon} fontSize="small" />
                  </IconButton>
                </Tooltip>,
                type === "HLC_CHART_DASHLET_TYPE" && config.parentDashletId ?
                config.autoDelete ?
                  <Tooltip key={`Pin-Dashlet-${id}`} title="Pin Dashlet">
                    <IconButton aria-label="settings" size="small" className={classes.iconButton} key={`dash-btn-pin-${id}`} onClick={() => updateDashletConfig(false)} disableRipple disableTouchRipple disableFocusRipple>
                      <PinDropIcon className={classes.icon} fontSize="small" />
                    </IconButton>
                  </Tooltip> 
                  :
                  <Tooltip key={`UnPin-Dashlet-${id}`} title="UnPin Dashlet">
                    <IconButton aria-label="settings" size="small" className={classes.iconButton} key={`dash-btn-pin-${id}`} onClick={() => updateDashletConfig(true)} disableRipple disableTouchRipple disableFocusRipple>
                      <LocationOffIcon className={classes.icon} fontSize="small" />
                    </IconButton>
                  </Tooltip> : null,
                iconShowed ? <Tooltip key={`Refresh-tooltip-${id}`} title="Refresh">
                <IconButton aria-label="settings" size="small" onClick={onRefreshHandler} className={classes.iconButton}>
                  <SyncIcon className={classes.icon} fontSize="small" />
                </IconButton>
              </Tooltip> : null,

              iconShowed ? <Tooltip key={`Time Range-tooltip-${id}`} title="Time Range">
                <IconButton
                    key={`1585302898222-${id}`}
                    className={classes.iconButton}
                    size="small"
                    onClick={this.onClickTimeRangeHandler}
                    disableRipple
                    disableTouchRipple
                >
                  <AccessTimeIcon
                      key={`dashlet-time-icon-1586045386775-${id}`}
                      className={classes.icon}
                      fontSize="small"
                  />
                </IconButton>
                </Tooltip> : null,
                <Popover
                    key={`1585302898215-${id}`}
                    id={`1585304590125-${id}`}
                    open={Boolean(timeRangeAnchorEl)}
                    anchorEl={timeRangeAnchorEl}
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom'}}
                    getContentAnchorEl={null}
                    onClose={this.closeTimeRange}
                >
                  {DashletTimeRangeComponent && <DashletTimeRangeComponent
                      dashletId={id}
                      backendKey={config.backendKey}
                      close={this.closeTimeRange}
                  />}
                </Popover>,

                iconShowed ? <Tooltip key={`Open Chart-tooltip-${id}`} title="Open Chart">
                <IconButton
                  key={`1586044426615-${id}`}
                  className={classes.iconButton}
                  size="small"
                  onClick={this.onClickMenuIconHandler}
                  disableRipple
                  disableTouchRipple
                >
                  <Chart
                    className={classes.icon}
                    fontSize="small"
                  />
                </IconButton>
                </Tooltip> : null,

                <Popover
                  key={`158530289821232-${id}`}
                  id={`1586046751570-${id}`}
                  open={Boolean(menuAnchorE1)}
                  getContentAnchorEl={null}
                  onClose={this.closeMenu}
                  hidden={true}
                  >
                  {DashletOpenTemplateAsChartComponent && <DashletOpenTemplateAsChartComponent
                      key={`dashlet-open-template-as-chart-1586044426613-${id}`}
                      dashletId={id}
                      close={this.closeMenu}
                    />}
                </Popover>,

                iconShowed ? <Tooltip key={`Settings-tooltip-${id}`} title="Settings">
                <IconButton
                    key={`settings-icon-button-1586044426613-${id}`}
                    className={classes.iconButton}
                    size="small"
                    onClick={this.onClickPropertiesHandler}
                    disableRipple
                    disableTouchRipple
                >
                  <SettingsIcon
                      key={`settings-icon-1586044426613-${id}`}
                      className={classes.icon}
                      fontSize="small"
                  />
                </IconButton>
                </Tooltip> : null,
                <Popover
                    key={`dashlet-settings-popover-1586046751567-${id}`}
                    open={Boolean(propertiesAnchorEl)}
                    anchorEl={propertiesAnchorEl}
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom'}}
                    getContentAnchorEl={null}
                    onClose={this.closeProperties}
                >
                  {DashletSettingsComponent ? 
                    <DashletSettingsComponent
                      dashletId={id}
                      close={this.closeProperties}
                    /> : null
                  }
                  
                </Popover>,
                
                type != DASHLET_TYPE_NAME ? <><IconButton size="small" className={classes.iconButton} key={`mo-vert-icon-button-1585302534997-${id}`} onClick={this.onClickMoreActionsHandler} disableRipple disableTouchRipple disableFocusRipple>
                  <MoreVertIcon key={`mo-vert-icon-1585302534997-${id}`} className={classes.icon} fontSize="small" />
                </IconButton>
                  <DropdownMenu
                    key={`1585231741030-${id}`}
                    anchorEl={moreAnchorEl}
                    onClose={this.handleMoreActionsClose}
                  >
                    {iconShowed ? <DropdownMenuItem text="Refresh" icon="refresh" onClick={this.onClickRefreshHandler} /> : null}
                    {/*todo: temporary enable only for Heatmap*/}
                    {type === 'HEATMAP_DASHLET_TYPE' && <DropdownMenuItem text="Config" icon="settings" onClick={this.onClickEditConfigHandler} />}
                    {type != DASHLET_TYPE_NAME ? <DropdownMenuItem text="Export dashlet" icon="upload" onClick={this.onClickExportDashletHandler} /> : null}
                    <DropdownMenuItem text="Delete" icon="delete" onClick={this.onClickRemoveHandler} />
                  </DropdownMenu> </> : null
              ]
              }
              titleTypographyProps={{
                component: 'span',
                align: 'left',
                display: 'initial'
              }}
              title={name + (config?.templateName ? " - " + config.templateName : "")}

          />
          <CardContent className={classes.cardContent}>
            {this.props.children}
          </CardContent>
        </Card>
    );
  }
}

DashletWrapper.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  dashlet: PropTypes.object.isRequired,

  //func
  onRefreshHandler: PropTypes.func,
  onRemoveHandler: PropTypes.func,
  onEditConfigHandler: PropTypes.func,
  onClickMenuHandler: PropTypes.func,
  updateDashletConfig: PropTypes.func,
  onDashletPreviewHandler: PropTypes.func,
  //...
};

export default withStyles(styles)(DashletWrapper);
