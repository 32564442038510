import React, { Component } from 'react';
import PropTypes from "prop-types";
import classNames from 'classnames';
import { IconButton } from "@material-ui/core";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";

class NumberInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.initialValue || 0
    };
  }

  onChangeHandler = (event) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue) && newValue >= 0) {
      this.setState({ value: newValue });
      if (this.props.onChange) {
        this.props.onChange(newValue);
      }
    }
  };

  incrementHandler = () => {
    const newValue = parseInt(this.state.value, 10) + 1;
    this.setState({ value: newValue });
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  };

  decrementHandler = () => {
    const currentValue = parseInt(this.state.value, 10);
    if (currentValue > 1) {
      const newValue = currentValue - 1;
      this.setState({ value: newValue });
      if (this.props.onChange) {
        this.props.onChange(newValue);
      }
    }
  };

  render() {
    const { isLocked, initialValue } = this.props;
    const { value } = this.state;

    return (
      <div className="number-inp">
        <input
          type="text"
          disabled={isLocked}
          value={initialValue}
          onChange={this.onChangeHandler}
        />
        <IconButton
          className={`up Alterdropup ${isLocked ? 'disabled' : ''}`}
          onClick={isLocked ? null : this.incrementHandler}
          disabled={isLocked}
        >
          <KeyboardArrowUp className='arrowUpIcon up'/>
        </IconButton>
        <IconButton
          className={`down Alterdropdown ${isLocked ? 'disabled' : ''}`}
          onClick={isLocked ? null : this.decrementHandler}
          disabled={isLocked}
        >
          <KeyboardArrowDown className='arrowDownIcon down'/>
        </IconButton>
      </div>
    );
  }
}

NumberInput.propTypes = {
  initialValue: PropTypes.number,
  isLocked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default NumberInput;
