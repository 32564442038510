import * as queryString from "qs";
import {loadAuthState} from "../../store/LocalStore";

class CommonRestClient {
    location;

    constructor(location) {
        this.location = location;
    }

    headers() {
        const authState = loadAuthState();

        return {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authState ? authState.accessToken : null}`
        };
    }

    handleResponse(response) {
        if (response.ok) {
            return response.json();
        } else {
            return response.text().then( error => {
                throw new Error(error)
            });
        }
    }

    async get(path = '', queryParams = {}) {
        return await fetch(this.location + path + "?" + queryString.stringify(queryParams), {
            method: "GET",
            headers: this.headers()
        })
            .then(this.handleResponse);
    }

    async post(path, data) {
        return await fetch(this.location + path, {
            method: "POST",
            headers: this.headers(),
            body: JSON.stringify(data)
        })
            .then(this.handleResponse);
    }

    async put(path, data) {
        return await fetch(this.location + path, {
            method: "PUT",
            headers: this.headers(),
            body: JSON.stringify(data)
        })
            .then(this.handleResponse);
    }

    async delete(path) {
        return await fetch(this.location + path, {
            method: "DELETE",
            headers: this.headers()
        })
            .then(this.handleResponse);
    }
}

export default CommonRestClient;