import {concat as _concat, filter as _filter} from "lodash";
import BackendDTO from "types/model/infrastructure/BackendDTO";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {normalize} from "normalizr";
import backendSchema from "schemas/infrastructure/backendSchema";
import BackendUserDTO from "../../types/model/infrastructure/BackendUserDTO";


interface BackendManagementState {
    entities: Record<number, BackendDTO> | {};
    ids: number[];
    loading: boolean;
    error: string | null;
}

const initialState: BackendManagementState = {
    entities: {},
    ids: [],
    loading: false,
    error: null,
};

interface DeleteBackendUserAction {
    backendId: number;
    username: string;
}

interface SetBackendSecretKeyAction {
    backendId: number;
    backendSecretKey: string;
}

const backendManagementSlice = createSlice({
    name: "backendManagement",
    initialState,
    reducers: {
        setBackendManagementList(state, action: PayloadAction<BackendDTO[]>) {
            const normalizedBackends = normalize(action.payload, [backendSchema]);
            // @ts-ignore
            state.entities = normalizedBackends.entities.backends;
            state.ids = normalizedBackends.result;
        },
        addBackendManagement(state, action: PayloadAction<BackendDTO>) {
            const backend = action.payload;
            // @ts-ignore
            state.entities[backend.id] = backend;
            // @ts-ignore
            state.ids = _concat(state.ids, backend.id);
        },
        updateBackendManagement(state, action: PayloadAction<BackendDTO>) {
            const backend = action.payload;
            // @ts-ignore
            state.entities[backend.id] = {
                // @ts-ignore
                ...state.entities[backend.id],
                ...backend
            }
        },
        deleteBackendManagement(state, action: PayloadAction<number>) {
            const backendId = action.payload;
            const entities = {
                ...state.entities
            };
            delete entities[backendId]
            state.entities = entities;
            state.ids = _filter(state.ids, (id) => id !== backendId);
        },
        addBackendManagementUser(state, action: PayloadAction<BackendUserDTO>) {
            const backendUser = action.payload;
            // @ts-ignore
            state.entities[backendUser.backendId].users = _concat(state.entities[backendUser.backendId].users, backendUser);
        },
        deleteBackendManagementUser: {
            reducer(state, action: PayloadAction<DeleteBackendUserAction>) {
                const {backendId, username} = action.payload;
                // @ts-ignore
                state.entities[backendId].users = _filter(state.entities[backendId].users,
                    (user: BackendUserDTO) => user.username !== username);
            },
            prepare(backendId: number, username: string) {
                return {
                    payload: {backendId, username}
                }
            }
        },
        setBackendManagementSecretKey: {
            reducer(state, action: PayloadAction<SetBackendSecretKeyAction>) {
                const {backendId, backendSecretKey} = action.payload;
                // @ts-ignore
                state.entities[backendId].backendSecretKey = backendSecretKey;
            },
            prepare(backendId: number, backendSecretKey: string) {
                return {
                    payload: {backendId, backendSecretKey}
                }
            }
        },
        setBackendManagementLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
    }
});

export const {
    setBackendManagementList,
    addBackendManagement,
    updateBackendManagement,
    deleteBackendManagement,
    addBackendManagementUser,
    deleteBackendManagementUser,
    setBackendManagementSecretKey,
    setBackendManagementLoading
} = backendManagementSlice.actions;

export default backendManagementSlice.reducer;

export const selectBackendManagementEntities = (state: any) => state.backendManagement.entities;

export const selectBackendManagementIds = (state: any) => state.backendManagement.ids;