import ApplicationPage from "components/common/ApplicationPage/ApplicationPage";
import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import StartPageContentContainer from "views/gate/Start/StartPageContentContainer";
import StartPageLeftNavBarContainer from "views/gate/Start/StartPageLeftNavBarContainer";


const styles = theme => ({
  root: {
  },
});

class StartPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLeftMenuOpened: true
    }
  }

  setLeftMenuOpened = (open) => {
    this.setState({
      isLeftMenuOpened: open
    });
  };

  render() {
    const {isLeftMenuOpened} = this.state;

    return (
        <ApplicationPage
            leftNavBarComponent={<StartPageLeftNavBarContainer isLeftMenuOpened={isLeftMenuOpened} setLeftMenuOpened={this.setLeftMenuOpened} />}
            contentComponent={<StartPageContentContainer />}
        />
    );
  }
}

export default withStyles(styles)(StartPage);