import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Subscription from "./Subscription";
import { getCustomerPortalUrl } from "actions/dashboardActions";

class SubscriptionContainer extends Component {

  render() {
    const {userId, userEmail} = this.props;
    return (
      userId && userEmail
      ? <Subscription {...this.props} />
      : null
    );
  }
}
SubscriptionContainer.propTypes = {
    //data
    userEmail: PropTypes.string,
    userId: PropTypes.number,
    subscription: PropTypes.object,
    //func
    getCustomerPortalURL: PropTypes.func
};
const mapStateToProps = (state) => {
  return {
    userEmail: state.userInfo.currentUserInfo.username,
    userId: state.userInfo.currentUserInfo.id,
    subscription: state.userInfo.currentUserInfo.subscription
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerPortalURL: (subscription) => {
      dispatch(getCustomerPortalUrl(subscription));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionContainer);
