import CommonRestClient from "api/rest/CommonRestClient";
import {BASE_API_URL} from "config";


class PEIDashletsRestClient extends CommonRestClient {

    async listUserBackends() {
        return await this.get("/backends/list");
    }

    async getActiveBackendKey() {
        return await this.get('/backend/activebackendkey');
    }
}

export default new PEIDashletsRestClient(`${BASE_API_URL}/dashboard/groups/dashboards/dashlets/pei`);