import UserInfoRestClient from "api/rest/UserInfoRestClient";
import {loadState, removeState, saveState} from "store/LocalStore";
import {setCurrentUserInfo, setIsLoading} from "reducers/userInfoSlice";
import uuid from "uuid";

const CURRENT_USER_INFO_LOCAL_STORAGE_KEY = "enteros_upbeat_user_info";
const USER_SESSION_ID_LOCAL_STORAGE_KEY =
    "enteros_upbeat_hlc_user_session_key";


export const loadUserInfo = () => (dispatch: any) => {
    setIsLoading(true);
    UserInfoRestClient.getUserInfo()
        .then(userInfo => {
            const authLocalState = loadState(CURRENT_USER_INFO_LOCAL_STORAGE_KEY);
            // clear local storage if different user logged in
            if (authLocalState && authLocalState.username !== userInfo.username) {
                Object.keys(localStorage).forEach(key => {
                    if (key.indexOf("hlc") > -1) {
                        removeState(key);
                    }
                })
            }
            dispatch(setCurrentUserInfo(userInfo));
            saveState(CURRENT_USER_INFO_LOCAL_STORAGE_KEY, userInfo);
        })
        .catch(error => console.error(error))
        .finally(() => dispatch(setIsLoading(false)));
};

export const getUserSessionId = () => {
    const userSessionId =
        sessionStorage.getItem(USER_SESSION_ID_LOCAL_STORAGE_KEY) || uuid.v4();
    sessionStorage.setItem(USER_SESSION_ID_LOCAL_STORAGE_KEY, userSessionId);
    return userSessionId;
};