import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import React, {Component} from "react";

const loadingProgressStyles = theme => ({
  root: {
    position: 'relative',
    top: '40%'
  },
  preview: {
    position: 'relative',
    top: '50%',
    left: '50%'
  }
});

class LoadingProgress extends Component {

  render() {
    const {classes, ...other} = this.props;

    return (
        <CircularProgress className={!this.props.isDashletPreviewOpen ? classes.root : classes.preview}/>
    )
  }
}

LoadingProgress.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  isDashletPreviewOpen: PropTypes.bool,

  //func
};

export default withStyles(loadingProgressStyles)(LoadingProgress)
