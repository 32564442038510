import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectHLCStompClient} from "../../../reducers/webSocketSlice";
import {setDashletDataConfigProperty} from "../../../reducers/dashboard/createDashletSlice";
import HLCDashletsRestClient from "../../../api/rest/HLCDashletsRestClient";
import DashletConfigDropdown from "../../../components/dashboard/dashlets/DashletConfigDropdown/DashletConfigDropdown";
import {backendAsOption} from "../../HeatmapDashlet/utils";
import DashletConfigSelectTargetNames from "../../components/DashletConfigSelectTargetNames";
import HLCBackend from "types/HLCBackend";

interface FinOpsDashletCreateProps {
    dashletData: any;
    dashletErrors?: any;
}

const FinOpsDashletCreate: React.FC<FinOpsDashletCreateProps> = ({
    dashletErrors,
    dashletData
}) => {
    const dispatch = useDispatch();
    const {backendKey, targetNames} = dashletData.config;
    const stompClient = useSelector(selectHLCStompClient);
    const [backends, setBackends] = useState<HLCBackend[]>([]);
    const [availableTargetNames, setAvailableTargetNames] = useState<string[]>([]);
    const [isLoadingBackends, setIsLoadingBackends] = useState<boolean>(false);
    const [isLoadingTargetNames, setIsLoadingTargetNames] = useState<boolean>(false);

    const isLoading = isLoadingBackends || isLoadingTargetNames;

    const onChangeDashletConfigPropertyHandler = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        event.preventDefault();
        // @ts-ignore
        dispatch(setDashletDataConfigProperty(event.target.name, event.target.value));
    };

    const onSubmitTargetNames = (values: string[]) => {
        // @ts-ignore
        dispatch(setDashletDataConfigProperty("targetNames", values));
    }

    // initial loading of the Backend list
    useEffect(() => {
        setIsLoadingBackends(true);
        HLCDashletsRestClient.listUserBackends()
            .then(backends => {
                setBackends(backends);
                setIsLoadingBackends(false);
            })
            .catch(error => {
                console.error(error); //todo: handle error
                setIsLoadingBackends(false);
            });
    }, []);

    return <React.Fragment>
        <DashletConfigDropdown
            label="HLC Backend"
            name="backendKey"
            value={backendKey}
            options={backends.map((b: HLCBackend) => backendAsOption(b))}
            disabled={isLoading}
            error={dashletErrors?.backendKey}
            onChange={onChangeDashletConfigPropertyHandler}
        />
        <DashletConfigSelectTargetNames
            multi
            backendKey={backendKey}
            targetNames={targetNames}
            onSubmitTargetNames={onSubmitTargetNames}
        />
    </React.Fragment>;
};

export default FinOpsDashletCreate;
