import DashboardToolbar from "components/dashboard/DashboardToolbar/DashboardToolbar";
import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import { onAddAccordion, refreshAnomalyDashboard, updateDashboard } from "actions/dashboardActions";
import { activeDashletsSelector, dashboardByIdSelector } from "selectors/dashboard/DashboardSelectors";
import { createAnomalyDashboard } from "dashlets/HLCChartDashlet/actions";
import { ANOMALY_DASHBOARD } from "dashlets/AnomalyDetectionDashlet/constants";

class DashboardToolbarContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      object: props.object ? {...props.object} : {},
    }
  }

  componentDidUpdate() {
    if (this.props.activeDashboard && this.props.activeDashboard.dashboardType === ANOMALY_DASHBOARD && Object.keys(this.state.object).length === 0) {
      this.setState({ object: {...this.props.activeDashboard.timeSettings} });
    }
  }

  handleSubmit = (event) => {
    let dashboard = {
      ...this.props.activeDashboard,
      timeSettings: this.state.object
    }
      this.props.submit(dashboard);
  };

  handleChange = (name, value) => {
    const object = this.state.object;
    object[name] = value;

    this.setState({object});

    if (name == "refreshTime") {
      let dashboard = {
        ...this.props.activeDashboard,
        timeSettings: this.state.object
      }
      this.props.submit(dashboard);
    }
  };

  onChangeTimeRangeSettingsPropertiesObject = (obj) => {
    
    let object = this.state.object;
    object = {
      ...object,
      ...obj
    }
    this.setState({object})
  }

  
  render() {
    return (
        <DashboardToolbar 
        timeSettings={this.state.object}
        handleChangeProperty={this.handleChange}
        handleConfirm={this.handleSubmit}
        onChangeTimeRangeSettingsPropertiesObject={this.onChangeTimeRangeSettingsPropertiesObject}
        {...this.props}
        />
    );
  }
}

DashboardToolbarContainer.propTypes = {
  //data
  object: PropTypes.object,
  activeDashboard: PropTypes.object,
  
  //func
  onAddAccordionHandler: PropTypes.func,
  onRefreshDashboardHandler: PropTypes.func,
  onChangeTimeRangeSettingsPropertiesObject: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    activeDashboard: dashboardByIdSelector(state, state.dashboard.activeDashboardId),
    activeDashlets: activeDashletsSelector(state),
    object: state.dashboard.dashboardTree.entities.dashboards[state.dashboard.activeDashboardId].timeSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAccordionHandler: () => {
      dispatch(onAddAccordion())
    },
    onRefreshDashboardHandler: (dashboard) => {
      dispatch(createAnomalyDashboard(dashboard, false));
    },
    submit: (dashboard) => {
      dispatch(updateDashboard(dashboard));
    }
    //...
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardToolbarContainer)
