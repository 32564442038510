import {createSlice} from "@reduxjs/toolkit";
import {createSelector} from "reselect";

export const STOMP_CLIENT = {
    HLC: "HLC",
    PEI: "PEI"
};

const initialState = {
    stompClient: null,
    stompClients: {},
    subsciptionPaths: [],
    hlcActiveBackendKey: null,
    peiActiveBackendKey: null
};

const webSocketSlice = createSlice({
    name: 'webSocket',
    initialState,
    reducers: {
        setStompClient: {
            reducer(state, action) {
                const {stompClientKey, stompClient} = action.payload;
                state.stompClients[stompClientKey] = stompClient;
            },
            prepare(stompClientKey, stompClient) {
                return {
                    payload: {stompClientKey, stompClient}
                }
            },
        },
        setHLCActiveBackendKey: {
            reducer(state, action) {
                const { activeBackendKey } = action.payload;
                state.hlcActiveBackendKey = activeBackendKey;
            },
            prepare(activeBackendKey) {
                return {
                    payload: {activeBackendKey}
                }
            }
        },
        setPEIActiveBackendKey: {
            reducer(state, action) {
                const { activeBackendKey } = action.payload;
                state.peiActiveBackendKey = activeBackendKey;
            },
            prepare(activeBackendKey) {
                return {
                    payload: {activeBackendKey}
                }
            }
        },
        setSubscriptionPaths: {
            reducer(state, action) {
                const { subsciptionPaths } = action.payload;
                state.subsciptionPaths = subsciptionPaths;
            },
            prepare(subsciptionPaths) {
                return {
                    payload: {subsciptionPaths}
                }
            }
        },
    }
});

export const {setStompClient, setHLCActiveBackendKey, setPEIActiveBackendKey, setSubscriptionPaths} = webSocketSlice.actions;

export default webSocketSlice.reducer;

/**
 * ==========
 * Selectors
 * ==========
 */

const selectStompClientByKey = createSelector(
    [
        state => state.webSocket.stompClients,
        (state, stompClientKey) => stompClientKey
    ],
    (stompClients, stompClientKey) => stompClients[stompClientKey]
);

export const selectHLCStompClient_old = state => state.webSocket.stompClients[STOMP_CLIENT.HLC];

export const selectHLCStompClient = state => {
    if (!state) {
        console.error("selectHLCStompClient: 'state' is null or undefined.");
        return null;
    }
    if (!state.webSocket) {
        console.error("selectHLCStompClient: 'state.webSocket' is null or undefined.");
        return null;
    }
    if (!state.webSocket.stompClients) {
        console.error("selectHLCStompClient: 'state.webSocket.stompClients' is null or undefined.");
        return null;
    }
    //return state.webSocket.stompClients[STOMP_CLIENT.HLC];
    const hlcStompClient = state.webSocket.stompClients[STOMP_CLIENT.HLC];

    if (!hlcStompClient) {
        console.error("selectHLCStompClient: 'hlcStompClient' is null or undefined.");
    }
    else {
        // Log the Stomp client details for troubleshooting
        // console.log("HLC StompClient Details:", hlcStompClient);
        // If the connection details are properties on the client, log them:
        // console.log("HLC StompClient Connection Details:", {
        //     // Replace 'connectionDetail' with the actual property names
        //     ip: hlcStompClient.connectionDetail?.ip,
        //     port: hlcStompClient.connectionDetail?.port,
        //     url: hlcStompClient.connectionDetail?.url,
        //     // ... any other relevant details
        // });
        // If the Stomp client has methods to get connection details, call them:
        if (typeof hlcStompClient.getConnectionDetails === 'function') {
            console.log("HLC StompClient Connection Details:", hlcStompClient.getConnectionDetails());
        }
    }

    return hlcStompClient;

};

export const selectIsHLCStompClientConnected = state => selectHLCStompClient(state)?.connected;

export const selectPEIStompClient = state => state.webSocket.stompClients[STOMP_CLIENT.PEI];
export const selectIsPEIStompClientConnected = state => state.webSocket.stompClients[STOMP_CLIENT.PEI] && state.webSocket.stompClients[STOMP_CLIENT.PEI].connected;

export const selectSubscriptionPaths = state => state.webSocket.subsciptionPaths;