import React from 'react';
import * as _ from 'lodash';
import Dialog from "@material-ui/core/Dialog";
import {Scrollbars} from "react-custom-scrollbars";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {formatDateTime, formatFloatNumber} from "../oldUtils";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Draggable from "react-draggable";
import Paper from "@material-ui/core/Paper";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#alert-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

/**
 * Spike selection dialog box component with list of the latest spikes.
 *
 * @param open is spike selection dialog open
 * @param lastTargetSpikes the list of the last target spikes
 * @param handleClose handler function for close button
 * @param handleSelect handler function for the selection of the spike
 * @returns {JSX.Element}
 * @constructor
 */
const SpikeSelectionDialog = ({open, lastTargetSpikes, handleClose, handleSelect}) => {
    const getColor = (dataValueDouble) => {
        if (dataValueDouble < 1.5) {
            return '#81c784';
        }
        if (dataValueDouble < 2) {
            return '#FFC428';
        }
        if (dataValueDouble < 2.5) {
            return '#FF7987';
        }
        return '#FF2371';
    }

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            PaperComponent={PaperComponent}
            aria-labelledby="alert-dialog-title"
        >
            <DialogTitle
                id="alert-dialog-title"
                style={{ cursor: 'move' }}
            >
                Select spike to preview
            </DialogTitle>
            <DialogContent>
                <Scrollbars style={{height: '300px', width: '250px'}}>
                    <List sx={{pt: 0}} component="nav">
                        {_.map(lastTargetSpikes, spike => {
                            const targetName = spike.target;
                            const timestamp = spike.timestamp;
                            return <ListItem key={timestamp} button onClick={() => {
                                handleSelect(targetName, timestamp)
                            }}>
                                <ListItemText style={{color: getColor(spike.dataValueDouble)}}
                                              primary={`${formatDateTime(timestamp)} - ${formatFloatNumber(spike.dataValueDouble)}`}/>
                            </ListItem>
                        })}
                    </List>
                </Scrollbars>
            </DialogContent>
        </Dialog>
    );
}

export default SpikeSelectionDialog;
