import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LOGIN_PATH } from '../../../routes/paths';

export default function requireAuthentication(Component) {

  class AuthenticatedComponent extends React.Component {
    render() {
      return this.props.isAuthenticated === true
        ? <Component {...this.props} />
        : <Redirect to={LOGIN_PATH} />;
    }
  }

  const mapStateToProps = (state) => {
    return {
      isAuthenticated: state.auth.isAuthenticated
    }
  };

  return connect(
    mapStateToProps
  )(AuthenticatedComponent);
}