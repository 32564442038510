import PropTypes from "prop-types";
import React, {Component} from "react";

class Hidden extends Component {

  render() {
    return this.props.hidden ? null : this.props.children;
  }
}

Hidden.propTypes = {
  hidden: PropTypes.bool,
};

export default Hidden;