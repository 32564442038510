const padding = '3px 4px';
const borderRadius = '2px';
const label = {
  textTransform: 'none',
  fontWeight: 'bold',
};

const submitButtonStyles = {
  root: {
    padding: padding,
    borderRadius: borderRadius,
    background: '#ec7211',
    color: '#fff',
    border: '1px solid #ec7211',
    textShadow: 'none',
    "&:hover": {
      backgroundColor: '#eb5f07',
      borderColor: '#eb5f07',
    },
  },
  label: label,
};

const cancelButtonStyles = {
  root: {
    padding: padding,
    borderRadius: borderRadius,
    color: '#545b64',
    "&:hover": {
      backgroundColor: '#fafafa',
      color: '#16191f',
    },
  },
  label: label
};

export {
  submitButtonStyles,
  cancelButtonStyles
};