import React, { useState } from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import { Input } from "@material-ui/core";
import { InputAdornment, IconButton, Tooltip, Zoom } from "@material-ui/core";

const FileInput = (props) => {

    const [importFile, setImportFile] = useState({});
    const [importFileName, setImportFileName] = useState('');

    const inputRef = React.useRef(null);

    const handleChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImportFileName(file.name);
            setImportFile(file);

            let reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = () => {
                const fileInfo = {
                name: file.name,
                type: file.type,
                size: Math.round(file.size / 1000) + ' kB',
                base64: reader.result,
                file: file,
                };
                props.onDone(fileInfo);
            }
        }
    }

    return <React.Fragment>
        <input
            type="file"
            ref={inputRef}
            onChange={handleChange}
            style={{ display: 'none' }}
            accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
        />
        <Tooltip title="Upload ZIP" placement="top" TransitionComponent={Zoom} arrow>
            <div onClick={() => inputRef.current.click()} style={{ display: "flex" }}>
                <Input fullWidth
                    value={importFileName}
                    readOnly
                    endAdornment={
                        <InputAdornment position='end'>
                            <IconButton disableRipple={true} size="small"><PublishIcon /></IconButton>
                        </InputAdornment>
                    }
                />
            </div>
        </Tooltip>
    </React.Fragment>

}

export default FileInput;