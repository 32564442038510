import pjson from './../package.json';

export const CLIENT_VERSION = pjson.version;
export const REACT_VERSION = pjson.dependencies.react;

export const IS_PEI_WS_ENABLED = (process.env.NODE_ENV === 'production' ? true : false);
// export const IS_PEI_WS_ENABLED = true;
//export const IS_HLC_WS_ENABLED = (process.env.NODE_ENV === 'production' ? true : false);
export const IS_HLC_WS_ENABLED = true;

export const GATE_SERVER_URL = (process.env.NODE_ENV === 'production' ? 'https://app.enteros.com/upbeat-api' : 'http://localhost:8091/upbeat-api');
export const HLC_SERVER_URL = (process.env.NODE_ENV === 'production' ? 'https://app.enteros.com/hlc' : 'http://localhost:8092/hlc');
export const PEI_SERVER_URL = (process.env.NODE_ENV === 'production' ? 'https://app.enteros.com/pei' : 'http://localhost:8093/pei');
// export const GATE_SERVER_URL = (process.env.NODE_ENV === 'production' ? 'https://appdemo.enteros.com/upbeat-api' : 'http://localhost:8091/upbeat-api');
// export const HLC_SERVER_URL = (process.env.NODE_ENV === 'production' ? 'https://appdemo.enteros.com/hlc' : 'http://localhost:8092/hlc');
// export const PEI_SERVER_URL = (process.env.NODE_ENV === 'production' ? 'https://appdemo.enteros.com/pei' : 'http://localhost:8093/pei');

export const PEI_WEB_SOCKET_URL = `${PEI_SERVER_URL}/app/ws`;
export const HLC_WEB_SOCKET_URL = `${HLC_SERVER_URL}/app/ws`;

export const DASHBOARD_URL = (process.env.NODE_ENV === 'production' ? 'https://app.enteros.com/dashboard' : 'http://localhost:3000/dashboard');
export const EMAIL_URL = (process.env.NODE_ENV === 'production' ? 'https://app.enteros.com/emailConfiguration' : 'http://localhost:3000/emailConfiguration');
export const HLC_URL = (process.env.NODE_ENV === 'production' ? 'https://app.enteros.com/hlc' : 'http://localhost:8092/hlc');
export const PEI_URL = (process.env.NODE_ENV === 'production' ? 'https://app.enteros.com/pei' : 'http://localhost:8093/pei');

// export const DASHBOARD_URL = (process.env.NODE_ENV === 'production' ? 'https://appdemo.enteros.com/dashboard' : 'http://localhost:3000/dashboard');
// export const EMAIL_URL = (process.env.NODE_ENV === 'production' ? 'https://appdemo.enteros.com/emailConfiguration' : 'http://localhost:3000/emailConfiguration');
// export const HLC_URL = (process.env.NODE_ENV === 'production' ? 'https://appdemo.enteros.com/hlc' : 'http://localhost:8092/hlc');
// export const PEI_URL = (process.env.NODE_ENV === 'production' ? 'https://appdemo.enteros.com/pei' : 'http://localhost:8093/pei');

export const BASE_API_URL = `${GATE_SERVER_URL}/api`

export const WRITE_TO_SUPPORT_URL = 'https://enteros.com/contact-us';
export const HELP_DOCS_URL = 'https://content.enteros.com';
export const VIDEO_GUIDES_URL = 'https://www.youtube.com/@Enterosinc/videos';
export const REFER_URL = 'https://enteros.com/contact-us';

export const APP_LEGAL_URL = 'https://enteros.com/legal/';