import {publish} from "../api/websocket/hlc/hlcCommonWebSocketClient";
import {WEB_SOCKET_ACTION_PREFIX, WEB_SOCKET_SUBSCR_PREFIX} from "constants/webSocketConstants";
import ListDashletTargetConfigurationOptionsStompFrame from "./types/ListDashletTargetConfigurationOptionsStompFrame";
import ListBaselinesStompFrame from "./types/ListBaselinesStompFrame";

export const SAVE_HLC_DASHLET_CONFIGURATION_ACTION =       `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.dashlet.configuration.save`
export const HLC_DASHLET_CONFIGURATION_SUBSCRIPTION =      `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.dashlet.configuration`

export const DELETE_HLC_DASHLET_CONFIGURATION_ACTION =        `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.dashlet.configuration.delete.execute`
export const DELETE_DASHLET_CONFIGURATION_SUBSCRIPTION =      `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.dashlet.configuration.delete`

export const saveDashletConfiguration = (appState: any, backendKey: string,
                                         dashletId: number, dashletConfiguration: any) =>
    publish(appState, backendKey, SAVE_HLC_DASHLET_CONFIGURATION_ACTION, {
        backendKey,
        dashletId,
        dashletConfiguration
    });

export const deleteDashletConfiguration = (appState: any, backendKey: string, dashletId: number) =>
    publish(appState, backendKey, DELETE_HLC_DASHLET_CONFIGURATION_ACTION, {
        dashletId
    });

const LIST_TARGET_NAMES_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.dashlet.configuration.target.options.list`;
export const LIST_TARGET_NAMES_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.dashlet.configuration.target.options`;

export const listTargetNames = (appState: any, backendKey: string) =>
    publish(appState, backendKey, LIST_TARGET_NAMES_ACTION, {
        empty: ""
    } as ListDashletTargetConfigurationOptionsStompFrame);

const LIST_BASELINES_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.tools.baselines.list.execute`;
export const LIST_BASELINES_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.tools.baselines.list`;

export const listBaselines = (appState: any, backendKey: string) =>
    publish(appState, backendKey, LIST_BASELINES_ACTION, {
        empty: ""
    });
