import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import PEIChartBagDashletContainer from "./PEIChartBagDashletContainer";
import {selectIsPEIStompClientConnected, selectPEIStompClient} from "reducers/webSocketSlice";
import * as webSocketClient from "../webSocketClient";
import {handleLoadChartBagChart, handleLoadChart} from "../actions";
import {selectDashletData} from "reducers/dashboard/dashboardSlice";
import WebSocketSubscription from "websocket/WebSocketSubscription";

const PEIChartBagDashletView = (props) => {
    const {dashlet} = props;
    const data = useSelector(state => selectDashletData(state, dashlet.id));
    const stompClient = useSelector(selectPEIStompClient);
    const isStompClientConnected = useSelector(selectIsPEIStompClientConnected);
    const dispatch = useDispatch();

    return <React.Fragment>
        <PEIChartBagDashletContainer
            {...props}
            data={data}
        />
        {isStompClientConnected && [<WebSocketSubscription
            stompClient={stompClient}
            subscriptionDestination={`${webSocketClient.LOAD_PEI_CHART_BAG_CHART_SUBSCRIPTION}-${dashlet.config.backendKey}-${dashlet.id}`}
            // onSubscribeCallback={() => dispatch(refresh(dashlet))}
            handleMessage={response => dispatch(handleLoadChartBagChart(response))}
        />
        ,
        <WebSocketSubscription
            stompClient={stompClient}
            subscriptionDestination={`${webSocketClient.LOAD_PEI_CHART_SUBSCRIPTION}-${dashlet.config.backendKey}-${dashlet.id}`}
            // onSubscribeCallback={() => dispatch(refresh(dashlet))}
            handleMessage={response => dispatch(handleLoadChart(response))}
        />
        ]}
    </React.Fragment>
};

export default PEIChartBagDashletView;