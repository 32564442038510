import AnomalyDetectionView from "./components/AnomalyDetectionView";
import { DASHLET_TYPE_LABEL, DASHLET_TYPE_NAME } from "./constants";


export const ANOMALY_DETECTION_DASHLET_CONFIG = {
    [DASHLET_TYPE_NAME]: {
      typeName: DASHLET_TYPE_NAME,
      typeLabel: DASHLET_TYPE_LABEL,
      viewComponent: AnomalyDetectionView,
    }
  };