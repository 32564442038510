import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import StartPageContent from "./StartPageContent";
import {downloadDefaultBackend, downloadDefaultBackendProperties} from "../../../actions/infrastructure/backendActions";
import {Scrollbars} from 'react-custom-scrollbars';
import * as _ from "lodash";
import { toastr } from "react-redux-toastr";
import { cancelEditDashboard, saveEditDashboard } from 'actions/dashboardActions';
import { setTargetConfiguration } from 'reducers/dashboard/dashboardSlice';
import { dashboardModalSelector } from 'selectors/dashboard/DashboardSelectors';
import { selectIsPEIStompClientConnected } from 'reducers/webSocketSlice';
import { createDefaultTarget } from 'actions/dashboardActions';
import { checkBackendConnection } from 'dashlets/HLCChartDashlet/actions';
class StartPageContentContainer extends Component {

  // // Lifecycle method: componentDidMount()
  // componentDidMount() {
  //   this.props.downloadDefaultBackend();
  //   this.props.downloadDefaultBackendProperties();
  // }

  constructor(props) {
    super(props);

    this.state = {
      isLocked: false,
      errors: {},
      backendKey: "",
    };
  }

  lockForm = () => {
    this.setState({isLocked: true});
  };

  unlockForm = () => {
    this.setState({isLocked: false});
  };

  resetForm = () => {
    this.setState({
      errors: {},
    });
  };

  onChangeInputHandler = (event) => {
    event.preventDefault();

    let {errors} = this.state;
    const target = event.target;

    this.props.handleChangeProperty(target.name, target.value);
    errors[target.name] = false;

    if (this.props.targetConfiguration.useDatabaseUrl) {
      this.props.handleChangeProperty(target.name, target.value);
      errors[target.name] = false;
    }
    
    this.setState({errors});
  };

  validateObjectData = (objectData) => {
    const errors = {};
    
    if (!_.trim(objectData.databaseHost)) {
      errors.databaseHost = true;
    }
    if (!_.trim(objectData.databasePort)) {
      errors.databasePort = true;
    }
    if (!_.trim(objectData.databaseUser)) {
      errors.databaseUser = true;
    }
    if (!_.trim(objectData.databasePassword)) {
      errors.databasePassword = true;
    }
    if (!_.trim(objectData.databaseName)) {
      errors.databaseName = true;
    }
    if (this.props.targetConfiguration.useDatabaseUrl) {
      errors.databaseUrl = true;
      errors.databaseName = false;
      errors.databaseHost = false;
      errors.databasePort = false;
      errors.databaseUser = false;
      errors.databasePassword = false;
    } 
    
    if (this.state.backendKey === "") {
      toastr.error("Backend was not selected..");
      errors.backendKey = true;
    }
    return errors;
  };

  onClickCancelHandler = (event) => {
    event.preventDefault();
    this.props.cancel();
  };

  handleBackendSelect = (event) => {
    this.setState({backendKey: event.target.value})
  }
  
  createTarget = (createDashboard) => {
    this.props.handleCreateTarget(this.state.backendKey, createDashboard);
  }

  handleCheckBackendConnection = () => {
    this.props.checkBackendConnection(this.state.backendKey);
  }

  render() {
    const {errors, isLocked} = this.state;
    const {objectData} = this.props;
    return (
      <Scrollbars>
          <StartPageContent
              objectData={objectData}
              errors={errors}
              isLocked={isLocked}
              handleChangeInput={this.onChangeInputHandler}
              handleClose={this.onClickCancelHandler}
              backendKey={this.state.backendKey}
              handleBackendSelect={this.handleBackendSelect}
              onOpen={this.resetForm}
              createTarget={this.createTarget}
              handleCheckBackendConnection={this.handleCheckBackendConnection}
              {...this.props}
          />
      </Scrollbars>
    );
  }
}

StartPageContentContainer.propTypes = {
  //data
  startPageApplication: PropTypes.string,
  isHLCStompClientConnected: PropTypes.bool,
  isPEIStompClientConnected: PropTypes.bool,

  //func
  downloadDefaultBackend: PropTypes.func,
  downloadDefaultBackendProperties: PropTypes.func,
  targetConfiguration: PropTypes.object,
  // setDashboardModalProperty: PropTypes.func,
  objectData: PropTypes.object,
  submit: PropTypes.func,
  handleCreateTarget: PropTypes.func,
  checkBackendConnection: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    objectData: dashboardModalSelector(state),
    startPageApplication: state.ui.startPageApplication,
    targetConfiguration: state.dashboard.targetConfiguration,
    isPEIStompClientConnected: selectIsPEIStompClientConnected(state),
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    downloadDefaultBackend: () => {
      dispatch(downloadDefaultBackend())
    },
    downloadDefaultBackendProperties: () => {
      dispatch(downloadDefaultBackendProperties())
    },
    cancel: () => {
      dispatch(cancelEditDashboard());
    },
    submit: (objectData, backendKey) => {
      dispatch(saveEditDashboard(objectData, backendKey, "", true));
    },
    handleChangeProperty: (propertyName, propertyValue) => {
      dispatch(setTargetConfiguration(propertyName, propertyValue))
    },
    handleCreateTarget: (backendKey, isCreateDefaultDashboard) => {
      dispatch(createDefaultTarget(backendKey, isCreateDefaultDashboard));
    },
    checkBackendConnection: (backendKey) => {
      dispatch(checkBackendConnection(backendKey));
    }
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StartPageContentContainer)