import React, { useState } from 'react';
import { InputLabel, Menu, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import ScheduleIcon from '@material-ui/icons/Schedule';
import NumberInput from 'components/NumberInput/NumberInput';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import CancelButton from 'components/common/Button/CancelButton';
import SubmitButton from 'components/common/Button/SubmitButton';

const TimeRangeMenu = ({ timeSettings,
  anchorEl, isMenuOpen, handleMenuClose, handleChangeInput, handleConfirm, onChangeTimeRangeSettingsPropertiesObject
}) => {

    const handleClickTimeAmount = () => {
        onChangeTimeRangeSettingsPropertiesObject({
            isTimeAmount: true,
            isTimeRange: false,
        });
    }

    const handleClickTimeRange = () => {
        onChangeTimeRangeSettingsPropertiesObject({
            isTimeAmount: false,
            isTimeRange: true,
        });
    }

    return (
    <Menu
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          marginTop: '40px',
          width: '400px',
        },
      }}
    >
      <div style={{
        marginTop: '5px',
        marginBottom: '3px',
        borderBottom: 'dashed 1px grey',
        width: '90%',
        display: 'flex',
        marginLeft: '10px',
      }}>
        <ScheduleIcon fontSize="medium" style={{ fontSize: '17px', marginRight: '7px', marginBottom: '5px' }} />
        Time Range Settings
      </div>
      <div className='settingHeader'>
        <div className="timeSettingName" style={{color: (timeSettings?.isTimeAmount ? '#ec7211' : '')}} 
          onClick={() => handleClickTimeAmount()}>Time Amount</div>
        <div className="timeSettingName" style={{color : (timeSettings?.isTimeRange ? '#ec7211' : ''), borderRight: 'unset'}}  
          onClick={() => handleClickTimeRange()}>Time Range</div>
      </div>
      {timeSettings.isTimeAmount ? (
        <div>
          <div style={{ display: 'flex', marginTop: '15px' }}>
            <div style={{ display: 'flex', marginLeft: '15px' }}>
              <InputLabel style={{ marginTop: '9px', marginRight: '20px' }}>Use:</InputLabel>
              <div style={{ display: 'flex', marginRight: '5px' }}>
                <NumberInput
                  initialValue={timeSettings.timeAmount}
                  isLocked={false}
                  onChange={(value) => handleChangeInput("timeAmount", value)}
                />
              </div>
            </div>
            <div style={{ marginRight: '60px' }}>
            <Select
              value={timeSettings.timeMeasure}
              onChange={(event) => handleChangeInput("timeMeasure", event.target.value)}
              style={{ minWidth: 120, marginLeft: '10px', border: '1px solid #c0c0c4', paddingLeft: '2px', fontSize: '14px' }}
            >
              {['Minute', 'Hour', 'Day', 'Week', 'Month', 'Year'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            </div>
          </div>
          <div style={{ display: 'flex', marginTop: '15px' }}>
            <div style={{ display: 'flex', marginLeft: '15px' }}>
              <InputLabel style={{ marginTop: '9px', marginRight: '10px' }}>From:</InputLabel>
              <div style={{ display: 'flex', marginRight: '5px' }}>
                <NumberInput
                  initialValue={timeSettings.timeAmountAgo}
                  isLocked={false}
                  onChange={(value) => handleChangeInput("timeAmountAgo", value)}
                />
              </div>
            </div>
            <div style={{ display: 'flex', marginRight: '60px' }}>
            <Select
              value={timeSettings.timeMeasureAgo}
              onChange={(event) => handleChangeInput("timeMeasureAgo", event.target.value)}
              style={{ minWidth: 120, marginLeft: '10px', border: '1px solid #c0c0c4', paddingLeft: '2px', fontSize: '14px' }}
            >
              {['Minute', 'Hour', 'Day', 'Week', 'Month', 'Year'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
            </div>
          </div>
        </div>
    ) : null}

      {timeSettings.isTimeRange ? (
        <div>
          <div style={{ marginLeft: '40px', marginTop: '10px' }}>
            <span style={{ fontSize: '13px' }}>From Time:</span>
            <KeyboardDateTimePicker
              variant="inline"
              ampm={false}
              label=""
              value={timeSettings.timeRangeStartTime}
              onChange={(value) => {
                const milliseconds = value ? new Date(value).getTime() : null;
                handleChangeInput("timeRangeStartTime", milliseconds)}
              }
              minutesStep={5}
              format="DD/MM/YY HH:mm"
              className='dateTimePicker'
              style={{ marginTop: '5px', border: '1px solid #c0c0c4', width: '300px' }}
              InputProps={{
                style: {
                  fontSize: '13px', paddingLeft: '2px'
                }
              }}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '40px' }}>
              <span style={{ fontSize: '13px', marginRight: '10px' }}>To Time:</span>
            </div>
            <KeyboardDateTimePicker
              variant="inline"
              ampm={false}
              label=""
              value={timeSettings.timeRangeEndTime}
              onChange={(value) => {
                const milliseconds = value ? new Date(value).getTime() : null;
                handleChangeInput("timeRangeEndTime", milliseconds)
              }}
              minutesStep={5}
              format="DD/MM/YY HH:mm"
              style={{ marginTop: '5px', marginLeft: '40px', border: '1px solid #c0c0c4', width: '300px' }}
              InputProps={{
                style: {
                  fontSize: '13px', paddingLeft: '2px'
                }
              }}
            />
          </div>
        </div>
      ) : null}

      {/* {timeSettings.isTextInput ? (
        <>
          <textarea className="text-input" rows={20}></textarea>
        </>
      ) : null} */}

      {/* {!timeSettings.isTextInput ? (
        <>
          <div style={{
            marginTop: '5px',
            marginBottom: '3px',
            borderBottom: 'dashed 1px grey',
            width: '90%',
            display: 'flex',
            marginLeft: '10px',
            marginTop: '20px'
          }}>
            <RestoreIcon fontSize="default" style={{ fontSize: '17px', marginRight: '7px', marginBottom: '5px' }} />
            Superimpose Historical Data
          </div>

        <div style={{ marginLeft: '15px', marginTop: '15px', fontSize: '12px' }}>
            <FormControlLabel
              value="option1"
              control={<Checkbox checked={selectedOptions.timeAmountFromOption} onChange={handleOptionChange} name="option1" sx={{ fontSize: 28 }} />}
              label={
                <>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontSize: '13px', marginRight: '11px' }}>Time Amount From:</span>
                    <NumberInput
                      initialValue={0}
                      isLocked={false}
                      onChange={(value) => console.log('New value:', value)}
                    />
                  </div>
                </>
              }
            />
            <div className='timeDrop' style={{display: 'flex', marginLeft:'150px', marginTop:'10px'}}>
                <Select
                    value="Hour"
                    // onChange={this.handleSelectChange}
                    style={{ minWidth: 208, marginLeft:'10px', border: '1px solid #c0c0c4', paddingLeft:'2px', fontSize:'14px' }}
                >
                    <MenuItem value={'Minute'}>Minute</MenuItem>
                    <MenuItem value={'Hour'}>Hour</MenuItem>
                    <MenuItem value={'Day'}>Day</MenuItem>
                    <MenuItem value={'Week'}>Week</MenuItem>
                    <MenuItem value={'Month'}>Month</MenuItem>
                    <MenuItem value={'Year'}>Year</MenuItem>
                </Select> 
            </div>

            <FormControlLabel
              value="option2"
              control={<Checkbox checked={selectedOptions.superImposeTimeRangeOption} onChange={handleOptionChange} name="option2"/>}
              label={
                <div style={{ alignItems: 'center', marginTop: '20px' }}>
                  <span style={{ fontSize: '13px', marginRight: '11px' }}>Superimpose Time Range:</span>
                  <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    label=""
                    value={selectedSuperimposeTime}
                    onChange={handleSuperimposeTimeChange}
                    minutesStep={5}
                    format="DD/MM/YY HH:mm"
                    style={{ marginTop: '5px', border: '1px solid #c0c0c4', width: '300px' }}
                    InputProps={{
                      style: {
                        fontSize: '13px', paddingLeft: '2px'
                      }
                    }}
                    disabled={!selectedOptions.superImposeTimeRangeOption}
                  />
                </div>
              }
            />
            <FormControlLabel
                value="option3"
                control={<Checkbox checked={selectedOptions.adjustEndTimeOption} onChange={handleOptionChange} name="option3"/>}
                label={
                    <span style={{ fontSize: '13px', marginRight: '11px' }}>Adjust end-time after data processing</span>
                }
                style={{ marginTop: '10px' }}
            />                       
         </div> 
          
        </>
      ) : null} */}

      {/* <Divider /> */}

      <div className='settings-footer'>
        <CancelButton onClick={handleMenuClose}>Cancel</CancelButton>
        <SubmitButton onClick={handleConfirm} style={{ marginRight: '10px' }}>Apply</SubmitButton>
      </div>
    </Menu>
  );
};

TimeRangeMenu.propTypes = {
  timeSettings: PropTypes.object,

  handleConfirm: PropTypes.func,
  handleMenuClose: PropTypes.func.isRequired,
  onChangeTimeRangeSettingsPropertiesObject: PropTypes.func,
};

export default TimeRangeMenu;
