import {createSlice} from "@reduxjs/toolkit";
import * as appsConfig from "../constants/appsConfig";
import {routeTo} from "../actions/routingActions";
import {DASHBOARD_HOME_PATH} from "../routes/paths";

const initialState = {
    startPageApplication: appsConfig.HLC // pei or hlc
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setStartApplication(state, action) {
            state.startPageApplication = action.payload;
        }
    }
});

export const {setStartApplication} = uiSlice.actions;

export default uiSlice.reducer;

export const routeToDashboard = () => dispatch =>
    dispatch(routeTo(DASHBOARD_HOME_PATH));