import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ForgotPassword from "./ForgotPassword";
import * as AuthActions from "../../../actions/authActions";
import {clearForgotPasswordErrorMessage} from "../../../reducers/authSlice";

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userDetails: {
        username: '',
      }
    };
  }

  onClickForgotPasswordHandler = (event) => {
    event.preventDefault();

    this.props.forgotPassword(this.state.userDetails);
  };

  onChangeInputHandler = (event) => {
    event.preventDefault();

    let {userDetails} = this.state;
    const target = event.target;

    userDetails[target.name] = target.value;

    this.setState({userDetails});
  };

  render() {
    const {errorMessage, isInProgress} = this.props;

    return (
      <ForgotPassword
        errorMessage={errorMessage}
        disabled={isInProgress}
        onSend={this.onClickForgotPasswordHandler}
        onChangeInputHandler={this.onChangeInputHandler}
      />
    );
  }

  componentWillUnmount() {
    // this.props.clearLoginErrorMessage();
  }
}

ForgotPasswordContainer.propTypes = {
  //data
  isInProgress: PropTypes.bool,
  errorMessage: PropTypes.string,

  //func
  showRegistrationPage: PropTypes.func,
  showForgotPasswordPage: PropTypes.func,
  forgotPassword: PropTypes.func.isRequired,
  clearForgotPasswordErrorMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.forgotPasswordErrorMessage,
    isInProgress: state.auth.isForgotPasswordInProgress,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (forgotPasswordData) => {
      dispatch(AuthActions.forgotPassword(forgotPasswordData));
    },
    clearForgotPasswordErrorMessage: () => {
      dispatch(clearForgotPasswordErrorMessage());
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordContainer)