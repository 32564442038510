import { refresh, setDashletFrameSize, setExtremes, setXExtremes } from "../actions";
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Typography from "@material-ui/core/Typography";
import { Scrollbars } from 'react-custom-scrollbars';
import LoadingProgress from "components/dashboard/dashlets/LoadingProgress";
import { setIsChartDataLoading } from "reducers/dashboard/dashboardSlice";

let firstInitialLoad = {};

class HLCChartDashletChartContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashlet: null,
    }
  }

  componentDidMount() {
    const { dashlet, containerWidth, containerHeight, isResizing, setDashletFrameSize, refresh, isDashletPreviewOpen, setIsChartDataLoading } = this.props;
    if (!isDashletPreviewOpen)
      setDashletFrameSize(dashlet.id, containerWidth, containerHeight);
    if (!firstInitialLoad[dashlet.id] || isDashletPreviewOpen) {
      setIsChartDataLoading(true)
      refresh(dashlet, true, containerWidth, containerHeight);
      firstInitialLoad[dashlet.id] = true;
    }
  }

  componentDidUpdate(prevProps) {
    const { dashlet, containerWidth, containerHeight, isResizing, setDashletFrameSize, refresh, isDashletPreviewOpen, setIsChartDataLoading} = this.props;
    if ((!isResizing && (containerWidth !== prevProps.containerWidth || containerHeight !== prevProps.containerHeight))) {
      if (!isDashletPreviewOpen)
        setDashletFrameSize(dashlet.id, containerWidth, containerHeight);
      setIsChartDataLoading(true)
      refresh(dashlet, true, containerWidth, containerHeight);
    }
  }

  componentWillUnmount() {
    const { dashlet, refresh, setIsChartDataLoading, isDashletPreviewOpen} = this.props;
    if (isDashletPreviewOpen) {
      setIsChartDataLoading(true)
      refresh(dashlet, true, 0, 0);
    }
  }

  render() {
    const {
      // dashlet: {
      //   settings: {
      //     displayAs,
      //   },
      // },
      data,
      containerHeight,
      containerWidth
    } = this.props;

    if (!data || !data.base64ImageChart) {
      if (data && data.hasErrors) {
        return (
          <Typography variant="body2">{data.errorMessage}</Typography>
        );
      }
      return (
        <Typography variant="body2">No data to display</Typography>
      );
    }

    return (
      this.props.isChartDataLoading ?
        <LoadingProgress isDashletPreviewOpen={this.props.isDashletPreviewOpen} /> :
        <Scrollbars style={{ height: this.props.isDashletPreviewOpen ? containerHeight : containerHeight + 10}}>
          <img src={`data:image/gif;base64,${data.base64ImageChart}`} style={{paddingTop: this.props.isDashletPreviewOpen ? '29px' : '0px'}}/>
        </Scrollbars>
    );
  }
}

HLCChartDashletChartContainer.propTypes = {
  //data
  dashlet: PropTypes.object.isRequired,
  data: PropTypes.object,
  containerHeight: PropTypes.number,
  containerWidth: PropTypes.number,
  isResizing: PropTypes.bool,
  //...

  //func
  setDashletFrameSize: PropTypes.func,
  refresh: PropTypes.func,
  setExtremes: PropTypes.func,
  setXExtremes: PropTypes.func,
  setIsChartDataLoading: PropTypes.func,
  //...
};

const mapStateToProps = (state) => {
  return {
    isDashletPreviewOpen: state.dashboard.isDashletPreviewOpen,
    isChartDataLoading: state.dashboard.isChartDataLoading,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setExtremes: (dashletId, key, xMin, xMax, yMin, yMax) => {
      dispatch(setExtremes(dashletId, key, xMin, xMax, yMin, yMax));
    },
    setXExtremes: (dashletId, xMin, xMax, isManualZoom) => {
      dispatch(setXExtremes(dashletId, xMin, xMax, isManualZoom));
    },
    setDashletFrameSize: (dashletId, width, height) => {
      dispatch(setDashletFrameSize(dashletId, width, height));
    },
    refresh: (dashlet, isResizeOnly, containerWidth, containerHeight) => {
      dispatch(refresh(dashlet, isResizeOnly, containerWidth, containerHeight));
    },
    setIsChartDataLoading: (value) => {
      dispatch(setIsChartDataLoading(value))
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HLCChartDashletChartContainer)
