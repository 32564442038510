import React from "react";
import SectionHeader from "../atomic/SectionHeader";
import Grid from "@material-ui/core/Grid";
import HeaderLabel from "../atomic/HeaderLabel";
import HeaderValue from "../atomic/HeaderValue";
import SectionContainer from "../atomic/SectionContainer";
import SnowflakeDatabaseReportWarehousesSection from "../../types/report/SnowflakeDatabaseReportWarehousesSection";
import {formatDecimalCurrency, formatDecimalNumber, formatNumber} from "utils/FormattingUtils";

interface WarehousesSectionProps {
    title: string;
    data: SnowflakeDatabaseReportWarehousesSection;
}

const WarehousesSection: React.FC<WarehousesSectionProps> = ({
    title,
    data
                                                             }) => (
    <SectionContainer>
        <SectionHeader>{title}</SectionHeader>
        <Grid container spacing={1}>
            <Grid item xs={2}><HeaderLabel>Warehouses</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderLabel>Credits Consumed</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderLabel>Compute Cost</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderLabel>Avg. Daily Credits Used</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderLabel>Avg. Daily Cost</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderLabel>Remote Spillage (GB)</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderValue>{formatNumber(data?.warehouses)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalNumber(data?.creditsConsumed)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalCurrency(data?.computeCost)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalNumber(data?.avgDailyCreditsUsed)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalCurrency(data?.avgDailyCost)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalNumber(data?.remoteSpillageGb)}</HeaderValue></Grid>
        </Grid>
    </SectionContainer>
);

export default WarehousesSection;
