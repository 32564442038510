import {publish} from 'api/websocket/hlc/hlcCommonWebSocketClient';
import {publish as publishPEI} from 'api/websocket/pei/peiCommonWebSocketClient';
import {
    WEB_SOCKET_ACTION_PREFIX,
    WEB_SOCKET_SUBSCR_GROUP_PREFIX,
    WEB_SOCKET_SUBSCR_PREFIX
} from "constants/webSocketConstants";

export const SAVE_EMAIL_CONFIG_URL =                            `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.save.emailconfiguration`;
export const SAVE_EMAIL_CONFIG_URL_SUBSCRIPTION =               `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.save.email.configuration.result`
export const GET_EMAIL_CONFIG_URL =                             `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.get.emailconfiguration`;
export const GET_EMAIL_CONFIG_URL_SUBSCRIPTION =                `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.get.emailconfiguration.result`;
export const SAVE_EMAIL_CONFIGURATION_FOR_PEI =                 `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.pei.save.emailconfiguration`;
export const SAVE_EMAIL_CONFIGURATION_FOR_PEI_SUBSCRIPTION =    `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.pei.save.email.config.result`;
export const GET_EMAIL_CONFIG_URL_FOR_PEI =                     `${WEB_SOCKET_ACTION_PREFIX}/ui.get.email.configuration`;
export const GET_EMAIL_CONFIG_URL_PEI_SUBSCRIPTION =            `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.pei.get.emailconfiguration.result`;
export const TEST_EMAIL_CONFIG_URL_FOR_PEI =                    `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.pei.test.emailconfiguration`;
export const TEST_EMAIL_CONFIG_URL_FOR_PEI_SUBSCRIPTION =       `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.pei.test.emailconfiguration.result`;
export const TEST_EMAIL_CONFIG_URL_FOR_HLC =                    `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.test.emailconfiguration`;
export const TEST_EMAIL_CONFIG_URL_FOR_HLC_SUBSCRIPTION =       `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.test.emailconfiguration.result`;

export const saveEmailConfiguration = (appState, backendKey, params) => 
    publish(appState, backendKey, SAVE_EMAIL_CONFIG_URL, params);

export const getEmailConfiguration = (appState, backendKey) =>
    publish(appState, backendKey, GET_EMAIL_CONFIG_URL, {});

export const testEmailConfigurationHLC = (appState, backendKey, params) => 
    publish(appState, backendKey, TEST_EMAIL_CONFIG_URL_FOR_HLC, params);

export const saveEmailConfigurationForPEI= (appState, backendKey, params) => 
    publishPEI(appState, backendKey, SAVE_EMAIL_CONFIGURATION_FOR_PEI, params);

export const getEmailConfigurationPEI= (appState, backendKey, params) =>
    publishPEI(appState, backendKey, GET_EMAIL_CONFIG_URL, params);

export const testEmailConfigurationPEI = (appState, backendKey, params) =>
    publishPEI(appState, backendKey, TEST_EMAIL_CONFIG_URL_FOR_PEI, params);