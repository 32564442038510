import {TableBody} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {Scrollbars} from 'react-custom-scrollbars';
import * as uuid from "uuid";
import * as _ from 'lodash';
import moment from "moment";
import numeral from "numeral";

const styles = theme => ({
  root: {
    overflowX: 'initial'
  },
  table: {
    overflowX: 'initial'
  },
  headCell: {
    fontWeight: 'bold',
  }
});

const buildTableRows = (rowGroups) => {
  let rows = [];

  _.each(_.keys(rowGroups), (groupKey) => {
    if (groupKey !== 'none') {
      rows.push({
        type: 'groupKey',
        text: groupKey
      });
    }
    rows.push({
      type: 'header',
    });
    _.each(rowGroups[groupKey], (item) => {
      rows.push({
        type: 'data',
        // name: `${item.target}/${item.capture}/${item.metric}/${item.measurement}`,
        item: item
      });
    })
  });

  return rows;
};

const formatDateTime = (timestamp) => {
  return moment(timestamp).format('M/D/YYYY hh:mm:ss');
};

const formatNumber = (number) => {
  return numeral(number).format("0,0");
};

const formatFloatNumber = (number) => {
  return numeral(number).format("0,0.00");
};

class HLCChartDashletReport extends Component {
  render() {
    const {
      classes, reportData: {
        isEmpty,
        items,
        timeRanges: {
          startTime,
          endTime,
          historicalStartTime,
          historicalEndTime
        }
      }
    } = this.props;

    if (isEmpty) {
      return (
          <Typography variant="body2">No Data to Show.</Typography>
      );
    }

    return (
        <Scrollbars>
          <TableContainer component={Paper} className={classes.root}>
            <Table className={classes.table} size="small" aria-label="a dense table">

              <TableBody>
                <TableRow>
                  {startTime && endTime && <TableCell colSpan={4} align="center" className={classes.headCell}>
                    Time Period: {formatDateTime(startTime)} - {formatDateTime(endTime)}
                  </TableCell>}
                </TableRow>

                {historicalStartTime > 0 && historicalEndTime > 0 &&
                  <TableRow>
                    <TableCell colSpan={4} align="center" className={classes.headCell}>
                      Historical Time Period: {formatDateTime(historicalStartTime)} - {formatDateTime(historicalEndTime)}
                    </TableCell>
                  </TableRow>
                }

                {buildTableRows(items).map((row) => {
                  if (row.type === 'groupKey') {
                    return (
                        <TableRow key={uuid.v4()}>
                          <TableCell colSpan={4} align="center" className={classes.headCell}>
                            {row.text}
                          </TableCell>
                        </TableRow>
                    )
                  }
                  if (row.type === 'header') {
                    return (
                        <TableRow key={uuid.v4()}>
                          <TableCell align="center" className={classes.headCell}>Name</TableCell>
                          <TableCell align="center" className={classes.headCell}>Max Value</TableCell>
                          <TableCell align="center" className={classes.headCell}>Average</TableCell>
                          <TableCell align="center" className={classes.headCell}>Count</TableCell>
                        </TableRow>
                    )
                  }
                  if (row.type === 'data') {
                    return (
                        <TableRow key={uuid.v4()}>
                          <TableCell>{`${row.item.target}/${row.item.capture}/${row.item.metric}/${row.item.measurement}`}</TableCell>
                          <TableCell align="right">{formatFloatNumber(row.item.maxValue)}</TableCell>
                          <TableCell align="right">{formatFloatNumber(row.item.avgValue)}</TableCell>
                          <TableCell align="right">{formatNumber(row.item.count)}</TableCell>
                        </TableRow>
                    )
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbars>
    );
  }
}

HLCChartDashletReport.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  reportData: PropTypes.object,
  // configList: PropTypes.array,

  //func
  //...
};

export default withStyles(styles)(HLCChartDashletReport);
