import DashletTargetConfigurationOption from "../../types/DashletTargetConfigurationOption";
import React, {useMemo, useState} from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {ArrowRightIcon} from "@material-ui/pickers/_shared/icons/ArrowRightIcon";
import StorageIcon from '@material-ui/icons/Storage';
import {Checkbox} from "@material-ui/core";
import {TreeView} from "@material-ui/lab";
import * as _ from "lodash";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    treeItem: {
        fontSize: '0.875rem',
    },
});

const transformData = (data: DashletTargetConfigurationOption[]): Record<string, string[]> => {
    return data.reduce((acc: Record<string, string[]>, item: DashletTargetConfigurationOption) => {
        acc[item.databasePlatform] = acc[item.databasePlatform] || [];
        acc[item.databasePlatform].push(item.targetName);
        return acc;
    }, {});
};

interface DashletTargetConfigurationTreeViewProps {
    data: DashletTargetConfigurationOption[];
    selectedTargetNames: string[];
    filterText: string;
    toggleTargetName: (targetName: string) => void;
}

const DashletTargetConfigurationTreeView: React.FC<DashletTargetConfigurationTreeViewProps> = ({
                                                                                                   data,
                                                                                                   selectedTargetNames,
                                                                                                   filterText,
                                                                                                   toggleTargetName
                                                                                               }) => {
    const classes = useStyles();
    const groupedData = useMemo(() => transformData(data), [data]);
    const [expanded, setExpanded] = useState<string[]>([])

    const toggleDatabasePlatform = (databasePlatform: string) => {
        const prevExpanded = expanded || [];
        const nextExpanded: string[] = prevExpanded.indexOf(databasePlatform) !== -1
            ? _.without(prevExpanded, databasePlatform)
            : _.concat(prevExpanded, databasePlatform);
        setExpanded(nextExpanded);
    }

    const renderTree = (groupedData: Record<string, string[]>): JSX.Element[] => {
        return Object.keys(groupedData).map((platform, index) => (
            <TreeItem
                key={index}
                nodeId={`platform-${index}`}
                endIcon={<div/>}
                collapseIcon={<ArrowDropDownIcon/>}
                expandIcon={<ArrowRightIcon/>}
                label={
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <StorageIcon style={{marginRight: 8}}/>
                        {platform}
                    </div>
                }
                onClick={() => toggleDatabasePlatform(`platform-${index}`)}
            >
                {groupedData[platform].map((targetName, targetIndex) => (
                    <TreeItem
                        onClick={() => toggleTargetName(targetName)}
                        key={targetIndex}
                        nodeId={`target-${index}-${targetIndex}`}
                        data-testid={`target-${index}-${targetIndex}`}
                        label={
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox
                                    checked={selectedTargetNames.indexOf(targetName) > -1}
                                />
                                {targetName}
                            </div>
                        }
                    />
                ))}
            </TreeItem>
        ));
    };

    React.useEffect(() => {
        setExpanded(Object.keys(groupedData)
            .map((platform, platformIndex) => `platform-${platformIndex}`));
    }, []);

    React.useEffect(() => {
        if (filterText.length >= 2) {
            // Automatically expand platforms containing matching targets
            const newExpanded = Object.keys(groupedData).filter(platform =>
                groupedData[platform].some(targetName => targetName.toLowerCase().includes(filterText.toLowerCase()))
            );
            setExpanded(newExpanded.map((platform, index) => `platform-${index}`));
        } else {
            // setExpanded([]); // Collapse all when the filter is cleared
        }
    }, [filterText]);

    return (
        <TreeView
            expanded={expanded}
            defaultCollapseIcon={<ArrowDropDownIcon/>}
            defaultExpandIcon={<ArrowRightIcon/>}
            defaultEndIcon={<div/>}
            className={classes.treeItem}
        >
            {renderTree(groupedData)}
        </TreeView>
    );
};

export default DashletTargetConfigurationTreeView;

