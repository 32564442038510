import ListItemText from "@material-ui/core/ListItemText";
import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
  root: {

  },
  active: {
    color: '#ec7211',
    fontWeight: 'bold',
  },
});

class LeftNavBarListItemText extends Component {
  render() {
    const {classes, active} = this.props;

    return (
        <ListItemText
            {...this.props}
            className={classes.root}
            active=""
            classes={{
              primary: active ? classes.active : null
            }}
        />
    );
  }
}

LeftNavBarListItemText.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  primary: PropTypes.string,
  active: PropTypes.bool,

  //func
};

export default withStyles(styles)(LeftNavBarListItemText);