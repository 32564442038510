import { createSelector } from 'reselect';
import * as _ from 'lodash';

export const dashboardStateSelector = state => state.dashboard;
export const dashboardTreeSelector = state => dashboardStateSelector(state).dashboardTree;
export const editDashboardGroupSelector = state => dashboardStateSelector(state).editDashboardGroup;
export const dashboardModalSelector = state => dashboardStateSelector(state).dashboardModal;
export const activeDashboardIdSelector = state => dashboardStateSelector(state).activeDashboardId;
export const dashboardsSelector = state => dashboardTreeSelector(state).entities.dashboards;
export const dashletsSelector = state => dashboardTreeSelector(state).entities.dashlets;
export const dashletsDataSelector = state => dashboardStateSelector(state).dashletsData;
export const dashboardByIdSelector = (state, dashboardId) => dashboardsSelector(state)[dashboardId];
export const dashletByIdSelector = (state, dashletId) => dashletsSelector(state)[dashletId];
export const selectAnomalyDashboard = (state, dashboardId) => dashboardStateSelector(state).anomalyDashboard[dashboardId];
export const isDashletPreviewOpenSelector = state => dashboardStateSelector(state).isDashletPreviewOpen;
export const quickDashboardModalSelector = state => dashboardStateSelector(state).quickDahboardConfig.isQuickDahboardModalOpen;

export const activeDashboardSelector = createSelector(
    activeDashboardIdSelector,
    dashboardsSelector,
    (activeDashboardId, dashboards) => dashboards && activeDashboardId ? dashboards[activeDashboardId] : null);

export const activeDashboardLayoutSelector = createSelector(
    activeDashboardSelector,
    (activeDashboard) => {

      //return activeDashboard ? (activeDashboard.layoutConfig || {}) : null;
      //RW fix to have an array
      return activeDashboard ? (activeDashboard.layoutConfig || []) : null;
    }
);

// export const activeDashboardLayoutSelector = createSelector(
//     activeDashboardSelector,
//     (activeDashboard) => {

//         //if (!activeDashboard || !Array.isArray(activeDashboard.layoutConfig)) {
//         //    console.warn('Expected layoutConfig to be an array, received:', activeDashboard.layoutConfig);
//         //    return [];
//         //}

//         //const layoutConfig = activeDashboard ? (activeDashboard.layoutConfig || []) : null;
//         const layoutConfig = activeDashboard ? (activeDashboard.layoutConfig || []) : [];
//         console.log('layoutConfig', layoutConfig); // This will output the value in the console
//         return layoutConfig;

//     }
// );


export const activeDashletsSelector = createSelector(
    activeDashboardSelector,
    dashletsSelector,
    (activeDashboard, dashlets) => {
      const dashletsMap = {};
      if (!activeDashboard) {
        return dashletsMap;
      }
      _.each(activeDashboard.dashlets, (dashletId) => {
        return dashletsMap[dashletId] = dashlets[dashletId];
      });

      return dashletsMap;
    }
);

export const activeDashletsDataSelector = createSelector(
    activeDashboardSelector,
    dashletsDataSelector,
    (activeDashboard, dashletsData) => {
      const dashletsDataMap = {};
      if (!activeDashboard) {
        return dashletsDataMap;
      }
      _.each(activeDashboard.dashlets, (dashletId) => {
        return dashletsDataMap[dashletId] = dashletsData[dashletId];
      });

      return dashletsDataMap;
    }
);


