import {BASE_API_URL} from "config";
import CommonRestClient from "api/rest/CommonRestClient";

class BackendsRestClient extends CommonRestClient {

    async listUserBackends() {
        return await this.get("/backends/list");
    }
}

export default new BackendsRestClient(`${BASE_API_URL}/dashboard/groups/dashboards/dashlets/hlc`);