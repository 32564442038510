interface UserDTO {
    id: number | null;
    name: string | null;
    username: string | null;
}

export const getDefaultUserDTO = (id: number | null = null): UserDTO => {
    return {
        id,
        name: null,
        username: null,
    }
}

export default UserDTO;
