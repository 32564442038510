import React from 'react';
import { useDispatch } from "react-redux";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import { handleDefultDashletConfig } from './actions';
import { CREATE_TARGET_SUBSCRIPTION } from './webSocketClient';

const HLCDefaultChartDashletCreate = () => {
    const dispatch = useDispatch();

    return <React.Fragment>

        {/* <HLCUserSessionWebSocketSubscription
            key={`hlc-chart-dashlet-ws-chart-subscription`}
            subscriptionDestination={CREATE_TARGET_SUBSCRIPTION}
            handleMessage={response => dispatch(handleDefultDashletConfig(response))}
        /> */}
    </React.Fragment>
}

export default HLCDefaultChartDashletCreate;