import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CancelButton from "components/common/Button/CancelButton";
import SubmitButton from "components/common/Button/SubmitButton";
import RadioButton from "components/common/RadioButton/RadioButton";
import Select from "components/common/Select/Select";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import TimeRangeTab from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTab";
import TimeRangeTabPanel from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTabPanel";
import TimeRangeTabs from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTabs";
import TIME_RANGE_TYPES from "constants/dashboard/timeRangeTypes";
import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { KeyboardDateTimePicker } from "@material-ui/pickers";

const styles = theme => ({
  root: {

  },
  appBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
  formLabel: {
    padding: '0.4rem 0rem',
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formControlLabel: {
    margin: theme.spacing(1),
    minWidth: '40px'
  },
  formControlInput: {
    margin: theme.spacing(1),
    minWidth: '60px',
  },
  formControlSelect: {
    margin: theme.spacing(1),
    minWidth: '120px',
  },
  formControlDatetime: {
    margin: theme.spacing(1),
    width: '192px',
    minWidth: '160px',
  },
  footer: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    }
  },
  grow: {
    flexGrow: 1,
  },
  switchRoot: {
    marginLeft: 0,
  },
  switchLabel: {
    marginLeft: 8,
  },
});


class PEIChartBagDashletTimeRange extends Component {

  handleChangeTimeRangeType = (event, value) => {
    this.props.handleChangeProperty('timeRangeType', value);
  };

  handleChangeInput = (event) => {
    this.props.handleChangeProperty(event.target.name, event.target.value);
  };

  handleChangeTimeRangeStartTime = (value) => {
    this.props.handleChangeProperty('timeRangeStartTime', value);
  };

  handleChangeTimeRangeEndTime = (value) => {
    this.props.handleChangeProperty('timeRangeEndTime', value);
  };

  toggleIsHistorical = () => {
    this.props.handleChangeProperty('isHistorical', !this.props.timeRangeSettings.isHistorical);
  };

  changeHistoricalTimeRangeType = (timeRangeType) => {
    this.props.handleChangeProperty('historicalTimeRangeType', timeRangeType);
  };

  handleChangeHistoricalTimeFrom = (value) => {
    this.props.handleChangeProperty('historicalTimeFrom', value);
  };


  render() {
    const {
      classes,
      timeRangeSettings: {timeRangeType, lastTime, lastTimeMeasure, timeAmount, timeAmountMeasure, timeAmountFrom, timeAmountFromMeasure, timeRangeStartTime, timeRangeEndTime,
        isHistorical, historicalTimeRangeType, historicalTimeAmountFrom, historicalTimeAmountFromMeasure, historicalTimeFrom},
      handleConfirm,
      handleClose
    } = this.props;

    return (
        <Paper className={classes.root}>
          <AppBar position="relative" className={classes.appBar} component="div">
            <TimeRangeTabs value={timeRangeType} onChange={this.handleChangeTimeRangeType}>
              <TimeRangeTab
                  label="Last Time"
                  value={TIME_RANGE_TYPES.LAST_TIME}
              />
              <TimeRangeTab
                  label="Time Amount"
                  value={TIME_RANGE_TYPES.TIME_AMOUNT}
              />
              <TimeRangeTab
                  label="Time Range"
                  value={TIME_RANGE_TYPES.TIME_RANGE}
              />
            </TimeRangeTabs>
          </AppBar>
          <TimeRangeTabPanel hidden={TIME_RANGE_TYPES.LAST_TIME !== timeRangeType}>
            <FormGroup row>
              <FormControl className={classes.formControlLabel}>
                <Typography component="div" className={classes.formLabel}>
                  Last
                </Typography>
              </FormControl>
              <FormControl className={classes.formControlInput}>
                <TextField
                    name="lastTime"
                    onChange={this.handleChangeInput}
                    value={lastTime}
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 120,
                      type: 'number',
                    }}
                />
              </FormControl>
              <FormControl className={classes.formControlSelect}>
                <Select value={lastTimeMeasure} name="lastTimeMeasure" onChange={this.handleChangeInput}>
                  <MenuItem value="Minute">Minutes</MenuItem>
                  <MenuItem value="Hour">Hours</MenuItem>
                  <MenuItem value="Day">Days</MenuItem>
                  <MenuItem value="Week">Weeks</MenuItem>
                  <MenuItem value="Month">Months</MenuItem>
                  <MenuItem value="Year">Years</MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
          </TimeRangeTabPanel>
          <TimeRangeTabPanel hidden={TIME_RANGE_TYPES.TIME_AMOUNT !== timeRangeType}>
            <FormGroup row>
              <FormControl className={classes.formControlLabel}>
                <Typography component="div" className={classes.formLabel}>
                  Use
                </Typography>
              </FormControl>
              <FormControl className={classes.formControlInput}>
                <TextField
                    name="timeAmount"
                    onChange={this.handleChangeInput}
                    value={timeAmount}
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 120,
                      type: 'number',
                    }}
                />
              </FormControl>
              <FormControl className={classes.formControlSelect}>
                <Select value={timeAmountMeasure} name="timeAmountMeasure" onChange={this.handleChangeInput}>
                  <MenuItem value="Minute">Minutes</MenuItem>
                  <MenuItem value="Hour">Hours</MenuItem>
                  <MenuItem value="Day">Days</MenuItem>
                  <MenuItem value="Week">Weeks</MenuItem>
                  <MenuItem value="Month">Months</MenuItem>
                  <MenuItem value="Year">Years</MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
            <FormGroup row>
              <FormControl className={classes.formControlLabel}>
                <Typography component="div" className={classes.formLabel}>
                  From
                </Typography>
              </FormControl>
              <FormControl className={classes.formControlInput}>
                <TextField
                    name="timeAmountFrom"
                    onChange={this.handleChangeInput}
                    value={timeAmountFrom}
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 120,
                      type: 'number',
                    }}
                />
              </FormControl>
              <FormControl className={classes.formControlSelect}>
                <Select value={timeAmountFromMeasure} name="timeAmountFromMeasure" onChange={this.handleChangeInput}>
                  <MenuItem value="Minute">Minutes</MenuItem>
                  <MenuItem value="Hour">Hours</MenuItem>
                  <MenuItem value="Day">Days</MenuItem>
                  <MenuItem value="Week">Weeks</MenuItem>
                  <MenuItem value="Month">Months</MenuItem>
                  <MenuItem value="Year">Years</MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
          </TimeRangeTabPanel>
          <TimeRangeTabPanel hidden={TIME_RANGE_TYPES.TIME_RANGE !== timeRangeType}>
            <FormGroup row>
              <FormControl className={classes.formControlLabel}>
                <Typography component="div" className={classes.formLabel}>
                  From
                </Typography>
              </FormControl>
              <FormControl className={classes.formControlDatetime}>
                <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    value={timeRangeStartTime}
                    onChange={this.handleChangeTimeRangeStartTime}
                    onError={console.log}
                    disableFuture
                    // disableToolbar
                    minutesStep={5}
                    format="DD/MM/YY HH:mm"
                />
              </FormControl>
            </FormGroup>
            <FormGroup row>
              <FormControl className={classes.formControlLabel}>
                <Typography component="div" className={classes.formLabel}>
                  To
                </Typography>
              </FormControl>
              <FormControl className={classes.formControlDatetime}>
                <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    value={timeRangeEndTime}
                    onChange={this.handleChangeTimeRangeEndTime}
                    onError={console.log}
                    disableFuture
                    // disableToolbar
                    minutesStep={5}
                    format="DD/MM/YY HH:mm"
                />
              </FormControl>
            </FormGroup>
          </TimeRangeTabPanel>

          <TimeRangeTabPanel>
            <FormGroup row>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                    control={<Switch checked={isHistorical}
                                     onChange={this.toggleIsHistorical}
                                     name="isHistorical"
                    />}
                    label="Superimpose Historical Data"
                    classes={{root: classes.switchRoot, label: classes.switchLabel}}
                />
              </FormControl>
            </FormGroup>
            <div style={{display: isHistorical ? 'block' : 'none'}}>
              <FormGroup row>
                <FormGroup className={classes.formControlLabel}>
                  <RadioButton
                      checked={historicalTimeRangeType === TIME_RANGE_TYPES.HISTORICAL_TIME_AMOUNT}
                      onChange={() => this.changeHistoricalTimeRangeType(TIME_RANGE_TYPES.HISTORICAL_TIME_AMOUNT)}
                  />
                </FormGroup>
                <FormControl className={classes.formControlInput}>
                  <TextField
                      name="historicalTimeAmountFrom"
                      onChange={this.handleChangeInput}
                      value={historicalTimeAmountFrom}
                      disabled={historicalTimeRangeType !== TIME_RANGE_TYPES.HISTORICAL_TIME_AMOUNT}
                      inputProps={{
                        step: 1,
                        min: 1,
                        max: 120,
                        type: 'number',
                      }}

                  />
                </FormControl>
                <FormControl className={classes.formControlSelect}>
                  <Select
                      value={historicalTimeAmountFromMeasure}
                      name="historicalTimeAmountFromMeasure"
                      onChange={this.handleChangeInput}
                      disabled={historicalTimeRangeType !== TIME_RANGE_TYPES.HISTORICAL_TIME_AMOUNT}
                  >
                    <MenuItem value="Minute">Minutes</MenuItem>
                    <MenuItem value="Hour">Hours</MenuItem>
                    <MenuItem value="Day">Days</MenuItem>
                    <MenuItem value="Week">Weeks</MenuItem>
                    <MenuItem value="Month">Months</MenuItem>
                    <MenuItem value="Year">Years</MenuItem>
                  </Select>
                </FormControl>
              </FormGroup>
              <FormGroup row>
                <FormGroup className={classes.formControlLabel}>
                  <RadioButton
                      checked={historicalTimeRangeType === TIME_RANGE_TYPES.HISTORICAL_TIME_FROM}
                      onChange={() => this.changeHistoricalTimeRangeType(TIME_RANGE_TYPES.HISTORICAL_TIME_FROM)}
                  />
                </FormGroup>
                <FormControl className={classes.formControlDatetime}>
                  <KeyboardDateTimePicker
                      variant="inline"
                      ampm={false}
                      value={historicalTimeFrom}
                      onChange={this.handleChangeHistoricalTimeFrom}
                      onError={console.log}
                      disableFuture
                      // disableToolbar
                      minutesStep={5}
                      format="DD/MM/YY HH:mm"
                      disabled={historicalTimeRangeType !== TIME_RANGE_TYPES.HISTORICAL_TIME_FROM}
                  />
                </FormControl>
              </FormGroup>
            </div>
          </TimeRangeTabPanel>

          <Divider />

          <div className={classes.footer}>
            <div className={classes.grow} />
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
            <SubmitButton onClick={handleConfirm}>Apply</SubmitButton>
          </div>
        </Paper>
    );
  }
}

PEIChartBagDashletTimeRange.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  timeRangeSettings: PropTypes.object,
  errors: PropTypes.object,
  isLocked: PropTypes.bool,
  open: PropTypes.bool,

  //func
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  handleChangeProperty: PropTypes.func,
};

export default withStyles(styles)(PEIChartBagDashletTimeRange);
