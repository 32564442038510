import {publish} from 'api/websocket/hlc/hlcCommonWebSocketClient';

import {
    WEB_SOCKET_ACTION_PREFIX,
    WEB_SOCKET_SUBSCR_PREFIX
} from "constants/webSocketConstants";
import GenerateSnowflakeReportStompFrame from "./types/GenerateSnowflakeReportStompFrame";

// Load report names
export const SNOWFLAKE_DATABASE_REPORT_GENERATE_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.dbreport.snowflake.generate`;
export const SNOWFLAKE_DATABASE_REPORT_GENERATE_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.dbreport.snowflake`;

export const generateSnowflakeDatabaseReport = (appState: any, backendKey: string, payload: GenerateSnowflakeReportStompFrame) =>
    publish(appState, backendKey, SNOWFLAKE_DATABASE_REPORT_GENERATE_ACTION, payload);
