const AUTH = 'AUTH';

export const loadState = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return null;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return null;
  }
};

export const saveState = (key, value) => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    // ignore write errors
  }
};

export const removeState = key => {
  delete localStorage[key];
};

export const loadAuthState = () => {
  return loadState(AUTH);
};

export const saveAuthState = (auth) => {
  saveState(AUTH, auth);
};

export const removeAuthState = () => {
  removeState(AUTH)
};