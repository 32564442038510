import React from 'react';
import Typography from "@material-ui/core/Typography";
import HighchartsReact from "highcharts-react-official";
import {buildHeatmapChartConfig} from "../oldUtils";
import highchartsHeatmap from "highcharts/modules/heatmap";
import Highcharts from "highcharts";
import HighchartsCustomEvents from "highcharts-custom-events";

/**
 * Heatmap Chart component.
 *
 * @param props {alerts, height, width, dashlet, handleClickOnTarget}
 * @returns {JSX.Element}
 * @constructor
 */
const HeatmapChart = ({alerts, height, width, dashlet, handleClickOnTarget}) => {
    const [isHeatmapComponentPrepared, setIsHeatmapComponentPrepared] = React.useState(false);
    React.useEffect(() => {
        highchartsHeatmap(Highcharts);
        HighchartsCustomEvents(Highcharts);
        setIsHeatmapComponentPrepared(true);
    }, [alerts])

    if (!alerts || !isHeatmapComponentPrepared) {
        return <Typography variant="body2">No Data to Show.</Typography>;
    }

    return <HighchartsReact
        highcharts={Highcharts}
        options={buildHeatmapChartConfig(dashlet, alerts, dashlet.settings, height, width, handleClickOnTarget)}
    />;
}

export default HeatmapChart;

