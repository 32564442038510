import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import HLCBackend from "../../../types/HLCBackend";
import {setDashletDataConfigProperty} from "../../../reducers/dashboard/createDashletSlice";
import HLCDashletsRestClient from "../../../api/rest/HLCDashletsRestClient";
import DashletConfigDropdown from "../../../components/dashboard/dashlets/DashletConfigDropdown/DashletConfigDropdown";
import {backendAsOption} from "../../HeatmapDashlet/utils";
import DashletConfigSelectTargetNames from "../../components/DashletConfigSelectTargetNames";

interface SnowflakeReportDashletCreateProps {
    dashletData: any;
    dashletErrors?: any;
}

const SnowflakeReportDashletCreate: React.FC<SnowflakeReportDashletCreateProps> = ({
                                                                           dashletData,
                                                                           dashletErrors
                                                                       }) => {
    const dispatch = useDispatch();
    const {backendKey, targetName} = dashletData.config;
    const [backends, setBackends] = useState<HLCBackend[]>([]);
    const [isLoadingBackends, setIsLoadingBackends] = useState<boolean>(false);

    const onChangeDashletConfigPropertyHandler = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        event.preventDefault();
        // @ts-ignore
        dispatch(setDashletDataConfigProperty(event.target.name, event.target.value));
    };

    const onSubmitTargetNames = (values: string[]) => {
        // @ts-ignore
        dispatch(setDashletDataConfigProperty("targetName", values[0]));
    }

    // initial loading of the Backend list
    useEffect(() => {
        HLCDashletsRestClient.listUserBackends()
            .then(backends => {
                setBackends(backends);
                setIsLoadingBackends(false);
            })
            .catch(error => {
                console.error(error); //todo: handle error
                setIsLoadingBackends(false);
            });
    }, []);

    return <React.Fragment>
        <DashletConfigDropdown
            label="HLC Backend"
            name="backendKey"
            value={backendKey}
            options={backends.map((b: HLCBackend) => backendAsOption(b))}
            disabled={isLoadingBackends}
            error={dashletErrors?.backendKey}
            onChange={onChangeDashletConfigPropertyHandler}
        />
        <DashletConfigSelectTargetNames
            backendKey={backendKey}
            targetNames={targetName ? [targetName] : []}
            onSubmitTargetNames={onSubmitTargetNames}
        />
    </React.Fragment>;
};

export default SnowflakeReportDashletCreate;
