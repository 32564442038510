import TIME_RANGE_TYPES from "constants/dashboard/timeRangeTypes";
import { REGULAR_DASHBOARD } from "dashlets/AnomalyDetectionDashlet/constants";
import moment from "moment";

export const newDashboardObject = () => {
  return {
    id: null,
    name:'',
    description: null,
    ownerId: null,
    dashboardGroupId: null,
    layoutConfig: null,
    dashboardType: REGULAR_DASHBOARD
    // layoutConfig: [],
  };
};

export const newDashletTimeRangeSettings = () => {
  const now = moment();

  return {
    timeRangeType: TIME_RANGE_TYPES.LAST_TIME,
    //last time
    lastTime: 60,
    lastTimeMeasure: 'Minute',
    //time amount
    timeAmount: 1,
    timeAmountMeasure: 'Hour',
    timeAmountFrom: 1,
    timeAmountFromMeasure: 'Day',
    //time range
    timeRangeStartTime: now,
    timeRangeEndTime: now,

    //historical
    isHistorical: false,
    historicalTimeRangeType: TIME_RANGE_TYPES.HISTORICAL_TIME_AMOUNT,
    historicalTimeAmountFrom: 2,
    historicalTimeAmountFromMeasure: 'Day',
    historicalTimeFrom: now.subtract(1, 'days'),
  }
};