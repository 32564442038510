import React, {FC, useEffect, useState} from 'react';
import * as _ from 'lodash';
import HLCDashletsRestClient from 'api/rest/HLCDashletsRestClient';
import {useDispatch, useSelector} from 'react-redux';
import {selectReportNames, selectTemplateNames, setDashletDataConfigProperty, setDashletOptionsProperty} from "reducers/dashboard/createDashletSlice";
import DashletConfigDropdown from 'components/dashboard/dashlets/DashletConfigDropdown/DashletConfigDropdown';
import HLCBackend from 'types/HLCBackend';
import {listReportNames, listTemplateNames} from "../actions";
import {
    HEATMAP_LIST_REPORT_NAMES_SUBSCRIPTION,
    HEATMAP_LIST_TEMPLATE_NAMES_SUBSCRIPTION
} from '../webSocketClient';
import {
    ListHeatmapReportNamesStompResponse,
    ListHeatmapTemplateNamesStompResponse
} from '../types';
import {backendAsOption, stringAsOption} from '../utils';
import DashletConfigSelectTargetNames from "../../components/DashletConfigSelectTargetNames";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import { loadHLCChartTemplates } from 'dashlets/HLCChartDashlet/actions';
import { LOAD_HLC_CHART_TEMPLATES_SUBSCRIPTION } from 'dashlets/HLCChartDashlet/webSocketClient';
import { selectIsLoadingReportNames, selectIsLoadingTemplateNames, selectIsLoadingTemplates, toggleIsLoadingReportNames, toggleIsLoadingTemplateNames, toggleIsLoadingTemplates } from 'reducers/dashboard/dashboardSlice';

interface HeatmapDashletCreateProps {
    dashletData: any;
    dashletErrors?: any;
}

const HeatmapDashletCreate: FC<HeatmapDashletCreateProps> = ({
                                             dashletErrors,
                                             dashletData
                                         }) => {
    const {backendKey, spikeDetectionReportName, staticTemplateName, targetNames} = dashletData.config;
    const [backends, setBackends] = useState<HLCBackend[]>([]);
    const [isLoadingBackends, setIsLoadingBackends] = useState<boolean>(false);
    // const [isLoadingTemplateNames, setIsLoadingTemplateNames] = useState<boolean>(false);
    // const [isLoadingReportNames, setIsLoadingReportNames] = useState<boolean>(false);
    // const [reportNames, setReportNames] = useState<string[]>([]);
    // const [templateNames, setTemplateNames] = useState<string[]>([]);
    // const [IsLoadingTemplates, setIsLoadingTemplates] = useState<boolean>(false);

    // const isLoadingTemplateNames = useSelector(selectIsLoadingTemplateNames);
    const isLoadingReportNames = useSelector(selectIsLoadingReportNames);
    const isLoadingTemplates = useSelector(selectIsLoadingTemplates);
    const templateNames = useSelector(selectTemplateNames)
    const reportNames = useSelector(selectReportNames);

    const dispatch = useDispatch();

    const isLoading = isLoadingBackends || isLoadingReportNames || isLoadingTemplates;

    const onChangeDashletConfigPropertyHandler = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        event.preventDefault();
        // @ts-ignore
        dispatch(setDashletDataConfigProperty(event.target.name, event.target.value));
    };

    const onSubmitTargetNames = (values: string[]) => {
        // @ts-ignore
        dispatch(setDashletDataConfigProperty('targetNames', values));
    }

    // const handleListHeatmapReportNamesStompResponse = (response: ListHeatmapReportNamesStompResponse) => (dispatch: any, getState: any) => {
    //     if (!response.hasErrors) {
    //         dispatch(setDashletOptionsProperty({propertyName: "reportNames", propertyValue: response}))
    //         // setReportNames(response.reportNames);
    //     } else {
    //         console.log(response.errorMessage); //todo: handle error
    //     }
    //     dispatch(toggleIsLoadingReportNames(false));
    //     // setIsLoadingReportNames(false);
    // };

    // const handleListHeatmapTemplateNamesStompResponse = (response: ListHeatmapTemplateNamesStompResponse) => (dispatch: any, getState: any) => {
    //     if (!response.hasErrors) {
    //         setTemplateNames(response.templateNames);
    //     } else {
    //         console.log(response.errorMessage); //todo: handle error
    //     }
    //     dispatch(toggleIsLoadingTemplateNames(false));
    //     // setIsLoadingTemplateNames(false);
    // };

    // initial loading of the Backend list
    useEffect(() => {
        setIsLoadingBackends(true);
        HLCDashletsRestClient.listUserBackends()
            .then(backends => {
                setBackends(backends);
                setIsLoadingBackends(false);
            })
            .catch(error => {
                console.error(error); //todo: handle error
                setIsLoadingBackends(false);
            });
    }, []);

    useEffect(() => {
        if (backendKey) {
            // setIsLoadingTemplates(true);
            // dispatch(toggleIsLoadingTemplates(true))
            // dispatch(toggleIsLoadingTemplateNames(true))
            dispatch(loadHLCChartTemplates(backendKey));
        }
    }, [backendKey]);

    // list reports, templates and targets on change Backend
    useEffect(() => {
        if (backendKey) {
            // load report names
            // setIsLoadingReportNames(true);
            dispatch(toggleIsLoadingReportNames(true))
            dispatch(listReportNames(backendKey));

            // load template names
            // setIsLoadingTemplateNames(true);
            // dispatch(toggleIsLoadingTemplateNames(true))
            // dispatch(listTemplateNames(backendKey));
        }
    }, [backendKey]);

    return <React.Fragment>
        <DashletConfigDropdown
            label="HLC Backend"
            name="backendKey"
            value={backendKey}
            options={backends.map((b: HLCBackend) => backendAsOption(b))}
            disabled={isLoading}
            error={dashletErrors?.backendKey}
            onChange={onChangeDashletConfigPropertyHandler}
        />
        <DashletConfigDropdown
            label="Spike Detection Report"
            name="spikeDetectionReportName"
            value={spikeDetectionReportName}
            options={_.map(reportNames, (name: string) => stringAsOption(name))}
            disabled={isLoading}
            error={dashletErrors?.spikeDetectionReportName}
            onChange={onChangeDashletConfigPropertyHandler}
        />
        <DashletConfigDropdown
            label="Static Template"
            name="staticTemplateName"
            value={staticTemplateName}
            options={_.map(templateNames, (name: string) => stringAsOption(name))}
            disabled={isLoading}
            error={dashletErrors?.staticTemplateName}
            onChange={onChangeDashletConfigPropertyHandler}
        />
        <DashletConfigSelectTargetNames
            multi
            backendKey={backendKey}
            targetNames={targetNames}
            onSubmitTargetNames={onSubmitTargetNames}
        />

        {/* <HLCUserSessionWebSocketSubscription
            subscriptionDestination={HEATMAP_LIST_REPORT_NAMES_SUBSCRIPTION}
            handleMessage={response => {
                if (!response.hasErrors) {
                    dispatch(setDashletOptionsProperty({propertyName: "reportNames", propertyValue: response.reportNames}))
                    // setReportNames(response.reportNames);
                } else {
                    console.log(response.errorMessage); //todo: handle error
                }
                dispatch(toggleIsLoadingReportNames(false));
            }}
        /> */}
        {/* <HLCUserSessionWebSocketSubscription
            subscriptionDestination={HEATMAP_LIST_TEMPLATE_NAMES_SUBSCRIPTION}
            handleMessage={handleListHeatmapTemplateNamesStompResponse}
        /> */}

        {/* <HLCUserSessionWebSocketSubscription
            subscriptionDestination={LOAD_HLC_CHART_TEMPLATES_SUBSCRIPTION}
            handleMessage={response => {
                const {templates} = response;
                dispatch(setDashletOptionsProperty({propertyName: "templates", propertyValue: templates}));
                // dispatch(toggleIsLoadingTemplateNames(false))
                dispatch(toggleIsLoadingTemplates(false))
                // setIsLoadingTemplates(false);
            }}
        /> */}

    </React.Fragment>;
};

export default HeatmapDashletCreate;