import * as _ from 'lodash';

export const sortChartSeriesList = (chartSeriesList, chartElementKeys) => {
  let sortedChartSeriesList = [];

  _.each(chartElementKeys,(chartElementKey) => {
    //find chart series with chart element key and add to result list
    sortedChartSeriesList = _.concat(sortedChartSeriesList, _.filter(chartSeriesList, (chartSeries) => {
      return chartSeries.chartElementKey === chartElementKey;
    }));
    //remove chart series with chart element key
    chartSeriesList = _.filter(chartSeriesList, (chartSeries) => {
      return chartSeries.chartElementKey !== chartElementKey;
    })
  });

  return sortedChartSeriesList;
};


export const getTimeAmountMilliseconds = (timeAmount, timeMeasure) => {
  let time = 0.0;
  const MS_IN_SECOND = 1000;
  const SECONDS_IN_MINUTE = 60;
  const MINUTES_IN_HOUR = 60;
  const HOURS_IN_DAY = 24;
  const DAYS_IN_WEEK = 7;
  const DAYS_IN_MONTH = 30;
  const DAYS_IN_YEAR = 365;

  switch (timeMeasure) {
    case 'Second':
      time = timeAmount * MS_IN_SECOND;
      break;
    case 'Minute':
      time = timeAmount * SECONDS_IN_MINUTE * MS_IN_SECOND;
      break;
    case 'Hour':
      time = timeAmount * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MS_IN_SECOND;
      break;
    case 'Day':
      time = timeAmount * HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MS_IN_SECOND;
      break;
    case 'Week':
      time = timeAmount * DAYS_IN_WEEK * HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MS_IN_SECOND;
      break;
    case 'Month':
      time = timeAmount * DAYS_IN_MONTH * HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MS_IN_SECOND;
      break;
    case 'Year':
      time = timeAmount * DAYS_IN_YEAR * HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MS_IN_SECOND;
      break;
    default:
      throw new Error(`Unsupported time measure: ${timeMeasure}`);
  }

  return Math.floor(time);
};
