import {cancelEditDashboardGroup, saveEditDashboardGroup} from "actions/dashboardActions";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as _ from "lodash";
import {editDashboardGroupSelector} from "selectors/dashboard/DashboardSelectors";
import EditDashboardGroup from "./EditDashboardGroup";
import {setEditDashboardGroupProperty} from "reducers/dashboard/dashboardSlice";

class EditDashboardGroupContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLocked: false,
      errors: {}
    };
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if(nextProps.objectData !== prevState.objectData) {
  //     return {
  //       objectData: nextProps.objectData,
  //       isLocked: false,
  //       errors: {}
  //     };
  //   } else {
  //     return null
  //   }
  // }

  lockForm = () => {
    this.setState({isLocked: true});
  };

  unlockForm = () => {
    this.setState({isLocked: false});
  };

  onClickSubmitHandler = (event) => {
    event.preventDefault();

    this.lockForm();

    if (!this.validate()) {
      this.props.submit(this.props.objectData);
    } else {
      this.unlockForm();
    }
  };

  onClickCancelHandler = (event) => {
    event.preventDefault();
    this.props.cancel();
  };

  onChangeInputHandler = (event) => {
    event.preventDefault();

    const {errors} = this.state;
    const target = event.target;

    this.props.setEditDashboardGroupProperty(target.name, target.value);
    errors[target.name] = false;

    this.setState({errors});
  };

  validateObjectData = (objectData) => {
    const errors = {};

    if (!_.trim(objectData.name)) {
      errors.name = true;
    }

    return errors;
  };

  validate = () => {
    const {objectData} = this.props;
    const errors = this.validateObjectData(objectData);
    this.setState({errors});

    return _.keys(errors).length > 0;
  };

  render() {
    const {errors, isLocked} = this.state;
    const {objectData} = this.props;
    const enabled = Boolean(objectData);

    return enabled ? (
        <EditDashboardGroup
            open={enabled}
            objectData={objectData}
            errors={errors}
            isLocked={isLocked}
            handleChangeInput={this.onChangeInputHandler}
            handleConfirm={this.onClickSubmitHandler}
            handleClose={this.onClickCancelHandler}
        />
        ) : null;
  }
}

EditDashboardGroupContainer.propTypes = {
  //data
  objectData: PropTypes.object,

  //func
  cancel: PropTypes.func,
  submit: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    objectData: editDashboardGroupSelector(state),
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancel: () => {
      dispatch(cancelEditDashboardGroup());
    },
    submit: (objectData) => {
      dispatch(saveEditDashboardGroup(objectData));
    },
    setEditDashboardGroupProperty: (propertyName, propertyValue) => {
      dispatch(setEditDashboardGroupProperty(propertyName, propertyValue))
    }
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditDashboardGroupContainer)