import React from "react";
import {LazyLog, ScrollFollow} from "react-lazylog";

interface LogsViewProps {
    lines: string[];
}

const LogsView: React.FC<LogsViewProps> = ({
                                               lines
                                           }) => {

    return (
        <>
            { lines.length > 0 &&
                <ScrollFollow
                    startFollowing={true}
                    render={({ follow, onScroll }) => (
                        <LazyLog
                            text={lines.join("\n")}
                            height={600}
                            enableSearch
                            caseInsensitive
                            style={{textAlign: "left"}}
                            stream
                            follow={follow}
                            // onScroll={onScroll}
                        />
                        // <LazyLog url="http://example.log" stream follow={follow} onScroll={onScroll} />
                    )}
                />
                }
        </>
    // <>
    //         { lines.length > 0 && <LazyLog
    //             text={lines.join("\n")}
    //             // height={600}
    //             enableSearch
    //             caseInsensitive
    //             style={{textAlign: "left"}}
    //         />}
    //     </>
    );
};

export default LogsView;
