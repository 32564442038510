import ApplicationPage from "components/common/ApplicationPage/ApplicationPage";
import DashboardLeftNavBarContainer from "containers/dashboard/DashboardLeftNavBarContainer";
import React, {Component} from 'react';
import DashboardPageContent from "views/dashboard/Home/DashboardPageContent";

class DashboardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLeftMenuOpened: true
    }
  }

  setLeftMenuOpened = (open) => {
    this.setState({
      isLeftMenuOpened: open
    });
  };

  render() {
    const {isLeftMenuOpened} = this.state;
    const {classes} = this.props;

    return (
        <ApplicationPage
            leftNavBarComponent={<DashboardLeftNavBarContainer isLeftMenuOpened={isLeftMenuOpened} setLeftMenuOpened={this.setLeftMenuOpened} />}
            contentComponent={<DashboardPageContent isLeftMenuOpened={isLeftMenuOpened} />}
        />
    );
  }
}

export default DashboardPage;