import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import AddEmailConfiguration from "./AddEmailConfiguration";
import * as EmailConfigurationActions from "actions/EmailConfigurationActions";
const passwordPlaceHolder = "******";
class AddEmailConfigurationContainer extends Component {

    componentDidMount() {
      this.props.getEmailConfigurationData();
    }

    render() {
        const {
            isOpened,
            hide,
          } = this.props;
        return  (
            <AddEmailConfiguration
            {...this.props}
            onHideClick={hide}
        />
        )
    }
}
AddEmailConfigurationContainer.propTypes = {
    //data
    isOpened: PropTypes.bool,
    senderEmail: PropTypes.string,
    senderPassword: PropTypes.string,
    host: PropTypes.string,
    emailConfigurationType: PropTypes.string,
    emailPort: PropTypes.string,
    isFormLocked: PropTypes.bool,
    //func
    hide: PropTypes.func,
    setEmailConfiguration: PropTypes.func,
    changeConfigurationType: PropTypes.func,
    changePort: PropTypes.func,
    onTestClick: PropTypes.func,
};
const mapStateToProps = (state) => {
    return {
      isOpened: state.emailConfiguration.isEmailConfigurationModalBoxOpened,
      senderEmail: state.emailConfiguration.senderEmail,
      senderPassword: state.emailConfiguration.senderPassword == "" && state.emailConfiguration.senderEmail ? passwordPlaceHolder : state.emailConfiguration.senderPassword,
      host: state.emailConfiguration.domainName,
      emailConfigurationType: state.emailConfiguration.emailConfigurationType,
      emailPort: state.emailConfiguration.emailPort,
      isFormLocked: state.emailConfiguration.isFormLocked
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
      hide: () => {
        dispatch(EmailConfigurationActions.closeEmailConfigurationModal());
      },
      setEmailConfiguration: (emailConfig) => {
        dispatch(EmailConfigurationActions.setEmailConfiguration(emailConfig));
      },
      changeConfigurationType: (value) => {
        dispatch(EmailConfigurationActions.changeConfigurationType(value));
      },
      changePort: (value) => {
        dispatch(EmailConfigurationActions.changePort(value));
      },
      changeEmailId: (value) => {
        dispatch(EmailConfigurationActions.changeEmailID(value));
      },
      changePassword: (value) => {
        dispatch(EmailConfigurationActions.changePassword(value));
      },
      changeHost: (value) => {
        dispatch(EmailConfigurationActions.changeHost(value));
      },
      onTestClick: (emailConfig) => {
        dispatch(EmailConfigurationActions.sendTestEmailToUser(emailConfig));
      },
      getEmailConfigurationData: () => {
        dispatch(EmailConfigurationActions.getEmailConfigurationData());
      }
    }
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddEmailConfigurationContainer);