import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadIsAuthenticated } from '../../../actions/authActions';

class AuthenticationAware extends Component {

  componentWillMount() {
    this.props.dispatch(loadIsAuthenticated());
  }

  render() {
    if (this.props.isAuthenticatedLoaded) {
      return this.props.children;
    } else {
      return (<div className="empty-auth-aware"></div>);
    }
  }
}

AuthenticationAware.propTypes = {
  //data
  isAuthenticatedLoaded: PropTypes.bool,

  //func
};

const mapStateToProps = (state) => {
  return {
    isAuthenticatedLoaded: state.auth.isAuthenticatedLoaded
  }
};

export default connect(
  mapStateToProps
)(AuthenticationAware);