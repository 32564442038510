import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import CancelButton from "components/common/Button/CancelButton";
import SubmitButton from "components/common/Button/SubmitButton";
import Select from "components/common/Select/Select";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import TimeRangeTab from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTab";
import TimeRangeTabPanel from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTabPanel";
import TimeRangeTabs from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTabs";
import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  root: {

  },
  appBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
  formLabel: {
    padding: '0.4rem 0rem',
  },
  boxWrapper: {
    display: 'inline-flex',
    marginRight: '8px',
    height: '1rem'
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formControlLabel: {
    margin: theme.spacing(1),
    minWidth: '40px'
  },
  formControlLabelLong: {
    margin: theme.spacing(1),
    minWidth: '160px'
  },
  formControlInput: {
    margin: theme.spacing(1),
    minWidth: '60px',
  },
  formControlInputLong: {
    margin: theme.spacing(1),
    minWidth: '168px',
  },
  formControlSelect: {
    margin: theme.spacing(1),
    minWidth: '92px',
  },
  formControlDatetime: {
    margin: theme.spacing(1),
    width: '220px',
    minWidth: '160px',
  },
  footer: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    }
  },
  grow: {
    flexGrow: 1,
  },
  switchRoot: {
    marginLeft: 0,
  },
  switchLabel: {
    minWidth: 100,
    marginLeft: 8,
  },
});


class PEIChartBagDashletSettings extends Component {

  handleChangeInput = (event) => {
    this.props.handleChangeProperty(event.target.name, event.target.value);
  };

  toggle = (event) => {
    const name = event.target.name;
    this.props.handleChangeProperty(name, !this.props.settings[name]);
  };


  render() {
    const {
      classes,
      settings: {
        isTopValueFilter,
        topValueFilter,
        isChartPct,
        isSplitMetrics,
        isSmoothData,
        smoothDataValue,
        smoothDataMeasure,
        isRegressionTrend,
      },
      handleConfirm,
      handleClose
    } = this.props;

    return (
        <Paper className={classes.root}>
          <AppBar position="relative" className={classes.appBar} component="div">
            <TimeRangeTabs value="Settings">
              <TimeRangeTab
                  label="Settings"
                  value="Settings"
              />
            </TimeRangeTabs>
          </AppBar>
          <TimeRangeTabPanel>
            <FormGroup row>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                    control={<Switch checked={isSplitMetrics}
                                     onChange={this.toggle}
                                     name="isSplitMetrics"
                    />}
                    label="Split Metrics"
                    classes={{root: classes.switchRoot, label: classes.switchLabel}}

                />
              </FormControl>
            </FormGroup>
            <FormGroup row>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                    control={<Switch checked={isTopValueFilter}
                                     onChange={this.toggle}
                                     name="isTopValueFilter"
                    />}
                    label="Top Filter"
                    classes={{root: classes.switchRoot, label: classes.switchLabel}}

                />
              </FormControl>
              <FormControl className={classes.formControlInputLong}>
                <TextField
                    name="topValueFilter"
                    onChange={this.handleChangeInput}
                    value={topValueFilter}
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 120,
                      type: 'number',
                    }}
                    disabled={!isTopValueFilter}
                />
              </FormControl>
            </FormGroup>
            <FormGroup row>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                    control={<Switch checked={isSmoothData}
                                     onChange={this.toggle}
                                     name="isSmoothData"
                    />}
                    label="Smooth Data"
                    classes={{root: classes.switchRoot, label: classes.switchLabel}}

                />
              </FormControl>
              <FormControl className={classes.formControlInput}>
                <TextField
                    name="smoothDataValue"
                    onChange={this.handleChangeInput}
                    value={smoothDataValue}
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 120,
                      type: 'number',
                    }}
                    disabled={!isSmoothData}
                />
              </FormControl>
              <FormControl className={classes.formControlSelect}>
                <Select value={smoothDataMeasure} name="smoothDataMeasure" onChange={this.handleChangeInput} disabled={!isSmoothData}>
                  <MenuItem value="Minute">Minutes</MenuItem>
                  <MenuItem value="Hour">Hours</MenuItem>
                  <MenuItem value="Day">Days</MenuItem>
                  <MenuItem value="Week">Weeks</MenuItem>
                  <MenuItem value="Month">Months</MenuItem>
                  <MenuItem value="Year">Years</MenuItem>
                </Select>
              </FormControl>
            </FormGroup>


            <FormGroup row>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                    disabled={isSplitMetrics ? true : false}
                    control={<Switch checked={isChartPct}
                                     onChange={this.toggle}
                                     name="isChartPct"
                    />}
                    label="Chart as % of Total"
                    classes={{root: classes.switchRoot, label: classes.switchLabel}}

                />
              </FormControl>
              <div className={classes.grow} />
            </FormGroup>

            <FormGroup row>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                    control={<Switch checked={isRegressionTrend}
                                     onChange={this.toggle}
                                     name="isRegressionTrend"
                    />}
                    label="Regression Trend"
                    classes={{root: classes.switchRoot, label: classes.switchLabel}}

                />
              </FormControl>
            </FormGroup>
          </TimeRangeTabPanel>

          <Divider />

          <div className={classes.footer}>
            <div className={classes.grow} />
            <CancelButton onClick={handleClose}>Cancel</CancelButton>
            <SubmitButton onClick={handleConfirm}>Apply</SubmitButton>
          </div>
        </Paper>
    );
  }
}

PEIChartBagDashletSettings.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  settings: PropTypes.object,
  errors: PropTypes.object,
  isLocked: PropTypes.bool,
  open: PropTypes.bool,

  //func
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  handleChangeProperty: PropTypes.func,
};

export default withStyles(styles)(PEIChartBagDashletSettings);
