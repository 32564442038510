import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import {Create} from "@material-ui/icons";
import Add from "@material-ui/icons/Add";
import LeftNavBar from "components/common/LeftNavBar/LeftNavBar";
import DashboardGroupItem from "components/dashboard/DashboardLeftNavBar/DashboardGroupItem";
import * as _ from "lodash";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as uuid from "uuid";

class DashboardLeftNavBar extends Component {
  constructor(props) {
    super(props);

    this.addGroupNameInputRef = React.createRef();

    this.state = {
      addGroupName: null,
      addGroupMode: false,
    };
  }

  setAddGroupMode = (value) => {
    this.setState({
      addGroupMode: value
    });
  };

  setAddGroupName = (value) => {
    this.setState({
      addGroupName: value
    });
  };

  handleNewGroupClick = (e) => {
    e.stopPropagation();

    this.setAddGroupName(null);
    this.setAddGroupMode(true);
  };

  resetAddGroupMode = () => {
    this.setAddGroupName(null);
    this.setAddGroupMode(false);
  };

  onChangeAddGroupNameHandler = (e) => {
    this.setAddGroupName(e.target.value);
  };

  onClickSubmitNewGroupHandler = (e) => {
    e.stopPropagation();

    const addGroupName = _.trim(this.addGroupNameInputRef.current.value);
    if (addGroupName != null && addGroupName !== '') {
      this.props.onSubmitCreateDashboardGroupHandler(addGroupName);
    }

    this.resetAddGroupMode();
  };

  onKeypressAddGroupInputHandler = (e) => {
    if (e.key === 'Enter') {
      this.onClickSubmitNewGroupHandler(e);
    }
  };

  renderAddGroupListItem = () => {
    const {addGroupMode} = this.state;

    if (addGroupMode) {
      return (
          <ClickAwayListener onClickAway={this.resetAddGroupMode}>
            <ListItem button key={uuid.v4()}>
              <TextField size="small" placeholder="New Group..." autoFocus inputRef={this.addGroupNameInputRef}
                         onKeyPress={this.onKeypressAddGroupInputHandler}/>
              <Create onClick={this.onClickSubmitNewGroupHandler}/>
            </ListItem>
          </ClickAwayListener>
      );
    } else {
      return (
          <ListItem button key={uuid.v4()} onClick={this.handleNewGroupClick}>
            <ListItemIcon><Add/></ListItemIcon>
            <ListItemText primary="New Group..."/>
          </ListItem>
      );
    }
  };

  render() {
    const {
      isLeftMenuOpened, setLeftMenuOpened, dashboardTree, onRemoveDashboardGroupHandler, activeDashboardId,
      setActiveDashboardId, toggleDashboardGroup, onRemoveDashboardHandler, onCreateDashboardHandler,
      onEditDashboardGroupHandler, onEditDashboardHandler, onAddDashletHandler, onRefreshDashboardHandler, onImportDashlet
    } = this.props;

    return (
        <LeftNavBar title="Dashboard" open={isLeftMenuOpened} setOpen={setLeftMenuOpened}>
          {dashboardTree.result.map(groupId => dashboardTree.entities.dashboardGroups[groupId])
              .map((group) => {
                return [
                  <DashboardGroupItem
                      key={`tgn-${group.id}`}
                      groupData={group}
                      dashboardEntities={_.pick(dashboardTree.entities.dashboards, group.dashboards)}
                      activeDashboardId={activeDashboardId}
                      onSelectDashboard={setActiveDashboardId}
                      onSelectDashboardGroup={toggleDashboardGroup}
                      onRemoveDashboardGroupHandler={onRemoveDashboardGroupHandler}
                      onRemoveDashboardHandler={onRemoveDashboardHandler}
                      onCreateDashboardHandler={onCreateDashboardHandler}
                      onEditDashboardGroupHandler={onEditDashboardGroupHandler}
                      onEditDashboardHandler={onEditDashboardHandler}
                      onAddDashletHandler={onAddDashletHandler}
                      onRefreshDashboardHandler={onRefreshDashboardHandler}
                      onImportDashlet={onImportDashlet}
                  />,

                ];
              })}
          {this.renderAddGroupListItem()}
        </LeftNavBar>
    );
  }


}

DashboardLeftNavBar.propTypes = {
  //data
  isLeftMenuOpened: PropTypes.bool,
  dashboardTree: PropTypes.object.isRequired,
  activeDashboardId: PropTypes.number,

  //func
  setLeftMenuOpened: PropTypes.func,
  onSubmitCreateDashboardGroupHandler: PropTypes.func.isRequired,
  onRemoveDashboardGroupHandler: PropTypes.func.isRequired,
  setActiveDashboardId: PropTypes.func.isRequired,
  toggleDashboardGroup: PropTypes.func.isRequired,
  onRemoveDashboardHandler: PropTypes.func.isRequired,
  onCreateDashboardHandler: PropTypes.func.isRequired,
  onEditDashboardGroupHandler: PropTypes.func.isRequired,
  onEditDashboardHandler: PropTypes.func.isRequired,
  onAddDashletHandler: PropTypes.func.isRequired,
  onRefreshDashboardHandler: PropTypes.func.isRequired,
  onImportDashlet: PropTypes.func.isRequired,
};

export default DashboardLeftNavBar;
