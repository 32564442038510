import React, {useEffect} from "react";
import {Box} from "@material-ui/core";
import BackendSelectionRow from "components/common/BackendSelectionRow";
import {useDispatch, useSelector} from "react-redux";
import {
    selectJobsBackendKey,
    selectJobsEntities, selectJobsIds,
    setJobsBackendKey
} from "reducers/infrastructure/jobsSlice";
import {
    handleJobOperation,
    handleListJobs,
    listJobs,
    pauseJob,
    resumeJob
} from "actions/infrastructure/jobsActions";
import {HLC_WEB_SOCKET_URL, IS_HLC_WS_ENABLED, IS_PEI_WS_ENABLED, PEI_WEB_SOCKET_URL} from "config";
import StompClient from "websocket/StompClient";
import {STOMP_CLIENT} from "reducers/webSocketSlice";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import {JOB_OPERATION_SUBSCRIPTION, LIST_JOBS_SUBSCRIPTION} from "api/websocket/hlc/jobsWebSocketClient";
import JobsTable from "./JobsTable";

const JobsList: React.FC = ({}) => {
    const dispatch = useDispatch();
    const backendKey = useSelector(selectJobsBackendKey);
    const jobsEntities = useSelector(selectJobsEntities);
    const jobsIds = useSelector(selectJobsIds);

    const handleChangeBackend = (backendKey: string) => dispatch(setJobsBackendKey(backendKey));

    const handlePauseJob = (jobId: string, jobGroupName: string, jobName: string) =>
        backendKey && dispatch(pauseJob(backendKey, jobId, jobGroupName, jobName));

    const handleResumeJob = (jobId: string, jobGroupName: string, jobName: string) =>
        backendKey && dispatch(resumeJob(backendKey, jobId, jobGroupName, jobName));

    const loadJobs = () => backendKey && dispatch(listJobs(backendKey));

    useEffect(() => {
        loadJobs();
    }, [backendKey]);

    return (
        <>
            <Box style={{
                display: 'block',
                height: "100%",
                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden"
            }}>
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: "100%",
                }}
                >
                    <BackendSelectionRow backendKey={backendKey} onChangeBackend={handleChangeBackend}/>

                    <JobsTable
                        rows={jobsIds.map((id: string) => jobsEntities[id])}
                        pauseAction={handlePauseJob}
                        resumeAction={handleResumeJob}
                    />
                </Box>
            </Box>

            {/* todo: move to common place */}
            {IS_PEI_WS_ENABLED ? [
                <StompClient
                    key="hlc-ws-stomp-client-1582840873831"
                    stompClientKey={STOMP_CLIENT.PEI}
                    sockJsEndpoint={PEI_WEB_SOCKET_URL}
                    debugMode
                />,
            ] : null}
            {IS_HLC_WS_ENABLED ? [
                <StompClient
                    key="pei-ws-stomp-client-1582840873831"
                    stompClientKey={STOMP_CLIENT.HLC}
                    sockJsEndpoint={HLC_WEB_SOCKET_URL}
                    debugMode
                />,
            ] : null}

            <HLCUserSessionWebSocketSubscription
                subscriptionDestination={LIST_JOBS_SUBSCRIPTION}
                handleMessage={response => dispatch(handleListJobs(response))}
                onSubscribeCallback={loadJobs}
            />
            <HLCUserSessionWebSocketSubscription
                subscriptionDestination={JOB_OPERATION_SUBSCRIPTION}
                handleMessage={response => dispatch(handleJobOperation(response))}
            />
        </>

    );
}

export default JobsList;