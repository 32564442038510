import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import bottomHintStyles from "../../../jss/gate/Auth/bottomHintStyles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(bottomHintStyles);

function BottomHint(props) {
  const classes = useStyles();

  return (
    <Typography component="p" className={classes.root}>
      By using Enteros, you confirm that you have read and agree to our <Link href="https://www.enteros.com/legal/" target="_blank" underline="hover">Privacy Policy</Link> and <Link href="https://www.enteros.com/legaltos/" target="_blank"  underline="hover">Terms of Service</Link>
    </Typography>
  );
}

export default BottomHint;