const TIME_RANGE_TYPES = {
    LAST_TIME: 'LAST_TIME',
    TIME_AMOUNT: 'TIME_AMOUNT',
    TIME_RANGE: 'TIME_RANGE',
    HISTORICAL_TIME_AMOUNT: 'HISTORICAL_TIME_AMOUNT_FROM',
    HISTORICAL_TIME_FROM: 'HISTORICAL_TIME_FROM',
}

export const MAX_ALLOWED_DYNAMIC_DASHLETS_COUNT = 5; // Count of dynamic dashlets to be allowed simultanously

export const DYNAMIC_DASHLETS_EXPIRATION_TIME = 60 * 60 * 1000; // 1 hour time to remove dashlet after no activity

export default TIME_RANGE_TYPES;
