import {WEB_SOCKET_ACTION_PREFIX, WEB_SOCKET_SUBSCR_PREFIX} from "constants/webSocketConstants";
import {publish} from "./hlcCommonWebSocketClient";
import JobOperationStompFrame from "types/payload/infrastructure/jobs/JobOperationStompFrame";

/* List Jobs */
const LIST_JOBS_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.jobs.system.list.execute`;
export const LIST_JOBS_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.jobs.system.list`;

export const listJobs = (appState: unknown, backendKey: string) =>
    publish(appState, backendKey, LIST_JOBS_ACTION, {
        empty: ""
    });

/* Pause Job */
const PAUSE_JOB_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.jobs.system.operation.pause`;
export const JOB_OPERATION_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.jobs.system.operation`;

export const pauseJob = (appState: unknown, backendKey: string, payload: JobOperationStompFrame) =>
    publish(appState, backendKey, PAUSE_JOB_ACTION, payload);

/* Resume Job */
const RESUME_JOB_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.jobs.system.operation.resume`;

export const resumeJob = (appState: unknown, backendKey: string, payload: JobOperationStompFrame) =>
    publish(appState, backendKey, RESUME_JOB_ACTION, payload);
