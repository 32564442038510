enum IntegrationType {
    SERVICE_NOW = "SERVICE_NOW",
    SLACK = "SLACK",
    NEW_RELIC = "NEW_RELIC",
    GEN_AI = "GEN_AI",
    APP_DYNAMICS = "APP_DYNAMICS",
    JIRA = "JIRA",
    SERVICE_DESK = "SERVICE_DESK",
    EMAIL = "EMAIL",
    MS_TEAMS = "MS_TEAMS",
    USE_ANOMALY_SPIKE = "USE_ANOMALY_SPIKE",
    SNOWFLAKE = "SNOWFLAKE"
}

export default IntegrationType;
