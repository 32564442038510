import React from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import SubmitButton from "components/common/Button/SubmitButton";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";
import GenAIIntegration from "types/model/settings/integration/GenAIIntegration";


interface GenAIIntegrationsProps {
    integration: GenAIIntegration;
    onSave: (integration: GenAIIntegration) => void;
}

const GenAiIntegrationSection: React.FC<GenAIIntegrationsProps> = ({
    onSave,
    ...props
}) => {
    const { integration, handleChangeProperty, handleEnabledToggle } = useIntegrationSectionState(props.integration);
    const {  aiKey, modalName } = integration as GenAIIntegration;
    const isSaveDisabled = !aiKey || !modalName;

    return (
        <Grid container spacing={2} direction="column" justifyContent="flex-start" style={{ marginTop: "8px", marginBottom: "8px" }}>
        
            <Grid item container spacing={2} xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={2} style={{ textAlign: "left" }}>
                    <InputLabel htmlFor="apiKey">AI Key</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        name="aiKey"
                        fullWidth
                        textAlign="left"
                        value={aiKey}
                        onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={2} style={{ textAlign: "left" }}>
                    <InputLabel htmlFor="modalName">Modal Name</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        name="modalName"
                        fullWidth
                        textAlign="left"
                        value={modalName || ""}
                        onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                    />
                </Grid>
            </Grid>
            
            <Grid
                item
                container
                spacing={2}
                xs={7}
                direction="row"
                justifyContent="flex-end"
                alignItems="center">
                <Grid item xs={1}>
                    {/*@ts-ignore*/}
                    <SubmitButton disabled={isSaveDisabled} onClick={() => onSave(integration)}>Save</SubmitButton>
                </Grid>
            </Grid>
            
        </Grid>
    );
};

export default GenAiIntegrationSection;
