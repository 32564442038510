import React, { Component } from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Input, MenuItem, Select, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "components/common/DialogTitle/DialogTitle";
import { Label } from '@material-ui/icons';
import PublishIcon from '@material-ui/icons/Publish';
import FileInput from './FileInput';
import HLCDashletsRestClient from 'api/rest/HLCDashletsRestClient';
import * as _ from 'lodash';

const styles = theme => ({
    root: {
        maxWidth: '700px',
    },
    actionButtonLabel: {
        fontWeight: 600,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    note: {
        padding: "8px 24px",
        fontSize: "13px",
    },
});

class ImportDashletModal extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            fileToImport: null,
            isLoadingBackends: false,
            backends: [],
            selectedBackendKey: "",
        };
    }

    componentDidMount() {
        this.setState({ isLoadingBackends: true });
        HLCDashletsRestClient.listUserBackends()
            .then(backends => {
                this.setState({ backends, isLoadingBackends: false });
            })
            .catch(error => {
                console.error(error);
                this.setState({ isLoadingBackends: false });
            });
    }


    handleSelectImportFile = (file) => {
        this.setState({ fileToImport: file });
        // this.props.handleImportDashlet(file);
    };

    onChangeBackend = (event) => {
        this.setState({selectedBackendKey: event.target.value});
    }

    render() {
        const { classes, open, handleClose, isLocked, errors } = this.props;
        // const isEditMode = Boolean(objectData.id);
        const inputRef = React.createRef();

        return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" onClose={handleClose}>
                    Import Dashlet
                </DialogTitle>

                <Backdrop className={classes.backdrop} open={isLocked}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogContent className={classes.dialogContent}>

                    <FormControl key="dashlet-hlc-chart-backendKey-1582673886219"
                        fullWidth
                        // error={dashletErrors.backendKey}
                        margin="dense"
                    >
                        <InputLabel id="dashlet-hlc-chart-backendKey-input-label-1582673886219">
                            HLC Backend
                        </InputLabel>
                        <Select
                            labelId="dashlet-hlc-chart-backendKey-label-1582673886219"
                            id="backendKey"
                            name="backendKey"
                            value={this.state.selectedBackendKey ? this.state.selectedBackendKey : ''}
                            onChange={this.onChangeBackend}
                            disabled={this.state.isLoadingBackends}
                        >
                            <MenuItem key="dashlet-hlc-chart-backendKey-menu-item-1582673956819" value=""></MenuItem>
                            {_.map(this.state.backends, (backend) => {
                                return (
                                    <MenuItem
                                        key={`dashlet-hlc-chart-backendKey-menu-item-key-${backend.backendKey}`}
                                        value={backend.backendKey}>
                                        {backend.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <FormControl key="dash-name" fullWidth required error={errors.name} margin="dense">
                        <InputLabel id="name-label" htmlFor="dash-name" disableAnimation focused shrink>Select Dashlet zip file</InputLabel>
                        <br />
                        <FileInput onDone={this.handleSelectImportFile} />
                    </FormControl>

                </DialogContent>
                <Typography variant="body1" className={classes.note} align="left">{"Note: Dashlet imports may ignore targets with duplicate names or already exists targets."}</Typography>
                <Typography variant="body1" className={classes.note} align="left">{"Note: Dashlet imports may override templates and reports if already exists by name."}</Typography>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" classes={{
                        label: classes.actionButtonLabel
                    }}>
                        Cancel
                    </Button>
                    <Button onClick={() => this.props.handleImportDashlet(this.state.fileToImport, this.state.selectedBackendKey)} disabled={!this.state.selectedBackendKey} color="primary" classes={{
                        label: classes.actionButtonLabel
                    }}>
                        Import
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

ImportDashletModal.propTypes = {
    //data
    classes: PropTypes.object.isRequired,
    objectData: PropTypes.object,
    errors: PropTypes.object.isRequired,
    isLocked: PropTypes.bool,
    open: PropTypes.bool,


    //func
    handleClose: PropTypes.func,
    handleConfirm: PropTypes.func,
    handleChangeInput: PropTypes.func,
    handleImportDashlet: PropTypes.func,
};

export default withStyles(styles)(ImportDashletModal);
