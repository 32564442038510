import React from "react";
import {trim as _trim} from "lodash";
import {Dialog, Grid} from "@material-ui/core";
import DialogTitle from "components/common/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import BackendDTO from "types/model/infrastructure/BackendDTO";
import DialogActions from "@material-ui/core/DialogActions";
import CancelButton from "../../../components/common/Button/CancelButton";
import SubmitButton from "../../../components/common/Button/SubmitButton";

export type BackendDialogMode = "create" | "edit";

interface BackendDialogProps {
    open: boolean;
    backend: BackendDTO;
    onClose: () => void;
    onSubmit: (backend: BackendDTO) => void;
    onChange: (propertyName: string, propertyValue: string) => void;
}

const dialogTitle = {
    "create": "Create Backend",
    "edit": "Edit Backend",
};

const submitButtonLabel = {
    "create": "Create",
    "edit": "Update",
};

const BackendDialog: React.FC<BackendDialogProps> = ({ open, backend, onClose, onSubmit, onChange }) => {
    const mode = backend.id !== null ? "edit" : "create";

    const isSaveEnabled = () => {
        if (_trim(backend.name || "")) {
            return true;
        }

        return false;
    }

    const handleSave = () => onSubmit(backend);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        onChange(event.target.name, event.target.value);

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="xs"
                open={open}
                onClose={onClose}
            >
                <DialogTitle id="backend-management-dialog" onClose={onClose}>
                    {dialogTitle[mode] || "Backend"}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <TextField
                                id="name"
                                name="name"
                                label="Name"
                                value={backend.name}
                                onChange={handleInputChange}
                                // disabled={loading || operation === EDIT_TARGET}
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                required
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                multiline
                                maxRows={5}
                                id="description"
                                name="description"
                                label="Decription"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                size="small"
                                // disabled={!params.useDatabaseUrl || loading}
                                value={backend.description}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {/*@ts-ignore*/}
                    <CancelButton variant="outlined" onClick={onClose}>
                        Cancel
                    </CancelButton>
                    {/*@ts-ignore*/}
                    <SubmitButton disabled={!isSaveEnabled()} onClick={handleSave}>
                        {submitButtonLabel[mode] || "Save"}
                    </SubmitButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default BackendDialog;
