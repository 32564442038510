import UserDTO, {getDefaultUserDTO} from "types/model/user/UserDTO";
import BackendUserDTO from "./BackendUserDTO";

interface BackendDTO {
    id: number | null;
    name: string | null;
    description: string | null;
    backendKey: string | null;
    backendSecretKey: string | null;
    owner: UserDTO;
    createdAt: Date | null;
    users: BackendUserDTO[];
    connected: boolean;
    isOwner: boolean;
}

export const getDefaultBackendDTO = (): BackendDTO => {
    return {
        id:  null,
        name: null,
        description: null,
        backendKey: null,
        backendSecretKey: null,
        owner: getDefaultUserDTO(),
        createdAt: null,
        users: [],
        connected: false,
        isOwner: false
    }
}

export default BackendDTO;
