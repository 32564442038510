import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as _ from "lodash";
import {updateDashletSettings} from "../../../actions/dashboard/dashletActions";
import HeatmapDashletSettings from "./HeatmapDashletSettings";
import {selectHasFinanceRole} from "../../../reducers/userInfoSlice";

class HeatmapDashletSettingsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      object: {
        ...props.object
      },
      errors: {},
      isLocked: false,
    }
  }

  getObject = () => {
    return this.state.object;
  };

  lockForm = () => {
    this.setState({isLocked: true});
  };

  unlockForm = () => {
    this.setState({isLocked: false});
  };

  handleSubmit = (event) => {
    this.lockForm();

    const errors = this.validate();

    if (!this.hasErrors(errors)) {
      //
      this.props.submit(this.props.dashletId, this.getObject());
      this.props.close();
      //
    } else {
      this.setErrors(errors);
      this.unlockForm();
    }
  };

  hasErrors = (errors) => {
    return _.keys(errors).length > 0;
  };

  setErrors = (errors) => {
    this.setState({errors});
  };

  handleCancel = (event) => {
    this.props.close();
  };

  validate = () => {
    const errors = {};

    const object = this.getObject();

    //todo: implement me!

    return errors;
  };

  handleChange = (name, value) => {
    const object = this.getObject();

    object[name] = value;

    this.setState({object});
  };

  render() {
    const {object, errors, isLocked} = this.state;
    const {hasFinanceRole} = this.props;

    return <HeatmapDashletSettings
        settings={object}
        errors={errors}
        isLocked={isLocked}
        hasFinanceRole={hasFinanceRole}
        handleChangeProperty={this.handleChange}
        handleConfirm={this.handleSubmit}
        handleClose={this.handleCancel}
    />;
  }
}

HeatmapDashletSettingsContainer.propTypes = {
  //data
  dashletId: PropTypes.number,
  object: PropTypes.object,
  hasFinanceRole: PropTypes.bool,

  //func
  close: PropTypes.func,
  submit: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
  return {
    object: state.dashboard.dashboardTree.entities.dashlets[ownProps.dashletId].settings,
    hasFinanceRole: selectHasFinanceRole(state),
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (dashletId, settings) => {
      dispatch(updateDashletSettings(dashletId, settings));
    },
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeatmapDashletSettingsContainer)
