import React from "react";
import ApplicationPage from "components/common/ApplicationPage/ApplicationPage";
import InfrastructureLeftNavBar from "components/gate/InfrastructureLeftNavBar";
import JobsPageContent from "./JobsPageContent";

const JobsPage: React.FC = ({}) => (
    <ApplicationPage
        leftNavBarComponent={<InfrastructureLeftNavBar />}
        contentComponent={<JobsPageContent />}
    />
);

export default JobsPage;