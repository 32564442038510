import {createSlice} from "@reduxjs/toolkit";
import {loadAuthState, removeAuthState, saveAuthState} from "../store/LocalStore";

const initialState = {
    isAuthenticatedLoaded: false,
    isAuthenticated: false,
    auth: loadAuthState() ? loadAuthState() : null,
    //login page
    isLoginInProgress: false,
    loginErrorMessage: null,
    //registration page
    isRegistrationInProgress: false,
    registrationErrorMessage: null,
    //forgot password
    isForgotPasswordInProgress: false,
    forgotPasswordErrorMessage: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth(state, action) {
            const auth = action.payload;
            saveAuthState(auth);
            state.auth = auth;
        },
        setIsAuthenticated(state, action) {
            state.isAuthenticated = action.payload;
            state.isAuthenticatedLoaded = true;
        },
        setIsAuthenticatedLoaded(state, action) {
            state.isAuthenticatedLoaded = action.payload;
        },
        clearAuthentication(state) {
            removeAuthState();
            state.auth = null;
            state.isAuthenticated = false;
        },
        setIsLoginInProgress(state) {
            state.isLoginInProgress = true;
            state.loginErrorMessage = null;
            state.loginErrorMessage = null;
        },
        clearLoginErrorMessage(state) {
            state.loginErrorMessage = null;
        },
        setLoginErrorMessage(state, action) {
            state.loginErrorMessage = action.payload;
            state.isLoginInProgress = false;
        },
        setLoginSuccess(state, action) {
            const auth = action.payload;
            saveAuthState(auth);
            state.auth = auth;
            state.isLoginInProgress = false;
            state.loginErrorMessage = null;
            state.isAuthenticated = true;
        },
        setRegistrationErrorMessage(state, action) {
            state.registrationErrorMessage = action.payload;
            state.isRegistrationInProgress = false;
        },
        setRegistrationInProgress(state) {
            state.isRegistrationInProgress = true;
            state.registrationErrorMessage = null;
        },
        setRegistrationSuccess(state, action) {
            const auth = action.payload;
            saveAuthState(auth);
            state.auth = auth;
            state.isRegistrationInProgress = false;
            state.registrationErrorMessage = null;
        },
        setForgotPasswordInProgress(state) {
            state.isForgotPasswordInProgress = true;
            state.forgotPasswordErrorMessage = null;
        },
        clearForgotPasswordErrorMessage(state) {
            state.forgotPasswordErrorMessage = null;
        },
        setForgotPasswordErrorMessage(state, action) {
            state.forgotPasswordErrorMessage = action.payload;
            state.isForgotPasswordInProgress = false;
        },
        setForgotPasswordSuccess(state) {
            state.isForgotPasswordInProgress = false;
            state.forgotPasswordErrorMessage = null;
        },
        setResetPasswordErrorMessage(state, action) {
            state.resetPasswordErrorMessage = action.payload;
            state.isResetPasswordInProgress = false;
        },
        //...
    }
});

export const {
    setAuth,
    setIsAuthenticated,
    setIsAuthenticatedLoaded,
    clearAuthentication,
    setIsLoginInProgress,
    clearLoginErrorMessage,
    setLoginErrorMessage,
    setLoginSuccess,
    setRegistrationErrorMessage,
    setRegistrationInProgress,
    setRegistrationSuccess,
    setForgotPasswordInProgress,
    clearForgotPasswordErrorMessage,
    setForgotPasswordErrorMessage,
    setForgotPasswordSuccess,
    setResetPasswordErrorMessage,
} = authSlice.actions;

export default authSlice.reducer;

export const selectAuthObject = state => state.auth.auth;