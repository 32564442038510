import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import ReactHighcharts from 'react-highcharts/ReactHighstock';
import HighchartsMap from 'highcharts-map';
import Boost from 'react-highcharts/node_modules/highcharts/modules/boost';
import Exporting from 'react-highcharts/node_modules/highcharts/modules/exporting';
import { generateHLCChart, handleClickOnPoint, handleLoadSelectedChart } from 'actions/dashboardActions';
import { closeChartDialog, setExtremes } from 'reducers/dashboard/dashboardSlice';
import * as _ from "lodash";
import Moment from "moment";
import numeral from 'numeral';
import ChartsContainer from './ChartsContainer';
import {normalizeScale} from "utils/normalizeScale";

const styles = theme => ({
    root: {
        maxWidth: '700px',
    },
    dialogContent: {
        width: '400px',
    },
    actionButtonLabel: {
        fontWeight: 600,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

class HLCChartTemplateContainer extends Component {

    componentDidMount() {
        this.props.generateHLCChart(this.props.dashletId);
    }

    generateSeriesName = (series) => {
        return series.target + "/" + series.metric + "/" + series.measurement;
    }

    generateTitle = (groupBy, series) => {
        if (groupBy == "none") {
            return "None";
        } else if (groupBy == "metric_and_measurement") {
            return series.capture + " | " + series.measurement;
        }else if(groupBy == "measurement"){
            return series.measurement;
        }else if(groupBy == "matric"){
            return series.matric;
        }else if(groupBy == "capture"){
            return series.matric;
        }else if("target"){
            return series.target;
        }
    }

    getMinTime = (data) => {
        let min = new Date().getTime();
        data.forEach((value) => {
          if(value[0] < min) {
            min = value[0];
          }
        })
        return min;
      }
      
    getMaxTime = (data) => {
        let max = 0;
        data.forEach((value) => {
          if(value[0] > max) {
            max = value[0];
          }
        })
        return max;
      }

    handleResetZoom = () => {
        _.each(ReactHighcharts.Highcharts.charts, (chart) => {
            if (chart !== undefined) {
                chart.xAxis[0].setExtremes(
                    (chart.xAxis[0].dataMin - 5000),
                    chart.xAxis[0].dataMax + 5000,
                    undefined,
                    false,
                    {trigger: 'load'}
                );
                chart.yAxis[0].setExtremes(
                    chart.yAxis[0].dataMin,
                    chart.yAxis[0].dataMax,
                    undefined,
                    false,
                    {trigger: 'load'}
                  );
            }
          })
        this.props.setExtremes(null, null, null, null, null);
    }

    handleCancel = (event) => {
        this.props.close();
        this.props.closeChartDialog();
      };

    render() {
        const { chartData, isOpen, handleClickOnPoint, setExtremes, xMin, xMax, handleLoadSelectedChart } = this.props;
        
        let configList = [];
        const startTimeList = [];
        const endTimeList = [];
        const convertedGroups = [];
        if (chartData != null) {
            let chartSeries = chartData.chartSeriesList;
            _.each(_.keys(chartSeries), (chartkey) => {

                const groupKey = chartkey;
                const groupArray = chartSeries[groupKey];
                const groupSeries = [];
                let timeRangesMap = this.props.dashletData[this.props.dashletId].timeRangesMap;
                _.each(groupArray, (groupArrayItem) => {
                    const data = _.slice(groupArrayItem.values);
                    startTimeList.push(this.getMinTime(data));
                    endTimeList.push(this.getMaxTime(data));
                                        const result = {
                        name: `${groupArrayItem.target}/${groupArrayItem.capture}/${groupArrayItem.metric}/${groupArrayItem.measurement}${groupArrayItem.isHistorical ? '/Historical' : ''}`,
                        data: data,
                        isHistorical: groupArrayItem.isHistorical,
                        target: groupArrayItem.target,
                        capture: groupArrayItem.capture,
                        metric: groupArrayItem.metric,
                        measurement: groupArrayItem.measurement,
                        historicalTimeOffset: groupArrayItem.isHistorical ? timeRangesMap[groupArrayItem.target].startTime - timeRangesMap[groupArrayItem.target].historicalStartTime : null,
                        yAxisTitle: groupKey,
                        chartElementKey: groupArrayItem.chartElementKey,
                      };
                      groupSeries.push(result)
                });
                convertedGroups.push({
                    series: groupSeries,
                    title: groupKey
                });
            });

            let minTime = _.min(startTimeList) - 5000;
            let maxTime = _.max(endTimeList) + 5000;
            convertedGroups.forEach((group => {
                let config = {
                    time: {
                        useUTC: false,
                    },
                    boost: {
                        // enabled: (chartData.chartType === ChartTypes.VRCA),
                        enabled: false,
                        useGPUTranslations: false,
                        debug: {
                            timeSetup: true,
                            timeSeriesProcessing: true,
                            timeBufferCopy: true,
                            timeKDTree: true,
                            showSkipSummary: true
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    rangeSelector: {
                        enabled: false
                    },
                    navigator: {
                        enabled: false,
                    },
                    navigation: {
                        buttonOptions: {
                            enabled: false
                        }
                    },
                    scrollbar: {
                        enabled: false
                    },
                    xAxis: {
                        min: minTime,
                        max: maxTime,
                        type: 'datetime',
                        minRange: 1,
                        ordinal: false,
                    },
                    yAxis: {
                        labels: {
                            x: -8,
                            y: 0
                        },
                        type: chartData.chartsTemplate.chartsSettings.isLogarithmic ? 'logarithmic' : 'linear',
                        opposite: false
                    },
                    plotOptions: {
                        series: {
                            cropThreshold: 0,
                            dataGrouping: {
                                enabled: chartData.chartsTemplate.chartsSettings.isSmoothData && !chartData.chartsTemplate.chartsSettings.isSpikeAnalysis,
                                approximation: 'average',
                                forced: true,
                                units: [[chartData.chartsTemplate.chartsSettings.smoothDataMeasure.toLowerCase(), [chartData.chartsTemplate.chartsSettings.smoothDataValue]]]
                            },
                            marker: {
                                enabled: true,
                                radius: 2,
                            },
                            point: {
                                events: {
                                    click: function (e) {
                                        handleClickOnPoint(chartData.chartsTemplate.templateName);
                                    }
                                }
                            }
                        }
                    },
                    title: {
                        text: group.title,
                        style: {
                            "fontSize": "14px",
                        },
                    },
                    chart: {
                        marginRight: 18,
                        marginLeft: 80,
                        style: {
                          fontFamily: 'FuturaPT'
                        },
                        resetZoomButton: {                
                            theme: {
                                display: 'none'
                            }
                        },
                        type: 'line',
                        zoomType: 'xy',
                        reflow: true,
                        events: {
                            load: (e) => {
                                let chart = e.target;

                                if (!xMin && !xMax) {
                                    chart.xAxis[0].setExtremes(
                                        chart.xAxis[0].dataMin,
                                        chart.xAxis[0].dataMax,
                                        undefined,
                                        false,
                                        {trigger: 'load'}
                                    );                                  
                                } else {
                                    chart.xAxis[0].setExtremes(
                                      xMin,
                                      xMax,
                                      undefined,
                                      false,
                                      {trigger: 'load'}
                                    );
                                  }
                            },
                            selection: (e) => {
                                if (!e.resetSelection) {
                                  let xMin = e.xAxis[0].min;
                                  let xMax = e.xAxis[0].max;
                                  let yMin = e.yAxis[0].min;
                                  let yMax = e.yAxis[0].max;
                  
                                  const key = e.target.userOptions.title.text;
                                  setExtremes(key, xMin, xMax, yMin, yMax);
                                }
                                e.preventDefault();
                            },
                        }
                    },
                    series: chartData.chartsTemplate.chartsSettings.isNormalizeScale && !chartData.chartsTemplate.chartsSettings.isSpikeAnalysis ? _.map(group.series, (ser) => {
                        return {
                          ...ser,
                          data: normalizeScale(ser.data)
                        }
                    }) : group.series,
                    tooltip: {
                        formatter: function() {
                            const info = this.series.userOptions;
                            if(info) {
                                let isHistorical = info.isHistorical ? info.isHistorical : false;
                                let timeLabelTimestamp = (isHistorical ? (this.x - info.historicalTimeOffset) : this.x);
                                let timeLabel = Moment(timeLabelTimestamp).format('M/D/YYYY hh:mm:ss A');
                                return `${this.series.name} : (${timeLabel}, </br> ${numeral(this.y).format("0,0.00")} </br> )`;
                            }else {
                                let timeLabel = Moment(this.x).format('M/D/YYYY hh:mm:ss A');
                                return `${this.series.name} : (${timeLabel}, </br> ${numeral(this.y).format("0,0.00")} </br> )`;
                            }
                        },
                        split: false,
                    }
                }
                configList.push(config);
            }));
            configList.forEach((chart, index) => {
                let series = chart.series;
                let validationSeries = [];
                if (chartData && chartData.spikeSeries) {
                    let uniqueKeys = new Set();
                    series.forEach(ser => {
                        uniqueKeys.add(ser.chartElementKey);
                    });
                    uniqueKeys.forEach(chartElementKey => {
                        const spikeSeriesList = chartData?.spikeSeries[chartElementKey];
                        if (spikeSeriesList) {
                            spikeSeriesList.forEach(spikeSeries => {
                                let spikeSeriesData = [];
                                const filteredSeries = _.filter(series, ser => {
                                    return ser.chartElementKey === chartElementKey;
                                });
                                if (filteredSeries && filteredSeries.length > 0) {
                                    filteredSeries.forEach(series => {
                                      if (series.target === spikeSeries.validationData.targetName
                                        && series.capture === spikeSeries.validationData.capture
                                        && series.metric === spikeSeries.validationData.metric
                                        && series.measurement === spikeSeries.validationData.measurement) {
                                            spikeSeriesData = spikeSeries.validationData.data;
                                        }
                                    });
                                }
                                if (spikeSeriesData.length > 0) {
                                    validationSeries.push({
                                        data: spikeSeriesData,
                                        spikeChartElementKey: `${chartElementKey}_spikes`,
                                        name: "Validated Data",
                                        type: 'scatter',
                                        marker: {
                                            fillColor: 'rgba(0,0,0,0)',
                                            enabled: true,
                                            radius: 12,
                                            symbol: 'circle',
                                            lineWidth: 3,
                                            lineColor: '#FF0000'
                                        },
                                        showInLegend: false
                                    });
                                }
                            });
                        }
                    });
                }
                if(this.props.isRegressionTrend) {
                    let regrationSeriesList = [];
                    let obj = {};
                    series.forEach((element, i) => {
                        if(element.data.length >= 2) {
                            obj = {
                            ...element,
                            regression: this.props.isRegressionTrend,
                            regressionSettings: {
                                name: "Linear Regression - " + element.name,
                                type: "linear"
                            },
                            }
                        } else {
                            obj = {
                            ...element,
                            regression: false,
                            regressionSettings: {
                                name: "Linear Regression" + element.name,
                                type: "linear"
                            },
                            }
                        }
                        regrationSeriesList.push(obj);
                    });
                    series = regrationSeriesList;
                }
                if (validationSeries) {
                    series = series.concat(validationSeries);
                }
                configList[index].series = series;
            }); 
        }
        return (
            isOpen && chartData ? <ChartsContainer configList={configList} handleClose={this.handleCancel} templateName={chartData.chartsTemplate.templateName} 
                                    handleResetZoom={this.handleResetZoom} handleLoadSelectedChart={handleLoadSelectedChart}/> : null
        );
    }
}

HLCChartTemplateContainer.propTypes = {
    //data
    dashletId: PropTypes.number,
    object: PropTypes.object,
  
    //func
    close: PropTypes.func,
    submit: PropTypes.func,
    generateHLCChart: PropTypes.func,
    handleClickOnPoint: PropTypes.func,
    handleLoadSelectedChart: PropTypes.func,
    setExtremes: PropTypes.func,
  };

const mapStateToProps = (state, props) => {
    return {
        isOpen: state.dashboard.isChartDialogOpen,
        chartData : state.dashboard.chartData,
        isRegressionTrend: state.dashboard.chartData ? state.dashboard.chartData.chartsTemplate.chartsSettings.isRegressionTrend : null,
        dashletData: state.dashboard.dashletsData,
        xMin: state.dashboard.chartData?.xMin,
        xMax: state.dashboard.chartData?.xMax
    //   object: state.dashboard.dashboardTree.entities.dashlets[ownProps.dashletId].settings,
    }
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
    //   submit: (dashletId) => {
    //     dispatch(generateHLCChart(dashletId));
    //   },
        generateHLCChart: (dashletId) => {
            dispatch(generateHLCChart(dashletId));
        },
        closeChartDialog: () => {
            dispatch(closeChartDialog());
        },
        handleClickOnPoint: (templateName) => {
            dispatch(handleClickOnPoint(templateName));
        },
        setExtremes: (key, xMin, xMax, yMin, yMax) => {
            dispatch(setExtremes(key, xMin, xMax, yMin, yMax))
        },
        handleLoadSelectedChart: () => {
            dispatch(handleLoadSelectedChart());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(HLCChartTemplateContainer)