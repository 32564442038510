import * as types from 'actions/dashboard/EmailConfigurationTypes';
  const initialState = {
    //load Email modal
    emailConfigurationType: 'Secure (TLS)',
    // isEmailConfigurationModalBoxOpened: false,
    senderEmail: null,
    senderPassword: null,
    domainName: null,
    emailPort: 587,
};
const emailConfiguration = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_EMAIL_CONFIGURATION: {
            let emailConfig = action.emailConfig;
            if(emailConfig.domainName) {
                if (emailConfig.domainName.substring(0,4) == 'smtp') {
                    emailConfig.domainName = emailConfig.domainName.substring(5);
                }
            }
            return {
                ...state,
                senderEmail: emailConfig.senderEmail ? emailConfig.senderEmail : "",
                senderPassword: emailConfig.senderPassword ? emailConfig.senderPassword : "",
                domainName: emailConfig.domainName ? emailConfig.domainName : "",
                emailConfigurationType: emailConfig.emailConfigurationType ? emailConfig.emailConfigurationType : state.emailConfigurationType,
                emailPort: emailConfig.emailPort ? emailConfig.emailPort : state.emailPort,
            }
        }
        case types.CHANGE_EMAIL_CONFIGURATION_TYPE: {
            const emailConfigurationType = action.value;
            return {
                ...state,
                emailConfigurationType: emailConfigurationType,
                emailPort: emailConfigurationType == 'Secure (TLS)' ? 587 : 465
            }
        }
        case types.CHANGE_EMAIL_PORT: {
            return {
                ...state,
                emailPort: action.value != '' ? parseInt(action.value) : null
            }
        }
        case types.CHANGE_EMAIL_ID: {
            return {
                ...state,
                senderEmail: action.value 
            }
        }
        case types.CHANGE_EMAIL_PASSWORD: {
            return {
                ...state,
                senderPassword: action.value 
            }
        }
        case types.CHANGE_EMAIL_HOST: {
            return {
                ...state,
                domainName: action.value 
            }
        }
        case types.RESET_EMAIL_CONFIGURATION_STATE: {
            return {
                ...initialState
            }
        }
        default:
            return state
    }
}
export default emailConfiguration;