import Radio from "@material-ui/core/Radio";
import withStyles from "@material-ui/core/styles/withStyles";
import radioButtonStyles from "assets/jss/common/radioButtonStyles";
import clsx from "clsx";
import React, {Component} from "react";

class RadioButton extends Component {

  render() {
    const {classes, ...other} = this.props;

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...other}
        />
    );
  }
}

export default withStyles(radioButtonStyles)(RadioButton)