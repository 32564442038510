import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import ReactHighcharts from 'react-highcharts/ReactHighstock';
import HighchartsMap from 'highcharts-map';
// import regression from 'highcharts-regression';
import Boost from 'react-highcharts/node_modules/highcharts/modules/boost';
import Exporting from 'react-highcharts/node_modules/highcharts/modules/exporting';
import { generatePEIChart, handleClickOnPointPEI } from 'actions/dashboardActions';
import {closeChartDialog} from 'reducers/dashboard/dashboardSlice'
import * as _ from "lodash";
import Moment from "moment";
import numeral from 'numeral';
import ChartsContainer from 'dashlets/HLCChartDashlet/components/ChartsContainer';
// import ChartsContainer from './ChartsContainer';

Boost(ReactHighcharts.Highcharts);
Exporting(ReactHighcharts.Highcharts);
// regression(ReactHighcharts.Highcharts);
HighchartsMap(ReactHighcharts.Highcharts);

const styles = theme => ({
    root: {
        maxWidth: '700px',
    },
    dialogContent: {
        width: '400px',
    },
    actionButtonLabel: {
        fontWeight: 600,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

class PEIChartTemplateContainer extends Component {

    componentDidMount() {
        this.props.generatePEIChart(this.props.dashletId);
    }

    handleResetZoom() {
        _.each(ReactHighcharts.Highcharts.charts, (chart) => {
            if (chart !== undefined) {
                chart.zoomOut();
            }
          })
    }

    handleCancel = (event) => {
      this.props.close();
      this.props.closeChartDialog();
    };

    render() {
        const { chartData, isOpen, handleClickOnPoint, isRegressionTrend } = this.props;
        let configList = [];
        if(chartData!==null) {
        _.map(chartData.chartsResult.chartsGroupList, (group, index) => {
            // if(group.yAxisTitle != null) {
            //   if(group.yAxisTitle.length > 11) {
            //     yaxisMargin = 4  
            //   } else {
            //     yaxisMargin = 20
            //   }
            // } else {
            //   yaxisMargin = 20
            // }
            // if(!isUniversalTimeLine) {
            //   if(isSplitTimeFrame || chartData.isReportExecution) {
            //     minTime = group.minTime - 5000;
            //     maxTime = group.maxTime + 5000;
            //   }
            // }
            let chartConfig =  {
              sectionId: group.sectionId,
              customOptions: {
                key: group.key,
                databaseName: group.databaseName,
              },
              mapNavigation: {
                enabled: true,
                enableButtons: false,
              },
              time: {
                useUTC: false,
              },
              boost: {
                // enabled: (chartData.chartType === ChartTypes.VRCA),
                enabled: false,
                useGPUTranslations: false,
                debug: {
                  timeSetup: true,
                  timeSeriesProcessing: true,
                  timeBufferCopy: true,
                  timeKDTree: true,
                  showSkipSummary: true
                }
              },
              credits: {
                enabled: false
              },
              rangeSelector: {
                enabled: false
              },
              navigator: {
                enabled: false,
              },
              navigation: {
                buttonOptions: {
                  enabled: false
                }
              },
              scrollbar: {
                enabled: false
              },
              xAxis: {
                min: group.minTime,
                max: group.maxTime,
                type: 'datetime',
                minRange: 1,
                ordinal: false
              },
              yAxis: {
                title: {
                  text: group.yAxisTitle,
                //   margin: yaxisMargin,
                },
                labels: {
                  x: -8,
                  y: 0
                },
                opposite: false
              },
              plotOptions: {
                series: {
                  cropThreshold: 0,
                  dataGrouping: {
                    enabled: chartData.chartsTemplate.options.isSmoothData,
                    approximation: 'average',
                    forced: true,
                    units: [[chartData.chartsTemplate.options.smoothDataMeasure.toLowerCase(), [chartData.chartsTemplate.options.smoothDataValue]]]
                  },
                  marker: {
                    enabled: true,
                    radius: 2
                  },
                  point: {
                    events: {
                        click: function (e) {
                            handleClickOnPoint(chartData.chartsTemplate.templateName);
                        }
                    }
                }
                }
              },
              title: {
                text: group.title,
                style: {
                  "fontSize": "14px",
                },
              },
              subtitle: {
                text: group.subtitle,
                html: true,
                style: {
                  "fontSize": "12px",
                },
              },
              chart: {
                marginRight: 35,
                marginLeft: 80,
                style: {
                  fontFamily: 'FuturaPT'
                },
                resetZoomButton: {
                  theme: {
                    display: 'none'
                  }
                },
                type: 'line',
                zoomType: 'xy',
                reflow: true,
                events: {
                  load: (e) => {
                    let chart = e.target;
                    
                    chart.xAxis[0].setExtremes(
                        chart.xAxis[0].dataMin,
                        chart.xAxis[0].dataMax,
                        undefined,
                        false,
                        {trigger: 'load'}
                    );
                    },
                },
              },
              legend: {
                enabled: false
              },
              series: group.chartSeriesList,
              tooltip: {
                formatter: function() {
                    const info = this.series.userOptions.info;
                    if(info) {
                      let isHistorical = info.isHistorical;
                      let timeLabelTimestamp = (isHistorical ?  (this.x - chartData.chartsResult.historicalOffset) : this.x);
                      let timeLabel = Moment(timeLabelTimestamp).format('M/D/YYYY hh:mm:ss A');
                      return `${this.series.name} : (${timeLabel}, </br> ${numeral(this.y).format("0,0.00")} </br> )`;
                    } else {
                      let timeLabel = Moment(this.x).format('M/D/YYYY hh:mm:ss A');
                      return `${this.series.name} : (${timeLabel}, </br> ${numeral(this.y).format("0,0.00")} </br> )`;
                    }
                },
                xDateFormat: '%m/%d/%Y %H:%M:%S',
                shared: false,
                valueDecimals: 2,
                split: false
              },
            }
      
            if(chartConfig && isRegressionTrend ){
              let chartSeriesList = group.chartSeriesList || [];
              let regrationSeriesList = [];
              let obj = {}
              if(chartSeriesList) {
                chartSeriesList.forEach((element, i) => {
                  if(element.data.length >= 2) {
                    let data = null;
                    if (Object.isFrozen(element.data)) {
                      data = Array.from(element.data)
                    }
                    obj = {
                      data: data != null ? data : element.data,
                      regression: isRegressionTrend,
                      name: element.name,
                      regressionSettings: {
                        name: "Linear Regression - " + element.name,
                        type: "linear",
                      },
                      info: element.info
                    }
                  } else {
                    obj = {
                      data: element.data,
                      regression: false,
                      name: element.name,
                      regressionSettings: {
                        name: "Linear Regression" + element.name,
                        type: "linear",
                      },
                      info: element.info
                    }
                  }
                  regrationSeriesList.push(obj);
                });
              }
              chartConfig.series = regrationSeriesList;

            } else {
              chartConfig.series = group.chartSeriesList;
            }
            configList.push(chartConfig);
          });
        }
        return (
            isOpen && chartData ? <ChartsContainer configList={configList} handleClose={this.handleCancel} templateName={chartData.chartsTemplate.templateName} handleResetZoom={this.handleResetZoom}/> : null
        );
    }
}

PEIChartTemplateContainer.propTypes = {
    //data
    dashletId: PropTypes.number,
    object: PropTypes.object,
  
    //func
    close: PropTypes.func,
    submit: PropTypes.func,
    generatePEIChart: PropTypes.func,
    handleClickOnPoint: PropTypes.func,
  };

const mapStateToProps = (state, props) => {
    return {
        isOpen: state.dashboard.isChartDialogOpen,
        chartData : state.dashboard.chartData,
        isRegressionTrend : state.dashboard.chartData ? state.dashboard.chartData.chartsTemplate.options.isRegressionTrend : null,
    //   object: state.dashboard.dashboardTree.entities.dashlets[ownProps.dashletId].settings,
    }
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        generatePEIChart: (dashletId) => {
            dispatch(generatePEIChart(dashletId));
        },
        closeChartDialog: () => {
            dispatch(closeChartDialog());
        },
        handleClickOnPoint: (templateName) => {
            dispatch(handleClickOnPointPEI(templateName));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PEIChartTemplateContainer)