import React from "react";
import SectionHeader from "../atomic/SectionHeader";
import {Scrollbars} from "react-custom-scrollbars";
import {Table, TableBody, TableRow} from "@material-ui/core";
import HeaderTableCell from "../atomic/table/HeaderTableCell";
import SectionContainer from "../atomic/SectionContainer";
import SnowflakeDatabaseReportInventorySection from "../../types/report/SnowflakeDatabaseReportInventorySection";
import StringTableCell from "../atomic/table/StringTableCell";
import BooleanTableCell from "../atomic/table/BooleanTableCell";
import NumberTableCell from "../atomic/table/NumberTableCell";
import DecimalCurrencyTableCell from "../atomic/table/DecimalCurrencyTableCell";
import DecimalNumberTableCell from "../atomic/table/DecimalNumberTableCell";

interface InventorySectionProps {
    title: string;
    data: SnowflakeDatabaseReportInventorySection;
}

const InventorySection: React.FC<InventorySectionProps> = ({
    title,
    data
                                    }) => (
    <SectionContainer>
        <SectionHeader>{title}</SectionHeader>
        <Scrollbars style={{width: "100%", height: "300px"}}>
            <Table size="small">
                <TableRow>
                    <HeaderTableCell>Warehouse name</HeaderTableCell>
                    <HeaderTableCell>Cost ($)</HeaderTableCell>
                    <HeaderTableCell>Credits Used</HeaderTableCell>
                    <HeaderTableCell>WH Size</HeaderTableCell>
                    <HeaderTableCell>Scaling Policy</HeaderTableCell>
                    <HeaderTableCell>WH Cluster Size (Min-Max)</HeaderTableCell>
                    <HeaderTableCell>Auto Suspend (s)</HeaderTableCell>
                    <HeaderTableCell>Auto Resume</HeaderTableCell>
                    <HeaderTableCell>Query Accl. Enabled</HeaderTableCell>
                    <HeaderTableCell>Query Accl. Max Scale Factor</HeaderTableCell>
                    <HeaderTableCell>WH Type</HeaderTableCell>
                    <HeaderTableCell>Owner</HeaderTableCell>
                </TableRow>
                <TableBody>
                    {data?.rows.map((row, index) => (
                        <TableRow key={`inv-table-row-${index}`}>
                            <StringTableCell>{row.warehouseName}</StringTableCell>
                            <DecimalCurrencyTableCell>{row.cost}</DecimalCurrencyTableCell>
                            <DecimalNumberTableCell>{row.creditsUsed}</DecimalNumberTableCell>
                            <StringTableCell>{row.whSize}</StringTableCell>
                            <StringTableCell>{row.scalingPolicy}</StringTableCell>
                            <StringTableCell>{row.whClusterSize}</StringTableCell>
                            <NumberTableCell>{row.autoSuspend}</NumberTableCell>
                            <BooleanTableCell>{row.autoResume}</BooleanTableCell>
                            <BooleanTableCell>{row.queryAcclEnabled}</BooleanTableCell>
                            <NumberTableCell>{row.queryAcclMaxScaleFactor}</NumberTableCell>
                            <StringTableCell>{row.whType}</StringTableCell>
                            <StringTableCell>{row.owner}</StringTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Scrollbars>
    </SectionContainer>
);

export default InventorySection;
