import {BASE_API_URL} from "config";
import CommonCrudRestClient from "api/rest/CommonCrudRestClient";


class UserInfoRestClient extends CommonCrudRestClient {

    async getUserInfo() {
        return await super.get("");
    }
}

export default new UserInfoRestClient(`${BASE_API_URL}/userInfo`);