import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: {

  },
});

class RemoveConfirmation extends Component {
  render() {
    const {classes, open, title, message, handleClose, handleConfirm} = this.props;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

RemoveConfirmation.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,

  //func
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,

  //...
};

export default withStyles(styles)(RemoveConfirmation);