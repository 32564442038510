import {fontFamily} from "./globalAuthStyles";

const bottomHintStyles = {
  root: {
    marginTop: '8px',
    fontSize: '13px',
    color: '#555',
    fontFamily: fontFamily,
  }
};

export default bottomHintStyles;