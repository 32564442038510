import React from "react";
import ApplicationPage from "components/common/ApplicationPage/ApplicationPage";
import IntegrationContent from "./IntegrationContent";
import IntegrationLeftNavBar from "./IntegrationLeftNavBar";

const Integration: React.FC = ({}) => {
    return (
        <ApplicationPage
            leftNavBarComponent={<IntegrationLeftNavBar />}
            contentComponent={<IntegrationContent />}
        />
    );
};

export default Integration;
