import JobInfo from "types/model/infrastructure/JobInfo";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {normalize} from "normalizr";
import jobSchema from "schemas/infrastructure/jobSchema";

interface SetJobStatusPayload {
    jobId: string;
    jobStatus: string;
}

interface JobsState {
    backendKey: string | null;
    entities: Record<string, JobInfo> | {};
    ids: number[];
    loading: boolean;
    error: string | null;
};

const initialState: JobsState = {
    backendKey: null,
    entities: {},
    ids: [],
    loading: false,
    error: null,
};

const jobsSlice = createSlice({
    name: "jobs",
    initialState,
    reducers: {
        setJobsLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setJobs(state, action: PayloadAction<JobInfo[]>) {
            const normalizedJobs = normalize(action.payload, [jobSchema]);
            // @ts-ignore
            state.entities = normalizedJobs.entities.jobs;
            state.ids = normalizedJobs.result;
        },
        setJobStatus: {
            reducer(state, action: PayloadAction<SetJobStatusPayload>) {
                const {jobId, jobStatus} = action.payload;
                // @ts-ignore
                if (state.entities[jobId]) {
                    // @ts-ignore
                    state.entities[jobId].jobStatus = jobStatus;
                }
            },
            prepare(jobId: string, jobStatus: string) {
                return {
                    payload: {jobId, jobStatus},
                };
            },
        },
        setJobsBackendKey(state, action: PayloadAction<string>) {
            state.backendKey = action.payload;
            // clear results
            state.ids = [];
            state.entities = {};
        },
    }
});

export const {
    setJobsLoading,
    setJobs,
    setJobStatus,
    setJobsBackendKey,
} = jobsSlice.actions;

export default jobsSlice.reducer;

export const selectJobsBackendKey = (state: any) => state.jobs.backendKey;

export const selectJobsEntities = (state: any) => state.jobs.entities;

export const selectJobsIds = (state: any) => state.jobs.ids;

export const selectJobsLoading = (state: any) => state.jobs.loading;