import React, {Component} from 'react';
import { connect } from 'react-redux';
import { logout } from '../actions/authActions';

class LogoutHandler extends Component {

  componentWillMount() {
    this.props.dispatch(logout());
  }

  render() {
    return (<div className="empty-auth-aware"></div>);
  }
}

const mapStateToProps = (state) => {
  return {

  }
};

export default connect(
  mapStateToProps
)(LogoutHandler);