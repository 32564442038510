import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLeftNavBarOpen, setLeftNavBarOpen} from "reducers/settingsSlice";
import {useHistory, useLocation} from "react-router-dom";
import LeftNavBar from "components/common/LeftNavBar/LeftNavBar";
import LeftNavBarListItem from "components/common/LeftNavBar/LeftNavBarListItem";
import * as paths from "routes/paths";
import LeftNavBarListItemText from "components/common/LeftNavBar/LeftNavBarListItemText";

const IntegrationLeftNavBar: React.FC = ({}) => {
    const dispatch = useDispatch();
    const leftNavBarOpen = useSelector(selectLeftNavBarOpen);
    const location = useLocation();
    const history = useHistory();
    const { pathname } = location;

    const handleClick = (path: any) => {
        history.push(path);
    }

    return (
        // @ts-ignore
        <LeftNavBar
            title="Integrations"
            open={leftNavBarOpen}
            setOpen={() => dispatch(setLeftNavBarOpen(!leftNavBarOpen))}
        >
            {/*@ts-ignore*/}
            <LeftNavBarListItem onClick={() => handleClick(paths.HOME_PATH)}>
                <LeftNavBarListItemText primary="< Back to Home" active={false} />
            </LeftNavBarListItem>
            {/*@ts-ignore*/}
            <LeftNavBarListItem onClick={() => handleClick(paths.INTEGRATIONS_APM)}>
                <LeftNavBarListItemText primary="APM" active={pathname === paths.INTEGRATIONS_APM} />
            </LeftNavBarListItem>
            {/*@ts-ignore*/}
            <LeftNavBarListItem onClick={() => handleClick(paths.INTEGRATIONS_TICKETING)}>
                <LeftNavBarListItemText primary="Ticketing" active={pathname === paths.INTEGRATIONS_TICKETING} />
            </LeftNavBarListItem>
            {/*@ts-ignore*/}
            <LeftNavBarListItem onClick={() => handleClick(paths.INTEGRATIONS_BOTS)}>
                <LeftNavBarListItemText primary="Bots" active={pathname === paths.INTEGRATIONS_BOTS} />
            </LeftNavBarListItem>
            {/*@ts-ignore*/}
            <LeftNavBarListItem onClick={() => handleClick(paths.INTEGRATIONS_LEGACY_CHANNELS)}>
                <LeftNavBarListItemText primary="Legacy Channels" active={pathname === paths.INTEGRATIONS_LEGACY_CHANNELS} />
            </LeftNavBarListItem>
            {/*@ts-ignore*/}
            <LeftNavBarListItem onClick={() => handleClick(paths.INTEGRATIONS_GEN_AI)}>
                <LeftNavBarListItemText primary="GEN AI" active={pathname === paths.INTEGRATIONS_GEN_AI} />
            </LeftNavBarListItem>
            {/*@ts-ignore*/}
            <LeftNavBarListItem onClick={() => handleClick(paths.INTEGRATIONS_OTHER)}>
                <LeftNavBarListItemText primary="Other" active={pathname === paths.INTEGRATIONS_OTHER} />
            </LeftNavBarListItem>
        </LeftNavBar>
    );
};

export default IntegrationLeftNavBar;
