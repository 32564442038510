import { HLC_SERVER_URL, PEI_SERVER_URL } from '../config';

export const HLC = 'HLC';
export const PEI = 'PEI';

export const APP_CONF = {
  [HLC]: {
    KEY: HLC,
    NAME: "UpBeat High Load Capture",
    START_APP_TITLE: "Start UpBeat High Load Capture",
    URL: HLC_SERVER_URL,
    API: {
      LOGIN: `${HLC_SERVER_URL}/login/authenticate`,
      LOGOUT: `${HLC_SERVER_URL}/api/logout`,
      DOWNLOAD_AGENT: `${HLC_SERVER_URL}/api/backendManagement/downloadBackend`,
    },
  },
  [PEI]: {
    KEY: PEI,
    NAME: "UpBeat Performance Explorer",
    START_APP_TITLE: "Start UpBeat Performance Explorer",
    URL: PEI_SERVER_URL,
    API: {
      LOGIN: `${PEI_SERVER_URL}/login/authenticate`,
      LOGOUT: `${PEI_SERVER_URL}/api/logout`,
      DOWNLOAD_AGENT: `${PEI_SERVER_URL}/api/downloadBackend`,
    },
  },
};
