import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tabs,
    Tab,
    Typography,
    Button,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Grid,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    Backdrop,
    withStyles,
    Input
} from "@material-ui/core";
import TargetDatabaseType from 'types/model/common/TargetDatabaseType';
import { REGULAR_DASHBOARD, ANOMALY_DASHBOARD, DEFAULT_DASHBOARD } from 'dashlets/AnomalyDetectionDashlet/constants';
import HLCDashletsRestClient from 'api/rest/HLCDashletsRestClient';
import _ from 'lodash';
import GUIDELINES_CONTENT from './GuidelinesContent'; 
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
    root: {
        maxWidth: '700px',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '470px',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #f0f0f0',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f0f0f0',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px', 
        },
    },
    actionButtonLabel: {
        fontWeight: 600,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    toggleButton: {
        padding: '8px',
        gap: '8px'
    }
});

class QuickDashboardModal extends Component {
    state = {
        activeTab: 'guidelines',
        backends: [],
    };

    componentDidMount() {
        HLCDashletsRestClient.listUserBackends()
            .then(backends => {
                this.setState({ backends });
            })
            .catch(error => {
                console.error(error);
            });
    }

    handleTabChange = (event, newValue) => {
        this.setState({ activeTab: newValue });
    };

    render() {
        const { classes, open, handleClose, handleConfirm, isLocked, errors, objectData, isDashboardMenuOpen, addDashboardMenuAnchorEl, handleMenuClose, handleChangeInput, handleDashboardTypeChange, handleBackendSelect, backendKey, dashboardType, targetConfiguration } = this.props;
        const { activeTab, backends } = this.state;

        const {
            databaseType,
            databaseHost,
            databasePort,
            databaseName,
            databaseUser,
            databasePassword,
            databaseUrl,
            useDatabaseUrl
        } = targetConfiguration;


        return (
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    {"Quick Dashboard"}
                    <Tabs value={activeTab} onChange={this.handleTabChange} aria-label="tabs">
                        <Tab label="Guidelines" value="guidelines" />
                        <Tab label="Add Dashboard" value="addDashboard" />
                    </Tabs>
                    <Divider />
                </DialogTitle>

                <Backdrop open={isLocked}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <DialogContent className={classes.dialogContent}>

                    {activeTab === 'guidelines' && (
                         <>
                         <Button onClick={(event) => activeTab === 'guidelines' ? this.handleTabChange(event, 'addDashboard') : null} color="secondary" startIcon={<AddIcon />}>
                             CREATE YOUR FIRST DASHBAORD
                         </Button>
                         <Typography variant="body1" sx={{ padding: 2 }} className={classes.dialogContent}>
                             {GUIDELINES_CONTENT}
                         </Typography>
                     </>
                    )}
                    {
                        activeTab === 'addDashboard' && (
                            <>
                                <Backdrop className={classes.backdrop} open={isLocked}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <DialogContent className={classes.dialogContent}>

                                    <FormControl key="dash-name" fullWidth required error={errors.name} margin="dense">
                                        <InputLabel id="name-label" htmlFor="dash-name">Dashboard Name</InputLabel>
                                        <Input
                                            id="dash-name"
                                            name="name"
                                            type="text"
                                            value={objectData.name}
                                            onChange={handleChangeInput}
                                        />
                                    </FormControl>

                                    <FormControl variant="outlined" size="small" fullWidth margin="normal">
                                        <InputLabel id="dashboard-type-label">Dashboard Type</InputLabel>
                                        <Select
                                            labelId="dashboard-type-label"
                                            id="dashboardType"
                                            name="dashboardType"
                                            value={this.props.dashboardType}
                                            onChange={this.props.handleDashboardTypeChange}
                                            label="Dashboard Type"
                                            fullWidth
                                        >
                                            <MenuItem value={REGULAR_DASHBOARD}>Regular Dashboard</MenuItem>
                                            <MenuItem value={ANOMALY_DASHBOARD}>Anomaly Dashboard</MenuItem>
                                            <MenuItem value={DEFAULT_DASHBOARD}>Default Dashboard</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {this.props.dashboardType === ANOMALY_DASHBOARD && (
                                        <Grid item xs={12}>
                                            <FormControl variant="outlined" size="small" fullWidth>
                                                <InputLabel id="hlc-backend">Select Upbeat Backend</InputLabel>
                                                <Select
                                                    labelId="dashlet-hlc-chart-backendKey-label-1582673886219"
                                                    id="backendKey"
                                                    name="backendKey"
                                                    value={backendKey ? backendKey : ''}
                                                    onChange={this.props.handleBackendSelect}
                                                    label="Select Upbeat Backend"
                                                    fullWidth
                                                >
                                                    <MenuItem key="dashlet-hlc-chart-backendKey-menu-item-1582673956819" value=""></MenuItem>
                                                    {_.map(this.state.backends, (backend) => {
                                                        return (
                                                            <MenuItem
                                                                key={`dashlet-hlc-chart-backendKey-menu-item-key-${backend.backendKey}`}
                                                                value={backend.backendKey}>
                                                                {backend.name}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )}
                                    <Grid
                                        direction="row"
                                        alignItems="center">
                                        {this.props.dashboardType === DEFAULT_DASHBOARD && (
                                            <div>
                                                <Grid item xs={12}>
                                                    <FormControl variant="outlined" size="small" fullWidth>
                                                        <InputLabel id="hlc-backend">Select Upbeat Backend</InputLabel>
                                                        <Select
                                                            labelId="dashlet-hlc-chart-backendKey-label-1582673886219"
                                                            id="backendKey"
                                                            name="backendKey"
                                                            value={backendKey ? backendKey : ''}
                                                            onChange={this.props.handleBackendSelect}
                                                            label="Select Upbeat Backend"
                                                            fullWidth
                                                        >
                                                            <MenuItem key="dashlet-hlc-chart-backendKey-menu-item-1582673956819" value=""></MenuItem>
                                                            {_.map(this.state.backends, (backend) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={`dashlet-hlc-chart-backendKey-menu-item-key-${backend.backendKey}`}
                                                                        value={backend.backendKey}>
                                                                        {backend.name}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl margin="dense" variant="outlined" size="small" fullWidth>
                                                        <InputLabel id="database-type-label">Database Type</InputLabel>
                                                        <Select
                                                            id="databaseType"
                                                            name="databaseType"
                                                            labelId="database-type-label"
                                                            value={databaseType}
                                                            onChange={this.handleChangeInput}
                                                            disabled={!backendKey}
                                                            label="Database Type"
                                                            fullWidth
                                                        >
                                                            {Object.values(TargetDatabaseType).map((dbType) => (
                                                                // @ts-ignore
                                                                <MenuItem key={`db-type-${dbType}`} value={dbType}>
                                                                    {dbType}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item container spacing={1}>
                                                    <Grid item xs={8}>
                                                        <TextField
                                                            required
                                                            error={errors.databaseHost}
                                                            id="databaseHost"
                                                            name="databaseHost"
                                                            label="Host"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            value={databaseHost}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={4}>
                                                        <TextField
                                                            required
                                                            error={errors.databasePort}
                                                            id="databasePort"
                                                            name="databasePort"
                                                            label="Port"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            value={databasePort}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid item container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            required
                                                            error={errors.databaseUser}
                                                            id="databaseUser"
                                                            name="databaseUser"
                                                            label="Username"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            value={databaseUser}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            required
                                                            error={errors.databasePassword}
                                                            id="databasePassword"
                                                            name="databasePassword"
                                                            label="Password"
                                                            type="password"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            value={databasePassword}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        error={errors.databaseName}
                                                        id="databaseName"
                                                        name="databaseName"
                                                        label="Database"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="dense"
                                                        size="small"
                                                        value={databaseName}
                                                        onChange={handleChangeInput}
                                                    />
                                                </Grid>

                                                <Grid item>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                color='primary'
                                                                id="useDatabaseUrl"
                                                                name="useDatabaseUrl"
                                                                size="small"
                                                                checked={useDatabaseUrl}
                                                                onChange={this.toggle}
                                                            />
                                                        }
                                                        label="Use Database/JDBC URL"
                                                    />
                                                </Grid>

                                                <Grid item>
                                                    <TextField
                                                        required={useDatabaseUrl}
                                                        error={errors.databaseUrl}
                                                        multiline
                                                        maxRows={5}
                                                        id="databaseUrl"
                                                        name="databaseUrl"
                                                        label="Database/JDBC URL"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="dense"
                                                        size="small"
                                                        disabled={!useDatabaseUrl || !backendKey}
                                                        value={databaseUrl}
                                                        onChange={handleChangeInput}
                                                    />
                                                </Grid>
                                            </div>)}
                                    </Grid>

                                </DialogContent>
                            </>
                        )
                    }
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={(event) => activeTab === 'guidelines' ? this.handleTabChange(event, 'addDashboard') : handleConfirm(event)} color="primary">
                        {activeTab === 'guidelines' ? 'Next' : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

QuickDashboardModal.propTypes = {
    //data
    open: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    objectData: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    isLocked: PropTypes.bool,
    open: PropTypes.bool,
    targetConfiguration: PropTypes.object,

    //func
    handleClose: PropTypes.func,
    handleConfirm: PropTypes.func,
    handleChangeInput: PropTypes.func,
    handleDashboardTypeChange: PropTypes.func,
    handleChangeProperty: PropTypes.func,
    handleBackendSelect: PropTypes.func,
};

export default withStyles(styles)(QuickDashboardModal);
