import React, { useEffect, useState, useRef } from "react";
import Dimensions from 'react-dimensions';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useDispatch, useSelector } from "react-redux";
import { selectAnomalyDashboard, isDashletPreviewOpenSelector } from "selectors/dashboard/DashboardSelectors";
import * as _ from 'lodash';
import numeral from "numeral";
import { setTimeRanges, setExtremes } from "reducers/dashboard/dashboardSlice";

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}

const AnomalyDetectionView = React.forwardRef((props, ref) => {
    const { dashlet, isPreviewOpen } = props;
    const targetName = dashlet.config.targetName;
    const captureName = dashlet.name;
    const [dashletData, setDashletData] = useState([]);
    const [series, setSeries] = useState([]);
    const [isTimeRangeSet, setIsTimeRangeSet] = useState(false);
    const anomalyDashboard = useSelector(state => selectAnomalyDashboard(state, dashlet.dashboardId));
    const isDashletPreviewOpen = useSelector(state => isDashletPreviewOpenSelector(state, dashlet.dashboardId));
    const windowSize = useWindowSize();
    const xMin = useSelector(state => state.dashboard.startTime);
    const xMax = useSelector(state => state.dashboard.endTime);
    const dispatch = useDispatch();
    const chartRef = useRef(null);

    useEffect(() => {
        let anomalyDashletData = [];
        if (anomalyDashboard && Object.keys(anomalyDashboard).length > 0) {
            if (!anomalyDashboard[targetName] || !anomalyDashboard[targetName][captureName]) {
                setSeries([]);
                return;
            }
            anomalyDashletData = anomalyDashboard[targetName][captureName];
        }

        let groupSeries = [];
        _.each(anomalyDashletData, (groupArrayItem) => {
            const data = _.slice(groupArrayItem.values);
            const result = {
                name: `${groupArrayItem.target}/${groupArrayItem.capture}`,
                data: data,
                isHistorical: groupArrayItem.isHistorical,
                target: groupArrayItem.target,
                capture: groupArrayItem.capture,
                metric: groupArrayItem.metric,
                measurement: groupArrayItem.measurement,
            };
            groupSeries.push(result)
        });
        setSeries(groupSeries);
        setDashletData(anomalyDashletData);

        // Dispatch default time ranges
        if (isDashletPreviewOpen && isPreviewOpen && !isTimeRangeSet) {
            if (groupSeries.length > 0) {
                const chartStartTime = groupSeries[0].data[0][0];
                const chartEndTime = groupSeries[0].data[groupSeries[0].data.length - 1][0];
                if (chartStartTime === chartEndTime)
                    dispatch(setTimeRanges({ startTime: chartStartTime - 180000, endTime: chartEndTime + 180000 }));
                else 
                    dispatch(setTimeRanges({ startTime: chartStartTime, endTime: chartEndTime }));
                setIsTimeRangeSet(true)
            }
        }
        
    }, [anomalyDashboard, dispatch, targetName, captureName]);

    const calculateHeight = () => {
        if (isDashletPreviewOpen) {
            return windowSize.height * 0.9;
        } else {
            return '45%';
        }
    };

    const chartOptions = {
        time: {
            useUTC: false,
        },
        title: {
            enabled: false,
            style: {
                display: 'none',
            }
        },
        xAxis: {
            visible: isDashletPreviewOpen,
            type: 'datetime',
            minRange: 1,
            ordinal: false,
        },
        yAxis: {
            visible: isDashletPreviewOpen,
            title: {
                text: 'Sum of Anomalies'
            }
        },
        tooltip: {
            enabled: isDashletPreviewOpen,
            formatter: function () {
                const date = new Date(this.x);
                const formattedDateTime = date.toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                });
                const formattedYValue = this.y.toFixed(2);
                return `<b>${this.series.name}</b><br>${formattedDateTime}: ${formattedYValue}`;
            },
            useHTML: true,
            // positioner: function (labelWidth, _, point) {
            //     return {
            //         x: point.plotX - labelWidth + 30 > 0 ? point.plotX - labelWidth + 30 : point.plotX + 70,
            //         y: point.plotY,
            //     };
            // }
        },
        chart: {
            type: "area",
            height: calculateHeight(),
            zoomType: 'xy',
            reflow: true,
            events: {
                load: function () {
                    let chart = this;
                    if (isDashletPreviewOpen) {
                        chart.xAxis[0].setExtremes(
                            chart.xAxis[0].dataMin,
                            chart.xAxis[0].dataMax,
                            undefined,
                            false,
                            { trigger: 'load' }
                        );
                    }                    
                },
                selection: function (e) {
                    if (isDashletPreviewOpen) { 
                        if (e.xAxis) {
                            dispatch(setTimeRanges({ startTime: e.xAxis[0].min, endTime: e.xAxis[0].max }));
                            this.xAxis[0].setExtremes(e.xAxis[0].min, e.xAxis[0].max, undefined, false, { trigger: 'selection' });
                        }
                    } else {
                        e.preventDefault();
                    }
                }
            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: true,
                    radius: 3,
                    symbol: 'circle',
                    states: {
                        hover: {
                            enabled: true,
                        }
                    }
                },
                point: {
                    events: {
                        mouseOver: function () {
                            const chart = this.series.chart;
                            const date = new Date(this.x);
                            const formattedDateTime = date.toLocaleString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            });
                            if (!isDashletPreviewOpen) {
                                document.getElementById(`tooltip-${dashlet.id}`).innerHTML = `<b>${formatFloatNumber(this.y)}</b>, ${formattedDateTime}`;
                            }
                        }
                    }
                },
                events: {
                    mouseOut: function () {
                        if (!isDashletPreviewOpen) {
                            document.getElementById(`tooltip-${dashlet.id}`).innerHTML = `<b>${formatFloatNumber(dashletData[0].maxValue)} (Total Counts)</b>, Selected Duration`;
                        }
                    }
                }
            }
        },
        series: series
    };

    const labelStyle = {
        position: 'absolute',
        left: '50%',
        top: '35%',
        transform: 'translate(-50%, -50%)',
        color: 'black',
        zIndex: 10,
    };

    const formatFloatNumber = (number) => {
        return numeral(number).format("0,0.00");
    };

    return (
        <div>
            <div ref={ref}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    ref={chartRef}
                />
            </div>
            {series.length > 0 ? dashletData && dashletData.length > 0 &&
                <div>
                    <div style={labelStyle}><b>{formatFloatNumber(dashletData[0].avgValue)}</b></div> </div> :
                <div style={labelStyle}><b>NO DATA</b></div>}
            {!isDashletPreviewOpen && series.length > 0 && dashletData && dashletData.length > 0 && (
                <>
                    <div id={`tooltip-${dashlet.id}`} style={{ textAlign: 'left', fontSize: '12px', minHeight: '30px', backgroundColor: '#D3D3D350', padding: '1px', border: '5px solid transparent' }}>
                        {dashletData && dashletData.length > 0 ? <b>{formatFloatNumber(dashletData[0].maxValue)} (Total Count)</b> : "-"}, Selected Duration
                    </div>
                </>
            )}
        </div>
    );
});

export default Dimensions({elementResize: true})(AnomalyDetectionView);
