import React from "react";

interface FinOpsDashletEditConfigProps {

}

const FinOpsDashletEditConfig: React.FC<FinOpsDashletEditConfigProps> = ({}) => {

    //todo: implement me!

    return null;
}

export default FinOpsDashletEditConfig;
