import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {LoginLink, RegistrationLink} from "../../../routes/links";
import {Container} from "@material-ui/core";
import {AuthSubmitButton, AuthLogo, AuthLinkBordered} from "../../../components/gate/Auth/Auth";
import AuthFormHeader from "../../../components/gate/Auth/AuthFormHeader";
import BottomHint from "../../../components/gate/Auth/BottomHint";
import AuthLink from "../../../components/gate/Auth/AuthLink";
import {authFormStyles} from "../../../jss/gate/Auth/authStyles";

class ForgotPasswordFinish extends Component {
  render() {
    const {classes, onBack} = this.props;

    return (
      <Container id="login-container" className={classes.container}>
        <main className={classes.main}>
          <Paper className={classes.paper}>
            <AuthLogo />
            <AuthFormHeader title="Reset Password" />

            <Typography component="p" className={classes.hint}>
              Email with new password was sent.
            </Typography>

            <form className={classes.form}>
              <AuthSubmitButton
                type="submit"
                fullWidth
                variant="contained"
                onClick={onBack}
              >
                Back
              </AuthSubmitButton>
            </form>

            <Container className={classes.linksContainer}>
              <AuthLinkBordered label="Sign in" actionComponent={LoginLink}/>
              <AuthLink label="Create new account" actionComponent={RegistrationLink}/>
            </Container>

            <BottomHint />

          </Paper>
        </main>
      </Container>

    );
  }
}

ForgotPasswordFinish.propTypes = {
  //data
  classes: PropTypes.object,

  //func
  onBack: PropTypes.func.isRequired,
};

export default withStyles(authFormStyles)(ForgotPasswordFinish);