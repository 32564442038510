import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import {authLinkStyles} from "../../../jss/gate/Auth/authStyles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(authLinkStyles);

function AuthLink(props) {
  const classes = useStyles(props);
  const {label, actionComponent } = props;

  return (
    <Typography component="div" className={classes.root}>
      <Link component={actionComponent} underline="hover" className={classes.link}>{label}</Link>
    </Typography>
  )
}

AuthLink.propTypes = {
  //data
  classes: PropTypes.object,
  label: PropTypes.string.isRequired,
  actionComponent: PropTypes.func.isRequired,
  rightBorder: PropTypes.bool,

};

export default AuthLink;