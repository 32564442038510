import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ChangePassword from "./ChangePassword";
import * as _ from 'lodash';
import {changePassword} from "../../../actions/authActions";

class ChangePasswordContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLocked: false,
      passwordsDetails: {
        password: '',
        password2: ''
      },
      errors: {
        password: '',
        password2: ''
      }
    };
  }

  validate = () => {
    let hasErrors = false;
    const {passwordsDetails} = this.state;
    const errors = {
      password: '',
      password2: ''
    };

    const password = _.trim(passwordsDetails.password);
    if (!password) {
      hasErrors = true;
      errors.password = 'Password is required'
    }
    const password2 = _.trim(passwordsDetails.password2);
    if (!password2) {
      hasErrors = true;
      errors.password2 = 'Password is required'
    }

    if (!hasErrors && password !== password2) {
      hasErrors = true;
      errors.password = "Passwords don't match";
      errors.password2 = "Passwords don't match";
    }
    passwordsDetails.password = password;
    passwordsDetails.password2 = password2;

    this.setState({errors, passwordsDetails});

    return hasErrors;
  };

  lockForm = () => {
    this.setState({isLocked: true});
  };

  unlockForm = () => {
    this.setState({isLocked: false});
  };

  onClickSubmitHandler = (event) => {
    event.preventDefault();

    this.lockForm();

    if (!this.validate()) {
      this.props.changePassword(this.state.passwordsDetails.password)
    } else {
      this.unlockForm();
    }
  };

  onChangeInputHandler = (event) => {
    event.preventDefault();

    let {passwordsDetails, errors} = this.state;
    const target = event.target;

    passwordsDetails[target.name] = target.value;

    errors[target.name] = '';
    this.setState({passwordsDetails, errors});
  };

  render() {
    const {isLocked} = this.props;
    const {errors} = this.state;

    return (
      <ChangePassword
        disabled={isLocked}
        errors={errors}
        onSubmit={this.onClickSubmitHandler}
        onChangeInputHandler={this.onChangeInputHandler}
      />
    );
  }
}

ChangePasswordContainer.propTypes = {
  //data

  //func
  changePassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    // errorMessage: state.auth.loginErrorMessage,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (password) => {
      dispatch(changePassword(password));
    },
}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordContainer)