import {buildDashletObject, refresh, validateConfig} from './actions';
import HeatmapDashletView from './components/HeatmapDashletView';
import HeatmapDashletCreate from './components/HeatmapDashletCreate';
import HeatmapDashletSettingsContainer from "./components/HeatmapDashletSettingsContainer";
import HeatmapDashletEdit from "./components/HeatmapDashletEdit";

const DASHLET_TYPE_NAME = 'HEATMAP_DASHLET_TYPE';
export const HEATMAP_DASHLET_CONFIG = {
  [DASHLET_TYPE_NAME]: {
    typeName: DASHLET_TYPE_NAME,
    typeLabel: 'Heatmap',
    viewComponent: HeatmapDashletView,
    createFormComponent: HeatmapDashletCreate,
    editConfigFormComponent: HeatmapDashletEdit,
    settingsComponent: HeatmapDashletSettingsContainer,
    openTemplateAsChartComponent: null,
    timeRangeComponent: null,
    validateConfigFunc: validateConfig,
    loadOptionsAction: null,
    refreshAction: refresh,
    buildDashletObjectFunc: buildDashletObject,
  }
};