import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isSpikePreviewOpened: false,
    isLoading: true,
    targetName: null,
    timestamp: null,
    data: null
};

const heatmapSpikePreviewSlice = createSlice({
    name: 'heatmapSpikePreview',
    initialState,
    reducers: {
        openSpikePreview: {
            reducer(state, action) {
                const {targetName, timestamp} = action.payload;
                state.isSpikePreviewOpened = true;
                state.isLoading = true;
                state.targetName = targetName;
                state.timestamp = timestamp;
                state.data = null;
            },
            prepare(targetName, timestamp) {
                return {
                    payload: {targetName, timestamp}
                }
            }
        },
        closeSpikePreview(state, action) {
            state.isSpikePreviewOpened = false;
            state.isLoading = false;
            state.targetName = null;
            state.timestamp = null;
            state.data = null;
        },
        setSpikePreviewData(state, action) {
            state.data = action.payload;
            state.isLoading = false;
        }
    }
});

export const {
    openSpikePreview,
    closeSpikePreview,
    setSpikePreviewData,
} = heatmapSpikePreviewSlice.actions;

export default heatmapSpikePreviewSlice.reducer;

/**
 * ==========
 * Selectors
 * ==========
 */

export const selectIsSpikePreviewOpened = state => state.heatmapSpikePreview.isSpikePreviewOpened;
export const selectIsSpikePreviewLoading = state => state.heatmapSpikePreview.isLoading;
export const selectSpikePreviewTargetName = state => state.heatmapSpikePreview.targetName;
export const selectSpikePreviewTimestamp = state => state.heatmapSpikePreview.timestamp;
export const selectSpikePreviewData = state => state.heatmapSpikePreview.data;
export const selectLastTargetSpikes = state => state.heatmapSpikePreview.lastTargetSpikes; 
export const selectIsSelectSpikeOpened = state => state.heatmapSpikePreview.isSelectSpikeOpened;
