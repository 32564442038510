import HLCChartDashletSettingsContainer from "dashlets/HLCChartDashlet/components/HLCChartDashletSettingsContainer";
import {refresh, validateConfig} from "./actions";
import * as constants from "./constants";
import EmptyDashletView from "./components/EmptyDashletView";
import EmptyDashletCreate from "./components/EmptyDashletCreate";

export const EMPTY_DASHLET_CONFIG = process.env.NODE_ENV !== 'production' ? {
  [constants.DASHLET_TYPE_NAME]: {
    typeName: constants.DASHLET_TYPE_NAME,
    typeLabel: constants.DASHLET_TYPE_LABEL,
    viewComponent: EmptyDashletView,
    createFormComponent: EmptyDashletCreate,
    settingsComponent: HLCChartDashletSettingsContainer,
    validateConfigFunc: validateConfig,
    loadOptionsAction: null,
    refreshAction: refresh,
  }
} : {};