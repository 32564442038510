const switchStyles = theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#fff',
    '&$checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#137cbd',
        borderColor: '#137cbd',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#545b64',
  },
  checked: {},
});

export default switchStyles;