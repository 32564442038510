import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import PropTypes from "prop-types";
import React, {Component} from "react";

const timeRangeTabsStyles = theme => ({
  root: {
    marginBottom: '8px',
    border: 'none',
    padding: '8px 8px 0 8px',
    minHeight: 'auto',
  },
  indicator: {
    display: 'none',
    // height: '3px',
    // backgroundColor: '#16191f',
    // width: '84px',
    // minWidth: '84px',
  },
});

class TimeRangeTabs extends Component {

  render() {
    const {classes, value, onChange, ...other} = this.props;

    return (
        <Tabs
            classes={{root: classes.root, indicator: classes.indicator}}
            scrollButtons="off"
            value={value}
            onChange={onChange}
            {...other}
        >
          {this.props.children}
        </Tabs>
    );
  }
}

TimeRangeTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default withStyles(timeRangeTabsStyles)(TimeRangeTabs)