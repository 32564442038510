import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Registration from "./Registration";
import * as AuthActions from "../../../actions/authActions";
import {clearLoginErrorMessage} from "../../../reducers/authSlice";

class RegistrationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userDetails: {
        name: '',
        username: '',
        password: '',
      }
    };
  }

  onClickRegisterHandler = (event) => {
    event.preventDefault();

    this.props.register(this.state.userDetails);
  };

  onChangeInputHandler = (event) => {
    event.preventDefault();

    let {userDetails} = this.state;
    const target = event.target;

    userDetails[target.name] = target.value;

    this.setState({userDetails});
  };

  render() {
    const {errorMessage, isInProgress} = this.props;

    return (
      <Registration
        errorMessage={errorMessage}
        disabled={isInProgress}
        onRegister={this.onClickRegisterHandler}
        onChangeInputHandler={this.onChangeInputHandler}
      />
    );
  }

  componentWillUnmount() {
    // this.props.clearLoginErrorMessage();
  }
}

RegistrationContainer.propTypes = {
  //data
  isInProgress: PropTypes.bool,
  errorMessage: PropTypes.string,

  //func
  showRegistrationPage: PropTypes.func,
  showForgotPasswordPage: PropTypes.func,
  register: PropTypes.func.isRequired,
  clearLoginErrorMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.registrationErrorMessage,
    isInProgress: state.auth.isRegistrationInProgress,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: (registerUserDetails) => {
      dispatch(AuthActions.register(registerUserDetails));
    },
    clearLoginErrorMessage: () => {
      dispatch(clearLoginErrorMessage());
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationContainer)