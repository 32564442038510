import {lightTextColor, blackTextColor} from "../../globalStyles";
import {fontFamily} from "./globalAuthStyles";

const headerStyles = {
  root: {
    fontSize: '18px',
    color: blackTextColor,
    marginBottom: '0px',
    fontFamily: fontFamily,
  }
};

const inputStyles = {
  root: {
    backgroundColor: 'rgba(60, 146, 212, 0.05)',
    padding: '5px 10px',
    height: '32px',
    color: '#535960',
    margin: '0',
    fontFamily: fontFamily,
  },
};

const submitButtonStyles = {
  root: {
    marginTop: '8px',
    backgroundColor: '#14559B',
    borderRadius: '15px',
    textTransform: 'none',
    fontSize: '16px',

    '&:hover': {
      backgroundColor: '#166fc1'
    }
  },
  label: {
    color: lightTextColor,
    fontFamily: fontFamily,
  }
};

const errorMessageStyles = {
  root: {
    fontFamily: fontFamily,
    fontSize: '14px'
  }
};

const authLinkStyles = {
  root: {
    padding: '10px',
    fontSize: '14px',
    fontFamily: fontFamily,
  },
  link: {
    color: blackTextColor,
  }
};

const authLinkStylesBorder = {
  ...authLinkStyles,
  root: {
    ...authLinkStyles.root,
    '&::after': {
      content: "''",
      display: 'inline-block',
      position: 'relative',
      right: 0,
      top: '50%',
      height: '16px',
      transform: 'translate(0, 15%)',
      background: '#000',
      width: '1px',
      left: '10px',
    },
  },
};

const authFormStyles = {
  container: {
    height: '100%',
    width: '100%',
    maxWidth: '100%',
    padding: 0,
    backgroundSize: 'cover',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    fontFamily: fontFamily,
  },
  main: {
    display: 'flex',
    width: 'auto',
    marginLeft: '24px',
    marginRight: '24px',
    maxWidth: '380px'
  },
  paper: {
    maxWidth: '100%',
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: '30px 40px 30px 40px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '8px',
  },

  linksContainer: {
    padding: 0,
    paddingTop: '8px',
    // margin: '-10px',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    justifyContent: 'center'
  },

  formControl: {
    marginTop: '8px',
  },
  hint: {
    marginTop: '8px',
    fontSize: '13px',
    color: '#555',
    fontFamily: fontFamily,
  },
};

export {
  headerStyles,
  inputStyles,
  submitButtonStyles,
  errorMessageStyles,
  authLinkStyles,
  authLinkStylesBorder,
  authFormStyles
};