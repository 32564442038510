import {Scrollbars} from 'react-custom-scrollbars';
import Typography from "@material-ui/core/Typography";
import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from "prop-types";

const drawerWidth = 280;
const miniDrawerWidth = 48;

const drawerStyles = {
  height: '100%',
  flexShrink: 0,
  width: drawerWidth,
};

const drawerPaperStyles = {
  position: 'relative',
  boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
  border: 'none',
  width: drawerWidth,
};

const drawerHeaderStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
};

const styles = theme => ({
  hide: {
    display: 'none',
  },
  drawer: drawerStyles,
  miniDrawer: {
    ...drawerStyles,
    width: miniDrawerWidth,
  },
  drawerPaper: drawerPaperStyles,
  miniDrawerPaper: {
    ...drawerPaperStyles,
    width: miniDrawerWidth,
  },
  drawerHeader: {
    ...drawerHeaderStyles,
    color: theme.palette.text.primary,
    padding: theme.spacing(0, 1),
  },
  miniDrawerHeader: {
    ...drawerHeaderStyles,
    color: theme.palette.text.primary,
    padding: 0,
  },
  drawerHeaderTitle: {
    flexGrow: 1,
    color: theme.palette.text.primary,
    fontSize: '1.2rem',
    fontWeight: 700,
    paddingLeft: theme.spacing(2),
  },
  drawerHeaderButton: {
    "&:hover": {
      backgroundColor: '#fff'
    }
  },
  drawerHeaderIcon: {
    color: theme.palette.text.primary,
  },
  drawerList: {
    height: '100%',
    padding: theme.spacing(0, 1),
  },
  divider: {

  }
});

class LeftNavBar extends Component {

  renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: '#00000033',
      borderRadius: '0.5rem'
    };
    return (
        <div
            style={{ ...style, ...thumbStyle }}
            {...props}/>
    );
  };

  handleClose = () => {
    this.props.setOpen(false);
  };

  handleOpen = () => {
    this.props.setOpen(true);
  };

  render() {
    const {classes, open, children, title} = this.props;

    return open ? (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={true}
            classes={{
              paper: classes.drawerPaper,
            }}
        >
          <div className={classes.drawerHeader}>
            <Typography variant="body1" align="left" className={classes.drawerHeaderTitle}>{title}</Typography>
            <IconButton onClick={this.handleClose} className={classes.drawerHeaderButton} disableRipple disableTouchRipple disableFocusRipple>
              <CloseIcon className={classes.drawerHeaderIcon}/>
            </IconButton>
          </div>
          <Divider/>

          <List dense className={classes.drawerList}>
            <Scrollbars renderThumbVertical={this.renderThumb}>
              {children}
            </Scrollbars>
          </List>
        </Drawer>
    ) : (
        <Drawer
            className={classes.miniDrawer}
            variant="persistent"
            anchor="left"
            open={true}
            classes={{
              paper: classes.miniDrawerPaper,
            }}
        >
          <div className={classes.miniDrawerHeader}>
            <IconButton onClick={this.handleOpen} className={classes.drawerHeaderButton} disableRipple disableTouchRipple disableFocusRipple>
              <MenuIcon className={classes.drawerHeaderIcon}/>
            </IconButton>
          </div>
          <Divider/>


        </Drawer>
    );
  }
}

LeftNavBar.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,

  //func
  setOpen: PropTypes.func,

  //...
};

export default withStyles(styles)(LeftNavBar);
