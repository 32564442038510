import React from "react";
import {TableCell} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface ListTableCellProps {
    children: string[] | null;
}

const useStyles = makeStyles({
    tableCell: {
        textAlign: "right",
        fontSize: "0.6vw",
    }
});

const ListTableCell: React.FC<ListTableCellProps> = ({ children }) => {
    const classes = useStyles();

    const renderCellContent = (values: string[] | null) => {
        if (values === null) {
            return "N/A";
        }

        return (<>
            {values.map((val: string, index: number) => <p key={`list-table-cell-${index}`}>{val}</p>)}
        </>);
    }


    return (
        <TableCell className={classes.tableCell}>{renderCellContent(children)}</TableCell>
    );
};

export default ListTableCell;
