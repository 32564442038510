import withStyles from "@material-ui/core/styles/withStyles";
import React, {Component} from "react";
import OpenInNew from "@material-ui/icons/OpenInNew";

const openInNewIconStyles = theme => ({
  root: {
    fontSize: '0.875rem'
  },

});

class OpenInNewIcon extends Component {

  render() {
    const {classes, ...other} = this.props;

    return (
        <OpenInNew
            className={classes.root}
            {...other}
        />
    );
  }
}

export default withStyles(openInNewIconStyles)(OpenInNewIcon)