import React from "react";
import SnowflakeDatabaseReportRecommendationsSection
    from "../../types/report/SnowflakeDatabaseReportRecommendationsSection";
import SectionHeader from "../atomic/SectionHeader";
import SectionContainer from "../atomic/SectionContainer";
import {Scrollbars} from "react-custom-scrollbars";
import {Table, TableBody, TableRow} from "@material-ui/core";
import HeaderTableCell from "../atomic/table/HeaderTableCell";
import StringTableCell from "../atomic/table/StringTableCell";
import DecimalCurrencyTableCell from "../atomic/table/DecimalCurrencyTableCell";
import DecimalNumberTableCell from "../atomic/table/DecimalNumberTableCell";
import NumberTableCell from "../atomic/table/NumberTableCell";
import BooleanTableCell from "../atomic/table/BooleanTableCell";
import ListTableCell from "../atomic/table/ListTableCell";

interface RecommendationsSectionProps {
    title: string;
    data: SnowflakeDatabaseReportRecommendationsSection;
}

const RecommendationsSection: React.FC<RecommendationsSectionProps> = ({
    title,
    data
                                                                       }) => (
    <SectionContainer>
        <SectionHeader>{title}</SectionHeader>
        <Scrollbars style={{width: "100%", height: "300px"}}>
            <Table size="small">
                <TableRow>
                    <HeaderTableCell>Warehouse name</HeaderTableCell>
                    <HeaderTableCell>Observations</HeaderTableCell>
                    <HeaderTableCell>Recommendations</HeaderTableCell>
                    <HeaderTableCell>WH Size</HeaderTableCell>
                    <HeaderTableCell>WH Cluster Size (Min-Max)</HeaderTableCell>
                    <HeaderTableCell>Scaling Policy</HeaderTableCell>
                    <HeaderTableCell>Auto Suspend (s)</HeaderTableCell>
                    <HeaderTableCell>Credits Used</HeaderTableCell>
                    <HeaderTableCell>Warehouse Cost ($)</HeaderTableCell>
                    <HeaderTableCell>Warehouse Utilization (%)</HeaderTableCell>
                    <HeaderTableCell>Idle Credits</HeaderTableCell>
                    <HeaderTableCell>Avg. Queued Overload Time (s)</HeaderTableCell>
                    <HeaderTableCell>Total Remote Spillage (GB)</HeaderTableCell>
                    <HeaderTableCell>Query Accl. Enabled</HeaderTableCell>
                </TableRow>
                <TableBody>
                    {data?.rows.map((row, index) => (
                        <TableRow key={`rec-table-row-${index}`}>
                            <StringTableCell>{row.warehouseName}</StringTableCell>
                            <ListTableCell>{row.observations}</ListTableCell>
                            <ListTableCell>{row.recommendations}</ListTableCell>
                            <StringTableCell>{row.whSize}</StringTableCell>
                            <StringTableCell>{row.whClusterSize}</StringTableCell>
                            <StringTableCell>{row.scalingPolicy}</StringTableCell>
                            <NumberTableCell>{row.autoSuspend}</NumberTableCell>
                            <DecimalNumberTableCell>{row.creditsUsed}</DecimalNumberTableCell>
                            <DecimalCurrencyTableCell>{row.warehouseCost}</DecimalCurrencyTableCell>
                            <NumberTableCell>{row.warehouseUtilization}</NumberTableCell>
                            <NumberTableCell>{row.idleCredits}</NumberTableCell>
                            <DecimalNumberTableCell>{row.avgQueuedOverloadTime}</DecimalNumberTableCell>
                            <DecimalNumberTableCell>{row.totalRemoteSpillageGb}</DecimalNumberTableCell>
                            <BooleanTableCell>{row.queueAcclEnabled}</BooleanTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Scrollbars>
    </SectionContainer>
)

export default RecommendationsSection;
