import HLCChartDashletTimeRangeContainer from './components/HLCChartDashletTimeRangeContainer';
import {buildDashletObject, refresh, validateConfig} from './actions';
import HLCChartDashletSettingsContainer from './components/HLCChartDashletSettingsContainer';
import HLCChartDashletCreate from './components/HLCChartDashletCreate';
import HLCChartDashletView from './components/HLCChartDashletView';
import HLCChartTemplateContainer from './components/HLCChartTemplateContainer';

const DASHLET_TYPE_NAME = 'HLC_CHART_DASHLET_TYPE';

export const HLC_CHART_DASHLET_CONFIG = {
  [DASHLET_TYPE_NAME]: {
    typeName: DASHLET_TYPE_NAME,
    typeLabel: 'HLC Chart',
    viewComponent: HLCChartDashletView,
    createFormComponent: HLCChartDashletCreate,
    settingsComponent: HLCChartDashletSettingsContainer,
    openTemplateAsChartComponent: HLCChartTemplateContainer,
    timeRangeComponent: HLCChartDashletTimeRangeContainer,
    validateConfigFunc: validateConfig,
    loadOptionsAction: null,
    refreshAction: refresh,
    buildDashletObjectFunc: buildDashletObject,
  }
};

export const TEMPLATES_DASHLET_CONFIG_OPTIONS_PROPERTY = 'templates';
