import {getCreateFormDashletComponent} from "dashlets/dashletsConfig";
import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import * as _ from "lodash";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {Input} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "components/common/DialogTitle/DialogTitle";
import { DASHLET_TYPE_LABEL, DASHLET_TYPE_NAME } from "dashlets/AlertHistoryDashlet/constants";

const styles = theme => ({
    root: {
        maxWidth: '700px',
    },
    dialogContent: {
        width: '400px',
    },
    actionButtonLabel: {
        fontWeight: 600,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

class CreateDashlet extends Component {

    getConfigureDashletContent = () => {
        const {dashletData, dashletConfigOptions, dashletErrors, handleChangeDashletConfigProperty} = this.props;
        const CreateFormDashletComponent = getCreateFormDashletComponent(dashletData.type);

        return CreateFormDashletComponent ? (
            <CreateFormDashletComponent
                dashletData={dashletData}
                dashletErrors={dashletErrors}
                dashletConfigOptions={dashletConfigOptions}
                handleChangeDashletConfigProperty={handleChangeDashletConfigProperty}
            />
        ) : null;
    };

    getStepContent = (step) => {
        const { handleChangeInput, dashletErrors, dashletTypes, dashletData } = this.props;
        switch (step) {
            case 0:
                return [
                    <FormControl key="dashlet-name" fullWidth required error={dashletErrors.name} margin="dense">
                        <InputLabel id="name-label" htmlFor="name">Title</InputLabel>
                        <Input
                            id="name"
                            name="name"
                            type="text"
                            value={dashletData.name}
                            onChange={handleChangeInput}
                        />
                    </FormControl>,
                    <FormControl key="dashlet-type" fullWidth required error={dashletErrors.type} margin="dense">
                        <InputLabel id="type-label">Dashlet Type</InputLabel>
                        <Select
                            id="type"
                            name="type"
                            value={dashletData.type}
                            onChange={handleChangeInput}
                        >
                        {_.map(dashletTypes.filter(type => type.value !== DASHLET_TYPE_NAME && type.label !== DASHLET_TYPE_LABEL), (type) => {
                            return (
                                <MenuItem key={`dt-mi-key-${type.value}`} value={type.value}>{type.label}</MenuItem>
                            )
                        })}
                        </Select>
                    </FormControl>
                ];
            case 1:
                return this.getConfigureDashletContent();
            default:
                return null;
        }
    };

    render() {
        const {classes, handleClose, activeStep, handleNext, handleBack, handleConfirm, isLocked, createDashletSteps} = this.props;

        return (
            <Dialog
                open={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" onClose={handleClose}>
                    Add Dashlet
                </DialogTitle>
                <Backdrop className={classes.backdrop} open={isLocked}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogContent className={classes.dialogContent}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {createDashletSteps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                                <StepContent>
                                    {this.getStepContent(index)}
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" classes={{
                        label: classes.actionButtonLabel
                    }}>
                        Cancel
                    </Button>
                    <Button onClick={handleBack} color="primary" disabled={activeStep === 0} classes={{
                        label: classes.actionButtonLabel
                    }}>
                        Back
                    </Button>
                    <Button onClick={activeStep === createDashletSteps.length - 1 ? handleConfirm : handleNext} color="primary" classes={{
                        label: classes.actionButtonLabel
                    }}>
                        {activeStep === createDashletSteps.length - 1 ? 'Save' : 'Next'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

CreateDashlet.propTypes = {
    //data
    classes: PropTypes.object.isRequired,
    isLocked: PropTypes.bool,
    peiTemplates: PropTypes.array,
    activeStep: PropTypes.number,
    dashletData: PropTypes.object,
    dashletErrors: PropTypes.object,
    dashletTypes: PropTypes.array,
    createDashletSteps: PropTypes.array,
    dashletConfigOptions: PropTypes.object,
    hasFinanceRole: PropTypes.bool,

    //func
    handleClose: PropTypes.func,
    handleConfirm: PropTypes.func,
    handleBack: PropTypes.func,
    handleNext: PropTypes.func,
    handleChangeInput: PropTypes.func,
    handleChangeDashletConfigProperty: PropTypes.func,
};

export default withStyles(styles)(CreateDashlet);