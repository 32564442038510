import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import IntegrationType from "types/model/settings/integration/IntegrationType";
import Integration from "types/model/settings/integration/Integration";
import {normalize} from "normalizr";
import integrationSchema from "schemas/settings/integrationSchema";

interface IntegrationsState {
    backendKey: string | null;
    entities: Record<IntegrationType, Integration> | {};
    ids: IntegrationType[];
    loading: boolean;
    error: string | null;
}

interface SettingsState {
    leftNavBarOpen: boolean;
    integrations: IntegrationsState;
}

interface SetIntegrationPropertyAction {
    integrationId: IntegrationType;
    propertyName: string;
    propertyValue: any;
}

const integrationsInitialState: IntegrationsState = {
    backendKey: null,
    entities: {},
    ids: [],
    loading: false,
    error: null,
};

const initialState: SettingsState = {
    leftNavBarOpen: true,
    integrations: integrationsInitialState
};

const settingsSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setLeftNavBarOpen(state, action: PayloadAction<boolean>) {
            state.leftNavBarOpen = action.payload;
        },
        setIntegrationsLoading(state, action: PayloadAction<boolean>) {
            state.integrations.loading = action.payload;
        },
        setIntegrationsBackendKey(state, action: PayloadAction<string>) {
            state.integrations.backendKey = action.payload;
        },
        setIntegrationProperty: {
            reducer(state, action: PayloadAction<SetIntegrationPropertyAction>) {
                const { integrationId, propertyName, propertyValue } = action.payload;
                // @ts-ignore
                state.integrations.entities[integrationId][propertyName] = propertyValue
            },
            prepare(integrationId, propertyName, propertyValue) {
                return {
                    payload: {integrationId, propertyName, propertyValue}
                }
            },
        },
        setIntegrations(state, action: PayloadAction<Integration[]>) {
            const normalizedIntegrations = normalize(action.payload, [integrationSchema]);
            // @ts-ignore
            state.integrations.entities = normalizedIntegrations.entities.integrations;
            state.integrations.ids = normalizedIntegrations.result;
        },
        setIntegration(state, action: PayloadAction<Integration>) {
            const integration = action.payload;
            // @ts-ignore
            state.integrations.entities[integration.integrationType] = integration;
        },
    }
});

export const {
    setLeftNavBarOpen,
    setIntegrationsLoading,
    setIntegrationsBackendKey,
    setIntegrations,
    setIntegration
} = settingsSlice.actions;

export default settingsSlice.reducer;

export const selectLeftNavBarOpen = (state: any) => state.settings.leftNavBarOpen;
export const selectIntegrationsLoading = (state: any) => state.settings.integrations.loading;
export const selectIntegrationsBackendKey = (state: any) => state.settings.integrations.backendKey;
export const selectIntegrationEntities = (state: any) => state.settings.integrations.entities;
