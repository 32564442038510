import React from "react";
import {useSelector} from "react-redux";
import {selectHLCStompClient, selectIsHLCStompClientConnected} from "reducers/webSocketSlice";
import {getUserSessionId} from "actions/userInfoActions";
import WebSocketSubscription from "websocket/WebSocketSubscription";

interface HLCUserSessionWebSocketSubscriptionProps {
    backendKey?: string | null;
    dashletId?: number | null;
    subscriptionDestination: string;
    handleMessage: (response: any) => void;
    onSubscribeCallback?: () => void;
}

const HLCUserSessionWebSocketSubscription: React.FC<HLCUserSessionWebSocketSubscriptionProps> = ({
                                                                                                     backendKey,
                                                                                                     dashletId,
                                                                                                     subscriptionDestination,
                                                                                                     handleMessage,
                                                                                                     onSubscribeCallback
                                                                                                 }) => {
    const userSessionId = getUserSessionId();
    const stompClient = useSelector(selectHLCStompClient);
    const isStompClientConnected = useSelector(selectIsHLCStompClientConnected);

    if (!isStompClientConnected) {
        return null;
    }

    return (
        <WebSocketSubscription
            stompClient={stompClient}
            backendKey={backendKey}
            dashletId={dashletId}
            userSessionId={userSessionId}
            subscriptionDestination={subscriptionDestination}
            handleMessage={handleMessage}
            onSubscribeCallback={onSubscribeCallback}
        />
    );
};

export default HLCUserSessionWebSocketSubscription;
