import * as _ from 'lodash';
import {setDashletData, setChartsData, selectDashletData, selectDashlet, setIsChartDataLoading, unSetTargetConfiguration, addAnomalyDashboard} from 'reducers/dashboard/dashboardSlice';
import {serializeDashletTimeRange} from '../../utils/dashboard/dashletTimeRangeUtils';
import * as webSocketClient from './webSocketClient';
import {TEMPLATES_DASHLET_CONFIG_OPTIONS_PROPERTY} from "./config";
import { toastr } from 'react-redux-toastr';
import { createDashletBatch, updateDashlet } from 'actions/dashboard/dashletActions';
import { createPanel } from 'actions/AccordionPanelAction';
import { setDashletOptionsProperty } from 'reducers/dashboard/createDashletSlice';
import { updateDashboard } from 'actions/dashboardActions';
import { dashboardByIdSelector, selectAccordionPanelByName } from 'selectors/dashboard/DashboardSelectors';
import { DASHLET_TYPE_NAME } from 'dashlets/AnomalyDetectionDashlet/constants';
import { DASHBOARD_URL, HLC_URL } from 'config';

export const buildDashletObject = (dashletData, configOptions) => {
  const templateName = dashletData.config.templateName;
  const templates = configOptions[TEMPLATES_DASHLET_CONFIG_OPTIONS_PROPERTY];
  const template = _.find(templates, (tpl) => {
    return tpl.config.templateName === templateName;
  });
  return {
    ...dashletData,
    config: {
      ...dashletData.config,
    },
    settings: template ? template.settings : null,
    timeRange: template ? template.timeRange : null,
  };

}

export const loadHLCChart = (dashlet) => (dispatch, getState) => {
  const appState = getState();
  const dashletData = appState.dashboard.dashletsData[dashlet.id];
  const timeRangesMap = dashletData ? dashletData.timeRangesMap : null;
  const loadHLCChartStompFrame = {
    dashletId: dashlet.id,
    templateName: dashlet.config.templateName,
    capture: dashlet.config.capture,
    dashletTimeRange: serializeDashletTimeRange(dashlet.timeRange),
    dashletSettings: dashlet.settings,
    prevTimeRangesMap: timeRangesMap,
  };

  webSocketClient.loadChart(appState, dashlet.config.backendKey, loadHLCChartStompFrame);
}

export const loadChartOnHLC = (templateName, startTime, endTime) => (dispatch, getState) => {
  const loadChartParams = {
    templateName: templateName,
    startTime: startTime,
    endTime: endTime
  };

  webSocketClient.loadChartToHLC(getState(), getState().webSocket.hlcActiveBackendKey, loadChartParams);
}

export const refresh = (dashlet, isResizeOnly, containerWidth, containerHeight) => (dispatch, getState) => {
  const appState = getState();
  const dashletData = appState.dashboard.dashletsData[dashlet.id];
  if (dashletData) {
    const timeRangesMap = dashletData.timeRangesMap;
    const loadHLCChartStompFrame = {
      dashletId: dashlet.id,
      templateName: dashlet.config.templateName,
      capture: dashlet.config.capture,
      dashletTimeRange: serializeDashletTimeRange(dashlet.timeRange),
      dashletSettings: dashlet.settings,
      prevTimeRangesMap: timeRangesMap,
      frameWidth: appState.dashboard.isDashletPreviewOpen ? containerWidth : dashletData ? dashletData.frameWidth : 520,
      frameHeight: appState.dashboard.isDashletPreviewOpen ? containerHeight - 40 : dashletData ? dashletData.frameHeight : 344,
      isResizeOnly: isResizeOnly
    };
  
    webSocketClient.loadStaticChart(appState, dashlet.config.backendKey, loadHLCChartStompFrame);
  }
};

export const handleLoadStaticChart = response => (dispatch, getState) => {
  if (!response.hasErrors) {
    let dashlet = selectDashlet(getState(), response.dashletId);
    let dashletData = response;
    if (dashlet?.settings == null && dashlet?.timeRange == null) {
      let upDashlet = {
        ...dashlet,
          settings: response.dashletSettings,
          timeRange: response.dashletTimeRange,
      };
      dispatch(updateDashlet(response.dashletId, upDashlet));
    }
    if (getState().dashboard.isDashletPreviewOpen) {
      const dashlet = selectDashletData(getState(), response.dashletId)
      dashletData.frameWidth = dashlet.frameWidth;
      dashletData.frameHeight = dashlet.frameHeight;
    }
    dispatch(setDashletData(response.dashletId, dashletData));
  } else {
    toastr.error(response.errorMessage)
  }
  dispatch(setIsChartDataLoading(false));
}

export const handleLoadChart = response => dispatch => {
  if (!response.hasErrors) {
    dispatch(setChartsData(response));
  } else {
    toastr.error(response.errorMessage);
  }
}

export const loadHLCChartTemplates = backendKey => (dispatch, getState) =>
    webSocketClient.loadChartTemplates(getState(), backendKey);

export const validateConfig = (dashletConfig) => {
  const errors = {};

  _.each(['backendKey', 'templateName'], (propertyName) => {
    if (!dashletConfig[propertyName]) {
      errors[propertyName] = true;
    }
  });

  return errors;
};

export const setExtremes = (dashletId, key, xMin, xMax, yMin, yMax) => (dispatch, getState) => {
  const prevDashletData = getState().dashboard.dashletsData[dashletId];
  if (prevDashletData) {
    const dashletData = {
      ...prevDashletData,
      isManualZoom: true,
      extremes: {
        ...prevDashletData.extremes,
        [key]: {
          yMin: yMin,
          yMax: yMax
        }
      },
      xMin: xMin,
      xMax: xMax,
    };

    dispatch(setDashletData(dashletId, dashletData));
  }
}

export const setXExtremes = (dashletId, xMin, xMax, isManualZoom) => (dispatch, getState) => {
  const prevDashletData = getState().dashboard.dashletsData[dashletId];
  if (prevDashletData) {
    const dashletData = {
      ...prevDashletData,
      isManualZoom: isManualZoom,
      extremes: {},
      xMin: xMin,
      xMax: xMax,
    };

    dispatch(setDashletData(dashletId, dashletData));
  }
}

export const setDashletFrameSize = (dashletId, width, height) => (dispatch, getState) => {
  const prevDashletData = getState().dashboard.dashletsData[dashletId];
  if (prevDashletData) {
    const dashletData = {
      ...prevDashletData,
      frameWidth: width,
      frameHeight: height,
    };

    dispatch(setDashletData(dashletId, dashletData));
  } else {
    dispatch(setDashletData(dashletId, {
      frameWidth: width,
      frameHeight: height,
    }));
  }
}

export const createTarget = (CreateDefaultTargetDashletStompFrame, backendKey) => (dispatch, getState) =>
  webSocketClient.createTarget(getState(), backendKey, CreateDefaultTargetDashletStompFrame);

export const checkBackendConnection = (backendKey) => (dispatch, getState) =>
  webSocketClient.checkBackendConnection(getState(), backendKey, {backendKey: backendKey});

export const handleDefultDashletConfig = (response) => async (dispatch, getState) => {
  let dashboardGroupId = null;  
  if (!response.hasErrors) {
    if (!response.onlyTargetCreate) {
      toastr.success("Target", "Target Created")
      toastr.success("Dashboard", "Dashboard Created")
      const accordionPanels = response.accordionPanels;
      const dashboardId = response.dashboardId;
      const layoutRepeat = response.layoutRepeat;
      let dashboard = getState().dashboard.dashboardTree.entities.dashboards[dashboardId];
      dashboardGroupId = dashboard.dashboardGroupId;
      dashboard = {
        ...dashboard,
        layoutRepeat: layoutRepeat
      }

      dispatch(updateDashboard(dashboard))
      accordionPanels.forEach(panel => {
        const accordion = {
          name: panel.name,
          dashboardId: dashboardId,
          layoutRepeat: panel.layoutRepeat
        }
        const templateList = panel.dashlets;
        dispatch(createPanel(accordion, templateList));
      });
      dispatch(unSetTargetConfiguration(getState()));
    } else {
      toastr.success("Target", "Target Created")
      dispatch(unSetTargetConfiguration(getState()));
    }
    const path = window.location.pathname;
    const segments = path.split('/');  // Split the path into an array of segments
    const lastSegment = segments.pop() || '/';  // Get the last segment
    if (lastSegment != "dashboard" && !response.onlyTargetCreate) {
      toastr.info("Redirecting...", "Redirecting to Dashboard")
      setTimeout(() => {
        window.open(DASHBOARD_URL + "?dashboardId=" + response.dashboardId + (dashboardGroupId != null ? "&dashboardGroupId=" + dashboardGroupId : ""), '_blank', 'noopener,noreferrer');
      }, 500);
    }
  } else {
    toastr.error(response.errorMessage);
    // dispatch(unSetTargetConfiguration(getState()));
  }
} 

export const createAnomalyDashboard = (dashboard, createDashboard) => async (dispatch, getState) => {
  const LoadAnomalyDataStompFrame = {
    timeSettings: dashboard.timeSettings,
    dashboardId: dashboard.id,
    backendKey: dashboard.defaultBackendKey,
    createDashboard: createDashboard
  }
  webSocketClient.loadAnomalyDashboardData(getState(), dashboard.defaultBackendKey, LoadAnomalyDataStompFrame);
}

export const handleLaodAomalyDashboardData = (response) => async (dispatch, getState) => {
  if (!response.hasErrors) {
    const { anomalySpike, dashboardId, backendKey, createDashboard } = response
    const state = getState();
    const dashboard = dashboardByIdSelector(state, dashboardId);
    dispatch(addAnomalyDashboard(response));

    if (Object.keys(anomalySpike).length != 0) {
      if (createDashboard) {
        Object.keys(anomalySpike).forEach(targetName => {
          const accordion = {
            name: targetName,
            dashboardId: dashboardId
          }
          dispatch(createPanel(accordion, [], anomalySpike[targetName], backendKey, targetName));
        });
        return;
      }

      if (!createDashboard) {
        Object.keys(anomalySpike).forEach(targetName => {
          // const dashboard = dashboardByIdSelector(state, dashboardId);
          const accordionPanels = dashboard ? dashboard.accordionPanels : {};

          const accordion = Object.values(accordionPanels).find(accordion => accordion.name === targetName);
          if (!accordion) {
            const newAccordion = {
              name: targetName,
              dashboardId: dashboardId
            }
            dispatch(createPanel(newAccordion, [], anomalySpike[targetName], backendKey, targetName));
          } else {
            let dashlets = [];
            let captureNames = Object.keys(anomalySpike[targetName]);
            captureNames.forEach(capture => {
              const existedDashlet = Object.values(state.dashboard.dashboardTree.entities.dashlets).find(dashlet => dashlet.accordionId === accordion.id && dashlet.name == capture);

              if (!existedDashlet) {
                const anomalyDashlet = {
                  config: {
                    backendKey: backendKey,
                    targetName: targetName
                  },
                  accordionId: accordion.id,
                  dashboardId: accordion.dashboardId,
                  name: capture,
                  type: DASHLET_TYPE_NAME
                }
                dashlets.push(anomalyDashlet);
              }
            });
            if (dashlets.length > 0) {
              dispatch(createDashletBatch(dashlets));
            }
          }
        });
        return;
      }
    } else {
      toastr.warning("No Data available for selected time period.")
    }

  } else {
    toastr.error("Anomaly Dashboard", response.errorMessage);
  }
}

export const openSpikeInAnalyzer = (dashlet) => (dispatch, getState) => {
  const state = getState();
  window.open(HLC_URL + "?target=" + dashlet.config.targetName + "&capture=" + dashlet.name + "&metric=" + "All_Elements" 
    + "&startTime=" + (state.dashboard.startTime ? state.dashboard.startTime : 0) + "&endTime=" + (state.dashboard.endTime ? state.dashboard.endTime : new Date().getTime()) + "&backendKey=" + dashlet.config.backendKey, '_blank', 'noopener,noreferrer');
}

// export const exportDashletHLC = dashlet => (dispatch, getState) => {
//   const exportDashletStompFrame = {
//     dashletId: dashlet.id,
//     config: dashlet.config,
//     dashletSettings: dashlet.settings,
//     dashletTimeRange: serializeDashletTimeRange(dashlet.timeRange),
//     type: dashlet.type
//   };
//   webSocketClient.exportDashlet(getState(), dashlet.config.backendKey, exportDashletStompFrame);
// }
