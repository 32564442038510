import React from "react";
import EmailIntegration from "types/model/settings/integration/EmailIntegration";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "../../../../../components/common/Switch/Switch";
import TextField from "../../../../../components/common/TextField/TextField";
import Select from "../../../../../components/common/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SubmitButton from "../../../../../components/common/Button/SubmitButton";

interface EmailIntegrationSectionProps {
    integration: EmailIntegration;
    onSave: (integration: EmailIntegration) => void;
}

const EmailIntegrationSection: React.FC<EmailIntegrationSectionProps> = ({
    onSave,
    ...props
                                                                         }) => {
    const { integration, handleChangeProperty, handleEnabledToggle }
        = useIntegrationSectionState(props.integration);
    const {
        enabled,
        emailType,
        host,
        port,
        username,
        password
    } = integration as EmailIntegration;

    return (
        <>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="flex-start"
                style={{marginTop: "8px", marginBottom: "8px"}}
            >
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="enable">Enabled</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <Switch checked={enabled || false}
                                onChange={handleEnabledToggle}
                                name="enabled"
                        />
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="enable">Security</InputLabel>
                    </Grid>
                    <Grid item xs={5} style={{paddingRight: "12px"}}>
                        <Select value={emailType} name="emailType"
                                onChange={(event: any) =>
                                    handleChangeProperty(event?.target?.name, event?.target?.value)}
                        >
                            <MenuItem
                                data-testid="email-type-dropdown-option-secure-TLS"
                                key="email-type-dropdown-option-TLS"
                                value="Secure (TLS)"
                            >
                                Secure (TLS)
                            </MenuItem>
                            <MenuItem
                                data-testid="email-type-dropdown-option-secure-SSL"
                                key="email-type-dropdown-option-SSL"
                                value="Secure (SSL)"
                            >
                                Secure (SSL)
                            </MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="host">Host</InputLabel>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            name="host"
                            fullWidth
                            textAlign="left"
                            value={host}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={1} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="port">Port</InputLabel>
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            name="port"
                            fullWidth
                            textAlign="left"
                            value={port}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="username">Username (Email)</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name="username"
                            fullWidth
                            textAlign="left"
                            value={username}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name="password"
                            type="password"
                            fullWidth
                            textAlign="left"
                            value={password}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    spacing={2}
                    xs={7}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Grid item xs={1}>
                        {/*@ts-ignore*/}
                        <SubmitButton onClick={() => onSave(integration)}>Save</SubmitButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default EmailIntegrationSection;
