import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import { HOME_PATH } from '../../../routes/paths'

export default function requireNoAuthentication(Component) {

  class NotAuthenticatedComponent extends React.Component {
    render() {
      return this.props.isAuthenticated === false
        ? <Component {...this.props} />
        : <Redirect to={HOME_PATH} />;
    }
  }

  const mapStateToProps = (state) => {
    return {
      isAuthenticated: state.auth.isAuthenticated
    }
  };

  return connect(
    mapStateToProps
  )(NotAuthenticatedComponent);
}