import React from "react";
import { Link as RouterLink } from 'react-router-dom'
import * as paths from './paths'

export const LoginLink = props => <RouterLink to={paths.LOGIN_PATH} {...props} />;
export const RegistrationLink = props => <RouterLink to={paths.REGISTRATION_PATH} {...props} />;
export const ForgotPasswordLink = props => <RouterLink to={paths.FORGOT_PASSWORD_PATH} {...props} />;

export const ChangePasswordLink = props => <RouterLink to={paths.CHANGE_PASSWORD_PATH} {...props} />;
export const SubscriptionLink = props => <RouterLink to={paths.SUBSCRIPTION_PATH} {...props} />;

export const IntegrationsAPMLink = props => <RouterLink to={paths.INTEGRATIONS_APM} {...props} />;

export const BackendsListLink = props => <RouterLink to={paths.BACKENDS_LIST} {...props} />;
export const BackendsAddLink = props => <RouterLink to={paths.BACKENDS_ADD} {...props} />;
export const BackendsLogsLink = props => <RouterLink to={paths.BACKENDS_LOGS} {...props} />;

export const JobsListLink = props => <RouterLink to={paths.JOBS_LIST} {...props} />;

export const OpenEmailConfigurationLink = props => <RouterLink to={paths.CHANGE_EMAIL_CONFIGURATION} {...props}/>;

export const HomeLink = props => <RouterLink to={paths.HOME_PATH} {...props} />;

export const DashboardHome = props => <RouterLink to={paths.DASHBOARD_HOME_PATH} {...props} />;