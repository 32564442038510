import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import {LoginLink, RegistrationLink} from "../../../routes/links";
import {Container} from "@material-ui/core";
import {AuthFormInput, AuthSubmitButton, AuthLogo, AuthLinkBordered} from "../../../components/gate/Auth/Auth";
import ErrorMessage from "../../../components/gate/Auth/ErrorMessage";
import AuthFormHeader from "../../../components/gate/Auth/AuthFormHeader";
import {authFormStyles} from "../../../jss/gate/Auth/authStyles";
import BottomHint from "../../../components/gate/Auth/BottomHint";
import AuthLink from "../../../components/gate/Auth/AuthLink";

class ForgotPassword extends Component {
  render() {
    const {classes, errorMessage, disabled, onSend, onChangeInputHandler} = this.props;

    return (
      <Container id="login-container" className={classes.container}>
        <main className={classes.main}>
          <Paper className={classes.paper}>
            <AuthLogo />
            <AuthFormHeader title="Reset Password" />

            <ErrorMessage message={errorMessage}/>

            <Typography component="p" className={classes.hint}>
              Enter the email address you registered with Enteros. We'll send you a password shortly.
            </Typography>
            <form className={classes.form}>
              <FormControl margin="normal" required fullWidth className={classes.formControl}>
                <AuthFormInput id="username" name="username" autoComplete="email" autoFocus onChange={onChangeInputHandler} disabled={disabled} className={classes.input} placeholder="E-mail"/>
              </FormControl>


              <AuthSubmitButton
                type="submit"
                fullWidth
                variant="contained"
                onClick={onSend}
                disabled={disabled}
              >
                Send
              </AuthSubmitButton>
            </form>

            <Container className={classes.linksContainer}>
              <AuthLinkBordered label="Sign in" actionComponent={LoginLink}/>
              <AuthLink label="Create new account" actionComponent={RegistrationLink}/>
            </Container>

            <BottomHint />

          </Paper>
        </main>
      </Container>

    );
  }
}

ForgotPassword.propTypes = {
  //data
  classes: PropTypes.object,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,

  //func
  onSend: PropTypes.func.isRequired,
  onChangeInputHandler: PropTypes.func.isRequired,
};

export default withStyles(authFormStyles)(ForgotPassword);