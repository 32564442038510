import {publish} from '../../api/websocket/pei/peiCommonWebSocketClient';
import {WEB_SOCKET_ACTION_PREFIX, WEB_SOCKET_SUBSCR_PREFIX} from '../../constants/webSocketConstants';

export const LOAD_PEI_CHART_BAG_TEMPLATES_ACTION =            `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.chartbag.templates.list.load`;
export const LOAD_PEI_CHART_BAG_TEMPLATES_SUBSCRIPTION =      `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.chartbag.templates.list`;
export const LOAD_PEI_CHART_BAG_CHART_ACTION =                `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.pei.chart.load`;
export const LOAD_PEI_CHART_BAG_CHART_SUBSCRIPTION =          `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.pei.chart`;
export const LOAD_PEI_CHART_ACTION =                          `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.pei.dashlet.chart.create`;
export const LOAD_PEI_CHART_SUBSCRIPTION =                    `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.pei.dashlet.chart`;
export const LOAD_CHART_ON_PEI_ACTION =                       `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.pei.load.chartTemplate`;

export const loadChartBagTemplates = (appState, backendKey) =>
    publish(appState, backendKey, LOAD_PEI_CHART_BAG_TEMPLATES_ACTION, {});

export const loadChartBagChart = (appState, backendKey, loadChartBagChartStompFrame) =>
    publish(appState, backendKey, LOAD_PEI_CHART_BAG_CHART_ACTION, loadChartBagChartStompFrame);

export const loadChart = (appState, backendKey, loadChartStompFrame) =>
    publish(appState, backendKey, LOAD_PEI_CHART_ACTION, loadChartStompFrame);

export const loadChartToPEI = (appState, backendKey, loadChartParams) =>
    publish(appState, backendKey, LOAD_CHART_ON_PEI_ACTION, loadChartParams);