import * as _ from "lodash";
import {EMPTY_DASHLET_CONFIG} from "dashlets/EmptyDashlet/config";
import {HLC_CHART_DASHLET_CONFIG} from "dashlets/HLCChartDashlet/config";
import {PEI_CHART_BAG_DASHLET_CONFIG} from "dashlets/PEIChartBagDashlet/config";
import {HEATMAP_DASHLET_CONFIG} from "dashlets/HeatmapDashlet/config";
import {FIN_OPS_DASHLET_CONFIG} from "./FinOpsDashlet/config";
import { HEATMAP_ALERT_HISTORY_DASHLET_CONFIG } from "./AlertHistoryDashlet/config";
import { ANOMALY_DETECTION_DASHLET_CONFIG } from "./AnomalyDetectionDashlet/config";
import {SNOWFLAKE_REPORT_DASHLET_CONFIG} from "./SnowflakeReportDashlet/config";

export const DASHLETS_CONFIG = {
  ...PEI_CHART_BAG_DASHLET_CONFIG,
  ...HLC_CHART_DASHLET_CONFIG,
  ...HEATMAP_DASHLET_CONFIG,
  ...SNOWFLAKE_REPORT_DASHLET_CONFIG,
  ...FIN_OPS_DASHLET_CONFIG,
  ...HEATMAP_ALERT_HISTORY_DASHLET_CONFIG,
  ...ANOMALY_DETECTION_DASHLET_CONFIG,
  ...EMPTY_DASHLET_CONFIG
};

export const getDashletTypes = () => {
  return _.keys(DASHLETS_CONFIG);
};

export const getDashletConfig = (dashletType) => {
  return dashletType ? DASHLETS_CONFIG[dashletType] : null;
};

export const getViewDashletComponent = (dashletType) => {
  const dashletConfig = getDashletConfig(dashletType);

  return dashletConfig ? dashletConfig.viewComponent : null;
};

export const getCreateFormDashletComponent = (dashletType) => {
  const dashletConfig = getDashletConfig(dashletType);

  return dashletConfig ? dashletConfig.createFormComponent : null;
};

export const getOpenTemplateAsChartComponent = (dashletType) => {
  const dashletConfig = getDashletConfig(dashletType);

  return dashletConfig ? dashletConfig.openTemplateAsChartComponent : null;
};

export const getDashletSettingsComponent = (dashletType) => {
  const dashletConfig = getDashletConfig(dashletType);

  return dashletConfig ? dashletConfig.settingsComponent : null;
};

export const getDashletTimeRangeComponent = (dashletType) => {
  const dashletConfig = getDashletConfig(dashletType);

  return dashletConfig ? dashletConfig.timeRangeComponent : null;
};

export const getValidateConfigFunc = (dashletType) => {
  const dashletConfig = getDashletConfig(dashletType);

  return dashletConfig ? dashletConfig.validateConfigFunc : null;
};

export const getDashletTypeLabel = (dashletType) => {
  const dashletConfig = getDashletConfig(dashletType);

  return dashletConfig ? dashletConfig.typeLabel : null;
};

export const getDashletRefreshAction = (dashlet) => {
  const dashletConfig = getDashletConfig(dashlet);

  return dashletConfig ? dashletConfig.refreshAction : null;
};

export const getBuildDashletObjectFunc = (dashletType) => {
  const dashletConfig = getDashletConfig(dashletType);

  return dashletConfig ? dashletConfig.buildDashletObjectFunc : () => { return {} }
};

export const getEditDashletConfigComponent = (dashletType) => {
  const dashletConfig = getDashletConfig(dashletType);

  return dashletConfig ? dashletConfig.editConfigFormComponent : null;
}
