import {selectIsHPEItompClientConnected, selectPEIStompClient} from "../../../reducers/webSocketSlice";
import {BACKEND_KEY_HEADER_NAME, WEBSOCKET_USER_CLIENT_HEADER} from "../../../constants/webSocketConstants";


export const STOMP_CLIENT = {
    PEI: "PEI"
};
export const publish = (appState, backendKey, destination, payload) => {
    if(appState.webSocket.stompClients[STOMP_CLIENT.PEI]) {
        selectPEIStompClient(appState).publish({
            destination: destination,
            body: JSON.stringify(payload || {}),
            headers: {
                [BACKEND_KEY_HEADER_NAME]: backendKey,
                ...WEBSOCKET_USER_CLIENT_HEADER
            }
        });
    }
}