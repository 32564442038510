import React, {Component} from 'react';
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

interface NotSupportedDashletProps {
    dashlet: any;
}

const NotSupportedDashlet: React.FC<NotSupportedDashletProps> = ({
                                                                     dashlet
                                                                 }) => {
    return <Typography>{`Dashlet Type '${dashlet.type}' is not supported.`}</Typography>;
}

export default NotSupportedDashlet;