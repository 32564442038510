import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectHLCStompClient, selectIsHLCStompClientConnected, selectIsPEIStompClientConnected, selectPEIStompClient } from "reducers/webSocketSlice";
import WebSocketSubscription from './WebSocketSubscription';
import { CREATE_TARGET_SUBSCRIPTION, EXPORT_DASHLET_SUBSRIPTION, IMPORT_DASHLET_SUBSRIPTION, LOAD_ANOMALY_DASHBOARD_DATA_SUBSCRIPTION, LOAD_HLC_CHART_TEMPLATES_SUBSCRIPTION } from 'dashlets/HLCChartDashlet/webSocketClient';
import { handleExportDashlet } from 'actions/dashboard/dashletActions';
import { handleImportDashlet, setDashletOptionsProperty } from 'reducers/dashboard/createDashletSlice';
import { getUserSessionId } from 'actions/userInfoActions';
import HLCUserSessionWebSocketSubscription from './HLCUserSessionWebSocketSubscription';
import { TEMPLATES_DASHLET_CONFIG_OPTIONS_PROPERTY } from 'dashlets/HLCChartDashlet/config';
import { toggleIsLoadingReportNames, toggleIsLoadingTemplateNames } from 'reducers/dashboard/dashboardSlice';
import { HEATMAP_LIST_REPORT_NAMES_SUBSCRIPTION, HEATMAP_LIST_TEMPLATE_NAMES_SUBSCRIPTION } from 'dashlets/HeatmapDashlet/webSocketClient';
import { handleDefultDashletConfig } from 'dashlets/HLCChartDashlet/actions';

const WebSocketSubscriptionsContainer = () => {
    const isHLCStompClientConnected = useSelector(selectIsHLCStompClientConnected);
    // const isPEIStompClientConnected = useSelector(selectIsPEIStompClientConnected);

    const hlcStompClient = useSelector(selectHLCStompClient);
    // const peiStompClient = useSelector(selectPEIStompClient);

    const dispatch = useDispatch();
    return <React.Fragment>
        {isHLCStompClientConnected && [<WebSocketSubscription
            key={`hlc-chart-dashlet-ws-export-chart-subscription`}
            userSessionId={getUserSessionId()}
            stompClient={hlcStompClient}
            subscriptionDestination={EXPORT_DASHLET_SUBSRIPTION}
            // onSubscribeCallback={() => dispatch(refresh(dashlet))}
            handleMessage={response => dispatch(handleExportDashlet(response))}
        />,
        <WebSocketSubscription
            key={`hlc-chart-dashlet-ws-import-chart-subscription`}
            userSessionId={getUserSessionId()}
            stompClient={hlcStompClient}
            subscriptionDestination={IMPORT_DASHLET_SUBSRIPTION}
            handleMessage={(response) => dispatch(handleImportDashlet(response))}
        />,
        <HLCUserSessionWebSocketSubscription
            key={`dashlet-hlc-chart-templates-subscription-1582673956819`}
            subscriptionDestination={LOAD_HLC_CHART_TEMPLATES_SUBSCRIPTION}
            handleMessage={response => {
                const { templates } = response;
                dispatch(setDashletOptionsProperty(TEMPLATES_DASHLET_CONFIG_OPTIONS_PROPERTY, templates));
                // setTemplateNames(templates.map(t => t.name));
                dispatch(toggleIsLoadingTemplateNames(false));
                // setIsLoadingTemplateNames(false);
            }}
        />,
        <HLCUserSessionWebSocketSubscription
            key={`hlc-report-name-subscription`}
            subscriptionDestination={HEATMAP_LIST_REPORT_NAMES_SUBSCRIPTION}
            handleMessage={response => {
                if (!response.hasErrors) {
                    dispatch(setDashletOptionsProperty({ propertyName: "reportNames", propertyValue: response.reportNames }))
                    // setReportNames(response.reportNames);
                } else {
                    console.log(response.errorMessage); //todo: handle error
                }
                dispatch(toggleIsLoadingReportNames(false));
            }}
        />,
        <HLCUserSessionWebSocketSubscription
            key={`hlc-template-name-subscription`}
            subscriptionDestination={HEATMAP_LIST_TEMPLATE_NAMES_SUBSCRIPTION}
            handleMessage={response => {
                if (!response.hasErrors) {
                    dispatch(setDashletOptionsProperty({ propertyName: "templates", propertyValue: response.templateNames }))
                    // setTemplateNames(response.templateNames);
                } else {
                    console.log(response.errorMessage); //todo: handle error
                }
                dispatch(toggleIsLoadingTemplateNames(false))
                // setIsLoadingTemplateNames(false);
            }}
        />,
        <HLCUserSessionWebSocketSubscription
            key={`hlc-chart-dashlet-ws-chart-subscription`}
            subscriptionDestination={CREATE_TARGET_SUBSCRIPTION}
            handleMessage={response => dispatch(handleDefultDashletConfig(response))}
        />,
        ]}
    </React.Fragment>
};

export default WebSocketSubscriptionsContainer;