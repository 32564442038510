import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Scrollbars } from 'react-custom-scrollbars';
import * as uuid from "uuid";
import ReactHighstock from 'react-highcharts/ReactHighstock';
import * as _ from 'lodash';

const styles = theme => ({
  root: {

  },
});

class PEIChartBagDashlet extends Component {
  render() {
    const {classes, configList} = this.props;

    return (
        <Scrollbars>
          {
            _.map(configList, (config) => {
              const key = uuid.v4();
              return (<ReactHighstock key={key} config={config} ref={`chart_${key}`}></ReactHighstock>);
            })}
        </Scrollbars>
    );
  }
}

PEIChartBagDashlet.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  configList: PropTypes.array,

  //func
  //...
};

export default withStyles(styles)(PEIChartBagDashlet);
