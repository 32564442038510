import * as webSocketClient from './webSocketClient';

export const refresh = (dashlet: any) => (dispatch: any, getState: any) => {
    const {backendKey} = dashlet.config;

    webSocketClient.loadFinancialData(getState(), backendKey, {
        dashletId: dashlet.id,
        timeRangeSettings: dashlet.timeRange,
        targetNames: dashlet.config.targetNames,
    });
}

export const validateConfig = (dashletConfig: any) => {
    const errors = {};

    console.debug('===> HeatmapDashlet validateConfig() ');

    return errors;
};

export const buildDashletObject = (dashletData: any, configOptions: any) => {
    return {
        ...dashletData,
        backendKey: dashletData.config.backendKey
    }
}

