import React from "react";
import {useSelector} from "react-redux";
import {selectHLCStompClient, selectIsHLCStompClientConnected} from "reducers/webSocketSlice";
import WebSocketSubscription from "./WebSocketSubscription";

interface BackendUserGroupWebSocketSubscriptionProps {
    backendKey: string | null;
    subscriptionDestination: string;
    handleMessage: (response: any) => void;
    onSubscribeCallback?: () => void;
}

const BackendUserGroupWebSocketSubscription: React.FC<BackendUserGroupWebSocketSubscriptionProps> = ({
                                                                                                         backendKey,
                                                                                                         subscriptionDestination,
                                                                                                         handleMessage,
                                                                                                         onSubscribeCallback
                                                                                                     }) => {
    const stompClient = useSelector(selectHLCStompClient);
    const isStompClientConnected = useSelector(selectIsHLCStompClientConnected);

    if (!isStompClientConnected) {
        return null;
    }

    return (
        <WebSocketSubscription
            backendKey={backendKey}
            stompClient={stompClient}
            subscriptionDestination={subscriptionDestination}
            handleMessage={handleMessage}
            onSubscribeCallback={onSubscribeCallback}
        />
    );
};

export default BackendUserGroupWebSocketSubscription;
