import React from "react";
import Integration from "types/model/settings/integration/Integration";
import IntegrationType from "types/model/settings/integration/IntegrationType";
import IntegrationSection from "../IntegrationSection";
import SlackIntegration from "types/model/settings/integration/SlackIntegration";
import SlackIntegrationSection from "./sections/SlackIntegrationSection";
import MSTeamsIntegrationSection from "./sections/MSTeamsIntegrationSection";
import MSTeamsIntegration from "../../../../types/model/settings/integration/MSTeamsIntegration";

interface BotsIntegrationsProps {
    integrations: Record<string, Integration>;
    onSaveIntegration: (integration: Integration) => void;
}

const BotsIntegrations: React.FC<BotsIntegrationsProps> = ({
                                                             integrations,
                                                             onSaveIntegration
                                                         }) => (
    <>
        {integrations[IntegrationType.SLACK]
            && <IntegrationSection
                title={integrations[IntegrationType.SLACK].title}
                content={
                    <SlackIntegrationSection
                        integration={integrations[IntegrationType.SLACK] as SlackIntegration}
                        onSave={onSaveIntegration}
                    />
                }
            />}
        {integrations[IntegrationType.MS_TEAMS]
            && <IntegrationSection
                title={integrations[IntegrationType.MS_TEAMS].title}
                content={
                    <MSTeamsIntegrationSection
                        integration={integrations[IntegrationType.MS_TEAMS] as MSTeamsIntegration}
                        onSave={onSaveIntegration}
                    />
                }
            />}
    </>
);

export default BotsIntegrations;
