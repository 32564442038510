import {WEB_SOCKET_ACTION_PREFIX, WEB_SOCKET_SUBSCR_PREFIX} from "constants/webSocketConstants";
import {publish} from "./hlcCommonWebSocketClient";
import LoadLogsStompFrame from "../../../types/payload/infrastructure/logs/LoadLogsStompFrame";

export const LOAD_LOGS_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.console.logs.load`;

export const loadLogs = (appState: unknown, backendKey: string, payload: LoadLogsStompFrame) =>
    publish(appState, backendKey, LOAD_LOGS_ACTION, payload);

// export const CLEAR_LOGS_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.console.logs.clear`;

export const LOGS_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.console.logs`;
