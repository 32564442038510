import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectBackendsLogsBackendKey,
    selectBackendsLogsLines,
    setBackendsLogsBackendKey,
} from "reducers/infrastructure/backendsLogsSlice";
import {handleLoadLogs, loadLogs} from "actions/infrastructure/backendsLogsActions";
import {Box} from "@material-ui/core";
import BackendSelectionRow from "components/common/BackendSelectionRow";
import {HLC_WEB_SOCKET_URL, IS_HLC_WS_ENABLED, IS_PEI_WS_ENABLED, PEI_WEB_SOCKET_URL} from "config";
import StompClient from "websocket/StompClient";
import {STOMP_CLIENT} from "reducers/webSocketSlice";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import {LOGS_SUBSCRIPTION} from "api/websocket/hlc/backendsLogsWebSocketClient";
import LogsView from "./LogsView";

const Logs: React.FC = ({}) => {
    const dispatch = useDispatch();
    const backendKey = useSelector(selectBackendsLogsBackendKey);
    const logLines = useSelector(selectBackendsLogsLines);

    const handleChangeBackend = (backendKey: string) => dispatch(setBackendsLogsBackendKey(backendKey));

    const load = () => backendKey && dispatch(loadLogs(backendKey));

    useEffect(() => {
        load();
    }, [backendKey]);

    return (
        <>
            <Box style={{
                display: 'block',
                height: "100%",
                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden"
            }}>
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: "100%",
                    height: "100%"
                }}
                >
                    <BackendSelectionRow backendKey={backendKey} onChangeBackend={handleChangeBackend}/>

                    <LogsView lines={logLines} />
                </Box>
            </Box>

            {/* todo: move to common place */}
            {IS_PEI_WS_ENABLED ? [
                <StompClient
                    key="hlc-ws-stomp-client-1582840873831"
                    stompClientKey={STOMP_CLIENT.PEI}
                    sockJsEndpoint={PEI_WEB_SOCKET_URL}
                    debugMode
                />,
            ] : null}
            {IS_HLC_WS_ENABLED ? [
                <StompClient
                    key="pei-ws-stomp-client-1582840873831"
                    stompClientKey={STOMP_CLIENT.HLC}
                    sockJsEndpoint={HLC_WEB_SOCKET_URL}
                    debugMode
                />,
            ] : null}

            <HLCUserSessionWebSocketSubscription
                subscriptionDestination={LOGS_SUBSCRIPTION}
                backendKey={backendKey}
                handleMessage={response => dispatch(handleLoadLogs(response))}
                onSubscribeCallback={load}
            />

        </>

    );
};

export default Logs;
