import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {formatCurrency} from "../../../utils/FormattingUtils";
import {useSelector} from "react-redux";
import {selectDashletData} from "../../../reducers/dashboard/dashboardSlice";
import TargetAmountSpent from "../../../types/model/finance/TargetAmountSpent";

const headerStyle = {
    fontWeight: 'bold'
};

interface FinOpsDashletViewProp {
    data: any;
}

const FinOpsDashletView: React.FC<FinOpsDashletViewProp> = ({
                                                                data
                                                            }) => {
    // const data = useSelector(state => selectDashletData(state, dashletId));

    const rows: TargetAmountSpent[] = [];

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell style={headerStyle}>Target</TableCell>
                    <TableCell style={headerStyle}>Amount Delta</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => (
                    <TableRow key={index}>
                        <TableCell>{row.targetName}</TableCell>
                        <TableCell>{formatCurrency(row.amountDelta)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default FinOpsDashletView;
