import CommonRestClient from "./CommonRestClient";


class CommonCrudRestClient extends CommonRestClient {

    async load(id) {
        return await this.get(`/${id}`);
    }

    async create(data) {
        return await super.post('', data);
    }

    async update(id, data) {
        return await super.put(`/${id}`, data);
    }

    async save(data) {
        return await super.post('/save', data);
    }

    async delete(id) {
        return await super.delete(`/${id}`)
    }

}

export default CommonCrudRestClient;