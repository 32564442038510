import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DashboardPage from 'views/dashboard/Home/DashboardPage';
import {loadTreeData} from "actions/dashboardActions";

class DashboardPageContainer extends Component {

  componentDidMount() {
    this.props.loadTreeData();
  }

  render() {
    return (
      <DashboardPage
        {...this.props}
      />
    );
  }
}

DashboardPageContainer.propTypes = {
  //data

  //func
  loadTreeData: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {

  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTreeData: () => {
      dispatch(loadTreeData())
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPageContainer)