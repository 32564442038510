import {createTheme} from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#5797c7'
    },
    text: {
      primary: '#16191f',
      secondary: '#545b64'
    },
  },
  typography: {
    fontFamily: "Amazon Ember Regular, \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    h5: {
      fontWeight: 300,
    },
    body1: {
      fontWeight: 300,
    },
    body2: {
      fontWeight: 300,
    }
  },
  shape: {
    borderRadius: 0
  }
});