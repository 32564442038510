import {BASE_API_URL} from "../../config";
import CommonCrudRestClient from "./CommonCrudRestClient";
import ChangeBackendAccessRequest from "../../types/payload/infrastructure/backend/ChangeBackendAccessRequest";
import * as queryString from "qs";

class BackendManagementRestClient extends CommonCrudRestClient {

    handleBlobResponse(response: any) {
        if (response.ok) {
            return response.blob();
        } else {
            return response.text().then( (error: any) => {
                throw new Error(error)
            });
        }
    }

    async list() {
        return await this.get();
    }

    async getBackendSecretKey(id: number) {
        return await this.get(`/${id}/backendSecretKey`)
    }

    async grantAccess(id: number, username: string) {
        const data: ChangeBackendAccessRequest = {
            username
        };

        return await this.post(`/${id}/grantAccess`, data);
    }

    async revokeAccess(id: number, username: string) {
        const data: ChangeBackendAccessRequest = {
            username
        };

        return await this.post(`/${id}/revokeAccess`, data);
    }

    async getDownloadLink(id: number) {
        console.log("getDownloadLink ID: ", id);
        return await this.get(`/${id}/download`);
    }

    async getDefaultDownloadLink() {
        console.log("getDefaultDownloadLink" );
        return await this.get("/default/download");
    }

    async getDownloadPropertiesLink(id: number) {
        console.log("getDownloadPropertiesLink ID: ", id);
        return await this.get(`/${id}/download-properties`);
    }

    async getDefaultDownloadPropertiesLink() {
        console.log("getDefaultDownloadLink" );
        return await this.get("/default/download-properties");
    }


    createBackendDownloadLink(token: string) {
        console.log("createBackendDownloadLink token: ", token);
        return this.location + `/download/${token}`
    }

    createBackendDownloadPropertiesLink(token: string) {
        console.log("createBackendDownloadPropertiesLink token: ", token);
        return this.location + `/download-properties/${token}`
    }

}

export default new BackendManagementRestClient(`${BASE_API_URL}/infrastructure/backends`);
