import React from "react";
import {Box} from "@material-ui/core";
import {Route, Router, Switch} from "react-router-dom";
import history from "routes/history";
import * as paths from "routes/paths";
import Logs from "./Logs";

const BackendsLogsPageContent: React.FC = ({}) => {

    return (
        <Box style={{
            display: 'block',
            height: "100%",
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden"
        }}>
            <Router history={history}>
                <Switch>
                    <Route
                        path={paths.BACKENDS_LOGS}
                        render={() => <Logs />}
                    />
                </Switch>
            </Router>
        </Box>
    );
};

export default BackendsLogsPageContent;
