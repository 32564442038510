import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLeftNavBarOpen, setLeftNavBarOpen} from "reducers/settingsSlice";
import {useHistory, useLocation} from "react-router-dom";
import LeftNavBar from "components/common/LeftNavBar/LeftNavBar";
import LeftNavBarListItem from "components/common/LeftNavBar/LeftNavBarListItem";
import * as paths from "routes/paths";
import LeftNavBarListItemText from "components/common/LeftNavBar/LeftNavBarListItemText";

const InfrastructureLeftNavBar: React.FC = ({}) => {
    const dispatch = useDispatch();
    const leftNavBarOpen = useSelector(selectLeftNavBarOpen);
    const location = useLocation();
    const history = useHistory();
    const { pathname } = location;

    const handleClick = (path: any) => {
        history.push(path);
    }

    return (
        // @ts-ignore
        <LeftNavBar
            title="Infrastructure"
            open={leftNavBarOpen}
            setOpen={() => dispatch(setLeftNavBarOpen(!leftNavBarOpen))}
        >
            {/*@ts-ignore*/}
            <LeftNavBarListItem onClick={() => handleClick(paths.HOME_PATH)}>
                <LeftNavBarListItemText
                    primary="< Back to Home"
                    active={false}
                />
            </LeftNavBarListItem>
            {/*@ts-ignore*/}
            <LeftNavBarListItem onClick={() => handleClick(paths.BACKENDS_LIST)}>
                <LeftNavBarListItemText
                    primary="Backends"
                    active={pathname === paths.BACKENDS_LIST || pathname === paths.BACKENDS_ADD}
                />
            </LeftNavBarListItem>
            {/*@ts-ignore*/}
            <LeftNavBarListItem onClick={() => handleClick(paths.BACKENDS_COLLECTORS)}>
                <LeftNavBarListItemText
                    primary="Collectors"
                    active={pathname === paths.BACKENDS_COLLECTORS || pathname === paths.BACKENDS_COLLECTORS}
                />
            </LeftNavBarListItem>
            {/*@ts-ignore*/}
            <LeftNavBarListItem onClick={() => handleClick(paths.JOBS_LIST)}>
                <LeftNavBarListItemText
                    primary="Jobs"
                    active={pathname === paths.JOBS_LIST || pathname === paths.JOBS_LIST}
                />
            </LeftNavBarListItem>
            {/*@ts-ignore*/}
            <LeftNavBarListItem onClick={() => handleClick(paths.BACKENDS_LOGS)}>
                <LeftNavBarListItemText
                    primary="Logs"
                    active={pathname === paths.BACKENDS_LOGS || pathname === paths.BACKENDS_LOGS}
                />
            </LeftNavBarListItem>
        </LeftNavBar>
    );
};

export default InfrastructureLeftNavBar;
