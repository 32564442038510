import React from "react";
import {Theme, WithStyles} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import TimeRangeTabPanel from "../../../components/dashboard/dashlets/DashletTimeRange/TimeRangeTabPanel";
import Paper from "@material-ui/core/Paper";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import TextField from "../../../components/common/TextField/TextField";
import Select from "../../../components/common/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import CancelButton from "../../../components/common/Button/CancelButton";
import SubmitButton from "../../../components/common/Button/SubmitButton";
import {updateDashletTimeRange} from "../../../actions/dashboard/dashletActions";
import {withStyles} from "@material-ui/core/styles";
import {selectDashletTimeRange} from "../../../reducers/dashboard/dashboardSlice";

const styles = (theme: Theme) => ({
    root: {},
    appBar: {
        backgroundColor: '#fff',
        boxShadow: 'none',
    },
    formLabel: {
        padding: '0.4rem 0rem',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    formControlLabel: {
        margin: theme.spacing(1),
        minWidth: '40px'
    },
    formControlInput: {
        margin: theme.spacing(1),
        minWidth: '60px',
    },
    formControlSelect: {
        margin: theme.spacing(1),
        minWidth: '120px',
    },
    formControlFullWidthSelect: {
        margin: theme.spacing(1),
        width: '192px',
        minWidth: '160px',
    },
    formControlDatetime: {
        margin: theme.spacing(1),
        width: '192px',
        minWidth: '160px',
    },
    footer: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    grow: {
        flexGrow: 1,
    },
    switchRoot: {
        marginLeft: 0,
    },
    switchLabel: {
        marginLeft: 8,
    },
});

interface SnowflakeReportDashletTimeRangeProps extends WithStyles<typeof styles> {
    dashletId: number;
    backendKey: string;
    close: () => void;
}

const SnowflakeReportDashletTimeRange: React.FC<SnowflakeReportDashletTimeRangeProps> = ({
                                                       classes,
                                                       dashletId,
                                                       backendKey,
                                                       close
                                                   }) => {

    const getEmptyTimeRangeSettings = () => {
        return {
            timeUnitsCount: 3,
            timeUnit: "month"
        }
    }

    const dispatch = useDispatch();
    const initialTimeRangeSettings = useSelector(state => selectDashletTimeRange(state, dashletId));
    const [timeRangeSettings, setTimeRangeSettings]
        = React.useState(initialTimeRangeSettings && Object.keys(initialTimeRangeSettings).length > 0 ? initialTimeRangeSettings : getEmptyTimeRangeSettings());

    // const initialTimeRangeSettings = useSelector(state => selectDashletTimeRange(state, dashletId));
    // const initialTimeUnitsCount
    // const [timeUnitsCount, setTimeUnitsCount] = React.useState<number>(3);
    // const [timeUnit, setTimeUnit] = React.useState<string>("month");

    const {
        timeUnitsCount,
        timeUnit
    } = timeRangeSettings;

    const changeProperty = (name: string, value: any) => setTimeRangeSettings({
        ...timeRangeSettings,
        [name]: value
    });

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        changeProperty(event.target.name, event.target.value);
    };

    const handleCancel = () => close();

    const handleSubmit = () => {
        dispatch(updateDashletTimeRange(dashletId, timeRangeSettings));
        close();
    }

    return (
        <>
            <Paper className={classes.root}>
                {/* @ts-ignore */}
                <TimeRangeTabPanel>
                    <FormGroup row>
                        <FormControl className={classes.formControlLabel}>
                            <Typography component="div" className={classes.formLabel}>
                                Last
                            </Typography>
                        </FormControl>
                        <FormControl className={classes.formControlInput}>
                            <TextField
                                name="timeUnitsCount"
                                onChange={handleChangeInput}
                                value={timeUnitsCount}
                                inputProps={{
                                    step: 1,
                                    min: 1,
                                    max: 12,
                                    type: 'number',
                                }}
                            />
                        </FormControl>
                        <FormControl className={classes.formControlSelect}>
                            <Select
                                name="timeUnit"
                                value={timeUnit}
                                onChange={handleChangeInput}
                            >
                                <MenuItem value="day">Days</MenuItem>
                                <MenuItem value="week">Weeks</MenuItem>
                                <MenuItem value="month">Months</MenuItem>
                                {/*<MenuItem value="Year">Years</MenuItem>*/}
                            </Select>
                        </FormControl>
                    </FormGroup>
                </TimeRangeTabPanel>

                <Divider />

                <div className={classes.footer}>
                    <div className={classes.grow}/>
                    {/* @ts-ignore */}
                    <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                    {/* @ts-ignore */}
                    <SubmitButton onClick={handleSubmit}>Apply</SubmitButton>
                </div>
            </Paper>
        </>
    );
};

export default withStyles(styles)(SnowflakeReportDashletTimeRange);
