import {buildDashletObject} from "dashlets/PEIChartBagDashlet/actions";
import PEIChartBagDashletSettingsContainer
  from "dashlets/PEIChartBagDashlet/components/PEIChartBagDashletSettingsContainer";
import PEIChartBagDashletTimeRangeContainer
  from "dashlets/PEIChartBagDashlet/components/PEIChartBagDashletTimeRangeContainer";
import {refresh, validateConfig} from "./actions";
import PEIChartBagDashletCreate from "./components/PEIChartBagDashletCreate";
import PEIChartBagDashletView from "./components/PEIChartBagDashletView";
import PEIChartTemplateContainer from "./components/PEIChartTemplateContainer";

const DASHLET_TYPE_NAME = 'PEI_CHART_BAG_CHART_DASHLET_TYPE';

export const PEI_CHART_BAG_DASHLET_CONFIG = {
  [DASHLET_TYPE_NAME]: {
    typeName: DASHLET_TYPE_NAME,
    typeLabel: 'PEI Chart Bag',
    viewComponent: PEIChartBagDashletView,
    createFormComponent: PEIChartBagDashletCreate,
    settingsComponent: PEIChartBagDashletSettingsContainer,
    timeRangeComponent: PEIChartBagDashletTimeRangeContainer,
    openTemplateAsChartComponent: PEIChartTemplateContainer,
    validateConfigFunc: validateConfig,
    loadOptionsAction: null,
    refreshAction: refresh,
    buildDashletObjectFunc: buildDashletObject,
  }
};

/* Configuration options */
export const TEMPLATES_DASHLET_CONFIG_OPTIONS_PROPERTY = 'templates';

export const DASHLET_CONFIG_PROPERTIES = {
  BACKEND_KEY: 'backendKey',
  TEMPLATE_NAME: 'templateName',

  REQUIRED_PROPERTIES: ['backendKey', 'templateName']
};
