import React from "react";
import ApplicationPage from "components/common/ApplicationPage/ApplicationPage";
import InfrastructureLeftNavBar from "components/gate/InfrastructureLeftNavBar";
import BackendCollectorsContent from "./BackendCollectorsContent";

const BackendCollectors: React.FC = ({}) => (
    <ApplicationPage
        leftNavBarComponent={<InfrastructureLeftNavBar/>}
        contentComponent={<BackendCollectorsContent/>}
    />
);

export default BackendCollectors;
