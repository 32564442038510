import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import NavBarContainer from "containers/common/NavBarContainer";

const styles = theme => ({
  root: {
    height: '100%',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    height: 'calc(100% - 48px)',
    textAlign: 'center',
  },
});

class ApplicationPage extends Component {
  render() {
    const {classes, contentComponent, leftNavBarComponent} = this.props;

    return (
        <div className={classes.root}>
          <NavBarContainer />

          <section className={classes.section}>
            {leftNavBarComponent}
            {contentComponent}
          </section>
        </div>
    );
  }
}

ApplicationPage.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  contentComponent: PropTypes.object,
  leftNavBarComponent: PropTypes.object,

  //func
};

export default withStyles(styles)(ApplicationPage);