import CommonRestClient from "./CommonRestClient";
import {BASE_API_URL} from "../../config";

class AuthRestClient extends CommonRestClient {

    async login(loginData) {
        return await super.post('/signin', loginData);
    }

    async check() {
        return await super.get('/check');
    }

    async register(registrationData) {
        return await super.post('/signup', registrationData);
    }

    async changePassword(password) {
        return await super.post('/changePassword', {password: password})
    }

    async forgotPassword(forgotPasswordData) {
        return await super.post('/resetPassword', forgotPasswordData)
    }
}

export default new AuthRestClient(`${BASE_API_URL}/auth`);