import React from "react";
import {TableCell} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {formatNumber} from "utils/FormattingUtils";

interface NumberTableCellProps {
    children: number | null;
}

const useStyles = makeStyles({
    tableCell: {
        textAlign: "right",
        fontSize: "0.6vw",
    }
});

const NumberTableCell: React.FC<NumberTableCellProps> = ({ children }) => {
    const classes = useStyles();

    return <TableCell className={classes.tableCell}>{children != null ? formatNumber(children) : "N/A"}</TableCell>;
};

export default NumberTableCell;
