import Palette from "components/dashboard/Palette/Palette";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class PaletteContainer extends Component {

  render() {
    return (
        <Palette {...this.props} />
    );
  }
}

PaletteContainer.propTypes = {
  //data
  open: PropTypes.bool,

  //func
};

const mapStateToProps = (state) => {
  return {
    open: true,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {

  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaletteContainer)