import SnowflakeReportDashletView from "./components/SnowflakeReportDashletView";
import SnowflakeReportDashletCreate from "./components/SnowflakeReportDashletCreate";
import {buildDashletObject, refresh, validateConfig} from "./actions";
import SnowflakeReportDashletTimeRange from "./components/SnowflakeReportDashletTimeRange";


const DASHLET_TYPE_NAME = 'SNOWFLAKE_REPORT_DASHLET_TYPE';

export const SNOWFLAKE_REPORT_DASHLET_CONFIG = {
    [DASHLET_TYPE_NAME]: {
        typeName: DASHLET_TYPE_NAME,
        typeLabel: 'Snowflake Report',
        viewComponent: SnowflakeReportDashletView,
        createFormComponent: SnowflakeReportDashletCreate,
        editConfigFormComponent: null,

        settingsComponent: null,
        openTemplateAsChartComponent: null,
        timeRangeComponent: SnowflakeReportDashletTimeRange,
        validateConfigFunc: validateConfig,
        loadOptionsAction: null,
        refreshAction: refresh,
        buildDashletObjectFunc: buildDashletObject,
    }
}