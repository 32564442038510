import react, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAuthObject} from "reducers/authSlice";
import {loadUserInfo} from "actions/userInfoActions";

const UserInfoLoader: react.FC = () => {
    const dispatch = useDispatch();
    const auth = useSelector(selectAuthObject);

    useEffect(() => {
        if (auth) {
            dispatch(loadUserInfo());
        }
    }, [auth]);

    return null;
};

export default UserInfoLoader;
