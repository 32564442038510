import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Login from "./Login";
import * as AuthActions from "../../../actions/authActions";
import {clearLoginErrorMessage} from "../../../reducers/authSlice";

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userDetails: {
        username: '',
        password: ''
      }
    };
  }

  onClickLoginHandler = (event) => {
    event.preventDefault();

    this.props.login(this.state.userDetails);
  };

  onChangeInputHandler = (event) => {
    event.preventDefault();

    let {userDetails} = this.state;
    const target = event.target;

    userDetails[target.name] = target.value;

    this.setState({userDetails});
  };

  render() {
    const {errorMessage, isInProgress} = this.props;

    return (
      <Login
        errorMessage={errorMessage}
        disabled={isInProgress}
        onLogin={this.onClickLoginHandler}
        onChangeInputHandler={this.onChangeInputHandler}
      />
    );
  }

  componentWillUnmount() {
    // this.props.clearLoginErrorMessage();
  }
}

LoginContainer.propTypes = {
  //data
  isInProgress: PropTypes.bool,
  errorMessage: PropTypes.string,

  //func
  showRegistrationPage: PropTypes.func,
  showForgotPasswordPage: PropTypes.func,
  login: PropTypes.func.isRequired,
  clearLoginErrorMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.loginErrorMessage,
    isInProgress: state.auth.isLoginInProgress,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password) => {
      dispatch(AuthActions.login(username, password));
    },
    clearLoginErrorMessage: () => {
      dispatch(clearLoginErrorMessage());
    },
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer)