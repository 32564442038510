import React from "react";
import {WEBSOCKET_USER_CLIENT_HEADER} from "constants/webSocketConstants";
import { connect } from "react-redux";
import { setSubscriptionPaths } from "reducers/webSocketSlice";

interface WebSocketSubscriptionProps {
    stompClient: any;
    subscriptionDestination: string;
    backendKey?: string | null;
    userSessionId?: string;
    dashletId?: number | null;
    handleMessage: (data: any, header: any) => void;
    onSubscribeCallback?: () => void;
    setSubscriptionPaths?: (subsciptionPaths:any) => void;
}

let subscriptionPaths:any = [];

const WebSocketSubscription: React.FC<WebSocketSubscriptionProps> = ({
                                                                          stompClient,
                                                                          subscriptionDestination,
                                                                          backendKey,
                                                                          userSessionId,
                                                                          dashletId,
                                                                        //   subscriptionPaths,
                                                                          handleMessage,
                                                                          onSubscribeCallback,
                                                                          setSubscriptionPaths
                                                                      }) => {
    const [subscription, setSubscription] = React.useState<any>(null);

    React.useEffect(() => {
        const subscribe = () => {
            let fullSubscriptionDestination = subscriptionDestination;
            if (userSessionId) {
                fullSubscriptionDestination = `${fullSubscriptionDestination}-${userSessionId}`;
            }
            if (backendKey) {
                fullSubscriptionDestination = `${fullSubscriptionDestination}-${backendKey}`;
            }
            if (dashletId) {
                fullSubscriptionDestination = `${fullSubscriptionDestination}-${dashletId}`;
            }

            //TODO: Need to optimize/check
            if (subscriptionPaths !== undefined && subscriptionPaths.indexOf(fullSubscriptionDestination) === -1) {
                const newSubscription = stompClient.subscribe(fullSubscriptionDestination, (frame: any) => {
                    handleMessage(JSON.parse(frame.body), {...WEBSOCKET_USER_CLIENT_HEADER});
                });

                if (setSubscriptionPaths) {
                  subscriptionPaths = [...subscriptionPaths, fullSubscriptionDestination];
                  setSubscriptionPaths(subscriptionPaths);
                }
                setSubscription(newSubscription);
            }
            if (onSubscribeCallback) {
                onSubscribeCallback();
            }
        };

        subscribe();

        return () => {
            if (subscription) {
                subscription.unsubscribe();
                // if (setSubscriptionPaths) {
                //     // subscriptionPaths = [...subscriptionPaths, fullSubscriptionDestination];
                //     // setSubscriptionPaths(subscriptionPaths);
                // }
            }
        };
    // }, [stompClient, subscriptionDestination, userSessionId]);
    }, [stompClient, subscriptionDestination, backendKey, userSessionId]);

    // render nothing
    return null;
};

const mapStateToProps = (state: any) => {
    return {
    }
  };
  
const mapDispatchToProps = (dispatch: any) => {
    return {
      setSubscriptionPaths: (subsciptionPaths: any) => {
        dispatch(setSubscriptionPaths(subsciptionPaths));
      }  
    }
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(WebSocketSubscription);

