import React from 'react';
import {useDispatch} from "react-redux";
import HLCChartDashletContainer from "./HLCChartDashletContainer";
import {handleLoadStaticChart, refresh, handleLoadChart } from "../actions";
import * as webSocketClient from '../webSocketClient';
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import { handleExportDashlet } from 'actions/dashboard/dashletActions';

const HLCChartDashletView = (props) => {
    const {dashlet} = props;
    const dispatch = useDispatch();

    return <React.Fragment>
        <HLCChartDashletContainer key={`hlc-chart-dashlet-container-${dashlet.id}`} {...props}/>

        <HLCUserSessionWebSocketSubscription
            key={`hlc-chart-dashlet-ws-static-chart-subscription-${dashlet.id}`}
            dashletId={dashlet.id}
            subscriptionDestination={webSocketClient.LOAD_HLC_STATIC_CHART_SUBSCRIPTION}
            // onSubscribeCallback={() => dispatch(refresh(dashlet))}
            handleMessage={response => dispatch(handleLoadStaticChart(response))}
        />
        <HLCUserSessionWebSocketSubscription
            key={`hlc-chart-dashlet-ws-chart-subscription-${dashlet.id}`}
            dashletId={dashlet.id}
            subscriptionDestination={webSocketClient.LOAD_HLC_CHART_SUBSCRIPTION}
            // onSubscribeCallback={() => dispatch(refresh(dashlet))}
            handleMessage={response => dispatch(handleLoadChart(response))}
        />
        {/* <HLCUserSessionWebSocketSubscription
            key={`hlc-chart-dashlet-ws-export-chart-subscription-${dashlet.id}`}
            dashletId={dashlet.id}
            subscriptionDestination={`${webSocketClient.EXPORT_DASHLET_SUBSRIPTION}`}
            onSubscribeCallback={() => dispatch(refresh(dashlet))}
            handleMessage={response => dispatch(handleExportDashlet(response))}
        /> */}
    </React.Fragment>
}

export default HLCChartDashletView;