
export const ROUTING_ACTION_TYPE = 'ROUTING';

export function routeTo(routePath) {
  return {
    type: ROUTING_ACTION_TYPE,
    payload: {
      method: 'push', //or, replace
      nextUrl: routePath
    }
  }
}