import React from 'react';
import LoadingProgress from "../../../components/dashboard/dashlets/LoadingProgress";
import HLCChartDashletChartContainer from "./HLCChartDashletChartContainer";
import HLCChartDashletReportContainer from "./HLCChartDashletReportContainer";
import {useSelector} from 'react-redux';
import Typography from "@material-ui/core/Typography";
import Dimensions from 'react-dimensions';
import {selectDashletData} from "../../../reducers/dashboard/dashboardSlice";

const HLCChartDashletContainer = (props) => {
// const HLCChartDashletContainer = React.forwardRef((props, ref) => {
  // const {
  //   dashlet: {
  //     id,
  //     settings: {
  //       // displayAs,
  //     },
  //   }
  // } = props;

  const displayAs = props.settings?.displayAs ? props.settings?.displayAs : "chart"

  const data = useSelector(state => selectDashletData(state, props.dashlet.id));


    if (data && data.isLoading) {
      return <LoadingProgress/>;
    }

    if (displayAs === 'report') {
      return <HLCChartDashletReportContainer
          // ref={ref}
          {...props}
          data={data}
      />
    }

    if (displayAs === 'chart' || displayAs == null) {
      return  <HLCChartDashletChartContainer
          // ref={ref}
          {...props}
          data={data}
      />
    }

    return <Typography variant="body2">Display As {displayAs} is not supported.</Typography>
}
export default (Dimensions({elementResize: true})(HLCChartDashletContainer));

// );

//export default (Dimensions({elementResize: true})(HLCChartDashletContainer));
// Wrap the ref-forwarded component with Dimensions HOC
//export default Dimensions({elementResize: true})(React.forwardRef(HLCChartDashletContainer));
// export default HLCChartDashletContainer;

