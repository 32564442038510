import {
    WEB_SOCKET_ACTION_PREFIX,
    WEB_SOCKET_SUBSCR_GROUP_PREFIX,
    WEB_SOCKET_SUBSCR_PREFIX
} from "constants/webSocketConstants";
import {publish} from "./hlcCommonWebSocketClient";
import CollectorStompFrame from "../../../types/payload/infrastructure/collector/CollectorStompFrame";

/* List Collectors */
const LIST_BACKEND_COLLECTORS_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.infrastructure.collector.list.execute`;
export const LIST_BACKEND_COLLECTORS_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.infrastructure.collector.list`;

export const listCollectors = (appState: unknown, backendKey: string) =>
    publish(appState, backendKey, LIST_BACKEND_COLLECTORS_ACTION, {
        empty: ""
    });

/* Start Collector */
const START_BACKEND_COLLECTOR_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.infrastructure.collector.operation.start`;

export const startCollector = (appState: unknown, backendKey: string, payload: CollectorStompFrame) =>
    publish(appState, backendKey, START_BACKEND_COLLECTOR_ACTION, payload);

/* Stop Collector */
const STOP_BACKEND_COLLECTOR_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.infrastructure.collector.operation.stop`;

export const stopCollector = (appState: unknown, backendKey: string, payload: CollectorStompFrame) =>
    publish(appState, backendKey, STOP_BACKEND_COLLECTOR_ACTION, payload);

export const BACKEND_COLLECTOR_OPERATION_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.infrastructure.collector.operation`;

export const BACKEND_COLLECTOR_STATUS_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_GROUP_PREFIX}/ui.infrastructure.collector.status`;
