import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Client} from '@stomp/stompjs';
import {connect} from "react-redux";
import SockJS from 'sockjs-client';
import {setStompClient, setHLCActiveBackendKey, setPEIActiveBackendKey} from "../reducers/webSocketSlice";
import {DEV_AUTH_HEADER, WEBSOCKET_USER_CLIENT_HEADER} from "../constants/webSocketConstants";
import { getEmailConfigurationData } from 'actions/EmailConfigurationActions';
import HLCDashletsRestClient from 'api/rest/HLCDashletsRestClient';
import PEIDashletsRestClient from 'api/rest/PEIDashletsRestClient';

const noOp = () => {
};

class StompClient extends Component {
    static propTypes = {
        stompClientKey: PropTypes.string,
        endpoint: PropTypes.string,
        sockJsEndpoint: PropTypes.string,
        reconnectDelay: PropTypes.number,
        debugMode: PropTypes.bool,
        heartbeatIncoming: PropTypes.number,
        heartbeatOutgoing: PropTypes.number,
        onConnect: PropTypes.func,
        getEmailConfigurationData: PropTypes.func
    };

    static RECONNECT_DELAY = 120000;
    static HEARTBEAT_FREQUENCY = 120000;

    constructor(props) {
        super(props);

        this.state = {
            client: this.getNewClient()
        }
    }

    componentDidMount() {
        const { client } = this.state;
        const { stompClientKey, onConnect,getEmailConfigurationData, setHLCActiveBackendKey, setPEIActiveBackendKey } = this.props;

        client.onConnect = () => {
            onConnect(stompClientKey, client);
            if (stompClientKey == "PEI") {
                PEIDashletsRestClient.getActiveBackendKey().then(key => {
                    setPEIActiveBackendKey(key.backendKey);
                    getEmailConfigurationData();
                }).catch(error => {
                    console.error(error);
                });
            } else if (stompClientKey == "HLC") {
                HLCDashletsRestClient.getActiveBackendKey().then(key => {
                    setHLCActiveBackendKey(key.backendKey);
                    // getEmailConfigurationData();
                }).catch(error => {
                    console.error(error);
                });
            }
        }
        this.state.client.activate();
    }

    componentWillUnmount() {
        // this.state.client.deactivate();
    }

    render() {
        return null;
    }

    getNewClient() {
        console.debug("Creating new WebSocket client...")
        const {
            endpoint,
            sockJsEndpoint,
            reconnectDelay,
            debugMode,
            heartbeatIncoming,
            heartbeatOutgoing
        } = this.props

        let connectHeaders = {
            ...WEBSOCKET_USER_CLIENT_HEADER
        }
        //RW 02/29/2024 DEV_AUTH_HEADER contain a default username and password that would be used for authentication
        if (process.env.NODE_ENV !== 'production') {
            connectHeaders = {
                ...connectHeaders,
                ...DEV_AUTH_HEADER
            }
        }

        const client = new Client({
            brokerURL: endpoint,
            debug: debugMode ? console.debug : noOp,
            reconnectDelay: reconnectDelay || StompClient.RECONNECT_DELAY,
            heartbeatIncoming: heartbeatIncoming || StompClient.HEARTBEAT_FREQUENCY,
            heartbeatOutgoing: heartbeatOutgoing || StompClient.HEARTBEAT_FREQUENCY,
            connectHeaders: connectHeaders
        });

        if (sockJsEndpoint) {
            client.webSocketFactory = function () {
                return new SockJS(sockJsEndpoint);
            }
        }

        return client;
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.webSocket.stompClient,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConnect: (stompClientKey, stompClient) => {
            dispatch(setStompClient(stompClientKey, stompClient));
        },
        getEmailConfigurationData: () => {
            dispatch(getEmailConfigurationData());
        },
        setHLCActiveBackendKey: (activeBackendKey) => {
            dispatch(setHLCActiveBackendKey(activeBackendKey));
        },
        setPEIActiveBackendKey: (activeBackendKey) => {
            dispatch(setPEIActiveBackendKey(activeBackendKey));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StompClient);