import * as webSocketClient from './webSocketClient';

export const refresh = (dashlet: any) => (dispatch: any, getState: any) => {
    const {backendKey, targetName} = dashlet.config;
    const {id, timeRange} = dashlet;

    webSocketClient.generateSnowflakeDatabaseReport(getState(), backendKey, {
        dashletId: id,
        targetName: targetName,
        reportParams: timeRange,
    } );
};

export const validateConfig = (dashletConfig: any) => {
    const errors = {};

    console.debug('===> SnowflakeReportDashlet validateConfig() ');

    return errors;
};

export const buildDashletObject = (dashletData: any, configOptions: any) => {
    return {
        ...dashletData,
        backendKey: dashletData.config.backendKey
    }
};
