import React from "react";
import JobInfo from "types/model/infrastructure/JobInfo";
import {makeStyles} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

interface JobsTableProps {
    rows: JobInfo[];
    pauseAction: (jobId: string, jobGroupName: string, jobName: string) => void;
    resumeAction: (jobId: string, jobGroupName: string, jobName: string) => void;
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 600,
    },
});

const JobsTable: React.FC<JobsTableProps> = ({
                                                 rows,
                                                 pauseAction,
                                                 resumeAction
                                             }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Group</TableCell>
                            {/*<TableCell>Description</TableCell>*/}
                            <TableCell>Interval</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: JobInfo) => {
                            return (
                                <TableRow hover key={row.id}>
                                    <TableCell>{row.jobName}</TableCell>
                                    <TableCell>{row.groupName}</TableCell>
                                    {/*<TableCell>{row.description}</TableCell>*/}
                                    <TableCell align="right">{row.interval}</TableCell>
                                    <TableCell>{row.jobStatus}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            disabled={row.jobStatus === "PAUSED"}
                                            onClick={() => pauseAction(row.id, row.groupName, row.jobName)}
                                        >
                                            <PauseIcon fontSize="small"/>
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            disabled={!(row.jobStatus === "PAUSED")}
                                            onClick={() => resumeAction(row.id, row.groupName, row.jobName)}
                                        >
                                            <PlayArrowIcon fontSize="small"/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default JobsTable;
