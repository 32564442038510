import {whiteColor, blackColor} from "assets/jss/global.jsx";

const dialogTitleStyle = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#fafafa',
    borderBottom: '1px solid #eaeded',
  },
  typography: {
    color: '#16191F',
    fontSize: '18px',
    fontWeight: 700,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: blackColor,
  },
  minimizeButton: {
    top: '8px',
    color: blackColor,
    right: '108px',
    position: 'absolute',
  },
  maximizeButton: {
    top: '8px',
    color: blackColor,
    right: '60px',
    position: 'absolute',
  },
  resetZoomButton: {
    top: '8px',
    color: '#000',
    right: '160px',
    position: 'absolute',
  },
  openChart: {
    top: '7px',
    color: '#000',
    right: '108px',
    position: 'absolute',
  }
});

export default dialogTitleStyle;