import React from "react";
import IntegrationSection from "../IntegrationSection";
import Integration from "types/model/settings/integration/Integration";
import IntegrationType from "types/model/settings/integration/IntegrationType";
import NewRelicIntegrationSection from "./sections/NewRelicIntegrationSection";
import NewRelicIntegration from "types/model/settings/integration/NewRelicIntegration";
import AppDynamicsIntegration from "types/model/settings/integration/AppDynamicsIntegration";
import AppDynamicsIntegrationSection from "./sections/AppDynamicsIntegrationSection";

interface APMIntegrationsProps {
    integrations: Record<string, Integration>;
    onSaveIntegration: (integration: Integration) => void;
}

const APMIntegrations: React.FC<APMIntegrationsProps> = ({
                                                             integrations,
                                                             onSaveIntegration
                                                         }) => (
    <>
        {integrations[IntegrationType.NEW_RELIC]
            && <IntegrationSection
                title={integrations[IntegrationType.NEW_RELIC].title}
                content={
                    <NewRelicIntegrationSection
                        integration={integrations[IntegrationType.NEW_RELIC] as NewRelicIntegration}
                        onSave={onSaveIntegration}
                    />
                }
            />}
        {integrations[IntegrationType.APP_DYNAMICS]
            && <IntegrationSection
                title={integrations[IntegrationType.APP_DYNAMICS].title}
                content={
                    <AppDynamicsIntegrationSection
                        integration={integrations[IntegrationType.APP_DYNAMICS] as AppDynamicsIntegration}
                        onSave={onSaveIntegration}
                    />
                }
            />}
    </>
);

export default APMIntegrations;
