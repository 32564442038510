import {setDashletData, setChartsData, selectDashletData} from "reducers/dashboard/dashboardSlice";
import * as _ from 'lodash';
import {serializeDashletTimeRange} from '../../utils/dashboard/dashletTimeRangeUtils';
import * as webSocketClient from './webSocketClient';
import {DASHLET_CONFIG_PROPERTIES} from "./config";

export const buildDashletObject = (dashletData, configOptions) => {
    const peiChartBagTemplate = dashletData.config[DASHLET_CONFIG_PROPERTIES.TEMPLATE_NAME];
    const templates = configOptions.templates;
    const template = _.find(templates, (tpl) => {
        return tpl.name === peiChartBagTemplate;
    });
    return {
        ...dashletData,
        config: {
            ...dashletData.config,
        },
        settings: template ? template.settings : null,
        timeRange: template ? template.timeRange : null,
    };
};

export const refresh = (dashlet, isResizeOnly, containerWidth, containerHeight) => (dispatch, getState) => {
    const appState = getState();
    const dashletData = appState.dashboard.dashletsData[dashlet.id];
    const loadPEIChartStompFrame = {
        dashletId: dashlet.id,
        templateName: dashlet.config.templateName,
        dashletTimeRange: serializeDashletTimeRange(dashlet.timeRange),
        dashletSettings: dashlet.settings,    
        frameWidth: appState.dashboard.isDashletPreviewOpen ? containerWidth : dashletData ? dashletData.frameWidth : 520,
        frameHeight: appState.dashboard.isDashletPreviewOpen ? containerHeight - 40 : dashletData ? dashletData.frameHeight : 344,
        isResizeOnly: isResizeOnly
    };

    dispatch(loadChartBagChart(dashlet.config.backendKey, loadPEIChartStompFrame));
}

const loadChartBagChart = (backendKey, loadChartBagChartStompFrame) =>
    (dispatch, getState) =>
        webSocketClient.loadChartBagChart(getState(), backendKey, loadChartBagChartStompFrame);

export const loadPEIChartBagTemplates = backendKey => (dispatch, getState) =>
    webSocketClient.loadChartBagTemplates(getState(), backendKey);

export const handleLoadChartBagChart = response => (dispatch, getState) => {
    let dashletData = response;
    if (getState().dashboard.isDashletPreviewOpen) {
        const dashlet = selectDashletData(getState(), response.dashletId)
        dashletData.frameWidth = dashlet.frameWidth;
        dashletData.frameHeight = dashlet.frameHeight;
      }
    dispatch(setDashletData(response.dashletId, dashletData));
}

export const handleLoadChart = response => (dispatch, getState) => {
    dispatch(setChartsData(response));
}

export const setExtremes = (dashletId, key, xMin, xMax, yMin, yMax) =>
    (dispatch, getState) => {
        const prevDashletData = getState().dashboard.dashletsData[dashletId];
        if (prevDashletData) {
            const dashletData = {
                ...prevDashletData,
                isManualZoom: true,
                extremes: {
                    ...prevDashletData.extremes,
                    [key]: {
                        yMin: yMin,
                        yMax: yMax
                    }
                },
                xMin: xMin,
                xMax: xMax,
            };

            dispatch(setDashletData(dashletId, dashletData));
        }
    }

export const setXExtremes = (dashletId, xMin, xMax, isManualZoom) =>
    (dispatch, getState) => {
        const prevDashletData = getState().dashboard.dashletsData[dashletId];
        if (prevDashletData) {
            const dashletData = {
                ...prevDashletData,
                isManualZoom: isManualZoom,
                extremes: {},
                xMin: xMin,
                xMax: xMax,
            };

            dispatch(setDashletData(dashletId, dashletData));
        }
    }

    export const setDashletFrameSize = (dashletId, width, height) => (dispatch, getState) => {
        const prevDashletData = getState().dashboard.dashletsData[dashletId];
        if (prevDashletData) {
          const dashletData = {
            ...prevDashletData,
            frameWidth: width,
            frameHeight: height,
          };
      
          dispatch(setDashletData(dashletId, dashletData));
        } else {
          dispatch(setDashletData(dashletId, {
            frameWidth: width,
            frameHeight: height,
          }));
        }
      }

export const validateConfig = (dashletConfig) => {
    const errors = {};
    _.each(DASHLET_CONFIG_PROPERTIES.REQUIRED_PROPERTIES, (propertyName) => {
        if (!dashletConfig[propertyName]) {
            errors[propertyName] = true;
        }
    });
    return errors;
};

export const loadPEIChart = (dashlet) => (dispatch, getState) => {
    const appState = getState();
    // const dashletData = appState.dashboard.dashletsData[dashlet.id];
    const loadPEIChartStompFrame = {
      dashletId: dashlet.id,
      templateName: dashlet.config.templateName,
      dashletTimeRange: serializeDashletTimeRange(dashlet.timeRange),
      dashletSettings: dashlet.settings,
    };
  
    webSocketClient.loadChart(appState, dashlet.config.backendKey, loadPEIChartStompFrame);
  }

export const loadChartOnPEI = (templateName) => (dispatch, getState) => {
    const state = getState();
    let backendKey = state.webSocket.peiActiveBackendKey;

    const loadChartParams = {
      templateName: templateName
    };
    webSocketClient.loadChartToPEI(getState(), backendKey, loadChartParams);
  }
