import * as _ from 'lodash';
import DashletRestClient from "api/rest/DashletRestClient";
import {
    addDashletsToTree,
    addDashletToTree,
    removeDashletFromTree,
    selectDashlet,
    selectHeatmapHistoryDashlet,
    setDashboardModal, setDashletConfig,
    setDashletSettings, setDashletTimeRange,
    updateDashletData
} from "reducers/dashboard/dashboardSlice";
import {saveLayout} from "../dashboardActions";
import {dashboardByIdSelector} from "selectors/dashboard/DashboardSelectors";
import {extendLayout, reduceLayout} from "utils/dashboard/DashboardLayoutUtils";
import {deserializeDashletTimeRange, serializeDashletTimeRange} from "utils/dashboard/dashletTimeRangeUtils";
import {getBuildDashletObjectFunc, getDashletRefreshAction} from "dashlets/dashletsConfig";
import * as hlcDashletWebSocketClient from 'dashlets/hlcDashletWebSocketClient';
import * as webSocketClient from '../../dashlets/HLCChartDashlet/webSocketClient'
import { toastr } from 'react-redux-toastr';


// no usages for now
export const loadDashlet = dashletId => dispatch =>
    DashletRestClient.load(dashletId)
        .then(dashlet => {
            //do nothing
        })
        .catch(error => console.error(error));

export const createDashlet = dashlet => dispatch =>
    DashletRestClient.create(dashlet)
        .then(dashlet => dispatch(finishCreateDashlet(dashlet)))
        .catch(error => {
            console.error(error);
            dispatch(setDashboardModal(null));
        });

export const createDashletBatch = dashlets => dispatch =>
    DashletRestClient.createDashletBatch(dashlets)
        .then(createdDashlets => {
            
            dispatch(finishCreateDashletBatches(createdDashlets));
        })
        .catch(error => {
            console.error(error);
            dispatch(setDashboardModal(null));
        });
        
export const finishCreateDashletBatches = createdDashlets => (dispatch, getState) => {
    // Process all created dashlets in batch
    dispatch(addDashletsToTree(createdDashlets));

    let dashboardId = null;
    let newLayoutConfig = [];

    createdDashlets.forEach(dashlet => {
        dashboardId = dashlet.dashboardId;
        const currentLayoutConfig = dashboardByIdSelector(getState(), dashboardId).layoutConfig;

        if (currentLayoutConfig) {
            newLayoutConfig = extendLayout(currentLayoutConfig, dashlet);
            const dashletIds = Object.keys(getState().dashboard.dashboardTree.entities.dashlets);
            const layoutIds = newLayoutConfig.map(item => item.i);
            const differentIds = dashletIds.filter(dashletId => !layoutIds.includes(dashletId));

            differentIds.forEach(dashletId => {
                let maxY = _.max(_.map(newLayoutConfig, item => item.y)) || 0;

                const newLayoutArrayItem = {
                    x: 0,
                    y: maxY + 1,
                    i: dashletId.toString(),
                    w: 6,
                    h: 10,
                    moved: false,
                    static: false
                };

                newLayoutConfig.push(newLayoutArrayItem);
            });
        }
    });

    if (dashboardId && newLayoutConfig.length > 0) {
        dispatch(saveLayout(dashboardId, newLayoutConfig));
    }
};
        

export const finishCreateDashlet = dashlet => (dispatch, getState) => {
    // let accordionPanels = getState().dashboard.dashboardTree.entities.dashboards[dashlet.dashboardId].accordionPanels;
    // let objToUpdate = accordionPanels.find(obj => obj.id === dashlet.accordionId);
    // if (objToUpdate)
    //     objToUpdate.dashlets.push(...dashlet.id);
    dispatch(addDashletToTree(dashlet));

    //update dashboard layout
    const dashboardId = dashlet.dashboardId;
    const currentLayoutConfig = dashboardByIdSelector(getState(), dashboardId).layoutConfig;
    if (currentLayoutConfig) {
        const newLayoutConfig = extendLayout(currentLayoutConfig, dashlet);
        const dashlets = Object.keys(getState().dashboard.dashboardTree.entities.dashlets);
        const layoutIds = newLayoutConfig.map(item => item.i);
        const differentIds = dashlets.filter(dashletId => !layoutIds.includes(dashletId));
        let newLayoutArray = newLayoutConfig;
        differentIds.forEach(dashletId => {
            let maxY = _.max(_.map(newLayoutConfig, (item) => {
            return item.y;
            }));
        
            const newLayoutArrayItem = {
                x: 0,
                y: maxY ? maxY + 1 : 0,
                i: dashletId.toString(),
                w: 6,
                h: 10,
                moved: false,
                static: false
            };
        
            newLayoutArray.push(newLayoutArrayItem);
        })
        dispatch(saveLayout(dashboardId, newLayoutConfig));
    }

    //update dashlet configuration (on the db backend side)
    dispatch(saveBackendDashletConfiguration(dashlet));
    if (dashlet.type === "HEATMAP_DASHLET_TYPE") {
        let dashletConfig   = {
            config: {
                backendKey: dashlet.config.backendKey,
                connectedHeatmapDashletId: dashlet.id, 
            },
            accordionId: dashlet.accordionId,
            dashboardId: dashlet.dashboardId,
            name: dashlet.name + "- Alert History",
            timeRange: null,
            type: "HEATMAP_ALERT_HISTORY_TYPE"
        }
        dispatch(createDashlet(dashletConfig));
    } else if (dashlet.type === "HLC_CHART_DASHLET_TYPE" && dashlet.config.parentDashletId) {
        let parentDashlet = selectDashlet(getState(), dashlet.config.parentDashletId);
        if (parentDashlet) {
            const updatedParentDashlet = {
                ...parentDashlet,
                config: {
                    ...parentDashlet.config,
                    subDashlets: {
                        ...parentDashlet.config.subDashlets,
                        [dashlet.config.templateName]: dashlet.id
                    }
                }
            };
            dispatch(setDashletConfig(updatedParentDashlet.id, updatedParentDashlet.config));
            dispatch(updateDashletConfig(updatedParentDashlet.id, updatedParentDashlet.config, false));
            // dispatch(saveBackendDashletConfiguration(updatedParentDashlet));
        } 
    }
};

//no usages for now
export const updateDashlet = (dashletId, dashlet) => dispatch =>
    DashletRestClient.update(dashletId, dashlet)
        .then(dashlet => {
            dispatch(updateDashletData(dashlet))
            dispatch(refreshDashletData(dashlet));
        })
        .catch(error => console.error(error));

export const removeDashlet = dashletId => (dispatch, getState) => {
    if (dashletId) {
        const dashlet = selectDashlet(getState(), dashletId);
        if (dashlet && dashlet.config) {
            const subDashlets = dashlet.config.subDashlets;
            if (subDashlets && Object.keys(subDashlets).length != 0) {
                Object.keys(subDashlets).forEach(templateName => {
                    dispatch(removeDashlet(subDashlets[templateName]));
                })
            }
        }
        let historyDashletId = selectHeatmapHistoryDashlet(getState(), dashletId);
        if (historyDashletId && historyDashletId != undefined) 
            dispatch(removeDashlet(historyDashletId))
        DashletRestClient.delete(dashletId)
            .then(removeDashletResponse => {
                if (!removeDashletResponse.hasErrors) {
                    dispatch(finishRemoveDashlet(removeDashletResponse.backendKey, removeDashletResponse.id, removeDashletResponse.dashboardId, removeDashletResponse.dashletType))
                }
            })
            .catch(error => console.error(error));
    } 
}

export const finishRemoveDashlet = (backendKey, dashletId, dashboardId, dashletType) => (dispatch, getState) => {
    dispatch(removeDashletFromTree(dashletId));
    const currentLayoutConfig = dashboardByIdSelector(getState(), dashboardId).layoutConfig;
    if (currentLayoutConfig) {
        const newLayoutConfig = reduceLayout(currentLayoutConfig, dashletId);
        dispatch(saveLayout(dashboardId, newLayoutConfig));
    }

    if (dashletType === 'HEATMAP_DASHLET_TYPE') {
        dispatch(deleteBackendDashletConfiguration(backendKey, dashletId));
    }
};

export const saveBackendDashletConfiguration = dashlet => (dispatch, getState) => {
    const backendKey = dashlet.config.backendKey;
    const dashletType = dashlet.type;

    if (dashletType === 'HEATMAP_DASHLET_TYPE') {
        const config = _.cloneDeep(dashlet.config);
        delete config.backendKey;
        config.dashletType = dashletType;
        config.dashletId = dashlet.id;

        hlcDashletWebSocketClient.saveDashletConfiguration(getState(),
            backendKey, dashlet.id, config);

        //Wait for configuration update on the backend side. Temporary solution.
        setTimeout(() => {
            dispatch(refreshDashletData(dashlet));
        }, 2_000);
    }
}

export const deleteBackendDashletConfiguration = (backendKey, dashletId) => (dispatch, getState) =>
    hlcDashletWebSocketClient.deleteDashletConfiguration(getState(), backendKey, dashletId);

export const updateDashletConfig = (dashletId, config, isNeedToRefresh) => dispatch =>
    DashletRestClient.updateConfig(dashletId, config)
        .then(resultObject => { if (!resultObject.hasErrors) dispatch(updateDashletConfigSuccess(resultObject, isNeedToRefresh)) })
        .catch(error => console.log(error));

export const updateDashletConfigSuccess = (resultObject, isNeedToRefresh) => (dispatch, getState) => {
    const dashletId = resultObject.dashletId;
    const config = resultObject.config;
    dispatch(setDashletConfig(dashletId, JSON.parse(config)));

    const dashlet = getState().dashboard.dashboardTree.entities.dashlets[dashletId];

    //update dashlet config on the backend side
    dispatch(saveBackendDashletConfiguration(dashlet));

    if (dashlet.type !== 'HEATMAP_DASHLET_TYPE') {
        if (isNeedToRefresh)
            dispatch(refreshDashletData(dashlet));
    }
}

export const updateDashletSettings = (dashletId, settings) => dispatch =>
    DashletRestClient.updateSettings(dashletId, settings)
        .then(resulObject => dispatch(updateDashletSettingsSuccess(resulObject)))
        .catch(error => console.error(error));

export const updateDashletSettingsSuccess = (resulObject) => (dispatch, getState) => {
    const dashletId = resulObject.dashletId;
    const settings = resulObject.settings;
    dispatch(setDashletSettings(dashletId, settings));

    const dashlet = getState().dashboard.dashboardTree.entities.dashlets[dashletId];
    dispatch(refreshDashletData(dashlet));
}

export const updateDashletTimeRange = (dashletId, timeRange) => dispatch =>
    DashletRestClient.updateTimeRange(dashletId, serializeDashletTimeRange(timeRange))
        .then(resultObject => dispatch(updateDashletTimeRangeSuccess(resultObject)))
        .catch(error => console.log(error));

const updateDashletTimeRangeSuccess = resultObject => (dispatch, getState) => {
    const timeRange = deserializeDashletTimeRange(resultObject.timeRange);
    const dashletId = resultObject.dashletId;
    dispatch(setDashletTimeRange(dashletId, timeRange));

    //refresh after update time range
    const dashlet = getState().dashboard.dashboardTree.entities.dashlets[dashletId];
    dispatch(refreshDashletData(dashlet));
}

export const refreshDashletDataById = (dashletId) => {
    return (dispatch, getState) => {
        dispatch(refreshDashletData(getState().dashboard.dashboardTree.entities.dashlets[dashletId]))
    }
};

export const refreshDashletData = (dashlet) => {
    return (dispatch, getState) => {
        const refreshDashletAction = getDashletRefreshAction(dashlet.type);
        if (refreshDashletAction) {
            dispatch(refreshDashletAction(dashlet));
        } else {
            dispatch({
                type: 'TEMP_EMPTY_ACTION_TYPE'
            })
        }
    }
};

export const exportDashletData = (dashlet) => {
    return (dispatch, getState) => {
        const ExportDashletConfig = {
            targetNames: dashlet.config.targetNames ? dashlet.config.targetNames : [],
            spikeDetectionReportName: dashlet.config.spikeDetectionReportName ? dashlet.config.spikeDetectionReportName : "",
            staticTemplateName: dashlet.config.staticTemplateName ? dashlet.config.staticTemplateName : "",
            templateName: dashlet.config.templateName ? dashlet.config.templateName : "",
            backendKey: dashlet.config.backendKey ? dashlet.config.backendKey : ""
        }

        if (!dashlet.type.includes("PEI")) {
            const exportDashletStompFrame = {
                id: dashlet.id,
                config: ExportDashletConfig,
                dashletSettings: dashlet.settings,
                dashletTimeRange: serializeDashletTimeRange(dashlet.timeRange),
                name: dashlet.name,
                type: dashlet.type
            };
            toastr.info("Started dashlet exporting.")
            webSocketClient.exportDashlet(getState(), dashlet.config.backendKey, exportDashletStompFrame);
        } else {
            //TODO: Need to impliment for PEI
        }
    }
};

export const handleExportDashlet = response => (dispatch, getState) => {
    const {hasErrors, exportString, errorMessage} = response;
    if (!hasErrors) {
        toastr.success('Dashlet was exported.');
        const newTab = window.open();
        if (newTab) {
            newTab.document.write(exportString);
            newTab.document.close();
        } else {
            toastr.error("The target cannot be opened in a new tab. Please allow pop-ups on this site.");
        }
    } else {
        toastr.error(errorMessage);
    }
};
