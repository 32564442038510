import React, {FC} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as _ from 'lodash';
import FormControl from '@material-ui/core/FormControl';

export interface DashletConfigDropdownOption {
    value: string;
    label: string;
}

interface Props {
    label: string;
    name: string;
    value: string;
    options: Array<DashletConfigDropdownOption>;
    disabled?: boolean;
    error?: boolean;
    onChange?: (
        event: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => void;
}

const DashletConfigDropdown: FC<Props> = ({
                                              label,
                                              name,
                                              value,
                                              options,
                                              disabled,
                                              error,
                                              onChange
                                          }) => {
    const key = name;
    const labelId = `label-${key}`;

    return <FormControl fullWidth error={error} margin="dense">
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
            data-testid={`dashlet-config-dropdown-${name}`}
            labelId={labelId}
            id={name}
            name={name}
            value={value ? value : ''}
            onChange={onChange}
            disabled={disabled}
        >
            <MenuItem key={`menu-item-empty-${key}`} value=""></MenuItem>
            {_.map(options, (option) => {
                return (
                    <MenuItem
                        data-testid={`dashlet-config-dropdown-option-${option.value}`}
                        key={`menu-item-${option.value}`}
                        value={option.value}
                    >
                        {option.label}
                    </MenuItem>
                )
            })}
        </Select>
    </FormControl>;
};

export default DashletConfigDropdown;