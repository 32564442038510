import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import { GET_EMAIL_CONFIG_URL_PEI_SUBSCRIPTION, SAVE_EMAIL_CONFIGURATION_FOR_PEI_SUBSCRIPTION, TEST_EMAIL_CONFIG_URL_FOR_PEI_SUBSCRIPTION } from "views/gate/AddEmailConfiguration/webSocketClient";
import {selectIsPEIStompClientConnected, selectPEIStompClient} from "reducers/webSocketSlice";
import { getEmailConfigurationResult, saveEmailConfigurationResult, testEmailConfigurationResultPEI } from "actions/EmailConfigurationActions";
import WebSocketSubscription from "websocket/WebSocketSubscription";

const PEIChartBagWebSocketSubscription = (props) => {
    const wsClient = useSelector(selectPEIStompClient);
    const isWebSocketConnected = useSelector(selectIsPEIStompClientConnected);
    const dispatch = useDispatch();

    return <React.Fragment>
        {isWebSocketConnected &&
        [<WebSocketSubscription
            key="pei-ws-subscr-email-config-1582840873831"
            stompClient={wsClient}
            subscriptionDestination={GET_EMAIL_CONFIG_URL_PEI_SUBSCRIPTION}
            handleMessage={message => dispatch(getEmailConfigurationResult(message))}
        />,

        <WebSocketSubscription
            key="pei-ws-subscr-save-email-config-1582840873831"
            stompClient={wsClient}
            subscriptionDestination={SAVE_EMAIL_CONFIGURATION_FOR_PEI_SUBSCRIPTION}
            handleMessage={message => dispatch(saveEmailConfigurationResult(message))}
        />,

        <WebSocketSubscription
            key="pei-get-email-cinfiguration-1582840873831"
            stompClient={wsClient}
            subscriptionDestination={TEST_EMAIL_CONFIG_URL_FOR_PEI_SUBSCRIPTION}
            handleMessage={message => dispatch(testEmailConfigurationResultPEI(message))}
        />,]
    }
    </React.Fragment>
}

export default PEIChartBagWebSocketSubscription;