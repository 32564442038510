import Collector from "types/model/infrastructure/Collector";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {normalize} from "normalizr";
import collectorSchema from "../../schemas/infrastructure/collectorSchema";

interface SetBackendCollectorStatusPayload {
    collectorId: number;
    collectorStatus: string;
}

interface BackendCollectorsState {
    backendKey: string | null;
    entities: Record<number, Collector> | {};
    ids: number[];
    loading: boolean;
    error: string | null;
}

const initialState: BackendCollectorsState = {
    backendKey: null,
    entities: {},
    ids: [],
    loading: false,
    error: null,
};

const backendCollectorsSlice = createSlice({
    name: "backendCollectors",
    initialState,
    reducers: {
        setBackendCollectorsBackendKey(state, action: PayloadAction<string>) {
            state.backendKey = action.payload;
            state.entities = {};
            state.ids = [];
        },
        setBackendCollectorsLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setBackendCollectors(state, action: PayloadAction<Collector[]>) {
            const normalizedCollectors = normalize(action.payload, [collectorSchema]);
            // @ts-ignore
            state.entities = normalizedCollectors.entities.collectors;
            state.ids = normalizedCollectors.result;
        },
        updateBackendCollectorStatuses(state, action: PayloadAction<Record<number, string>>) {
            const statuses = action.payload;
            // @ts-ignore
            Object.keys(statuses).forEach((collectorId: number) => {
                // @ts-ignore
                if (state.entities[collectorId]) {
                    // @ts-ignore
                    state.entities[collectorId].status = statuses[collectorId];
                }
            })
        },
        setBackendCollectorStatus: {
            reducer(state, action: PayloadAction<SetBackendCollectorStatusPayload>) {
                const {collectorId, collectorStatus} = action.payload;
                // @ts-ignore
                if (state.entities[collectorId]) {
                    // @ts-ignore
                    state.entities[collectorId].status = collectorStatus;
                }
            },
            prepare(collectorId: number, collectorStatus: string) {
                return {
                    payload: {collectorId, collectorStatus},
                };
            },
        },
    }
});

export const {
    setBackendCollectorsBackendKey,
    setBackendCollectorsLoading,
    setBackendCollectors,
    setBackendCollectorStatus,
    updateBackendCollectorStatuses,
} = backendCollectorsSlice.actions;

export default backendCollectorsSlice.reducer;


export const selectBackendCollectorsBackendKey = (state: any) => state.backendCollectors.backendKey;
export const selectBackendCollectorsEntities = (state: any) => state.backendCollectors.entities;
export const selectBackendCollectorsIds = (state: any) => state.backendCollectors.ids;