import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import NavBar from "components/common/NavBar/NavBar";
import {logout} from "actions/authActions";
import {setStartApplication} from "../../reducers/uiSlice";
import { handleEmailClick } from 'actions/EmailConfigurationActions';
import { getCustomerPortalUrl } from 'actions/dashboardActions';

export const STOMP_CLIENT = {
  HLC: "HLC",
  PEI: "PEI"
};
class NavBarContainer extends Component {
  render() {
    return (
      <NavBar
        {...this.props}
      />
    );
  }
}

NavBarContainer.propTypes = {
  //data
  startPageApplication: PropTypes.string,
  currentUserInfo: PropTypes.object,

  //func
  handleLogout: PropTypes.func.isRequired,
  changeStartApplication: PropTypes.func,
  getCustomerPortalURL: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    startPageApplication: state.ui.startPageApplication,
    currentUserInfo: state.userInfo.currentUserInfo,
    isHLCConnected: state.webSocket.stompClients[STOMP_CLIENT.HLC] && state.webSocket.stompClients[STOMP_CLIENT.HLC].connected,
    isPEIconnected: state.webSocket.stompClients[STOMP_CLIENT.PEI] && state.webSocket.stompClients[STOMP_CLIENT.PEI].connected
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleLogout: () => {
      dispatch(logout());
    },
    changeStartApplication: (appKey) => {
      dispatch(setStartApplication(appKey));
    },
    handleEmailClick: () => {
      dispatch(handleEmailClick());
    },
    getCustomerPortalURL: (subscription) => {
      dispatch(getCustomerPortalUrl(subscription));
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBarContainer)