import DropdownMenu from "components/common/DropdownMenu/DropdownMenu";
import DropdownMenuItem from "components/common/DropdownMenu/DropdownMenuItem";
import LeftNavBarListItem from "components/common/LeftNavBar/LeftNavBarListItem";
import LeftNavBarListItemText from "components/common/LeftNavBar/LeftNavBarListItemText";
import DashboardItem from "components/dashboard/DashboardLeftNavBar/DashboardItem";
import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PropTypes from "prop-types";
import {ExpandLess, MoreHoriz, ChevronRight} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import * as uuid from "uuid";
import SvgIcon from "@material-ui/core/SvgIcon";

const styles = theme => ({
  root: {
    "&:hover": {
      backgroundColor: '#fff',
    }
  },
  icon: {
    minWidth: theme.spacing(4),
  },
  svgIcon: {
    color: theme.palette.text.primary,
  },

});

class DashboardGroupItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  setAnchorEl = (anchorEl) => {
    this.setState({
      anchorEl: anchorEl
    });
  };

  onClickActionsHandler = (e) => {
    e.stopPropagation();
    this.setAnchorEl(e.currentTarget);
  };

  closeActions = () => {
    this.setAnchorEl(null);
  };

  handleActionsClose = (e) => {
    e.stopPropagation();
    this.closeActions();
  };

  onClickAddDashboardHandler = (e) => {
    e.stopPropagation();
    this.props.onCreateDashboardHandler(this.props.groupData.id);
    this.closeActions();
  };

  onClickEditGroupHandler = (e) => {
    e.stopPropagation();
    this.props.onEditDashboardGroupHandler(this.props.groupData.id);
    this.closeActions();
  };

  onClickRemoveGroupHandler = (e) => {
    const {groupData, onRemoveDashboardGroupHandler} = this.props;
    e.stopPropagation();
    onRemoveDashboardGroupHandler(groupData.id, groupData.name);
    this.closeActions();
  };

  onClickGroupHandler = (e) => {
    e.stopPropagation();
    this.props.onSelectDashboardGroup(this.props.groupData.id);
  };

  renderDashboardList = () => {
    const dashboardList = this.props.groupData.dashboards;
    const dashboardEntities = this.props.dashboardEntities;
    const isOpened = this.props.groupData.isOpened;

    if (dashboardList.length < 1) {
      return null;
    }

    return (
        <Collapse in={isOpened} timeout="auto" unmountOnExit key={uuid.v4()}>
          <List component="div" disablePadding dense>
            {dashboardList.map(dashboardId => dashboardEntities[dashboardId]).map((dashboardData) => {
              return (
                  <DashboardItem
                      key={`dash-m-itm-${dashboardData.id}`}
                      dashboardData={dashboardData}
                      isActive={dashboardData.id === this.props.activeDashboardId}
                      onSelect={this.props.onSelectDashboard}
                      onRemoveDashboardHandler={this.props.onRemoveDashboardHandler}
                      onEditDashboardHandler={this.props.onEditDashboardHandler}
                      onAddDashletHandler={this.props.onAddDashletHandler}
                      onImportDashlet={this.props.onImportDashlet}
                      onRefreshDashboardHandler={this.props.onRefreshDashboardHandler}
                  />
              )
            })}
          </List>
        </Collapse>
    );
  };

  render() {
    const { classes, groupData } = this.props;
    const {anchorEl} = this.state;

    const hasDashboards = groupData.dashboards.length > 0;

    const idText = `group-actions-${groupData.id}`;
    return [
      <LeftNavBarListItem key={groupData.id} onClick={this.onClickGroupHandler}>
        <ListItemIcon className={classes.icon}>
          {hasDashboards ? (groupData.isOpened ? <ExpandLess className={classes.svgIcon} onClick={this.onClickGroupHandler} /> : <ChevronRight className={classes.svgIcon} color="primary" onClick={this.onClickGroupHandler} />) : <SvgIcon />}
        </ListItemIcon>
        <LeftNavBarListItemText primary={groupData.name} />
        <MoreHoriz
            aria-controls={idText}
            aria-haspopup="true"
            onClick={this.onClickActionsHandler}
        />
        <DropdownMenu
            anchorEl={anchorEl}
            onClose={this.handleActionsClose}
        >
          <DropdownMenuItem text="Create Dashboard" icon="add" onClick={this.onClickAddDashboardHandler}/>
          <DropdownMenuItem text="Edit Group" icon="edit" onClick={this.onClickEditGroupHandler}/>
          <DropdownMenuItem text="Delete Group" icon="delete" onClick={this.onClickRemoveGroupHandler}/>
        </DropdownMenu>
      </LeftNavBarListItem>,
      this.renderDashboardList()
    ];
  }


}

DashboardGroupItem.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  groupData: PropTypes.object,
  dashboardEntities: PropTypes.object,
  activeDashboardId: PropTypes.number,
  //...

  //func
  onRemoveDashboardGroupHandler: PropTypes.func,
  onSelectDashboard: PropTypes.func.isRequired,
  onSelectDashboardGroup: PropTypes.func.isRequired,
  onRemoveDashboardHandler: PropTypes.func.isRequired,
  onCreateDashboardHandler: PropTypes.func.isRequired,
  onEditDashboardGroupHandler: PropTypes.func.isRequired,
  onEditDashboardHandler: PropTypes.func.isRequired,
  onAddDashletHandler: PropTypes.func.isRequired,
  onRefreshDashboardHandler: PropTypes.func.isRequired,
  onImportDashlet: PropTypes.func.isRequired,
  //...
};

export default withStyles(styles)(DashboardGroupItem);