import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {Scrollbars} from 'react-custom-scrollbars';
import Typography from "@material-ui/core/Typography";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import {refresh} from "../actions";
import {useDispatch} from "react-redux";
import useCommonDashletProperties from "hooks/dashlets/useCommonDashletProperties";
import GenerateSnowflakeReportStompResponse from "../types/GenerateSnowflakeReportStompResponse";
import {SNOWFLAKE_DATABASE_REPORT_GENERATE_SUBSCRIPTION} from "../webSocketClient";
import {setDashletData} from "reducers/dashboard/dashboardSlice";
import SnowflakeDatabaseReport from "../types/report/SnowflakeDatabaseReport";
import {Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import HeaderLabel from "./atomic/HeaderLabel";
import HeaderValue from "./atomic/HeaderValue";
import HeaderTableCell from "./atomic/table/HeaderTableCell";
import SectionHeader from "./atomic/SectionHeader";
import WarehousesSection from "./sections/WarehousesSection";
import ConsumptionSection from "./sections/ConsumptionSection";
import InventorySection from "./sections/InventorySection";
import RecommendationsSection from "./sections/RecommendationsSection";


interface SnowflakeReportDashletViewProps {
    dashlet: any;
}

const SnowflakeReportDashletView: React.FC<SnowflakeReportDashletViewProps> = ({
                                                                                   dashlet
                                                                               }) => {
    const dispatch = useDispatch();
    const dashletId = dashlet.id;
    const {
        data,
        backendKey,
        stompClient,
        isStompClientConnected,
        userSessionId,
    } = useCommonDashletProperties(dashlet);

    const handleSnowflakeDatabaseReportResult = (response: GenerateSnowflakeReportStompResponse) => {
        console.log("===> Response:");
        console.log(response);
        // @ts-ignore
        dispatch(setDashletData(dashlet.id, {report: response.report}));
    }

    const report: SnowflakeDatabaseReport = data?.report;

    return (
        <>
            {report && (
                <Scrollbars style={{height: "100%"}}>
                    <WarehousesSection title="Warehouses" data={report?.warehousesSection} />
                    <ConsumptionSection title="Consumption" data={report?.consumptionSection} />
                    <InventorySection title="Warehouse Inventory" data={report?.inventorySection} />
                    <RecommendationsSection title="Warehouse Recommendations" data={report?.recommendationsSection} />
                </Scrollbars>
            )}
            {isStompClientConnected && (
                <HLCUserSessionWebSocketSubscription
                    key={`hlc-ws-subscr-snowflake-report-dashlet-${dashletId}`}
                    subscriptionDestination={SNOWFLAKE_DATABASE_REPORT_GENERATE_SUBSCRIPTION}
                    dashletId={dashletId}
                    handleMessage={handleSnowflakeDatabaseReportResult}
                    onSubscribeCallback={() => dispatch(refresh(dashlet))}
                />
            )}
        </>
    );
};

export default SnowflakeReportDashletView;
